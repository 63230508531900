import { Injectable } from '@angular/core';
import { SimpleNutritionCalculator } from '../../pages/nutrition/simple-nutrition-calculator';
import {
  ActiveLevelTypes,
  CustomMacro,
  Goal,
  NutritionPlan,
  PreferenceMacroCounting,
  Sex,
  TransphormationGoalTypes,
  Transphormer,
} from '../../../../interfaces';
import { MacroInfo } from '../../interfaces';

@Injectable()
export class MacroCalculatorService {
  /**
   * Returns value in oz of water intake
   *
   * @param currentWeight
   */
  public waterIntake(currentWeight: number) {
    if (currentWeight <= 200) {
      return 100;
    }

    return 120;
  }

  public getActivityLevelMultiplier(
    weightDifference: number,
    activityLevel: ActiveLevelTypes,
  ) {
    const multipliers = [
      [10, 11, 12], // For <60 lbs
      [11, 12, 13], // 60 - 100 lbs
      [12, 13, 14], // 100+ lbs
    ];
    const levelModifiers = {
      'Sedentary': 0,
      'Light Activity': 1,
      'Very Active': 2,
    };

    let weightDiff = 0;
    if (weightDifference > 60 && weightDifference < 100) {
      weightDiff = 1;
    } else if (weightDifference >= 100) {
      weightDiff = 2;
    }

    return multipliers[weightDiff][levelModifiers[activityLevel]];
  }

  public calculateBasicMacros(
    transphormer: Pick<
      Transphormer,
      | 'sex'
      | 'latest_weight'
      | 'transphormation_goal'
      | 'activity_level'
      | 'goal_weight_units'
      | 'preference_macro_counting'
      | 'likely_to_do'
      | 'is_paid_user'
      | 'custom_macros'
    >,
  ): MacroInfo {
    const nutritionCalculator = new SimpleNutritionCalculator(
      transphormer,
      transphormer.latest_weight.imperial.value,
    );
    return this.addMacroPercentages(nutritionCalculator.getMacros());
  }

  /**
   * This function calculates the Macros for a given user based on a number of different factors.
   *
   * It is based off of logic and spreadsheets provided by 1stPhorm.
   *
   * Note that if you are looking for a problem with Macro calculation, there is a 99.9999% chance your issue is not
   * here. This code has a test suite, and we've addressed a couple of edge cases since it was written. People
   * usually end up with extra calories because of their settings or too few as well.
   *
   * @param currentWeight
   * @param goalWeight
   * @param activityLevel
   * @param transphormationGoal
   * @param sex
   * @param macroCountingPreference
   */
  public calculateAdvancedMacros(
    currentWeight: number,
    goalWeight: number,
    activityLevel: ActiveLevelTypes,
    transphormationGoal: TransphormationGoalTypes,
    sex: Sex,
    macroCountingPreference: PreferenceMacroCounting,
  ): MacroInfo {
    const weightDifference = currentWeight - goalWeight;

    const activityLevelMultiplier = this.getActivityLevelMultiplier(
      weightDifference,
      activityLevel,
    );
    const goalMultiplier = this.getGoalMultiplier(transphormationGoal);

    const baseBmr = weightDifference > 60 ? goalWeight : currentWeight;
    const bmr = Math.round(baseBmr * activityLevelMultiplier * goalMultiplier);

    const protein = Math.round(goalWeight + (weightDifference > 100 ? 25 : 0));
    let fats, carbs, baseFatGrams, baseCarbGrams;

    // if female
    if (sex === Sex.Female) {
      baseFatGrams = 40;
      baseCarbGrams = 100;
    } else {
      baseFatGrams = 50;
      baseCarbGrams = 125;
    }

    fats = baseFatGrams + (weightDifference > 100 ? 20 : 0);
    carbs = baseCarbGrams + (weightDifference > 100 ? 25 : 0);

    if (macroCountingPreference === 'Carbs') {
      carbs = (bmr - protein * 4 - fats * 9) / 4;
    } else if (macroCountingPreference === 'Both') {
      const remainingCalories = (bmr - fats * 9 - carbs * 4 - protein * 4) / 2;
      fats += Math.ceil(remainingCalories / 9);
      carbs += Math.ceil(remainingCalories / 4);
    } else {
      fats = (bmr - protein * 4 - carbs * 4) / 9;
    }

    return this.addMacroPercentages({
      calories: bmr,
      protein,
      fats: Math.round(fats),
      carbs: Math.round(carbs),
    });
  }

  private addMacroPercentages(originalMacros: MacroInfo): MacroInfo {
    const macros = { ...originalMacros };
    macros.percentProtein = Math.round(
      ((macros.protein * 4) / macros.calories) * 100,
    );
    macros.percentCarbs = Math.round(
      ((macros.carbs * 4) / macros.calories) * 100,
    );
    macros.percentFats = Math.round(
      ((macros.fats * 9) / macros.calories) * 100,
    );
    const totalPercents =
      macros.percentProtein + macros.percentCarbs + macros.percentFats;
    if (totalPercents !== 100) {
      macros.percentProtein += 100 - totalPercents;
    }
    return macros;
  }

  private getGoalMultiplier(transphormationGoal: Goal) {
    switch (transphormationGoal) {
      case Goal.Lose:
        return 1;
      case Goal.Maintain:
        return 1.15;
      case Goal.Gain:
        return 1.5;
    }
  }

  public calculateMacros(
    transphormer: Pick<
      Transphormer,
      | 'sex'
      | 'latest_weight'
      | 'transphormation_goal'
      | 'activity_level'
      | 'goal_weight_units'
      | 'preference_macro_counting'
      | 'likely_to_do'
      | 'is_paid_user'
      | 'custom_macros'
    >,
    plan: NutritionPlan = null,
  ): MacroInfo | CustomMacro {
    if (plan === null) {
      plan = transphormer.likely_to_do;
    }

    if (
      plan !== NutritionPlan.CalorieMacroCounting ||
      !transphormer.is_paid_user
    ) {
      return this.calculateBasicMacros(transphormer);
    }

    if (
      transphormer.custom_macros &&
      !transphormer.custom_macros.reset_to_original
    ) {
      // if custom macro has not been reverted to original
      return transphormer.custom_macros;
    }

    return this.calculateAdvancedMacros(
      +transphormer.latest_weight.imperial.value,
      +transphormer.goal_weight_units.imperial.value,
      transphormer.activity_level,
      transphormer.transphormation_goal,
      transphormer.sex,
      transphormer.preference_macro_counting,
    );
  }
}
