import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import normalize from 'json-api-normalizer';
import { jsonApiUrl } from '../../../../helpers';
import { UserProfileResponse } from '../../interfaces/user-profiles';
import { JsonApiResponse } from '../../../assessments-v2/services/assessments/types';

@Injectable({
  providedIn: 'root',
})
export class UserProfilesService {
  constructor(protected http: HttpClient) {}

  getUserProfiles(profileIds: number[]): Observable<UserProfileResponse[]> {
    const filterBy = profileIds.toString();

    return this.http
      .get<JsonApiResponse<UserProfileResponse[]>>(
        jsonApiUrl(`user-profiles?filter[id]=${filterBy}`),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        map((data: JsonApiResponse<UserProfileResponse[]>) => {
          const normalizedData = normalize(data);
          return Object.values(normalizedData['userProfiles']);
        }),
        map((userProfiles: UserProfileResponse[]) => {
          const profiles = userProfiles.map(
            (userProfile: UserProfileResponse) => {
              const props = userProfile['attributes'];
              const profile: UserProfileResponse = {
                id: userProfile.id,
                ...props,
              };

              return profile;
            },
          );

          return profiles;
        }),
      );
  }
}
