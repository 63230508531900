import { Injectable } from '@angular/core';
import { Transphormer, UnitLabel, UnitTypes } from '../../interfaces';
import { Observable, ReplaySubject, timer } from 'rxjs';
import { Store } from '@ngxs/store';
import {
  SubscriptionActivated,
  SubscriptionDeactivated,
} from '../../store/actions/subscription.actions';
import { fixupTransphormer } from '../../helpers/transphormer';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../helpers';
import { catchFormError } from '../../helpers/operators';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isCurrentDay, isCurrentMonth } from '../../helpers/date';
import { StorageService } from '../storage.service';
import { differenceInDays } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _transphormer: Transphormer = null;
  public user$ = new ReplaySubject<Transphormer>(1);
  private loading = true;
  public remainingTrialDays$: Observable<number> = this.user$.pipe(
    filter(user => user && user?.is_trial_user),
    switchMap(user =>
      timer(0, 15000).pipe(
        map(() =>
          differenceInDays(new Date(user.subscription.expires_at), new Date()),
        ),
      ),
    ),
    distinctUntilChanged(),
  );
  public isTrialUser$ = this.user$.pipe(
    filter(user => !!user),
    map(user => user && user.is_trial_user),
    distinctUntilChanged(),
  );

  userSubscription$ = this.user$.pipe(
    filter(user => !!user),
    map(user => user.subscription),
    distinctUntilChanged(),
  );

  constructor(
    private storageService: StorageService,
    private store: Store,
    private platform: Platform,
    private http: HttpClient,
  ) {}

  public isCakeDay() {
    if (this.user) {
      return isCurrentDay(this.user.dob) && isCurrentMonth(this.user.dob);
    }

    return false;
  }

  private writeUserToStorage(newUser) {
    if (!newUser) {
      this.storageService.remove('transphormer');
    } else {
      this.storageService.set('transphormer', JSON.stringify(newUser));
    }
  }

  ready() {
    return new Promise(resolve => {
      const i = setInterval(() => {
        if (!this.loading) {
          clearInterval(i);
          resolve(this.user !== null);
        }
      }, 200);
    });
  }

  get user(): Transphormer | null {
    return this._transphormer;
  }

  set user(newUser: Transphormer | null) {
    if (this._transphormer !== null && newUser !== null) {
      this.takeSubscriptionAction(newUser);
    }

    this._transphormer = newUser;
    this.user$.next(newUser);
    this.writeUserToStorage(newUser);
  }

  public removeActiveTransphormer() {
    this.user = null;
  }

  /**
   Returns true if current user is paid user, else return false
   */
  public isPaidUser() {
    if (this.user) {
      return this.user.is_paid_user;
    }
    return false;
  }

  /**
   Returns true if current user has active trainer, else return false
   */
  public isTrainer() {
    return this.isPaidUser() && this.user.is_trainer;
  }

  /**
   * Returns correct label based on type of unit based on transphormer settings
   * @param type
   * @param singular
   */
  public unitTypeLabel(type: 'length' | 'weight', singular = false): UnitLabel {
    if (!this.user) {
      return type === 'length'
        ? UnitLabel.ImperialLength
        : UnitLabel.ImperialWeight;
    }

    let value;
    if (type === 'length') {
      value =
        this.user.unit_type === UnitTypes.Metric
          ? UnitLabel.MetricLength
          : UnitLabel.ImperialLength;
    } else {
      value =
        this.user.unit_type === UnitTypes.Metric
          ? UnitLabel.MetricWeight
          : singular
            ? UnitLabel.ImperialWeightSingular
            : UnitLabel.ImperialWeight;
    }

    return value;
  }

  /**
   * Returns unit type based on transphormer settings
   */
  public unitType(): UnitTypes {
    return this.user ? this.user.unit_type : UnitTypes.Imperial;
  }

  hasTrainer() {
    if (!this.user) {
      return false;
    }

    // Once the cache updates, this is the correct logic.
    return (
      this.user &&
      this.user.linked_trainer &&
      this.user.linked_trainer.status === 'accepted'
    );
  }

  public goPremium() {
    this.user = { ...this.user, is_paid_user: true };
  }

  public goUnpremium() {
    this.user = { ...this.user, is_paid_user: false };
  }

  public checkAndUpdateTZ() {
    if (!this.user) {
      return;
    }
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.user.timezone_identifier !== currentTimezone) {
      this.update({ timezone_identifier: currentTimezone }).subscribe();
    }
  }

  setup() {
    // This code has been changed because apparently we cannot trust that 1) Storage.get() will return a value
    // if the store is not ready and 2) Storage.ready() will actually fire if it's ready. It appears under some
    // circumstances that it will still .then() even if the platform.ready() hasn't fired.
    return this.platform
      .ready()
      .then(() => this.storageService.get('transphormer'))
      .catch(e => {
        // Handle any funky situations where an error occurs and just reset the user session.
        console.error(e);
        this.user = null;
        this.loading = false;
        return null;
      })
      .then(value => {
        let user;

        if (typeof value === 'string') {
          try {
            user = <Transphormer>JSON.parse(value);
          } catch (e) {
            console.error('An invalid value was provided.');
            console.error(value);
            console.error(e);
            user = null;
          }
        } else {
          user = null;
        }

        if (user && !user.id) {
          console.error('An invalid user was provided.', user);
          this.user = null;
          this.loading = false;
          return;
        }

        fixupTransphormer(user);
        this.user = user;
        this.loading = false;
      });
  }

  private takeSubscriptionAction(newUser: Transphormer) {
    if (this._transphormer.is_paid_user === newUser.is_paid_user) {
      return;
    }

    if (newUser.is_paid_user) {
      this.store.dispatch(new SubscriptionActivated());
    } else {
      this.store.dispatch(new SubscriptionDeactivated());
    }
  }

  update(data: Partial<Transphormer>) {
    return this.http.put<Transphormer>(apiUrl('profile'), data).pipe(
      catchFormError(),
      tap(() => (this.user = { ...this._transphormer, ...data })),
    );
  }
}
