import { WalkTimerModel } from './walk-timer.state';

export class LoadTimerForDate {
  static readonly type = '[Walk Timer] Load Todays Current Tracked Time';

  constructor(public date: string) {}
}

export class EditTodaysTime {
  static readonly type = '[Walk Timer] Edit Todays Current Tracked Time';

  constructor(
    public day: string,
    public time: number,
  ) {}
}

export class ImportTimers {
  static readonly type = '[Walk Timer] Import Timers';

  constructor(public timers: WalkTimerModel[]) {}
}

export class LoadNextPage {
  static readonly type = '[Walk Timer] Load Next Page';

  constructor(
    public limit: number = 15,
    public page: number,
  ) {}
}

export class PlayTimer {
  static readonly type = '[Walk Timer] Start Timer';

  constructor(
    public date: string,
    public ts = new Date(),
  ) {}
}

export class StopTimerForDay {
  static readonly type = '[Walk Timer] Pause Timer';

  constructor(
    public day: string,
    public ts = new Date(),
  ) {}
}
