import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jsonApiUrl } from '../../../helpers';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  JsonApiObject,
  JsonApiResponse,
} from '../../assessments-v2/services/assessments/types';
import {
  ReactionApiModel,
  ReactionAttributes,
  ReactionOption,
  ReactionServerAggregations,
  UserReactionPersisted,
} from '../types';
import { handleDates } from '../../assessment-common/services/questions/assessment-questions.service';
import { parseISO } from 'date-fns';

export function retrieveEmojiForValue<
  T extends Partial<ReactionAttributes> & Pick<ReactionAttributes, 'value'>,
>(r: T): T & { character: string } {
  return {
    ...r,
    character:
      baseReactions.filter(br => br.value === r.value)[0]?.character || null,
  };
}

export const baseReactions: ReactionOption[] = [
  {
    value: 'CHARACTER_FACE_WITH_TEARS_OF_JOY',
    character: '😂',
  },
  {
    value: 'CHARACTER_PLEADING_FACE',
    character: '🥺',
  },
  {
    value: 'CHARACTER_FIRE',
    character: '🔥',
  },
  {
    value: 'CHARACTER_PARTYING_FACE',
    character: '🥳',
  },
  {
    value: 'CHARACTER_THUMBS_UP',
    character: '👍',
  },
];

@Injectable({
  providedIn: 'root',
})
export class ReactionsService {
  constructor(private http: HttpClient) {}

  addReaction(
    attributes: Pick<
      ReactionAttributes,
      'value' | 'reactionable_id' | 'reactionable_type' | 'transphormer_id'
    >,
  ): Observable<UserReactionPersisted> {
    return this.http
      .post<JsonApiResponse<JsonApiObject<ReactionApiModel<string>>>>(
        jsonApiUrl('reactions'),
        {
          data: {
            type: 'reactions',
            attributes: {
              value: attributes.value,
              reactionable_id: attributes.reactionable_id,
              reactionable_type: attributes.reactionable_type,
              transphormer_id: attributes.transphormer_id,
            },
          },
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        map(r => r.data),
        map(r => ({
          id: +r.id,
          ...r.attributes,
          createdAt: parseISO(r.attributes.createdAt),
          reactionable_id: +r.attributes.reactionable_id,
        })),
      );
  }

  getReactionAggregations(
    id: number,
    type: string,
  ): Observable<ReactionServerAggregations> {
    return this.http
      .get<
        JsonApiResponse<JsonApiObject<ReactionServerAggregations<string>>[]>
      >(
        jsonApiUrl(
          `reaction-aggregations?filter[reactionable_type][]=${type}&filter[reactionable_id][]=${id}`,
        ),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        map(r => r.data),
        map(i => i.map(r => handleDates(r.attributes))),
        map(r => r[0] ?? null),
      );
  }

  getReactionsForTransphormer(
    transphormerId: number,
    id: number,
    type: string,
  ): Observable<UserReactionPersisted> {
    return this.http
      .get<
        JsonApiResponse<JsonApiObject<ReactionApiModel<string>>[]>
      >(jsonApiUrl(`reactions?filter[reactionable_type][]=${type}&filter[reactionable_id][]=${id}&filter[transphormer_id][]=${transphormerId}`))
      .pipe(
        map(r => r.data),
        map(r =>
          r.map(rs => ({
            id: +rs.id,
            ...rs.attributes,
            createdAt: parseISO(rs.attributes.createdAt),
            reactionable_id: +rs.attributes.reactionable_id,
          })),
        ),
        map(r => r[0] || null),
      );
  }

  removeReaction(reactionId: number) {
    return this.http.delete(jsonApiUrl(`reactions/${reactionId}`), {
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.api+json' }),
    });
  }
}
