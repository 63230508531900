import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NativeAudio } from '@capgo/native-audio';

interface Sound {
  key: string;
  asset: string;
}

@Injectable({
  providedIn: 'root',
})
export class SmartAudioService {
  private sounds: Sound[] = [];
  private audioPlayer: HTMLAudioElement = new Audio();
  private isNative = false;

  constructor(private platform: Platform) {
    platform.ready().then(() => {
      if (platform.is('capacitor')) {
        this.isNative = true;
      }
    });
  }

  preload(key: string, asset: string): void {
    if (!this.sounds.filter(sound => sound.key === key).length) {
      if (this.isNative) {
        this.platform
          .ready()
          .then(() => NativeAudio.preload({ assetId: key, assetPath: asset }))
          .then(() => {
            this.sounds.push({
              key: key,
              asset: asset,
            });
            console.log('Preload Worked: ', key);
          })
          .catch(err => {
            if (err.message === 'Audio Asset already exists') {
              return;
            }
          });
      } else {
        const audio = new Audio();
        audio.src = asset;
        this.sounds.push({
          key: key,
          asset: asset,
        });
      }
    }
  }

  play(key: string): boolean {
    const soundToPlay: Sound = this.sounds.find(sound => sound.key === key);

    if (soundToPlay) {
      if (this.isNative) {
        this.platform.ready().then(() =>
          NativeAudio.play({ assetId: soundToPlay.key }).then(
            res => console.log(res),
            err => console.log('play error', JSON.stringify(soundToPlay), err),
          ),
        );
      } else {
        this.audioPlayer.src = soundToPlay.asset;
        this.audioPlayer.play().catch(() => {
          // ignore web player errors
        });
      }
      return true;
    } else {
      return false;
    }
  }
}
