<ion-header>
  <ion-toolbar>
    <ion-title> Menu </ion-title>
    <ion-buttons slot="end">
      <ng-container
        *ngxLaunchDarkly="
          'menu--select-area-where-menu-displays';
          value: 'header-right'
        "
      >
        <ion-button (click)="dismiss()">
          <ion-icon name="close-outline"></ion-icon>
        </ion-button>
      </ng-container>
      <ng-container
        *ngxLaunchDarkly="
          'menu--select-area-where-menu-displays';
          value: 'bottom-nav'
        "
      >
        <app-logo></app-logo>
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="menu" *ngIf="transphormer$ | async as transphormer">
  <ion-list (click)="dismissUponItemClick($event)">
    <ion-item
      lines="none"
      [detail]="false"
      class="menu-item avatar"
      [routerDirection]="'root'"
      routerLink="info-personal"
      routerLinkActive="active"
      (click)="trackingClickOption('My Profile')"
    >
      <ion-avatar slot="start">
        <img
          [src]="transphormer?.profile_picture_url ?? defaultProfileIcon"
          alt="Profile Image"
        />
      </ion-avatar>
      <div class="menu-item-content">
        <div class="name">
          <span *ngIf="transphormer?.is_cake_day"> 🎂 </span>
          {{ transphormer?.display_name }}
          <div class="transphormer-description">
            <div>
              <small class="weight"
                >Weight: {{ transphormer?.latest_weight | convertWeight }}
                {{ user.unitTypeLabel('weight') }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <ion-chip
        class="ion-text-uppercase fw-semibold"
        slot="end"
        size="small"
        *ngIf="transphormer.is_paid_user"
        color="brand"
      >
        <ng-container *ngIf="(isTrialUser$ | async) === false">
          <ion-icon name="star"></ion-icon>
          <ion-label>Premium</ion-label>
        </ng-container>
        <ng-container *ngIf="isTrialUser$ | async">
          <ion-label>FREE TRIAL</ion-label>
        </ng-container>
      </ion-chip>
    </ion-item>

    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      (click)="goPremium()"
      *ngIf="isTrialUser$ | async"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="star-outline"
      ></ion-icon>
      <div class="fw-bold">Go Premium</div>
      <ion-badge
        mode="ios"
        class="ion-text-uppercase"
        slot="end"
        [color]="getColor(remainingTrialDays$ | async)"
      >
        <ion-label
          >{{ remainingTrialDays$ | async }} day{{
            (remainingTrialDays$ | async) === 1 ? '' : 's'
          }}</ion-label
        >
      </ion-badge>
    </ion-item>

    <ng-container *ngxLaunchDarkly="'menu--show-subscription-manage'">
      <ion-item
        *ngIf="(isTrialUser$ | async) === false"
        lines="none"
        class="menu-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="subscription-manager"
        routerLinkActive="active"
        (click)="trackingClickOption('My subscription')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="star-outline"
        ></ion-icon>
        <div class="fw-bold">My Subscription</div>
      </ion-item>
    </ng-container>

    <ion-item
      lines="none"
      class="expand menu-item"
      [detail]="false"
      (click)="collapseSettingsItem()"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="settings-outline"
      ></ion-icon>
      <div class="fw-bold">Settings</div>
      <ion-icon
        name="chevron-down"
        slot="end"
        *ngIf="isCollapsed$ | async"
      ></ion-icon>
      <ion-icon
        name="chevron-up"
        slot="end"
        *ngIf="(isCollapsed$ | async) === false"
      ></ion-icon>
    </ion-item>
    <div *ngIf="(isCollapsed$ | async) === false">
      <ion-item
        lines="none"
        class="menu-item settings-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="info-personal"
        routerLinkActive="active"
        (click)="trackingClickOption('My Profile')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="person-circle-outline"
        ></ion-icon>
        <div class="fw-bold">My Profile</div>
        <ion-button
          slot="end"
          type="icon"
          expand="full"
          fill="clear"
          class="secondary-menu-item"
        >
          <ion-icon color="dark" name="create-outline"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item settings-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="info-contact"
        routerLinkActive="active"
        (click)="trackingClickOption('Contact Info')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="at-circle-outline"
        ></ion-icon>
        <div class="fw-bold">Contact Info</div>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item settings-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="nutrition-config"
        routerLinkActive="active"
        (click)="trackingClickOption('Nutrition')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="restaurant-outline"
        ></ion-icon>
        <div class="fw-bold">Nutrition</div>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item settings-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="activity-settings"
        routerLinkActive="active"
        (click)="trackingClickOption('Activity')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="barbell-outline"
        ></ion-icon>
        <div class="fw-bold">Activity</div>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item settings-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="reminders"
        routerLinkActive="active"
        (click)="trackingClickOption('Reminders')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="alarm-outline"
        ></ion-icon>
        <div class="fw-bold">Reminders</div>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item settings-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="notifications"
        routerLinkActive="active"
        (click)="trackingClickOption('Notifications')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="notifications-outline"
        ></ion-icon>
        <div class="fw-bold">Notifications</div>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item settings-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="password-change"
        routerLinkActive="active"
        (click)="trackingClickOption('Change Password')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="lock-closed-outline"
        ></ion-icon>
        <div class="fw-bold">Change Password</div>
      </ion-item>
    </div>
    <ion-item
      *ngIf="
        !user.isTrainer() &&
        (!transphormer?.organization ||
          transphormer?.organization?.config?.can_refer)
      "
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="referrals"
      routerLinkActive="active"
      (click)="trackingClickOption('Referrals')"
    >
      <ion-icon slot="start" class="menu-item-icon" name="link"></ion-icon>
      <div class="fw-bold">Referrals</div>
    </ion-item>
    <ion-item
      *ngxLaunchDarkly="'!feed--display-feed-in-menu'"
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="announcements"
      routerLinkActive="active"
      (click)="trackingClickOption('Announcements')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="megaphone-outline"
      ></ion-icon>
      <div class="fw-bold">Announcements</div>
      <ng-container
        *ngIf="{
          count: (unreadTransphormerAnnouncements$ | async),
        } as unreadAnnouncements"
      >
        <ion-badge
          mode="ios"
          slot="end"
          color="brand"
          *ngIf="unreadAnnouncements.count > 0"
          >{{ unreadAnnouncements.count }}
        </ion-badge>
      </ng-container>
    </ion-item>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="trainer-requests"
      routerLinkActive="active"
      (click)="trackingClickOption('My Advisor')"
    >
      <ion-icon name="person-circle-outline" slot="start"></ion-icon>
      <div class="fw-bold">My Advisor</div>
    </ion-item>
    <ng-container
      *ngIf="
        user.hasTrainer() &&
        (transphormer.organization?.config?.can_upload_assessments ?? true)
      "
    >
      <ion-item
        lines="none"
        class="menu-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="assessments"
        routerLinkActive="active"
        (click)="trackingClickOption('Take Assessment')"
      >
        <ion-icon name="checkbox-outline" slot="start"></ion-icon>
        <div class="fw-bold">Take Assessment</div>
        <ng-container *ngIf="daysTillAllowed$ | async as daysTill">
          <ion-badge
            mode="ios"
            *ngIf="daysTill > 0"
            slot="end"
            class="ion-text-uppercase"
            color="secondary"
          >
            {{ daysTill }} {{ daysTill > 1 ? 'days' : 'day' }}
          </ion-badge>
        </ng-container>
      </ion-item>
    </ng-container>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="live-streaming"
      appActiveRoute
      *ngIf="
        !transphormer.organization ||
        transphormer.organization?.config?.can_view_1p_livestream
      "
      matchRoute="live-streaming"
      (click)="trackingClickOption('Live Streaming')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="play-forward-outline"
      ></ion-icon>
      <div class="fw-bold">Live Streaming</div>
    </ion-item>
    <ng-container *ngIf="this.user.user?.organization?.config?.can_use_lms">
      <ion-item
        *ngxLaunchDarkly="'lms-display-link-in-menu'"
        lines="none"
        class="menu-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="knowledge"
        routerLinkActive="active"
        (click)="trackingClickOption('Learning Center')"
      >
        <ion-icon name="book-outline" slot="start"></ion-icon>
        <div class="fw-bold">Learning Center</div>
      </ion-item>
    </ng-container>
    <ion-item
      *ngIf="stepsParticipation$ | async"
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="pedometer"
      routerLinkActive="active"
      (click)="trackingClickOption('Steps')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="walk-outline"
      ></ion-icon>
      <div class="fw-bold">Steps</div>
    </ion-item>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      *ngIf="transphormer.organization?.config?.can_upload_photos ?? true"
      [routerDirection]="'root'"
      routerLink="photo-listing"
      routerLinkActive="active"
      (click)="trackingClickOption('My Photos')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="images-outline"
      ></ion-icon>
      <div class="fw-bold">My Photos</div>
      <ion-button
        slot="end"
        type="icon"
        expand="full"
        fill="clear"
        class="secondary-menu-item"
        appActiveRoute
        matchRoute="camera"
        *appPlatform="'capacitor'"
        (click)="stopPropagationAndNavigate($event, 'camera')"
      >
        <ion-icon name="add"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="body-metrics"
      routerLinkActive="active"
      (click)="trackingClickOption('Body Metrics')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="analytics-outline"
      ></ion-icon>
      <div class="fw-bold">Body Metrics</div>
      <ion-button
        slot="end"
        type="icon"
        expand="full"
        fill="clear"
        class="secondary-menu-item"
        appActiveRoute
        matchRoute="^/body-metrics/list/weigh-ins"
        (click)="
          stopPropagationAndNavigate($event, '/body-metrics/list/weigh-ins')
        "
      >
        <ion-icon name="add"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="nutrition/custom-food"
      routerLinkActive="active"
      *ngIf="canShowFood(transphormer)"
      (click)="trackingClickOption('My Foods')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="basket-outline"
      ></ion-icon>
      <div class="fw-bold">My Foods</div>
    </ion-item>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="subscribe"
      routerLinkActive="active"
      *ngIf="!transphormer.is_paid_user"
      (click)="trackingClickOption('Go Premium')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="star-outline"
      ></ion-icon>
      <div class="fw-bold">Go Premium</div>
    </ion-item>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="products"
      routerLinkActive="active"
      (click)="trackingClickOption('1st Phorm Products')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="cart-outline"
      ></ion-icon>
      <div class="fw-bold">1st Phorm Products</div>
    </ion-item>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="help"
      routerLinkActive="active"
      (click)="trackingClickOption('Help')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="help-circle-outline"
      ></ion-icon>
      <div class="fw-bold">Help</div>
    </ion-item>
    <ng-container *ngIf="user.isTrainer()">
      <ion-list-header>Advisor Tools</ion-list-header>
      <ion-item
        lines="none"
        class="menu-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="advisor/invite"
        routerLinkActive="active"
        (click)="trackingClickOption('My Invite Url')"
      >
        <ion-icon slot="start" class="menu-item-icon" name="link"></ion-icon>
        <div class="fw-bold">My Invite Url</div>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item"
        [detail]="false"
        routerLink="advisor/my-transphormers"
        [routerDirection]="'root'"
        appActiveRoute
        routerDirection="forward"
        [matchMulti]="transphormersMatchingRoutes"
        (click)="trackingClickOption('My Transphormers')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="people-outline"
        ></ion-icon>
        <div class="fw-bold">My Transphormers</div>
      </ion-item>
      <ion-item
        *ngxLaunchDarkly="'feed--display-feed-in-menu'"
        lines="none"
        class="menu-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="advisor/user-engagement"
        routerLinkActive="active"
        (click)="trackingClickOption('User Engagement')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="options-outline"
        ></ion-icon>
        <div class="fw-bold">User Engagement</div>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="advisor/message-center"
        routerLinkActive="active"
        (click)="trackingClickOption('Message Center')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="chatbubbles-outline"
        ></ion-icon>
        <div class="fw-bold">Message Center</div>
        <ng-container
          *ngIf="{ count: (unreadAdvisorMessages$ | async) } as data"
        >
          <ion-badge
            mode="ios"
            slot="end"
            color="primary"
            *ngIf="data.count > 0"
          >
            {{ data.count >= 100 ? '99+' : data.count }}
          </ion-badge>
        </ng-container>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="advisor/announcements"
        routerLinkActive="active"
        (click)="trackingClickOption('My Announcements')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="megaphone-outline"
        ></ion-icon>
        <div class="fw-bold">My Announcements</div>
      </ion-item>
      <ion-item
        lines="none"
        class="menu-item"
        [detail]="false"
        [routerDirection]="'root'"
        routerLink="advisor/profile"
        routerLinkActive="active"
        (click)="trackingClickOption('My Advisor Profile')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="person-circle-outline"
        ></ion-icon>
        <div class="fw-bold">My Advisor Profile</div>
      </ion-item>
      <ng-container *ngIf="user.isTrainer()">
        <ion-item
          lines="none"
          class="menu-item"
          *ngxLaunchDarkly="'assessment-config-display-setting-in-menu'"
          [routerDirection]="'root'"
          routerLink="advisor/assessment-config"
          routerLinkActive="active"
          (click)="trackingClickOption('Assessment Config.')"
        >
          <ion-icon slot="start" name="settings-outline"></ion-icon>
          <div class="fw-bold">Assessment Config.</div>
        </ion-item>
      </ng-container>
      <ion-item
        lines="none"
        class="menu-item"
        [detail]="false"
        [queryParams]="{ filter: 'unread-assessments' }"
        [routerDirection]="'root'"
        routerLink="advisor/my-transphormers"
        (click)="trackingClickOption('Assessment Review')"
      >
        <ion-icon
          slot="start"
          class="menu-item-icon"
          name="reader-outline"
        ></ion-icon>
        <div class="fw-bold">Assessment Review</div>
        <ion-badge
          mode="ios"
          slot="end"
          color="brand"
          *ngIf="unreviewedAdvisorAssessments$ | async as unreadCount"
        >
          {{ unreadCount }}
        </ion-badge>
      </ion-item>
    </ng-container>
    <ion-list-header>Application</ion-list-header>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      [routerDirection]="'root'"
      routerLink="help-support"
      routerLinkActive="active"
      (click)="trackingClickOption('Support')"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="help-circle-outline"
      ></ion-icon>
      <div class="fw-bold">Support</div>
    </ion-item>
    <ion-item
      *ngxLaunchDarkly="'settings--show-theme-chooser-in-expanded-menu'"
      lines="none"
      class="menu-item"
      [detail]="false"
      (click)="trackingClickOption('Theme'); changeDarkModePreference($event)"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        [name]="darkModeIcon$ | async"
      ></ion-icon>
      <ion-text slot="end">{{ darkModeText$ | async }}</ion-text>
      <div class="fw-bold">Theme</div>
    </ion-item>
    <ion-item
      lines="none"
      class="menu-item"
      [detail]="false"
      (click)="trackingClickOption('Logout'); logout()"
    >
      <ion-icon
        slot="start"
        class="menu-item-icon"
        name="exit-outline"
        mode="md"
      ></ion-icon>
      <div class="fw-bold">Logout</div>
    </ion-item>
  </ion-list>
</ion-content>
