import { Injectable, Inject } from '@angular/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  /**
   * Create and open a URL link using AppLauncher if possible,
   * If it is not possible to open the url we register in rollbar the error and the url we are trying to open.
   * otherwise, open the URL in the browser.
   * @param url The URL to open.
   */
  async create(url: string) {
    if (!url.length) {
      this.rollbar.log('Unable to open link: URL String is empty.');
      return;
    }

    // Sometimes we pass in a whole url that has been written by
    // our checkHttp function. In such cases, we don't want to encode
    // the component.
    const urlToOpen = url.indexOf('http') === 0 ? url : encodeURIComponent(url);

    const { value } = await AppLauncher.canOpenUrl({ url: urlToOpen });

    try {
      if (!value) {
        return await this.internal(url);
      }
      return await AppLauncher.openUrl({ url: urlToOpen });
    } catch (e) {
      this.rollbar.error(`Error trying to open the url ${urlToOpen}`, e);
    }
  }

  internal(url: string) {
    return Browser.open({ url });
  }

  addFinishedListener(handler: () => void) {
    return Browser.addListener('browserFinished', handler);
  }
  addLoadedListener(handler: () => void) {
    return Browser.addListener('browserPageLoaded', handler);
  }
}
