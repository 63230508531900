import { Injectable } from '@angular/core';
import { GlobalEmitterService, GlobalEvents } from '../../../../services';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { UpdateCounters } from '../../../../store/unread/unread.actions';
import { UnreadState } from '../../../../store/unread/unread.store';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../../../helpers';
import { Platform } from '@ionic/angular';

interface UnreadCountsResponse {
  unread_transphormer_messages: number;
  unread_advisor_messages: number;
  unreviewed_advisor_assessments: number;
  unread_transphormer_announcements: number;
}

@Injectable({
  providedIn: 'root',
})
export class UnreadActionsService {
  @Select(UnreadState.total) totalUnreads$: Observable<number>;

  constructor(
    private http: HttpClient,
    private store: Store,
    public emitterService: GlobalEmitterService,
    private badge: Badge,
    private platform: Platform,
  ) {}

  async setupAppBadgeListener() {
    await this.platform.ready();

    this.totalUnreads$.subscribe(total =>
      total > 0 ? this.badge.set(total) : this.badge.clear(),
    );
  }

  public init() {
    if (this.platform.is('capacitor')) {
      this.setupAppBadgeListener();
    }

    this.emitterService
      .listen(GlobalEvents.NewTransphormerMessages)
      .subscribe(() => this.refreshCounts());
    this.emitterService
      .listen(GlobalEvents.NewAdvisorMessages)
      .subscribe(() => this.refreshCounts());
    this.emitterService
      .listen(GlobalEvents.NewAnnouncement)
      .subscribe(() => this.refreshCounts());
  }

  /**
   * Reload the message counts from the server.
   *
   * Failures are silent.
   */
  public refreshCounts() {
    this.http
      .get<UnreadCountsResponse>(apiUrl('transphormer/unread-counts'))
      .subscribe({
        next: result => {
          this.store.dispatch(
            new UpdateCounters({
              messageCount: result.unread_transphormer_messages,
              advisorMessageCount: result.unread_advisor_messages,
              announcementCount: result.unread_transphormer_announcements,
              advisorAssessmentCount: result.unreviewed_advisor_assessments,
            }),
          );
        },
        error: error => {
          console.warn('Unable to refresh counts', { error });
        },
      });
  }
}
