<ion-chip
  id="pending-messages"
  class="pending-messages"
  color="primary"
  (click)="togglePopOver()"
  *ngIf="(pendingMessageCount$ | async) > 0"
>
  <ion-icon name="hourglass-outline"></ion-icon>
  <ion-label>{{ pendingMessageCount$ | async }} pending messages</ion-label>
</ion-chip>

<ion-popover
  [isOpen]="(togglePopOver$ | async) && (pendingMessageCount$ | async) > 0"
  [showBackdrop]="false"
  trigger="pending-messages"
  [reference]="'trigger'"
  [side]="'bottom'"
  [alignment]="'end'"
  [dismissOnSelect]="true"
>
  <ng-template>
    <ion-item
      *ngFor="let pendingMessage of pendingMessageList$ | async; let i = index"
      lines="full"
    >
      <ion-icon
        (click)="cancelUpload(pendingMessage)"
        name="close-outline"
        slot="start"
      ></ion-icon>

      <ion-text (click)="goToConversation(pendingMessage)">
        {{ userProfileDisplayName(pendingMessage) }}
      </ion-text>

      <ng-container *ngIf="transferProgressList$ | async as tPl">
        <ion-progress-bar
          *ngIf="tPl.length > 0 && tPl[i]?.uploadState !== 'failed'"
          [value]="tPl[i]?.progress / 100 ?? 0"
          slot="end"
          (click)="goToConversation(pendingMessage)"
        ></ion-progress-bar>

        <ion-icon
          name="reload-outline"
          slot="end"
          (click)="retryUpload(pendingMessage)"
          *ngIf="tPl[i]?.uploadState === 'failed' ?? false"
        ></ion-icon>
      </ng-container>
    </ion-item>
  </ng-template>
</ion-popover>
