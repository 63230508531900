export enum MessageReadStatus {
  READ = 'read',
  UNREAD = 'unread',
  ALL = 'all',
  UNRESPONDED = 'unresponded',
}

export enum SortDirection {
  Descending = -1,
  Ascending = 1,
}
