import { Transphormer, Units, UnitTypes, WeighIn, Weight } from '../interfaces';
import { BodyMetric } from '../modules/body-metrics/types/body-metric';

/**
 * Converts weight primary entry log to imperial and sets imperial and metric property
 * @param weight
 */
export const mapWeightUnit = (weight: WeighIn): Weight => {
  let extraUnits;
  if (weight.unit_type === UnitTypes.Imperial) {
    extraUnits = {
      imperial: {
        value: weight.weight,
        unit: UnitTypes.Imperial,
      },
      metric: {
        value: convertPoundToKilo(weight.weight),
        unit: UnitTypes.Metric,
      },
    };
  } else {
    const value = convertKiloToPound(weight.weight);
    extraUnits = {
      imperial: {
        value,
        unit: UnitTypes.Imperial,
      },
      metric: {
        value: weight.weight,
        unit: UnitTypes.Metric,
      },
      weight: value,
      unit_type: UnitTypes.Imperial,
    };
  }

  return {
    ...weight,
    ...extraUnits,
  };
};

/**
 * Converts metric primary entry log to imperial and sets imperial and metric property
 * @param metric
 */
export const mapBodyMetricUnit = (metric: BodyMetric): BodyMetric => {
  const isImperial = metric.unit_type === UnitTypes.Imperial;
  metric.imperial = {
    value: isImperial
      ? metric.metric_value
      : convertCentiMetersToInch(metric.metric_value),
    unit: UnitTypes.Imperial,
  };
  metric.metric = {
    value: isImperial
      ? convertInchToCentiMeters(metric.metric_value)
      : metric.metric_value,
    unit: UnitTypes.Metric,
  };
  return metric;
};

/**
 * Converts metric primary entry log to imperial and sets imperial and metric property
 * @param transphormer
 */
export const mapGoalWeightUnit = <
  T extends Pick<
    Transphormer,
    | 'sex'
    | 'latest_weight'
    | 'goal_weight'
    | 'goal_weight_unit'
    | 'goal_weight_units'
  >,
>(
  transphormer: T,
): T => {
  transphormer.goal_weight_units = <Units>{};

  if (transphormer.goal_weight_unit === UnitTypes.Imperial) {
    transphormer.goal_weight_units.imperial = {
      value: transphormer.goal_weight,
      unit: UnitTypes.Imperial,
    };
    transphormer.goal_weight_units.metric = {
      value: convertPoundToKilo(transphormer.goal_weight),
      unit: UnitTypes.Metric,
    };
  } else {
    transphormer.goal_weight_units.imperial = {
      value: convertKiloToPound(transphormer.goal_weight),
      unit: UnitTypes.Imperial,
    };
    transphormer.goal_weight_units.metric = {
      value: transphormer.goal_weight,
      unit: UnitTypes.Metric,
    };
  }

  return transphormer;
};

export const convertKiloToPound = (value: number): number => {
  return value * 2.204623;
};

export const convertPoundToKilo = (value: number): number => {
  return value * 0.4535924;
};

export const convertInchToCentiMeters = (value: number): number => {
  return value * 2.54;
};

export const convertCentiMetersToInch = (value: number): number => {
  return value * 0.393;
};

export const scrubExtraZeroes = (value: string): string => {
  if (value.indexOf('.') === -1) {
    return value;
  }

  while (value.substr(-1) === '0') {
    value = value.slice(0, -1);
  }

  if (value.substr(-1) === '.') {
    value = value.slice(0, -1);
  }

  return value;
};
