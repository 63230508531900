import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { PendingMessageListEntitiesState } from './pending-message-list/pending-message-list.state';
import { ChatListEntitiesState } from './chat-list/chat-list.state';
import { LatestMessageEntitiesState } from './latest-message/latest-message.state';

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forFeature([
      PendingMessageListEntitiesState,
      ChatListEntitiesState,
      LatestMessageEntitiesState,
    ]),
  ],
})
export class ChatStateSharedModule {}
