import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  mapGoalWeightUnit,
  mapWeightUnit,
} from '../../helpers/map-unit-helper';
import { UserService } from '../user/user.service';
import {
  OnBoarding,
  StepsStatus,
  Transphormer,
  WrappedApiResponse,
} from '../../interfaces';
import { from, Observable } from 'rxjs';
import { catchFormError, handleApiError } from '../../helpers/operators';
import { map, tap } from 'rxjs/operators';
import { apiUrl } from '../../helpers';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    private localStorage: StorageService,
  ) {}

  public saveOnboardingState(data: Partial<OnBoarding>) {
    this.localStorage.set('onboarding-state', data);
  }

  public resetOnboardingState() {
    this.localStorage.remove('onboarding-state');
  }

  public getOnboardingState(): Observable<Partial<OnBoarding>> {
    return from(this.localStorage.get('onboarding-state'));
  }

  /**
   * Service to store onboarding data
   *
   * @param data
   */
  public saveOnBoard(data: OnBoarding): Observable<Transphormer> {
    return this.http
      .post<WrappedApiResponse<Transphormer>>(apiUrl('profile'), data)
      .pipe(
        handleApiError(),
        map(response => response.data),
        map(result => mapTransphormerWeights(result)),
        tap(result => this.saveTransphormer(result)),
      );
  }

  /**
   * Fetches the transphormer data
   */
  public fetchOnBoard(): Observable<Transphormer> {
    return this.http
      .get<WrappedApiResponse<Transphormer>>(apiUrl('profile?include=referral'))
      .pipe(
        handleApiError(),
        map(response => response.data),
        // test data
        // map(
        //   (data): Transphormer => ({
        //     ...data,
        //     is_trial_user: true,
        //     subscription: {
        //       active: '2024-06-04T18:20:29.000000Z',
        //       expires_at: '2024-06-09T18:20:29.000000Z',
        //       is_expired: false,
        //       platform: 'admin',
        //       subscription_id: 'app.admin.conversion_from_paid_user_override',
        //       type: 'custom',
        //     },
        //   }),
        // ),
        map(result => mapTransphormerWeights(result)),
        tap(result => this.saveTransphormer(result)),
      );
  }

  public updateOnBoardInformation(data: OnBoarding): Observable<Transphormer> {
    return this.http.put<Transphormer>(apiUrl('profile'), data).pipe(
      handleApiError(),
      // This is because I was stupid. -njv
      // map(response => response.data),
      map(result => mapTransphormerWeights(result)),
      tap(result => this.saveTransphormer(result)),
    );
  }

  // @todo Why is this separate? Why can't we just use what's above?
  public updateProfile(data: OnBoarding): Observable<Transphormer> {
    return this.http.put<Transphormer>(apiUrl('profile'), data).pipe(
      catchFormError(),
      map(result => mapTransphormerWeights(result)),
      tap(result => this.saveTransphormer(result)),
    );
  }

  public stepsOptin(): Observable<StepsStatus> {
    return this.http.post<StepsStatus>(apiUrl('steps/opt-in'), {}).pipe(
      tap(stepsStatus =>
        this.saveTransphormer({
          ...this.userService.user,
          ...stepsStatus.data,
        }),
      ),
      handleApiError(),
    );
  }

  public stepsOptout() {
    return this.http.post<StepsStatus>(apiUrl('steps/opt-out'), {}).pipe(
      tap(stepsStatus =>
        this.saveTransphormer({
          ...this.userService.user,
          ...stepsStatus.data,
        }),
      ),
      handleApiError(),
    );
  }

  public saveTransphormer(transphormer: Transphormer) {
    this.userService.user = transphormer;
  }
}

function mapTransphormerWeights(transphormer: Transphormer): Transphormer {
  if (transphormer.latest_weight) {
    transphormer.latest_weight = mapWeightUnit(transphormer.latest_weight);
  }
  if (transphormer.starting_weight) {
    transphormer.starting_weight = mapWeightUnit(transphormer.starting_weight);
  }
  transphormer = mapGoalWeightUnit(transphormer);
  return transphormer;
}
