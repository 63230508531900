import { TrainingConfig, WorkoutSessionV3 } from '../../../../interfaces';
import { RestDay } from '../../types';
import { yyyyMMdd } from '../../../../helpers/date';
import { ActivityConfig } from './activity.state';

export class LoadDay {
  static readonly type = '[Activity] Load Day';

  constructor(public date: string = yyyyMMdd(new Date())) {}
}

export class ChangeRestDay {
  static readonly type = '[Activity] Change Rest Day';

  constructor(
    public value: RestDay,
    public activeOn = new Date(),
  ) {}
}

export class DeleteTrainingConfig {
  static readonly type = '[Activity] Delete Training Config';

  constructor(public config: Partial<TrainingConfig>) {}
}

export class SetInitialOffset {
  static readonly type = '[Activity] Set Config Initial Offset';

  constructor(
    public configId: number,
    public day: number,
  ) {}
}

export class UpdateTrainingConfig {
  static readonly type = '[Activity] Update Training Config';

  constructor(public config: Partial<TrainingConfig>) {}
}

export class ActivateTrainingConfig {
  static readonly type = '[Activity] Activate Training Config';

  constructor(public config: TrainingConfig) {}
}

export class LoadTrainingConfigs {
  static readonly type = '[Activity] Load Training Configs';
}

export class LoadTrainings {
  static readonly type = '[Activity] Load Trainings';
}

export class UpdateActivityConfig {
  static readonly type = '[Activity] Update Activity Config';

  constructor(public config: Partial<ActivityConfig>) {}
}

export class CreateActivityConfig {
  static readonly type = '[Activity] Create Activity Config';

  constructor(public config: ActivityConfig) {}
}

export class SetWalkTimerGoal {
  static readonly type = '[Activity] Set Walk Timer Goal';

  constructor(
    public walk_timer_goal: number,
    public date: Date = new Date(),
  ) {}
}

export class UpdateStepsGoal {
  static readonly type = '[Activity] Set Steps Goal';

  constructor(
    public pedometer_goal_steps: number,
    public date: Date = new Date(),
  ) {}
}

export class StartTrainingProgram {
  static readonly type = '[Activity] Start Training Program';

  constructor(
    public planId: number,
    public startDate: Date = null,
  ) {
    this.startDate = startDate || new Date();
  }
}

export class RestartTrainingProgram {
  static readonly type = '[Activity] Restart Training Program';

  constructor(
    public planId: number,
    public startDate: Date = null,
  ) {
    this.startDate = startDate || new Date();
  }
}

export class UpdateDoingWorkouts {
  static readonly type = '[Activity] Update Doing Workouts';

  constructor(
    public doing_workouts: boolean,
    public date = new Date(),
  ) {}
}

export class UpdateDoingWalkTimer {
  static readonly type = '[Activity] Update Doing Walk Timer';
  public data;

  constructor(
    public doing_walk_timer: boolean,
    public date = new Date(),
  ) {}
}

export class LoadActivityConfig {
  static readonly type = '[Activity] Load Activity Config';
}

export class WorkoutSessionCreated {
  static readonly type = '[Activity] Workout Session Created';

  constructor(public session: Partial<WorkoutSessionV3>) {}
}

export class StopTrainingConfig {
  static readonly type = '[Activity] Stop Training Config';

  constructor(public config: TrainingConfig) {}
}

export class PickConfigBackUp {
  static readonly type = '[Activity] Pick Config Back Up';

  constructor(public config: TrainingConfig) {}
}

export class ScheduleTrainingPlanAfterConfig {
  static readonly type = '[Activity] Schedule Plan After Config';

  constructor(
    public trainingId: number,
    public configId: number,
  ) {}
}

export class SetDefaultRestDay {
  static readonly type = '[Activity] Set Default Rest Day';

  constructor(public day: RestDay) {}
}

export class SwapDays {
  static readonly type = '[Activity] Swap Days';

  constructor(
    public tc: TrainingConfig,
    public firstDay: number,
    public secondDay: number,
  ) {}
}
