import { Injectable } from '@angular/core';
import {
  AddUuidToWorkoutSession,
  CompleteSessionCooldown,
  CompleteSessionWarmup,
  CreateNewWorkoutSession,
  EnsureWorkoutSessionPersisted,
  LoadActivityDataBySession,
  LoadCustomWorkout,
  LoadTrainingProgram,
  LoadWorkoutById,
  LoadWorkoutSession,
  ToggleSetRepVisibility,
  ToggleWorkoutSessionCompletion,
  UpdateMovementGroup,
  UpdateMovementSetsReps,
  UpdateSessionNotes,
} from '../workouts.v3.actions';
import {
  Action,
  createSelector,
  Selector,
  SelectorOptions,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import {
  debounce,
  groupBy,
  mergeMap,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import { from, interval, Observable, Subject } from 'rxjs';
import produce, { Immutable } from 'immer';
import { SessionExistence, WorkoutsStateModel } from '../../types';
import { WorkoutsService } from '../../../../modules/training/services/workouts.service';
import { genericRetryStrategy } from '../../../../services/interceptors/automatic-retry-interceptor/automatic-retry-interceptor';
import {
  addDays,
  eachDayOfInterval,
  endOfWeek,
  format,
  isToday,
  isWithinInterval,
  startOfDay,
  startOfWeek,
  subDays,
} from 'date-fns';
import {
  ActivityDataPoint,
  Exercise,
  ProgramTypes,
  Swap,
  Training,
  TrainingLevel,
  Workout,
  WorkoutExercise,
  WorkoutExerciseData,
  WorkoutSession,
  WorkoutSessionV3,
  WorkoutSessionV3WithMetadata,
} from '../../../../interfaces';
import { handleApiError } from '../../../../helpers/operators';
import { SwapsService } from '../../services/swaps.service';
import { TrainingConfigService } from '../../services/training-config/training-config.service';
import { v1 } from 'uuid';
import {
  LoadTrainings,
  WorkoutSessionCreated,
} from '../activity/activity.actions';
import {
  addWeekAndDay,
  generateWorkoutSummaryDateFns,
  isActivityDataPointEmpty,
  isExercise,
  numberOfWorkoutDays,
} from '../functions';
import { addSetRepsToMovement } from '../v3.functions';
import { yyyyMMdd } from '../../../../helpers/date';
import {
  AddMovementSwap,
  ImportWorkoutSessions,
  LoadGlobalMovementSwaps,
  RemoveMovementSwap,
  RemoveWorkoutPhoto,
  ResetGlobalMovementSwaps,
  UpdateMovementSwap,
  UpdateWorkoutPhoto,
} from './workouts.actions';
import _ from 'lodash';

export interface WorkoutPhotoSizes {
  attachment_url_thumbnail: string;
  attachment_url_full: string;
}

export const WORKOUTS_STATE_TOKEN = new StateToken<WorkoutsStateModel>(
  'workouts',
);

export const WORKOUTS_STATE_EMPTY: WorkoutsStateModel = {
  isExerciseExpanded: {},
  isLoadingWorkout: false,
  isLoadingWorkouts: false,
  workoutSessionByDate: {},
  workoutSessions: {},
  customWorkoutId: null,
  group_scores: {},
  sessions: [],
  swap_days: [],
  workoutsById: {},
  trainingsById: {},
  trainings: [],
  trainingConfig: [],
};

function mergeWorkoutData(
  existingData: WorkoutExerciseData[],
  newData: WorkoutExerciseData,
): WorkoutExerciseData[] {
  return existingData
    .filter(
      i =>
        i.workout_session_id !== newData.workout_session_id ||
        i.exercise_group_id !== newData.exercise_group_id ||
        // We need to check for group_movement_id because in the older version of the api, we don't provide this data.
        // This will cause this to break for previous workouts.
        (i.group_movement_id &&
          i.group_movement_id !== newData.group_movement_id) ||
        i.exercise_id !== newData.exercise_id,
    )
    .concat([newData]);
}

@State<WorkoutsStateModel>({
  name: WORKOUTS_STATE_TOKEN,
  defaults: WORKOUTS_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class WorkoutsState {
  public nextValue$ = new Subject<Partial<WorkoutSessionV3>>();

  constructor(
    private workoutsService: WorkoutsService,
    private trainingService: TrainingConfigService,
    private swapsService: SwapsService,
    private store: Store,
  ) {
    this.nextValue$
      .pipe(
        groupBy(session => session.uuid),
        mergeMap(group => from(group).pipe(debounce(() => interval(2500)))),
        switchMap((workoutSession: WorkoutSessionV3) =>
          this.workoutsService.updateUuid(workoutSession).pipe(
            retryWhen(
              genericRetryStrategy({
                maxRetryAttempts: 150,
                scalingDuration: 2500,
              }),
            ),
            handleApiError(),
          ),
        ),
      )
      .subscribe();
  }

  @Selector()
  static isLoadingWorkout(state: WorkoutsStateModel) {
    return state.isLoadingWorkout;
  }

  @Selector()
  static isLoadingWorkouts(state: WorkoutsStateModel) {
    return state.isLoadingWorkouts;
  }

  @Selector()
  static workoutSessionDateIndex(state: WorkoutsStateModel) {
    return state.workoutSessionByDate;
  }

  @Selector()
  static workoutSessions(state: WorkoutsStateModel) {
    return state.workoutSessions;
  }
  @Selector()
  static swaps(state: WorkoutsStateModel) {
    return state.swap_days;
  }

  @Selector([WorkoutsState.swaps])
  @SelectorOptions({ injectContainerState: false })
  static globalSwaps(swaps: Swap[]) {
    return swaps.filter(
      s => s.workout_session_uuid === null && s.workout_session_id === null,
    );
  }

  static swapsForMovement(movement: Exercise | number) {
    const movementId = isExercise(movement) ? movement.id : movement;

    return createSelector([WorkoutsState.globalSwaps], (swaps: Swap[]) => {
      return swaps
        .filter(
          s => s.workout_session_uuid === null && s.workout_session_id === null,
        )
        .find(s => s.original_movement_id === movementId);
    });
  }

  static swapById(id: number) {
    return createSelector([WorkoutsState.swaps], (swaps: Swap[]) => {
      return swaps.find(s => s.id === id);
    });
  }

  @Selector([WorkoutsState.swaps])
  @SelectorOptions({ injectContainerState: false })
  static activeSwaps(swaps: Swap[]) {
    return swaps;
  }

  static workoutSessionById(id: string) {
    return createSelector(
      [WorkoutsState.sessions],
      (
        sessions: WorkoutSessionV3WithMetadata[],
      ): WorkoutSessionV3WithMetadata => {
        return sessions.find(s => s.uuid === id);
      },
    );
  }

  @Selector([WorkoutsState])
  static trainings(state: WorkoutsStateModel) {
    return state.trainingsById;
  }

  static trainingById(planId: number) {
    return createSelector([WorkoutsState.trainings], trainings => {
      return trainings[planId];
    });
  }

  static trainingListByIds(ids: number[]) {
    return createSelector([WorkoutsState.trainings], trainings =>
      Object.values(_.pick(trainings, ids)),
    );
  }

  static trainingsFiltered(
    filter: {
      search: string;
      level: '' | 'Beginner' | 'Intermediate' | 'Advanced';
      type: ProgramTypes;
    } = {
      search: '',
      level: '',
      type: null,
    },
  ) {
    return createSelector(
      [WorkoutsState.trainings],
      (trainings: Training[]) => {
        return Object.values(trainings)
          .filter(
            t =>
              filter.level === '' ||
              t.training_level === TrainingLevel[filter.level],
          )
          .filter(t => filter.type === null || t.program_type === filter.type)
          .filter(
            t =>
              filter.search === '' ||
              t.name.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1,
          );
      },
    );
  }

  static updateWorkout(workouts, training) {
    return workouts.map(workout => ({
      ...workout,
      training,
      ...addWeekAndDay(workout),
    }));
  }

  static swapsById(id: string) {
    return createSelector([WorkoutsState.swaps], (swaps: Swap[]): Swap[] => {
      return swaps.filter(s => s.workout_session_uuid === id);
    });
  }

  static workoutSessionByDate(date: Date) {
    return createSelector(
      [
        WorkoutsState.workoutSessions,
        WorkoutsState.workoutSessionDateIndex,
        WorkoutsState.expansionData,
      ],
      (sessions, index, expansionData): Immutable<WorkoutSession> => {
        const sessionData = sessions[index[format(date, 'yyyy-MM-dd')]];

        if (!sessionData) {
          return null;
        }

        return {
          is_swappable:
            sessionData.type !== 'temp' &&
            isWithinInterval(date, {
              start: subDays(startOfDay(new Date()), 7),
              end: addDays(startOfDay(new Date()), 7),
            }) &&
            !isToday(date) &&
            sessionData.type !== 'custom',
          ...enrichWorkoutSession(sessionData, expansionData),
        };
      },
    );
  }

  @Selector()
  static isTodayComplete(state: WorkoutsStateModel) {
    return state.workoutSessions[
      state.workoutSessionByDate[format(new Date(), 'yyyy-MM-dd')]
    ].completed;
  }

  static isWorkoutComplete(date = new Date()) {
    return createSelector([WorkoutsState], (state: WorkoutsStateModel) => {
      return state.workoutSessions[
        state.workoutSessionByDate[format(date, 'yyyy-MM-dd')]
      ].completed;
    });
  }

  static weeklySummary(start, end) {
    return createSelector(
      [WorkoutsState.workoutSessionDateIndex, WorkoutsState.workoutSessions],
      (index, sessions) => {
        return eachDayOfInterval({ start, end }).map(date => {
          const theDate = format(date, 'yyyy-MM-dd');
          if (index[theDate]) {
            return {
              completed: sessions[index[theDate]].completed,
              date: theDate,
            };
          } else {
            return {
              completed: false,
              date: theDate,
            };
          }
        });
      },
    );
  }

  @Selector([WorkoutsState])
  static workoutsById(state: WorkoutsStateModel) {
    return state.workoutsById;
  }

  @Selector([WorkoutsState])
  static activityDataByUUIDs(state: WorkoutsStateModel) {
    return state.group_scores;
  }

  @Selector([WorkoutsState])
  static customWorkout(state: WorkoutsStateModel) {
    if (state.customWorkoutId) {
      return state.workoutsById[state.customWorkoutId];
    }

    return null;
  }

  static workoutById(workoutId: number) {
    return createSelector(
      [WorkoutsState.workoutsById],
      (workouts: { [id: number]: Workout }) => {
        return workouts[workoutId] || null;
      },
    );
  }

  static activityDataByUuid(uuid: string) {
    return createSelector(
      [WorkoutsState.activityDataByUUIDs],
      (group_scores: { [id: string]: ActivityDataPoint }) => {
        return Object.values(group_scores).filter(
          i => i.workout_session_uuid === uuid,
        );
      },
    );
  }

  @Selector([WorkoutsState])
  static sessions(state: WorkoutsStateModel) {
    return state.sessions;
  }

  static workoutSessionForWorkoutAndDate(workoutId: number, date: Date) {
    const formattedDate = format(date, 'yyyy-MM-dd');
    return createSelector([WorkoutsState.sessions], sessions => {
      const matching = sessions.filter(
        s => s.workout_id === workoutId && s.workout_date === formattedDate,
      );
      return matching.pop() || null;
    });
  }

  static workoutsCompletedForDate(date: Date) {
    return createSelector([WorkoutsState.sessionsForDate(date)], sessions => {
      return (
        sessions.reduce((a, v) => {
          return a || v.completed;
        }, false) || false
      );
    });
  }

  static sessionsForDate(date: Date) {
    const formattedDate = yyyyMMdd(date);
    return createSelector([WorkoutsState.sessions], sessions => {
      return sessions.filter(s => s.workout_date === formattedDate);
    });
  }

  static currentWeekByDate(weekOfDate: Date) {
    // Get the first day of the week for the date.
    const startDate = startOfWeek(weekOfDate),
      endDate = endOfWeek(weekOfDate),
      today = new Date();

    return createSelector(
      [WorkoutsState.weeklySummary(startDate, endDate)],
      function (state) {
        return generateWorkoutSummaryDateFns(state, startDate, endDate, today);
      },
    );
  }

  @Selector()
  static expansionData(state: WorkoutsStateModel) {
    return state.isExerciseExpanded;
  }

  static isExerciseExpanded(
    sessionId: number,
    groupId: number,
    movementId: number,
  ) {
    return createSelector([WorkoutsState.expansionData], state => {
      return state[`${sessionId}:${groupId}:${movementId}`] ?? false;
    });
  }

  @Action(LoadCustomWorkout)
  loadCustomWorkout(ctx: StateContext<WorkoutsStateModel>) {
    return this.trainingService.fetchCustomWorkout().pipe(
      tap(workout => {
        ctx.setState(
          produce(draft => {
            draft.workoutsById[workout.id] = workout;
            draft.customWorkoutId = workout.id;
          }),
        );
      }),
    );
  }

  @Action(ImportWorkoutSessions)
  importWorkoutSessions(
    ctx: StateContext<WorkoutsStateModel>,
    { sessions }: ImportWorkoutSessions,
  ) {
    ctx.setState(
      produce(draft => {
        draft.sessions.push(
          ...sessions.map<WorkoutSessionV3WithMetadata>(s => ({
            ...s,
            status: SessionExistence.FOUND,
          })),
        );
        // MTST2-2857 - Since we do not full re-load sessions after they save, we need to look based on the UUID and not
        // the ID. What happens is that if we have multiple elements which do not have an ID, this will cause only one to
        // persist and will remove the others. So, don't do that.
        draft.sessions = draft.sessions.filter(
          (el, i, arr) =>
            arr.findIndex(a =>
              el.uuid ? el.uuid === a.uuid : a.id === el.id,
            ) === i,
        );
      }),
    );
  }

  @Action(ToggleSetRepVisibility)
  toggleSetRepVisibility(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId, groupId, groupMovementId }: ToggleSetRepVisibility,
  ) {
    ctx.setState(
      produce((draft: WorkoutsStateModel) => {
        const index = draft.sessions.findIndex(s => s.uuid === sessionId);
        draft.sessions[index].expansions[`${groupId}:${groupMovementId}`] = !(
          draft.sessions[index].expansions[`${groupId}:${groupMovementId}`] ??
          false
        );
      }),
    );
  }

  @Action(UpdateSessionNotes)
  updateSessionNotes(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId, notes }: UpdateSessionNotes,
  ) {
    const index = ctx.getState().sessions.findIndex(s => s.uuid === sessionId);

    ctx.setState(
      produce((draft: WorkoutsStateModel) => {
        draft.sessions[index].notes = notes;
      }),
    );

    this.nextValue$.next(ctx.getState().sessions[index]);
  }

  @Action(EnsureWorkoutSessionPersisted)
  ensureWorkoutSessionPersisted(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId }: EnsureWorkoutSessionPersisted,
  ) {
    const workoutSession = ctx
      .getState()
      .sessions.find(s => s.uuid === sessionId);

    // If we have an ID, then skip saving and just return.
    if (workoutSession.id) {
      return;
    }

    return this.workoutsService.updateUuid(workoutSession).pipe(
      tap(result => {
        const index = ctx
          .getState()
          .sessions.findIndex(s => s.uuid === sessionId);
        ctx.setState(
          produce(draft => {
            draft.sessions[index].id = result.id;
            draft.sessions[index].created_at = result.created_at;
            draft.sessions[index].updated_at = result.updated_at;
          }),
        );
      }),
    );
  }

  @Action(CreateNewWorkoutSession)
  createNewWorkoutSession(
    ctx: StateContext<WorkoutsStateModel>,
    { workoutId, date }: CreateNewWorkoutSession,
  ) {
    const session = {
      uuid: v1(),
      workout_id: workoutId,
      expansions: {},
      workout_date: format(date, 'yyyy-MM-dd'),
      workout_data: [],
      status: SessionExistence.FOUND,
    };

    ctx.setState(
      produce(draft => {
        draft.sessions.push(session);
      }),
    );

    this.store.dispatch(new WorkoutSessionCreated(session));
  }

  @Action(AddUuidToWorkoutSession)
  addUuidToWorkoutSession(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId }: AddUuidToWorkoutSession,
  ) {
    ctx.setState(
      produce(draft => {
        const index = draft.sessions.findIndex(i => i.id === sessionId);
        draft.sessions[index].uuid = v1();
        draft.sessions[index].status = SessionExistence.FOUND;
      }),
    );

    const session = ctx.getState().sessions.find(i => i.id === sessionId);

    this.store.dispatch(new WorkoutSessionCreated(session));
  }

  @Action(LoadTrainings)
  loadTrainings(ctx: StateContext<WorkoutsStateModel>) {
    return this.trainingService.fetchTrainings().pipe(
      tap(trainings => {
        ctx.setState(
          produce(draft => {
            trainings.forEach(training => {
              if (draft.trainings.indexOf(training.id) !== -1) {
                return;
              }

              draft.trainingsById[training.id] = training;
              draft.trainings.push(training.id);
            });
          }),
        );
      }),
    );
  }

  @Action(LoadActivityDataBySession)
  loadActivityDataBySession(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId }: LoadActivityDataBySession,
  ) {
    return this.workoutsService
      .getActivityDataForSession(sessionId)
      .subscribe(complexData => {
        if (!complexData) {
          return;
        }

        ctx.setState(
          produce(draft => {
            complexData.forEach(cd => {
              // const findData = complexData.findIndex(cds => cds.group_id === cd.group_id);
              draft.group_scores[cd.uuid] = cd; // [cd.group_id] = findData >= 0 ? complexData[findData] : {};
            });
          }),
        );
      });
  }

  @Action(LoadWorkoutSession)
  loadWorkoutSession(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId }: LoadWorkoutSession,
  ) {
    // Is it already in the index?
    let index = ctx.getState().sessions.findIndex(s => s.uuid === sessionId);

    // If it isn't in the index, create an initial record and update the state.
    if (index === -1) {
      ctx.setState(
        produce(draft => {
          draft.sessions.push({
            uuid: sessionId,
            status: SessionExistence.UNKNOWN,
          });
        }),
      );
    }

    // OK. Let's refresh it and get the correct index.
    index = ctx.getState().sessions.findIndex(s => s.uuid === sessionId);

    return this.workoutsService.getUuid(sessionId).pipe(
      tap(workoutSession => {
        // If the workoutSession is null and the internal status is UNKNOWN, then we don't have a copy here locally
        // and the server doesn't know what to do with it. Since we don't have an context for what date
        // the session is taking place, we need throw an exception. Or, at least, we need to let the
        // workout session that this isn't going to work.
        if (
          workoutSession === null &&
          ctx.getState().sessions[index].status === SessionExistence.UNKNOWN
        ) {
          ctx.setState(
            produce(draft => {
              draft.sessions[index].status = SessionExistence.NOT_FOUND;
            }),
          );
          return;
        }

        ctx.setState(
          produce(draft => {
            draft.sessions[index] = {
              ...draft.sessions[index],
              ...workoutSession,
              expansions: {},
              status: SessionExistence.FOUND,
            };

            // Add the workoutSession swaps to the swaps.
            if (workoutSession?.swap_days) {
              draft.swap_days.push(...workoutSession.swap_days);
              // Deduplicate.
              draft.swap_days = draft.swap_days.filter(
                (el, i, arr) => arr.findIndex(a => a.id === el.id) === i,
              );
            }
          }),
        );
      }),
    );
  }

  @Action(UpdateMovementSetsReps)
  updateMovementSetsReps(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId, workoutData }: UpdateMovementSetsReps,
  ) {
    const index = ctx.getState().sessions.findIndex(s => s.uuid === sessionId);

    ctx.setState(
      produce(draft => {
        draft.sessions[index].workout_data = mergeWorkoutData(
          draft.sessions[index].workout_data,
          workoutData as WorkoutExerciseData,
        );
      }),
    );

    this.nextValue$.next(ctx.getState().sessions[index]);
  }

  @Action(UpdateMovementGroup)
  updateMovementGroupValues(
    ctx: StateContext<WorkoutsStateModel>,
    { exerciseData }: UpdateMovementGroup,
  ) {
    if (
      isActivityDataPointEmpty(exerciseData) &&
      exerciseData.id &&
      !exerciseData?.notes.length
    ) {
      ctx.setState(
        produce(draft => {
          delete draft.group_scores[exerciseData.uuid];
        }),
      );
      return this.workoutsService.removeActivityData(exerciseData.id);
    }

    if (!exerciseData.uuid) {
      exerciseData.uuid = v1();
    }

    ctx.setState(
      produce(draft => {
        draft.group_scores[exerciseData.uuid] = exerciseData;
      }),
    );

    let updateOperation: Observable<Partial<ActivityDataPoint>>;
    if (!exerciseData?.id) {
      // Which means this has not been uploaded at all
      updateOperation = this.workoutsService.saveActivityDataForSession({
        workout_session_uuid: exerciseData.workout_session_uuid,
        group_id: exerciseData.group_id,
        ...exerciseData,
      });
    } else {
      // Which means something has already been sent
      updateOperation = this.workoutsService.updateActivityDataForSession({
        workout_session_uuid: exerciseData.workout_session_uuid,
        group_id: exerciseData.group_id,
        id: exerciseData.id,
        ...exerciseData,
      });
    }

    return updateOperation.pipe(
      tap(v => {
        ctx.setState(
          produce(draft => {
            draft.group_scores[v.uuid] = v;
          }),
        );
      }),
    );
  }

  @Action(AddMovementSwap)
  addMovementSwap(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId, originalMovementId, newMovementId }: AddMovementSwap,
  ) {
    if (typeof sessionId === 'string') {
      const sId = ctx.getState().sessions.findIndex(s => s.uuid === sessionId);
      this.nextValue$.next(ctx.getState().sessions[sId]);
    }

    return this.swapsService
      .swapMovement(sessionId, originalMovementId, newMovementId)
      .pipe(
        tap(result => {
          ctx.setState(
            produce(draft => {
              draft.swap_days.push(result);
            }),
          );
        }),
      );
  }

  @Action(RemoveMovementSwap)
  removeMovementSwap(
    ctx: StateContext<WorkoutsStateModel>,
    { swapId }: RemoveMovementSwap,
  ) {
    ctx.setState(
      produce(draft => {
        draft.swap_days = draft.swap_days.filter(swap => swap.id !== swapId);
      }),
    );

    return this.swapsService.removeSwap(swapId);
  }

  @Action(UpdateMovementSwap)
  updateMovementSwap(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId, originalMovementId, newMovementId }: UpdateMovementSwap,
  ) {
    const swap = ctx
      .getState()
      .swap_days.find(
        s =>
          s.original_movement_id === originalMovementId &&
          newMovementId === s.new_movement_id &&
          (sessionId === s.workout_session_id ||
            sessionId === s.workout_session_uuid),
      );

    // If we found it, delete then add a new one. Otherwise just create a new one. Wee!
    if (swap) {
      ctx.dispatch(new RemoveMovementSwap(swap.id)).subscribe(() => {
        ctx.dispatch(
          new AddMovementSwap(originalMovementId, newMovementId, sessionId),
        );
      });
    } else {
      ctx.dispatch(
        new AddMovementSwap(originalMovementId, newMovementId, sessionId),
      );
    }
  }

  @Action(LoadGlobalMovementSwaps)
  loadGlobalMovementSwaps(ctx: StateContext<WorkoutsStateModel>) {
    return this.swapsService.fetchSwaps().pipe(
      tap(swaps => {
        ctx.setState(
          produce(draft => {
            draft.swap_days = draft.swap_days
              .filter(
                s =>
                  s.workout_session_uuid !== null ||
                  s.workout_session_id !== null,
              )
              .concat(...swaps);
          }),
        );
      }),
    );
  }

  @Action(ResetGlobalMovementSwaps)
  resetGlobalMovementSwaps(ctx: StateContext<WorkoutsStateModel>) {
    ctx.patchState({ swap_days: [] });
  }

  @Action(LoadWorkoutById)
  loadWorkoutById(
    ctx: StateContext<WorkoutsStateModel>,
    { workoutId }: LoadWorkoutById,
  ) {
    return this.trainingService.fetchWorkout(workoutId).pipe(
      tap(workout => {
        ctx.setState(
          produce(draft => {
            draft.workoutsById[workout.id] = workout;
          }),
        );
      }),
    );
  }

  @Action(LoadTrainingProgram)
  loadTrainingProgram(
    ctx: StateContext<WorkoutsStateModel>,
    { trainingId }: LoadTrainingProgram,
  ) {
    const existingTraining = ctx.getState().trainingsById[trainingId];
    if (existingTraining) {
      const trainingKeys = existingTraining.workouts.map(w => +w.id);
      const loadedWorkoutKeys = Object.keys(ctx.getState().workoutsById);
      const unloadedWorkouts = loadedWorkoutKeys.filter(
        lw => trainingKeys.indexOf(+lw) === -1,
      );
      if (trainingKeys.length > 0 && unloadedWorkouts.length === 0) {
        return;
      }
    }
    return this.trainingService.fetchSingleTraining(trainingId).pipe(
      tap(trainingProgram => {
        ctx.setState(
          produce(draft => {
            draft.trainingsById[trainingProgram.id] = {
              ...trainingProgram,
              numberOfDays: numberOfWorkoutDays(trainingProgram.workouts),
            };
            if (draft.trainings.indexOf(trainingProgram.id) === -1) {
              draft.trainings.push(trainingProgram.id);
            }
            trainingProgram.workouts.forEach(
              workout => (draft.workoutsById[workout.id] = workout),
            );
          }),
        );
      }),
    );
  }

  @Action(ToggleWorkoutSessionCompletion)
  toggleWorkoutSessionCompletion(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionInfo }: ToggleWorkoutSessionCompletion,
  ) {
    const sId = ctx.getState().sessions.findIndex(s => s.uuid === sessionInfo);

    ctx.setState(
      produce((draft: WorkoutsStateModel) => {
        draft.sessions[sId].completed = !draft.sessions[sId].completed;
      }),
    );

    this.nextValue$.next(ctx.getState().sessions[sId]);
  }

  @Action(CompleteSessionWarmup)
  completeSessionWarmup(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId, completed }: CompleteSessionWarmup,
  ) {
    const index = ctx.getState().sessions.findIndex(s => s.uuid === sessionId);

    ctx.setState(
      produce((draft: WorkoutsStateModel) => {
        draft.sessions[index].warmup_completed = completed;
      }),
    );

    this.nextValue$.next(ctx.getState().sessions[index]);
  }

  @Action(UpdateWorkoutPhoto)
  updateWorkoutPhoto(
    ctx: StateContext<WorkoutsStateModel>,
    { uuid, photoInfo },
  ) {
    const index = ctx.getState().sessions.findIndex(s => s.uuid === uuid);

    ctx.setState(
      produce((d: WorkoutsStateModel) => {
        d.sessions[index].attachment_url_thumbnail =
          photoInfo.attachment_url_thumbnail;
        d.sessions[index].attachment_url_full = photoInfo.attachment_url_full;
      }),
    );
  }

  @Action(RemoveWorkoutPhoto)
  removeWorkoutPhoto(ctx: StateContext<WorkoutsStateModel>, { uuid }) {
    this.trainingService.removeCustomPhoto(uuid).subscribe(() => {
      const index = ctx.getState().sessions.findIndex(s => s.uuid === uuid);

      ctx.setState(
        produce((d: WorkoutsStateModel) => {
          d.sessions[index].attachment_url_thumbnail = null;
          d.sessions[index].attachment_url_full = null;
        }),
      );
    });
  }

  @Action(CompleteSessionCooldown)
  completeSessionCooldown(
    ctx: StateContext<WorkoutsStateModel>,
    { sessionId, completed }: CompleteSessionCooldown,
  ) {
    const index = ctx.getState().sessions.findIndex(s => s.uuid === sessionId);

    ctx.setState(
      produce((draft: WorkoutsStateModel) => {
        draft.sessions[index].cooldown_completed = completed;
      }),
    );

    this.nextValue$.next(ctx.getState().sessions[index]);
  }
}

const enrichWorkoutSession = (session: WorkoutSession, expandedExercises) => {
  return produce((workoutSession: WorkoutSession) => {
    // If there aren't any movements, there is nothing to do.
    if (!workoutSession.workout.exercise_groups) {
      return;
    }

    workoutSession.workout.exercise_groups =
      workoutSession.workout.exercise_groups.map(eg => {
        const exercises = eg.exercises
          .map(movement =>
            addSetRepsToMovement(movement, eg, workoutSession.workout_data),
          )
          .map(movement =>
            addMovementExpansion(
              movement,
              workoutSession.id,
              eg.id,
              expandedExercises,
            ),
          );

        return {
          ...eg,
          exercises,
          isComplete:
            eg.exercises.filter(movement => !movement.completed).length !== 0,
        };
      });
  }, session)();
};

const addMovementExpansion = (
  movement: WorkoutExercise,
  workoutSessionId,
  groupId,
  expandedExercises,
) => ({
  ...movement,
  expanded:
    expandedExercises[`${workoutSessionId}:${groupId}:${movement.id}`] ?? false,
});
