import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AdvisorService } from '../../services/advisor/advisor.service';
import { UserService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class AssessmentToUserAssessmentsGuard {
  constructor(
    private navCtrl: NavController,
    private user: UserService,
    private advisorService: AdvisorService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const assessmentId = +route.params?.id;
    return this.advisorService.getAssessment(assessmentId).pipe(
      mergeMap(assessment => {
        this.navCtrl.navigateRoot(
          `advisor/transphormer/${assessment?.transphormer?.id}/assessments`,
        );
        return of(true);
      }),
    );
  }
}
