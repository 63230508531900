import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';

import {
  NutritionixFoodDetail,
  NutritionixFoodDetailResult,
  NutritionixInstantSearchResult,
} from './types';
import { map } from 'rxjs/operators';
import {
  AltServing,
  FoodSearchResultItem,
  FoodSourceItem,
  TrackableItem,
  TrackableSourceItem,
  TrackedItem,
} from '../../interfaces';
import { FoodSourceServiceInterface } from '../food-manager/interfaces';
import { UserService } from '../../../../services';

export function apiUrl(endpoint: string) {
  return `${environment.nutritionix.url}${endpoint}`;
}

@Injectable({
  providedIn: 'root',
})
export class NutritionixService implements FoodSourceServiceInterface {
  constructor(
    protected http: HttpClient,
    protected userService: UserService,
  ) {}

  private getOpts(params?: { [param: string]: string | number | boolean }) {
    return {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'x-app-id': environment.nutritionix.appId,
        'x-app-key': environment.nutritionix.appKey,
      }),
      params: params || {},
    };
  }

  public search(searchValue): Observable<NutritionixInstantSearchResult> {
    const options = this.getOpts({
      query: searchValue,
      self: true,
      branded: true,
      common: true,
      common_general: true,
      common_grocery: true,
      common_restaurant: true,
      detailed: true,
    });
    return this.http.get<NutritionixInstantSearchResult>(
      apiUrl('search/instant'),
      options,
    );
  }

  public getTrackableItem({ source_type, source_id }, transphormerId?: number) {
    if (source_type === 'branded') {
      return this.brandedDetail(source_id).pipe(
        map(result => {
          return result.foods.length === 0
            ? null
            : this.convertBrandedFoodToTrackableItem(result.foods[0]);
        }),
      );
    } else if (source_type === 'common') {
      return this.commonDetail(source_id, transphormerId).pipe(
        map(result => {
          return result.foods.length === 0
            ? null
            : this.convertCommonFoodToTrackableItem(result.foods[0]);
        }),
      );
    }
  }

  public getTrackableBarcode(value: string): Observable<TrackableItem> {
    return this.searchBarcode(value).pipe(
      map(result => {
        return result.foods.length === 0
          ? null
          : this.convertBrandedFoodToTrackableItem(result.foods[0]);
      }),
    );
  }

  public commonDetail(
    query: string,
    transphormerId: number,
    params = {},
  ): Observable<NutritionixFoodDetailResult> {
    transphormerId = transphormerId || this.userService.user.id;
    const options = this.getOpts();
    options.headers = options.headers.set(
      'x-remote-user-id',
      transphormerId.toString(),
    );
    const body = {
      query,
      ...params,
    };

    return this.http.post<NutritionixFoodDetailResult>(
      apiUrl('natural/nutrients'),
      body,
      options,
    );
  }

  public convertDefaultNutritionValues(detail: NutritionixFoodDetail) {
    const convert = (a: number) => {
      return parseFloat(`${a}`).toFixed(2);
    };
    return {
      calories: detail.nf_calories ? convert(detail.nf_calories) : 0,
      carbs: detail.nf_total_carbohydrate
        ? convert(detail.nf_total_carbohydrate)
        : 0,
      fats: detail.nf_total_fat ? convert(detail.nf_total_fat) : 0,
      protein: detail.nf_protein ? convert(detail.nf_protein) : 0,
      fiber: detail.nf_dietary_fiber ? convert(detail.nf_dietary_fiber) : 0,
    };
  }

  private convertBrandedFoodToTrackableItem(
    detail: NutritionixFoodDetail,
  ): TrackableItem {
    const foodItem = this.convertCommonFoodToTrackableItem(detail);

    return {
      ...foodItem,
      source_type: 'branded',
      source_id: detail.nix_item_id,
    };
  }

  private convertCommonFoodToTrackableItem(
    detail: NutritionixFoodDetail,
  ): TrackableItem {
    detail.serving_qty = Math.round(100 * detail.serving_qty) / 100;

    return <TrackableItem>{
      source: 'nutritionix',
      source_type: 'common',
      source_id: detail.food_name,

      name: detail.food_name,
      brand_name: !!detail.nix_brand_name ? detail.nix_brand_name : '',
      thumbnail: detail.photo ? detail.photo.thumb : '',

      default_consumption_value: {
        unit: detail.serving_unit,
        amount: detail.serving_qty,
      },
      serving_size: {
        ...this.convertDefaultNutritionValues(detail),
        unit: detail.serving_unit,
        amount: detail.serving_qty,
      },
      amount_consumed: {
        unit: detail.serving_unit,
      },
      servings: this.generateServings(detail),
    };
  }

  public generateServings(data: NutritionixFoodDetail): AltServing[] {
    // Some values don't have this.
    data.alt_measures = data.alt_measures || [];

    const altServing = [];

    // If the values already exists, then let's go ahead and use it.
    if (data.alt_measures.length > 0) {
      altServing.push(...data.alt_measures);
    }

    const hasPrimaryServingInfo = altServing.find(
      serving => serving.measure === data.serving_unit,
    );

    if (!hasPrimaryServingInfo) {
      altServing.push({
        qty: data.serving_qty || 1,
        measure: data.serving_unit,
        serving_weight: data.serving_weight_grams || 1,
        seq: 0,
      });
    }

    return altServing;
  }

  public brandedDetail(
    nix_item_id: string,
  ): Observable<NutritionixFoodDetailResult> {
    const options = this.getOpts({
      nix_item_id,
    });

    return this.http.get<NutritionixFoodDetailResult>(
      apiUrl('search/item'),
      options,
    );
  }

  public searchBarcode(upc: string): Observable<NutritionixFoodDetailResult> {
    const options = this.getOpts();
    options.params = {
      upc,
      claims: false,
    };
    return this.http.get<NutritionixFoodDetailResult>(
      apiUrl('search/item'),
      options,
    );
  }

  afterTrack(_item: TrackedItem): void {
    //
  }

  defaultResults(): Observable<TrackableSourceItem[]> {
    return EMPTY;
  }

  getSearchDetails(_item: FoodSourceItem): Observable<TrackableSourceItem[]> {
    return;
  }

  multiSearch(
    terms: string[],
    transphormerId: number = null,
  ): Observable<TrackableItem[]> {
    return this.commonDetail(
      terms.join(', '),
      transphormerId || this.userService.user.id,
      { use_branded_foods: true },
    ).pipe(
      map(({ foods }) =>
        foods.map<TrackableItem>(csr =>
          this.convertCommonFoodToTrackableItem(csr),
        ),
      ),
    );
  }

  searchForTerm(term: string, options?): Observable<FoodSearchResultItem[]> {
    if (options.barcode) {
      // return this.searchBarcode(term)
      //   .pipe();
      return EMPTY;
    }

    return this.search(term).pipe(
      map<NutritionixInstantSearchResult, FoodSearchResultItem[]>(response => {
        const commonSearchResults = response.common.map<FoodSearchResultItem>(
          csr => {
            return {
              type: 'common',
              id: csr.food_name,
              data: csr,
            };
          },
        );
        const brandedSearchResults = response.branded.map<FoodSearchResultItem>(
          bsr => {
            return {
              type: 'branded',
              id: bsr.nix_item_id,
              data: bsr,
            };
          },
        );

        return [...commonSearchResults, ...brandedSearchResults];
      }),
    );
  }
}
