import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ProductButtonDetails } from '../../onboarding-modals/subscribe-v2/subscribe-v2.component';

@Component({
  selector: 'app-product-button',
  template: `
    <div class="product" (click)="choose()" *ngIf="buttonText">
      <ion-button fill="{{ selected ? 'solid' : 'outline' }}">{{
        buttonText.title
      }}</ion-button>
      <div class="billed fw-semibold">{{ buttonText.subtitle }}</div>
    </div>
  `,
  styles: [
    `
      .product {
        text-align: center;
      }
    `,
    `
      ion-button {
        display: block;
        text-transform: uppercase;
      }
    `,
    `
      .billed {
        vertical-align: top;
        color: var(--ion-color-text-secondary);
        font-size: calc(var(--default-font-size) * 0.7);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductButtonComponent {
  @Input() selected = false;
  @Input() buttonText: ProductButtonDetails = null;
  @Input() disabled = false;

  @Output() selectEmitter = new EventEmitter<null>();

  choose() {
    if (this.disabled) return;
    this.selectEmitter.emit();
  }
}
