import Pusher from 'pusher-js';
import { InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';
import { apiUrl } from '../helpers';
import { TokenService } from './token/token.service';

export const PusherUnauthenticated = new InjectionToken<Pusher>(
  'pusher-public',
);
export const PusherAuthenticated = new InjectionToken<Pusher>(
  'pusher-authenticated',
);

export function PusherUnauthenticatedFactory() {
  return new Pusher(environment.pusher.key, {
    cluster: environment.pusher.cluster,
    host: environment.pusher.host,
    encrypted: true,
  });
}

export function PusherAuthenticatedFactory(tokenService: TokenService) {
  const access_token = tokenService.get();

  return new Pusher(environment.pusher.key, {
    cluster: environment.pusher.cluster,
    host: environment.pusher.host,
    encrypted: true,
    authEndpoint: apiUrl('broadcasting/auth'),
    auth: {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    },
  });
}
