import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FueComponent } from './components/fue/fue.component';
import {
  AssessmentComponent,
  BodyMetricsComponent,
  ChatComponent,
  LeaderboardComponent,
  NutritionComponent,
  PhotosComponent,
  WeighInComponent,
  WorkoutComponent,
} from './components';
import { SexDirectiveModule } from '../../directives/sex-directive.module';
import { WeekInReviewComponent } from './components';
import { PlatformDirectiveModule } from '../../directives/platform-directive.module';
import { DailyTaskComponent } from './components/daily-task/daily-task.component';
import { WearablesComponent } from './components/wearables/wearables.component';

@NgModule({
  declarations: [
    WorkoutComponent,
    WeekInReviewComponent,
    WearablesComponent,
    WeighInComponent,
    NutritionComponent,
    AssessmentComponent,
    PhotosComponent,
    ChatComponent,
    BodyMetricsComponent,
    LeaderboardComponent,
    DailyTaskComponent,
    FueComponent,
  ],
  imports: [
    SexDirectiveModule,
    CommonModule,
    IonicModule,
    PlatformDirectiveModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FueModule {}
