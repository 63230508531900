import {
  differenceInDays,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  parse,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { SimpleNutritionResponse } from '../modules/nutrition/interfaces';
import { HealthAggregationBucket } from '../modules/health/types';

export const getDate = (date: string): Date =>
  parse(date, 'yyyy-MM-dd', new Date());
export const todaysDate = () => startOfDay(new Date());
export const isCurrentDay = (date: string): boolean =>
  yyyyMMdd(new Date()).split('-')[2] === date.split('-')[2];
export const isCurrentMonth = (date: string): boolean =>
  yyyyMMdd(new Date()).split('-')[1] === date.split('-')[1];
export const dateBeforeToday = (date: string) =>
  isBefore(getDate(date), todaysDate());
export const hasSimpleNutritionData = (data: SimpleNutritionResponse) =>
  data.data &&
  data.data.nutrition &&
  Array.isArray(data.data.nutrition) &&
  data.data.nutrition.length > 0;
export const hasMoreSimpleNutrition = (data: SimpleNutritionResponse, meals) =>
  data.data.nutrition.length > meals;
export const hasLessSimpleNutrition = (data: SimpleNutritionResponse, meals) =>
  data.data.nutrition.length < meals;

/** Is the first date before or the same as the second one? */
export const isSameDayOrBefore = (firstDate: Date, secondDate: Date): boolean =>
  isSameDay(firstDate, secondDate) || isBefore(firstDate, secondDate);
export const isSameDayOrAfter = (firstDate: Date, secondDate: Date): boolean =>
  isSameDay(firstDate, secondDate) || isAfter(firstDate, secondDate);

export const isSameOrBefore = (firstDate: Date, secondDate: Date): boolean =>
  isEqual(firstDate, secondDate) || isBefore(firstDate, secondDate);
export const isSameOrAfter = (firstDate: Date, secondDate: Date): boolean =>
  isEqual(firstDate, secondDate) || isAfter(firstDate, secondDate);

export const isBetweenDays = (
  firstDate: Date,
  secondDate: Date,
  thirdDate: Date,
) =>
  isSameDayOrAfter(firstDate, secondDate) &&
  isSameDayOrBefore(firstDate, thirdDate);
export const isBetweenInclusive = (
  firstDate: Date,
  secondDate: Date,
  thirdDate: Date,
) =>
  isSameOrAfter(firstDate, secondDate) && isSameOrBefore(firstDate, thirdDate);
export const isBetweenExclusive = (
  firstDate: Date,
  secondDate: Date,
  thirdDate: Date,
) => isAfter(firstDate, secondDate) && isBefore(firstDate, thirdDate);

/**
 * Apparently this is in date-fns 2.28 but it's here for now.
 * @param start
 * @param now
 */
export const weekForDate = (start, now) => {
  return Math.floor(differenceInDays(now, start) / 7);
};

export const yyyyMMdd = date => format(date, 'yyyy-MM-dd');
export const _yyyyMMdd = date => parse(date, 'yyyy-MM-dd', new Date());
export const formatIsoUserTz = date =>
  formatTz(date, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

export function startOf(date, unit?: HealthAggregationBucket): Date {
  if (!unit) {
    unit = date;
    date = new Date();
  }
  if (unit === 'hour') {
    return startOfHour(date);
  }
  if (unit === 'day') {
    return startOfDay(date);
  }
  if (unit === 'week') {
    return startOfWeek(date);
  }
  if (unit === 'month') {
    return startOfMonth(date);
  }
  if (unit === 'year') {
    return startOfYear(date);
  }
}

export function endOf(date, unit?: HealthAggregationBucket): Date {
  if (!unit) {
    unit = date;
    date = new Date();
  }
  if (unit === 'hour') {
    return endOfHour(date);
  }
  if (unit === 'day') {
    return endOfDay(date);
  }
  if (unit === 'week') {
    return endOfWeek(date);
  }
  if (unit === 'month') {
    return endOfMonth(date);
  }
  if (unit === 'year') {
    return endOfYear(date);
  }
}
