import { Injectable } from '@angular/core';
import { apiUrl, jsonApiUrl } from '../../../../helpers';
import { handleApiError } from '../../../../helpers/operators';
import { HttpClient } from '@angular/common/http';
import {
  ApiData,
  ModificationData,
  Training,
  TrainingConfig,
  TrainingConfigApi,
  Workout,
  WrappedApiResponse,
} from '../../../../interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityConfig, Day } from '../../state/activity/activity.state';
import produce from 'immer';
import { v1 } from 'uuid';
import { OffsetRecord, RestDayRecord, SwapDayRecord } from '../../types';
import { _yyyyMMdd, yyyyMMdd } from '../../../../helpers/date';
import { JsonApiResponse } from '../../../assessments-v2/services/assessments/types';

const v28 = (url: string) => apiUrl(url, 'v2.8');

function handleModifications(
  modifications: ModificationData<string>,
): ModificationData {
  return {
    swap_days: handleSwaps(modifications?.swap_days || []),
    rest_days: handleRestDays(modifications?.rest_days || []),
    offsets: handleOffsets(modifications?.offsets || []),
  };
}

function handleOffsets(
  offsets: OffsetRecord<string>[] | null | undefined,
): OffsetRecord[] {
  if (!Array.isArray(offsets)) {
    return [];
  }

  return offsets.map(rd => ({
    ...rd,
    recorded: !!rd.recorded ? new Date(rd.recorded) : undefined,
  }));
}

function handleRestDays(
  restDays: RestDayRecord<string>[] | null | undefined,
): RestDayRecord[] {
  if (!Array.isArray(restDays)) {
    return [];
  }

  return restDays.map(rd => ({
    ...rd,
    start_date: !!rd.start_date ? new Date(rd.start_date) : null,
  }));
}

function handleSwaps(
  swaps: SwapDayRecord<string>[] | null | undefined,
): SwapDayRecord[] {
  if (!Array.isArray(swaps)) {
    return [];
  }

  return swaps.map(rd => ({
    ...rd,
    recorded: new Date(rd.recorded),
  }));
}

@Injectable({
  providedIn: 'root',
})
export class TrainingConfigService {
  constructor(private http: HttpClient) {}

  public fetchActivityDay(day: string) {
    const url = apiUrl('activity/day', 'v2.8') + '?date=' + day;
    return this.http.get<ApiData<Day<string>>>(url).pipe(
      handleApiError(),
      map(r => r.data),
      map(r => ({
        ...r,
        trainingConfig: {
          ...r.trainingConfig,
          modifications: handleModifications(r.trainingConfig.modifications),
          ...this.handleDates(r.trainingConfig),
        },
        activityConfig: {
          ...r.activityConfig,
          ...this.handleActivityDates(r.activityConfig),
        },
      })),
      map(r =>
        produce(r, d => {
          d.workoutSessions = d.workoutSessions.map(ws => ({
            ...ws,
            uuid: ws.uuid || v1(),
          }));
        }),
      ),
    );
  }

  public removeCustomPhoto(uuid: string): Observable<void> {
    return this.http.delete<void>(
      apiUrl(`workout-session/${uuid}/attachment`, 'v2.8'),
    );
  }

  private handleDates(
    tc: Pick<TrainingConfigApi, 'start_date' | 'end_date'>,
  ): Pick<TrainingConfig, 'start_date' | 'end_date'> {
    return {
      start_date: tc.start_date ? _yyyyMMdd(tc.start_date.substr(0, 10)) : null,
      end_date: tc.end_date ? _yyyyMMdd(tc.end_date.substr(0, 10)) : null,
    };
  }

  private handleActivityDates(
    tc: Pick<ActivityConfig<string>, 'valid_from' | 'created_at'>,
  ): Pick<ActivityConfig, 'valid_from' | 'created_at'> {
    return {
      valid_from: tc.valid_from ? _yyyyMMdd(tc.valid_from.substr(0, 10)) : null,
      created_at: tc.created_at ? new Date(tc.created_at) : null,
    };
  }

  public fetchCustomWorkout() {
    return this.http.get<ApiData<Workout>>(v28('workouts-by-name/custom')).pipe(
      handleApiError(),
      map(r => r.data),
    );
  }

  public update(data: Partial<TrainingConfig>): Observable<TrainingConfig> {
    return this.http
      .put<ApiData<TrainingConfigApi>>(
        apiUrl(`training-config/${data.id}?include=training`, 'v2.8'),
        {
          ...data,
          start_date:
            data.start_date !== undefined
              ? data.start_date !== null
                ? yyyyMMdd(data.start_date)
                : null
              : undefined,
          end_date:
            data.end_date !== undefined
              ? data.end_date !== null
                ? yyyyMMdd(data.end_date)
                : null
              : undefined,
        },
      )
      .pipe(
        map(r => r.data),
        map<TrainingConfigApi, TrainingConfig>(r => ({
          ...r,
          modifications: handleModifications(r.modifications),
          ...this.handleDates(r),
        })),
        handleApiError(),
      );
  }

  public store(data: Partial<TrainingConfig>): Observable<TrainingConfig> {
    return this.http
      .post<ApiData<TrainingConfigApi>>(
        apiUrl('training-config?include=training', 'v2.8'),
        {
          ...data,
          start_date: data.start_date ? yyyyMMdd(data.start_date) : undefined,
          end_date: data.end_date ? yyyyMMdd(data.end_date) : undefined,
        },
      )
      .pipe(
        map(r => r.data),
        map<TrainingConfigApi, TrainingConfig>(r => ({
          ...r,
          modifications: handleModifications(r.modifications),
          ...this.handleDates(r),
        })),
        handleApiError(),
      );
  }

  public delete(trainingId: number) {
    return this.http
      .delete(apiUrl(`training-config/${trainingId}`, 'v2.8'))
      .pipe(handleApiError());
  }

  public fetchWorkout(workoutId: string | number): Observable<Workout> {
    return this.http
      .get<
        WrappedApiResponse<Workout>
      >(apiUrl(`workout/${workoutId}?include=exercise_groups,exercise_groups.exercises,exercise_groups.exercises.exercise`, 'v2.8'))
      .pipe(
        map(r => r.data),
        handleApiError(),
      );
  }

  public fetchConfigs(): Observable<TrainingConfig[]> {
    return this.http
      .get<
        WrappedApiResponse<TrainingConfigApi[]>
      >(apiUrl('training-config?include=training', 'v2.8'))
      .pipe(
        handleApiError(),
        map(response => response.data),
        map(data =>
          data.map(r => ({
            ...r,
            modifications: handleModifications(r.modifications),
            ...this.handleDates(r),
          })),
        ),
      );
  }

  public fetchTrainings() {
    return this.http
      .get<WrappedApiResponse<Training[]>>(apiUrl('training', 'v2.8'))
      .pipe(
        map(r =>
          r.data.map(i => ({
            ...i,
            numberOfDays: i.length || 56,
          })),
        ),
        handleApiError(),
      );
  }

  public createActivityConfig(ac: ActivityConfig) {
    return this.http
      .post<ApiData<ActivityConfig>>(apiUrl(`activity-config`, 'v2.8'), {
        ...ac,
        valid_from: ac.valid_from ? yyyyMMdd(ac.valid_from) : undefined,
      })
      .pipe(
        map(r => r.data),
        handleApiError(),
      );
  }

  public updateActivityConfig(ac: ActivityConfig) {
    return this.http
      .put<ApiData<ActivityConfig>>(
        apiUrl(`activity-config/${ac.uuid}`, 'v2.8'),
        {
          ...ac,
          valid_from: ac.valid_from ? yyyyMMdd(ac.valid_from) : undefined,
        },
      )
      .pipe(
        map(r => r.data),
        handleApiError(),
      );
  }

  public fetchTransphormerTraining(transphormerId: number) {
    return this.http.get<JsonApiResponse<Training[]>>(
      jsonApiUrl(
        `transphormers/${transphormerId}?include=training_configs.training`,
      ),
    );
  }

  public fetchSingleTraining(trainingId: number) {
    return this.http
      .get<
        WrappedApiResponse<Training & { workouts: Workout[] }>
      >(apiUrl(`training/${trainingId}?include=workouts,workouts.exercise_groups.exercises`, 'v2.8'))
      .pipe(
        map(r => r.data),
        handleApiError(),
      );
  }
}
