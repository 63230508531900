import { NutritionPlan, Transphormer } from '../../interfaces';

export function activeNutritionForUser(user: Transphormer) {
  if (user.is_paid_user) {
    return user.likely_to_do;
  }
  if (user.likely_to_do === NutritionPlan.CalorieMacroCounting) {
    return NutritionPlan.PortionControl;
  }
  return user.likely_to_do || NutritionPlan.PortionControl;
}

// Shamelessly stolen from https://awik.io/determine-color-bright-dark-using-javascript/.
export function lightOrDark(color: string): 'light' | 'dark' {
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const colors = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    );

    r = colors[1];
    g = colors[2];
    b = colors[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    const colorRgb = +(
      '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
    );

    /* tslint:disable:no-bitwise */
    r = colorRgb >> 16;
    g = (colorRgb >> 8) & 255;
    b = colorRgb & 255;
    /* tslint:enable:no-bitwise */
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  // actually, I changed this to be closer to HSV because it tends to favor
  // lighter contrast color.
  const hsp = 0.299 * r + 0.587 * g + 0.114 * b;

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 128) {
    return 'light';
  } else {
    return 'dark';
  }
}

export function lightDark(c: string): string {
  if (lightOrDark(c) === 'dark') {
    return 'var(--ion-color-light)';
  }

  return 'var(--ion-color-dark)';
}

export function lightDarkHex(c: string): string {
  if (lightOrDark(c) === 'dark') {
    return '#ffffff';
  }

  return '#000000';
}

export function hexToRgb(hex: string) {
  // Check for correct format
  if (/^#[0-9A-F]{6}$/i.test(hex)) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;
  } else {
    throw new Error('Invalid format of hex color');
  }
}

// Helper function to calculate darker color
export function darker(color, amount) {
  const num = parseInt(color, 16);
  const r = Math.max(0, (num >> 16) - amount);
  const b = Math.max(0, ((num >> 8) & 0x00ff) - amount);
  const g = Math.max(0, (num & 0x0000ff) - amount);
  return '#' + (g | (b << 8) | (r << 16)).toString(16);
}

// Helper function to calculate lighter color
export function lighter(color, amount) {
  const num = parseInt(color, 16);
  const r = Math.min(255, (num >> 16) + amount);
  const b = Math.min(255, ((num >> 8) & 0x00ff) + amount);
  const g = Math.min(255, (num & 0x0000ff) + amount);
  return '#' + (g | (b << 8) | (r << 16)).toString(16);
}

// Function to produce shade and tint
export function shadeAndTint(hexColor) {
  // Remove the '#' from the beginning of the hex color string
  const color = hexColor.slice(1);

  // Calculate shade and tint
  const shade = darker(color, 20);
  const tint = lighter(color, 10);

  // Return shade and tint
  return { shade, tint };
}

export function setDocumentColorVariable(property, value) {
  document.documentElement.style.setProperty(property, value);
}

export function unsetDocumentColorVariable(property) {
  document.documentElement.style.removeProperty(property);
}

export function createDocumentThemeColor(name, color) {
  const { shade, tint } = shadeAndTint(color);
  // Override the primary colors with medium:
  setDocumentColorVariable(`--ion-color-${name}`, color);
  setDocumentColorVariable(`--ion-color-${name}-rgb`, hexToRgb(color));
  setDocumentColorVariable(`--ion-color-${name}-contrast`, lightDarkHex(color));
  setDocumentColorVariable(
    `--ion-color-${name}-contrast-rgb`,
    hexToRgb(lightDarkHex(color)),
  );
  setDocumentColorVariable(`--ion-color-${name}-shade`, shade);
  setDocumentColorVariable(`--ion-color-${name}-tint`, tint);
}

export function unsetDocumentTheme(name) {
  // Override the primary colors with medium:
  unsetDocumentColorVariable(`--ion-color-${name}`);
  unsetDocumentColorVariable(`--ion-color-${name}-rgb`);
  unsetDocumentColorVariable(`--ion-color-${name}-contrast`);
  unsetDocumentColorVariable(`--ion-color-${name}-contrast-rgb`);
  unsetDocumentColorVariable(`--ion-color-${name}-shade`);
  unsetDocumentColorVariable(`--ion-color-${name}-tint`);
}

// Example usage
// const result = shadeAndTint('#ffffaa', 10);
// console.log('Shade:', result.shade);
// console.log('Tint:', result.tint);
