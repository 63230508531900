import { Injectable } from '@angular/core';
import { AssessmentQuestion, Update } from '../../../../interfaces';
import _ from 'lodash';
import {
  AssessmentQuestionsService,
  CombinedQuestions,
} from '../questions/assessment-questions.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { StorageService, UserService } from '../../../../services';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export const isAssessmentQuestion = (q: unknown): q is AssessmentQuestion =>
  q.hasOwnProperty('options');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AssessmentStateService {
  /**
   * Version of questions structure
   */
  public questionVersion: number | null = null;

  /**
   * All questions
   */
  public questions$ = new BehaviorSubject<CombinedQuestions[]>([]);

  /**
   * Current Step
   */
  public currentStep$ = new BehaviorSubject(1);

  public readonly showCameraStep$ = new BehaviorSubject(true);

  private alreadyLoaded = false;
  private initialized = false;
  private initializedData = null;

  private answers$ = new BehaviorSubject({});
  readonly photo$ = new BehaviorSubject<Update>(null);
  public readonly activeQuestion$: Observable<CombinedQuestions>;

  constructor(
    private userStorage: StorageService,
    private userService: UserService,
    private assessmentQuestionsService: AssessmentQuestionsService,
  ) {
    this.activeQuestion$ = this.questions$.pipe(
      filter(i => i && i.length > 0),
      switchMap(questions =>
        combineLatest([
          this.currentStep$.pipe(
            map(cs => questions[cs - 1]),
            filter(i => !!i),
          ),
          this.answers$,
        ]),
      ),
      map(([question, answers]) =>
        addAnswerToQuestion({ ...question }, answers[question.id]),
      ),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  public init() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    console.log('Assessment service initializing...');

    // If the user can (or cannot) upload photos, let the state know this.
    this.userService.user$
      .pipe(
        map(user => user?.organization?.config?.can_upload_photos ?? true),
        untilDestroyed(this),
      )
      .subscribe(canUploadPhotos => this.showCameraStep$.next(canUploadPhotos));

    this.userService.user$
      .pipe(
        filter(i => !!i),
        take(1),
        switchMap(u =>
          this.assessmentQuestionsService.combineAdvisorAndBaseQuestions(u.id),
        ),
      )
      .subscribe({
        next: result => {
          this.setQuestions(
            result.questions.map(val => {
              if (isAssessmentQuestion(val)) {
                val.options.map(q =>
                  q.icon === 'hand' ? (q.icon = 'hand-right') : q.icon,
                );
              }
              return val;
            }),
          );
          this.setVersion(result.version);
        },
      });
  }

  public async loadDataFromStorage() {
    if (this.alreadyLoaded) {
      return this.initializedData;
    }

    this.initializedData = {
      data: false,
      photo: false,
    };

    this.alreadyLoaded = true;

    console.log('Assessment service loading existing data...');

    const existingAnswers = await this.userStorage.get(
      'current-assessment-data',
    );

    const existingPhoto = await this.userStorage.get(
      'current-assessment-photo',
    );

    if (existingAnswers && Object.keys(existingAnswers).length > 0) {
      this.setAnswers(existingAnswers);
      this.initializedData.data = true;
    }

    if (existingPhoto) {
      this.photo$.next(existingPhoto);
      this.initializedData.photo = true;
    }

    return this.initializedData;
  }

  /**
   * Overwrites all questions
   * @param questions
   */
  public setQuestions(questions: CombinedQuestions[]) {
    this.questions$.next(questions);
  }

  get totalStep() {
    const cameraStep = this.showCameraStep$.getValue() ? 1 : 0;
    return this.questions$.getValue().length + cameraStep;
  }

  /**
   * Set the version of question structure
   * @param version
   */
  public setVersion(version: number) {
    this.questionVersion = version;
  }

  /**
   * Returns the question respect to current step
   */
  public get question(): CombinedQuestions {
    if (this.questions$.getValue().length === 0) {
      return null;
    }

    if (this.currentStep$.getValue() > this.questions$.getValue().length) {
      throw new Error('Current Step does contain the question');
    }

    return this.questions$.getValue()[this.currentStep$.getValue() - 1];
  }

  get filledOutQuestions$(): Observable<CombinedQuestions[]> {
    return combineLatest([this.questions$, this.answers$]).pipe(
      map(([questions, answers]) =>
        applyAnswersToQuestions(questions, answers),
      ),
      map(questions => this.orderedQuestions(questions)),
    );
  }

  /**
   * Move to next step
   */
  public nextStep() {
    if (this.currentStep$.getValue() < this.totalStep) {
      this.currentStep$.next(this.currentStep$.getValue() + 1);
    }
  }

  /**
   * Move to previous step
   */
  public previousStep() {
    if (this.currentStep$.getValue() > 0) {
      this.currentStep$.next(this.currentStep$.getValue() - 1);
    }
  }

  /**
   * Directly jump to step
   * @param step
   */
  public jumpTo(step: number) {
    this.currentStep$.next(step);
  }

  /**
   * Returns ordered questions with internal options ordered as well
   * @param questions
   */
  public orderedQuestions(questions: CombinedQuestions[]): CombinedQuestions[] {
    const orderedQuestions = _.orderBy(questions, ['order'], ['asc']);
    return orderedQuestions.map(q => {
      if (isAssessmentQuestion(q)) {
        q.options = _.orderBy(q.options, ['order'], ['asc']);
      }
      return q;
    });
  }

  /**
   * Returns true if current step is camera step
   */
  public get isCameraStep(): boolean {
    if (this.questions$.getValue().length === 0) {
      return false;
    }

    return this.currentStep$.getValue() === this.cameraStep;
  }

  get cameraStep(): number {
    return this.showCameraStep$.getValue()
      ? this.questions$.getValue().length + 1
      : 0;
  }

  /**
   * Reset the state
   */
  public reset() {
    // Reset answers.
    this.answers$.next({});
    this.userStorage.remove('current-assessment-data');

    // General state.
    this.initializedData = {};
    this.currentStep$.next(1);

    // Photo.
    this.setPhoto(null);
  }

  /**
   * Sets the camera shot
   * @param photo
   */
  public setPhoto(photo: Update) {
    this.photo$.next(photo);
    this.userStorage.set('current-assessment-photo', photo);
  }

  setAnswers(existingAnswers: Record<string, string | number>) {
    this.answers$.next(existingAnswers);
  }

  setAnswer(id: number | string, value: string | number) {
    this.answers$.next({
      ...this.answers$.getValue(),
      [id]: value,
    });

    this.userStorage.set('current-assessment-data', this.getAnswers());
  }

  isComplete$() {
    return this.questions$.pipe(
      filter(i => i.length > 0),
      map(
        () =>
          this.allQuestionsAnswered() &&
          (!this.needsPhoto() || this.hasPhoto()),
      ),
    );
  }

  allQuestionsAnswered() {
    return (
      Object.keys(this.answers$.value).length ===
      this.questions$.getValue().length
    );
  }

  hasPhoto() {
    return this.photo$.getValue() !== null;
  }

  needsPhoto() {
    return this.showCameraStep$.getValue() && this.photo$.getValue() === null;
  }

  getAnswers() {
    return this.answers$.getValue();
  }
}

function setAssessmentQuestionValue<T extends AssessmentQuestion>(
  question: T,
  selectedOption: number,
): T {
  const newOptions = question.options.map(option => {
    return { ...option, is_selected: option.value === selectedOption };
  });

  return { ...question, options: newOptions };
}

function applyAnswersToQuestions<T extends CombinedQuestions>(
  questions: T[],
  answers: Record<string, string | number>,
): T[] {
  return questions.map(question =>
    addAnswerToQuestion(question, answers[question.id] ?? null),
  );
}

function addAnswerToQuestion<T extends CombinedQuestions>(
  currentQuestion: T,
  answer: string | number | null,
): T {
  if (isAssessmentQuestion(currentQuestion) && answer !== undefined) {
    currentQuestion = setAssessmentQuestionValue(currentQuestion, +answer);
  } else if (!isAssessmentQuestion(currentQuestion) && answer !== undefined) {
    return { ...currentQuestion, value: answer as string };
  }

  return currentQuestion;
}
