import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { WrappedApiResponse } from '../../interfaces';
import { PermissionsData, PermissionsResponse } from './permissions';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../helpers';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private permissions$ = new ReplaySubject<PermissionsData[]>(1);

  constructor(private http: HttpClient) {
    this.getPermissions().subscribe();
  }

  hasAccessTo(value: PermissionsData) {
    return this.permissions$.pipe(
      map(permissions => permissions.includes(value)),
    );
  }

  getPermissions(): Observable<WrappedApiResponse<PermissionsResponse>> {
    return this.http
      .get<WrappedApiResponse<PermissionsResponse>>(apiUrl('permissions', 'v2'))
      .pipe(
        tap((response: WrappedApiResponse<PermissionsResponse>) =>
          this.permissions$.next(
            _.isEmpty(response.data.permissions)
              ? []
              : response.data.permissions,
          ),
        ),
      );
  }
}
