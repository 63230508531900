import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import {
  LoadHistoryData,
  LoadRecentData,
  LoadStepsByDate,
  UpdateSteps,
} from './health.actions';
import { EMPTY } from 'rxjs';
import { HealthStepsDayRecord } from '../types';
import { StepDaysService } from '../services/step-days.service';
import { indexStepData } from '../services/functions';

export const HEALTH_STATE_TOKEN = new StateToken<HealthStateModel>('health');
export const HEALTH_STATE_EMPTY: HealthStateModel = {
  isLoading: false,
  steps: [],
  stepsByDate: {},
};

export interface HealthStateModel {
  isLoading: boolean;
  steps: HealthStepsDayRecord[];
  stepsByDate: Record<string, HealthStepsDayRecord>;
}

@State<HealthStateModel>({
  name: HEALTH_STATE_TOKEN,
  defaults: HEALTH_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class HealthState {
  constructor(private stepService: StepDaysService) {}

  @Selector()
  static isLoading(state: HealthStateModel) {
    return state.isLoading;
  }

  @Selector([HealthState])
  static records(state: HealthStateModel) {
    return state.steps;
  }

  static stepsByDate(date: string) {
    return createSelector(
      [HealthState],
      (state: HealthStateModel) => state.stepsByDate[date],
    );
  }

  @Action([LoadRecentData, LoadHistoryData])
  loadRecentData(ctx: StateContext<HealthStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.stepService.get().pipe(
      tap(steps =>
        ctx.patchState({
          steps,
          stepsByDate: indexStepData(steps),
          isLoading: false,
        }),
      ),
      catchError(() => {
        ctx.patchState({ isLoading: false });
        return EMPTY;
      }),
    );
  }

  @Action([LoadStepsByDate])
  loadStepsByDate(ctx: StateContext<HealthStateModel>, { date }) {
    ctx.patchState({ isLoading: true });
    const filter = { source: 'app', recordDate: date };
    return this.stepService.get(filter).pipe(
      tap(steps =>
        ctx.patchState({
          stepsByDate: indexStepData(steps),
          isLoading: false,
        }),
      ),
      catchError(() => {
        ctx.patchState({ isLoading: false });
        return EMPTY;
      }),
    );
  }

  @Action(UpdateSteps)
  updateSteps(ctx: StateContext<HealthStateModel>, { steps }: UpdateSteps) {
    ctx.patchState({
      steps,
      stepsByDate: indexStepData(steps),
    });
  }
}
