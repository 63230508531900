import { UnitLabel, UnitTypes, UnitTypes as Unit } from './unit-types.enum';

export interface UnitType {
  unit: Unit;
  value: number;
}

export interface Units {
  metric: UnitType;
  imperial: UnitType;
}

export const UnitLabels = { weight: {}, length: {}, distance: {} };
UnitLabels.weight[UnitTypes.Imperial] = UnitLabel.ImperialWeight;
UnitLabels.weight[UnitTypes.Metric] = UnitLabel.MetricWeight;
UnitLabels.length[UnitTypes.Imperial] = UnitLabel.ImperialLength;
UnitLabels.length[UnitTypes.Metric] = UnitLabel.MetricLength;
UnitLabels.distance[UnitTypes.Imperial] = UnitLabel.ImperialDistance;
UnitLabels.distance[UnitTypes.Metric] = UnitLabel.MetricDistanceK;
