import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeaderboardListComponent } from './components/leaderboard-list/leaderboard-list.component';
import { LeaderboardListItemComponent } from './components/leaderboard-list-item/leaderboard-list-item.component';
import { StepsLeaderboardService } from './services/steps-leaderboard.service';
import { GenderPipe } from '../../pipes/gender/gender.pipe';
import { BadgeComponent } from './components/badge/badge.component';
import { BadgeShowcaseComponent } from './components/badge-showcase/badge-showcase.component';
import { LeaderboardsPopupComponent } from './components/leaderboards-popup/leaderboards-popup.component';
import { TransphormerProfilePictureModule } from '../../components/transphormer-profile-picture/transphormer-profile-picture.module';
import { UniversalComponentsModule } from '../universal/universal-components.module';
import { LogoModule } from '../../components/logo/logo.module';

@NgModule({
  declarations: [
    BadgeComponent,
    BadgeShowcaseComponent,
    LeaderboardListComponent,
    LeaderboardListItemComponent,
    GenderPipe,
    LeaderboardsPopupComponent,
  ],
  exports: [
    BadgeComponent,
    BadgeShowcaseComponent,
    LeaderboardListComponent,
    GenderPipe,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TransphormerProfilePictureModule,
    UniversalComponentsModule,
    LogoModule,
  ],
  providers: [StepsLeaderboardService],
})
export class StepsLeaderboardModule {}
