import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CameraPhotos } from '../../../../interfaces';
import { Observable } from 'rxjs';
import { apiUrl, jsonApiUrl } from '../../../../helpers';
import { getDay } from 'date-fns';
import {
  AssessmentConfig,
  AssessmentConfigJsonApiResponse,
  JsonApiObject,
  JsonApiResponse,
} from './types';
import { map } from 'rxjs/operators';
import { DayOptions } from '../../../training/types/common';
import { CombinedQuestions } from '../../../assessment-common/services/questions/assessment-questions.service';
import { isAssessmentQuestion } from '../../../assessment-common/services/assessment-state/assessment-state.service';
import Rollbar from 'rollbar';
import { RollbarService } from '../../../../rollbar';

@Injectable({
  providedIn: 'root',
})
export class AssessmentsService {
  constructor(
    protected http: HttpClient,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  /**
   * Returns list of questions against assessments
   */

  retrieveAssessmentConfig(advisorId: number): Observable<AssessmentConfig> {
    return this.http
      .get<AssessmentConfigJsonApiResponse>(
        jsonApiUrl(`trainers/${advisorId}/relationships/assessments-config`),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        // Give us a normalized object, or the default.
        map(ac =>
          ac.data?.attributes
            ? { id: ac.data.id, ...ac.data.attributes }
            : ac.meta['default-advisor-assessment-config'],
        ),
      );
  }

  updateAssessmentConfig(
    id: string | number,
    attributes: Partial<AssessmentConfig>,
  ) {
    let request: Observable<JsonApiResponse<JsonApiObject<AssessmentConfig>>>;
    if (id) {
      request = this.http.patch<
        JsonApiResponse<JsonApiObject<AssessmentConfig>>
      >(
        jsonApiUrl(`assessments-advisor-configs/${id}`),
        { data: { type: 'assessments-advisor-configs', id, attributes } },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      );
    } else {
      request = this.http.post<
        JsonApiResponse<JsonApiObject<AssessmentConfig>>
      >(
        jsonApiUrl(`assessments-advisor-configs`),
        { data: { type: 'assessments-advisor-configs', attributes } },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      );
    }

    return request.pipe(
      map(result => ({ id: result.data.id, ...result.data.attributes })),
    );
  }

  /**
   * Save assessment in the backend
   *
   * @param assessmentQuestions
   * @param questionVersion
   * @param cameraPhoto
   */
  public saveAssessment(
    assessmentQuestions: CombinedQuestions[],
    questionVersion: number,
    cameraPhoto: CameraPhotos = null,
  ): Observable<unknown> {
    const data = {
      response_data: {
        version: questionVersion,
        responses: assessmentQuestions.map(q => {
          return {
            ...q,
            value: isAssessmentQuestion(q)
              ? q?.options?.find(o => o.is_selected)?.value
              : q.value,
          };
        }),
      },
      update_id: cameraPhoto?.id,
    };

    this.rollbar.info('Saving Assessment', data);

    return this.http.post(apiUrl('assessment'), data, {
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.api+json' }),
    });
  }
}

export const checkIfDayAllowed = (date: DayOptions[]): boolean =>
  date.filter(d => d.value === getDay(new Date())).length > 0;
