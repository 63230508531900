import { Transphormer } from '../../interfaces';

export interface AvailableWearable {
  providerId: WearableProviderTypes;
  name: string;
  connect: WearableProviderConnections;
  // Sometimes, our available wearable will already have been authenticated. We'll
  // need to store a reference to its ID.
  authenticatedWearableId?: number;
}

export interface AvailableWearableMeta {
  availableWearables: AvailableWearable[];
}

export enum WearableProviderTypes {
  App = '1',
  Garmin = '2',
}

export enum WearableProviderConnections {
  Android = 'android',
  Apple = 'apple',
  Garmin = 'garmin',
}

export const AppleWearable: AvailableWearable = {
  providerId: WearableProviderTypes.App,
  name: 'Apple Health',
  connect: WearableProviderConnections.Apple,
};

export const AndroidWearable: AvailableWearable = {
  providerId: WearableProviderTypes.App,
  name: 'Google Health',
  connect: WearableProviderConnections.Android,
};

export const GarminWearable: AvailableWearable = {
  providerId: WearableProviderTypes.Garmin,
  name: 'Garmin',
  connect: WearableProviderConnections.Garmin,
};

export interface AuthenticatedWearable {
  id: number;
  provider: string;
  active: boolean;
}

export interface AuthorizeResponse {
  success?: boolean;
  message?: string;
  url: string;
}
export interface ProviderDetails {
  identifier: string;
  name: string;
}

export interface WearableConfig<T = string> {
  id?: string;
  createdAt?: T;
  updatedAt?: T;
  providerId?: WearableProviderTypes;
  providerDetails: ProviderDetails;
  transphormerId?: Transphormer['id'];
}
