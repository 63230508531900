import {
  defer,
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  OperatorFunction,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  take,
  tap,
} from 'rxjs/operators';
import { FormErrorDecoratorService } from '../decorators/form-error-decorator.service';
import { isEqual } from 'lodash';

export function catchFormError() {
  return <T>(source: Observable<T>) => {
    return source.pipe(
      catchError(e => {
        const toast = FormErrorDecoratorService.getToast();
        if (e.status !== 422 || !e.error) {
          throw e;
        }
        if (e.error && e.error.errors) {
          const firstKey = Object.keys(e.error.errors)[0];
          toast.flash(e.error.errors[firstKey][0]);
        } else if (e.error && e.error.message) {
          toast.flash(e.error.message);
        } else {
          toast.flash('Unable to process entity.');
        }
        return EMPTY;
      }),
    );
  };
}

// https://medium.com/javascript-everyday/rxjs-taponce-operator-yet-another-solution-435f88e2484a
export function tapOnce<T>(fn: (v: T) => unknown) {
  return (source$: Observable<T>) => {
    let isFirst = true;

    return source$.pipe(
      tap(v => {
        if (isFirst) {
          fn(v);
          isFirst = false;
        }
      }),
    );
  };
}

export function handleAnnouncementError() {
  return <T>(source: Observable<T>) => {
    return source.pipe(
      catchError(e => {
        const rollbar = FormErrorDecoratorService.getLogger();
        const toast = FormErrorDecoratorService.getToast();
        const errorMessage = Object.values(e.error.errors)[0].toString();
        rollbar.error(e.message, e);
        toast.flash(errorMessage);
        throw e;
      }),
    );
  };
}

export function handleApiError(
  rollbarService = null,
  toastService = null,
  rethrow = false,
) {
  return <T>(source: Observable<T>) => {
    return source.pipe(
      catchError(e => {
        const rollbar = rollbarService
          ? rollbarService
          : FormErrorDecoratorService.getLogger();
        const toast = toastService
          ? toastService
          : FormErrorDecoratorService.getToast();

        if (e.status === 401 && e.error.error === 'invalid_credentials') {
          // Don't respond, because we have an interceptor that deals with this.
          // In theory, we shouldn't even need this, though?
        } else if (e.status === 404) {
          rollbar.error(e.message, e);
          toast.flash('Invalid request.');
        } else if (e.status === 401) {
          toast.flash('Unauthorized request.');
        } else {
          if (!rethrow) {
            rollbar.error(e.message, e);
            toast.flash('An unknown error occurred.');
          } else {
            throw e;
          }
        }
        return EMPTY;
      }),
    );
  };
}

/**
 * Takes either an Observable of a single variable or multiple, and sends emits out only when there are values in every one
 * @param numberToTake
 */
export function filterAndTake<T>(
  numberToTake = 1,
): MonoTypeOperatorFunction<T> {
  return (sources: Observable<T>) =>
    sources.pipe(
      filter(data => (Array.isArray(data) ? !!data.every(e => !!e) : !!data)),
      take(numberToTake),
    );
}

export function distinctUntilKeysChanged<T, K extends keyof T>(
  keys: K[],
): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged(
    (x: T, y: T) => !!x && !!y && keys.every(a => isEqual(x[a], y[a])),
  );
}

export function tapFirst<T>(fn: (value) => void) {
  return (source: Observable<unknown>) =>
    defer(() => {
      let first = true;
      return source.pipe(
        tap<T>(payload => {
          if (first) {
            fn(payload);
          }
          first = false;
        }),
      );
    });
}

// @deprecated
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ignore404(): OperatorFunction<any, any> {
  return catchError(err => {
    return err.status && err.status === 404 ? EMPTY : err;
  });
}
