import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  mapBodyMetricUnit,
  mapGoalWeightUnit,
} from '../../helpers/map-unit-helper';
import { map, tap } from 'rxjs/operators';
import {
  CameraPhotos,
  dateMorphAt,
  ErrorFormat,
  Weight,
  WorkoutSession,
  WrappedApiResponse,
} from '../../interfaces';
import { Observable } from 'rxjs';
import { BodyMetric } from '../../modules/body-metrics/types/body-metric';
import {
  apiUrl,
  convertLoggedOn,
  filterEmptyValues,
  generateHttpOptions,
} from '../../helpers';
import { handleApiError } from '../../helpers/operators';
import { MacroReportingResponse } from '../../modules/nutrition/interfaces';
import { convertTransphormerWeights, loggedDateConversion } from './functions';
import {
  defaultFilterValue,
  ListingParams,
  PaginatedLinkedApplications,
} from './types';
import { _yyyyMMdd, yyyyMMdd } from '../../helpers/date';
import { TrainerTransphormerDetail } from '../../pages/my-transphormers/details/misc';

export { ListingParams, PaginatedLinkedApplications, defaultFilterValue };

@Injectable({
  providedIn: 'root',
})
export class TrainerTransphormerService {
  constructor(protected http: HttpClient) {}

  public transphormers(
    params: ListingParams | null = null,
    page = 1,
    perPage = 25,
  ): Observable<PaginatedLinkedApplications> {
    const options = {
      params: { ...filterEmptyValues(params ?? {}), perPage, page },
    };

    return this.http
      .get<PaginatedLinkedApplications>(apiUrl('my-transphormers'), options)
      .pipe(
        handleApiError(),
        map(applications => ({
          ...applications,
          ...(applications.meta ?? {}),
          data: applications.data.map(transphormer =>
            convertTransphormerWeights(transphormer),
          ),
        })),
      );
  }

  public transphormer(
    id: number,
    handleErrors = true,
  ): Observable<TrainerTransphormerDetail> {
    return this.http
      .get<TrainerTransphormerDetail>(apiUrl(`trainer/transphormer/${id}`))
      .pipe(
        handleErrors ? handleApiError() : tap(),
        map(transphormer => convertTransphormerWeights(transphormer)),
        map(transphormer => mapGoalWeightUnit(transphormer)),
      );
  }

  public transphormerPhotos(
    transphormerId: number,
  ): Promise<CameraPhotos[] | ErrorFormat> {
    return this.http
      .get<CameraPhotos[]>(
        apiUrl(`trainer/transphormer/${transphormerId}/camera`),
      )
      .pipe(handleApiError())
      .toPromise();
  }

  public transphormerWorkouts(
    transphormerId: number,
    from?: string,
    to?: string,
  ): Promise<WorkoutSession[] | ErrorFormat> {
    const opts = generateHttpOptions({ from, to });
    return this.http
      .get<WorkoutSession[]>(
        apiUrl(`trainer/transphormer/${transphormerId}/workouts${opts}`),
      )
      .pipe(handleApiError())
      .toPromise();
  }

  public transphormerBodyMetrics(
    transphormerId: number,
  ): Observable<WrappedApiResponse<BodyMetric[]>> {
    return this.http
      .get<
        WrappedApiResponse<BodyMetric[]>
      >(apiUrl(`trainer/transphormer/${transphormerId}/body-metrics`))
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(metric =>
            convertLoggedOn(mapBodyMetricUnit(metric)),
          ),
        })),
        handleApiError(),
      );
  }

  public transphormerWeights(
    transphormerId: number,
  ): Promise<Weight[] | ErrorFormat> {
    return this.http
      .get<Weight<string>[]>(
        apiUrl(`trainer/transphormer/${transphormerId}/weigh-ins`),
      )
      .pipe(
        handleApiError(),
        map(weights => weights.map(weight => loggedDateConversion(weight))),
      )
      .toPromise();
  }

  public trackedItems(
    transphormerId: number,
    from: Date = new Date(),
    to: Date = new Date(),
  ): Observable<MacroReportingResponse[]> {
    const opts = generateHttpOptions({
      from: yyyyMMdd(from),
      to: yyyyMMdd(to),
    });
    return this.http
      .get<
        MacroReportingResponse<string>[]
      >(apiUrl(`trainer/transphormer/${transphormerId}/tracked-items${opts}`))
      .pipe(
        handleApiError(),
        map(r =>
          r.map(i => ({
            ...i,
            ...dateMorphAt(i),
            track_date: _yyyyMMdd(i.track_date),
          })),
        ),
      );
  }
}
