// Functions which handle the generation of simple nutrition days.

import { MealItem, MealType, NutritionPlan, SimpleMeal } from '../interfaces';

export function generateDayFor(nutritionType: NutritionPlan, mealsPerDay = 5) {
  if (nutritionType === NutritionPlan.PortionControl) {
    return generateDays(5);
  } else if (nutritionType === NutritionPlan.MacroMealPlan) {
    return generateDays(mealsPerDay);
  }
}

export function generateDays(numberOfMeals = 3, offset = 0): SimpleMeal[] {
  const meals: SimpleMeal[] = [];

  for (let i = 1; i <= numberOfMeals; i++) {
    meals.push(meal(`Meal ${offset + i}`));
  }

  return meals;
}

const mealTemplate: MealItem[] = [
  {
    name: 'Protein',
    type: 'protein',
    selectedNutrition: null,
  },
  {
    name: 'Carb',
    type: 'carb',
    selectedNutrition: null,
  },
  {
    name: 'Veggie',
    type: 'veggie',
    selectedNutrition: null,
  },
];

function meal(name: string, type: MealType = 'meal'): SimpleMeal {
  return {
    name,
    type,
    expanded: true,
    items: [...mealTemplate],
  };
}
