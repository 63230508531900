import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  DecrementUnreadAdvisorMessages,
  ResetUnreadTransphormerMessages,
  DecrementUnreviewedAssessments,
  IncrementUnreadAdvisorMessages,
  MarkAllAnnouncementsRead,
  ResetAllCountersToZero,
  UpdateCounters,
} from './unread.actions';

export const UNREAD_STATE_TOKEN = new StateToken<UnreadStateModel>(
  'unreadState',
);

export interface UnreadStateModel {
  // For Transphormers: the number of unread messages from their advisor.
  messageCount: number;
  // For Transphormers: the number of unread announcements from their advisor.
  announcementCount: number;

  // For advisors: the number of unread *conversations* from their Transphormers.
  // Even if there are multiple unread messages, if I have one person with 5 unread
  // messages, it will only show a 1 here.
  advisorMessageCount: number;
  // For advisors: the number of Transphormers with unreviewed assessments. Transphormers
  // with multiple unread assessments will ONLY show as 1 here.
  advisorAssessmentCount: number;
}

@State<UnreadStateModel>({
  name: UNREAD_STATE_TOKEN,
  defaults: {
    advisorAssessmentCount: 0,
    advisorMessageCount: 0,
    announcementCount: 0,
    messageCount: 0,
  },
})
@Injectable({
  providedIn: 'root',
})
export class UnreadState {
  @Selector([UnreadState])
  static unreadTransphormerMessages(state: UnreadStateModel) {
    return state.messageCount;
  }

  @Selector([UnreadState])
  static unreadTransphormerAnnouncements(state: UnreadStateModel) {
    return state.announcementCount;
  }

  @Selector([UnreadState])
  static unreviewedAdvisorAssessments(state: UnreadStateModel) {
    return state.advisorAssessmentCount;
  }

  @Selector([UnreadState])
  static unreadAdvisorMessages(state: UnreadStateModel) {
    return state.advisorMessageCount;
  }

  /**
   * Returns the count shown in the menu in the bottom navigation. Calculates
   * everything based on the total advisor unreads, plus the announcements, plus
   * the assessment.
   */
  @Selector([UnreadState])
  static expandedMenuCount(state: UnreadStateModel) {
    return (
      state.advisorMessageCount +
      state.advisorAssessmentCount +
      state.announcementCount
    );
  }

  @Selector([UnreadState])
  static total(state: UnreadStateModel) {
    return (
      state.advisorMessageCount +
      state.advisorAssessmentCount +
      state.announcementCount +
      state.messageCount
    );
  }

  @Action(ResetAllCountersToZero)
  resetAllCountersToZero(ctx: StateContext<UnreadStateModel>) {
    ctx.setState({
      advisorAssessmentCount: 0,
      advisorMessageCount: 0,
      announcementCount: 0,
      messageCount: 0,
    });
  }

  @Action(UpdateCounters)
  updateCounters(
    ctx: StateContext<UnreadStateModel>,
    { counters }: UpdateCounters,
  ) {
    ctx.patchState(counters);
  }

  @Action(MarkAllAnnouncementsRead)
  markAllAnnouncementsRead(ctx: StateContext<UnreadStateModel>) {
    ctx.patchState({
      announcementCount: 0,
    });
  }

  @Action(DecrementUnreviewedAssessments)
  decrementUnreviewedAssessments(ctx: StateContext<UnreadStateModel>) {
    const { advisorAssessmentCount } = ctx.getState();
    ctx.patchState({
      advisorAssessmentCount:
        advisorAssessmentCount > 0 ? advisorAssessmentCount - 1 : 0,
    });
  }

  @Action(DecrementUnreadAdvisorMessages)
  decrementUnreadAdvisorMessages(ctx: StateContext<UnreadStateModel>) {
    const { advisorMessageCount } = ctx.getState();
    ctx.patchState({
      advisorMessageCount:
        advisorMessageCount > 0 ? advisorMessageCount - 1 : 0,
    });
  }

  @Action(ResetUnreadTransphormerMessages)
  decrementUnreadTransphormerMessages(ctx: StateContext<UnreadStateModel>) {
    ctx.patchState({
      messageCount: 0,
    });
  }

  @Action(IncrementUnreadAdvisorMessages)
  incrementUnreadAdvisorMessages(ctx: StateContext<UnreadStateModel>) {
    const { advisorMessageCount } = ctx.getState();
    ctx.patchState({
      advisorMessageCount: advisorMessageCount + 1,
    });
  }
}
