import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WrappedApiPaginatedResponse } from '../../../../app/interfaces';
import { apiUrl } from '../../../../app/helpers';
import { handleApiError } from '../../../../app/helpers/operators';
import { WeekInReview } from '../types';

@Injectable({
  providedIn: 'root',
})
export class WeekInReviewService {
  constructor(private http: HttpClient) {}

  public get(page = 1) {
    return this.http
      .get<
        WrappedApiPaginatedResponse<WeekInReview[]>
      >(apiUrl(`week-in-reviews?page=${page}`))
      .pipe(handleApiError());
  }
}
