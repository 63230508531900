import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  mapBodyMetricUnit,
  mapWeightUnit,
} from '../../helpers/map-unit-helper';
import { apiUrl } from '../../helpers';
import { handleApiError } from '../../helpers/operators';
import { dateMorph, dateMorphAt } from '../../interfaces';
import {
  Assessment,
  AssessmentData,
} from '../../modules/assessments-v2/services/assessments/types';

@Injectable({
  providedIn: 'root',
})
export class AdvisorService {
  constructor(protected http: HttpClient) {}

  public getTransphormerAssessments(
    transphormerId: number,
  ): Observable<AssessmentData[]> {
    return this.http
      .get<
        AssessmentData<string>[]
      >(apiUrl(`trainer/transphormer/${transphormerId}/assessments`))
      .pipe(
        handleApiError(),
        map(response => {
          return response.map(item => {
            return {
              ...item,
              ...dateMorphAt(item),
              week_end: new Date(item.week_end),
              week_start: new Date(item.week_start),
            };
          });
        }),
      );
  }

  public getAssessment(id: number): Observable<Assessment> {
    return this.http
      .get<Assessment<string>>(apiUrl(`trainer/assessment/${id}`))
      .pipe(
        handleApiError(),
        map<Assessment<string>, Assessment>(response => {
          return {
            ...response,
            body_metrics: response.body_metrics.map(item =>
              mapWeightUnit({ ...item, ...dateMorph(item) }),
            ),
            measurements: response.measurements.map(item =>
              mapBodyMetricUnit({ ...item, ...dateMorph(item) }),
            ),
          };
        }),
      );
  }

  /**
   * Mark a single assessment as read.
   * @param id
   */
  public markAssessmentAsReviewed(id: number) {
    return this.http
      .post(apiUrl(`trainer/assessment/${id}/review`), null)
      .pipe(handleApiError());
  }
}
