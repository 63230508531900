import {
  Action,
  createSelector,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import {
  ErrorUpload,
  FinishUpload,
  RemoveAllTransfers,
  RemoveTransfer,
  RetryDirectUpload,
  StartDirectUpload,
  StartUpload,
  UpdateProgress,
} from '../actions/transfers.actions';
import {
  Transfer,
  TransferState,
  TransferStreamType,
  TransferType,
} from '../../interfaces';
import produce from 'immer';
import { newTransfer } from './functions';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';
import { TokenService } from '../../services/token/token.service';
import { Platform } from '@ionic/angular';
import {
  BackgroundUpload,
  FileTransferManager,
  FTMPayloadOptions,
  UploadEvent,
  UploadState,
} from '@awesome-cordova-plugins/background-upload/ngx';
import { Capacitor } from '@capacitor/core';
import { StorageService } from '../../services';

export const TRANSFERS_STATE_TOKEN = new StateToken<TransfersStateModel>(
  'transfers',
);

export interface TransfersStateModel {
  transfers: Transfer[];
}

export type FilePath = string;

export type UploadData = FormData | string | Blob;

function isFormData(a: UploadData): a is FormData {
  return a instanceof FormData;
}

function isBlob(a: UploadData): a is Blob {
  return a instanceof Blob;
}

function isString(a: UploadData): a is string {
  return typeof a === 'string';
}

interface FTMOptions {
  config?: {
    parallelUploadsLimit?: number;
    requestTimeout?: number;
    resourceTimeout?: number;
  };
}

/**
 * Calculate the average transfer progress across an array of Transfers.
 * @param transfers
 */
function averageTransferProgress(transfers: Transfer[]): number {
  return transfers.filter(t => t.state !== TransferState.Error).length
    ? transfers.reduce((prev, curr) => prev + curr.progress, 0) /
        transfers.length
    : 0;
}

@State<TransfersStateModel>({
  name: TRANSFERS_STATE_TOKEN,
  defaults: {
    transfers: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class TransfersState {
  uploader: FileTransferManager;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private ngZone: NgZone,
    private store: Store,
    private platform: Platform,
    @Inject(RollbarService) private rollbar: Rollbar,
    private backgroundUpload: BackgroundUpload,
    private storage: StorageService,
  ) {
    this.initializeBackgroundUploader();
  }

  private bypassTestErrorMobile = false;

  @Selector([TransfersState])
  static transfers(state: TransfersStateModel): Transfer[] {
    return state.transfers;
  }

  @Selector([TransfersState.transfers])
  static uploads(transfers: TransfersStateModel['transfers']): Transfer[] {
    return transfers.filter(t => t.streamType === TransferStreamType.Upload);
  }

  static uploadsOfTypeAndStatus(type: TransferType, status: TransferState) {
    return createSelector([TransfersState.uploads], transfers => {
      return transfers.filter(t => t.type === type && t.state === status);
    });
  }

  @Selector([TransfersState])
  static transferCount(state: TransfersStateModel) {
    return state.transfers.filter(t => t.state !== TransferState.Error).length;
  }

  static upload(uploadId: string) {
    return createSelector([TransfersState], (state: TransfersStateModel) => {
      return state.transfers.find(t => t.id === uploadId);
    });
  }

  @Selector([TransfersState.transfers])
  static inProgress(transfers: Transfer[]) {
    return (
      transfers.findIndex(
        t =>
          t.state === TransferState.Sending ||
          t.state === TransferState.Receiving,
      ) !== -1
    );
  }

  @Selector([TransfersState.transfers])
  static avgProgress(transfers: Transfer[]) {
    return averageTransferProgress(transfers);
  }

  @Action(StartUpload)
  startUpload(
    ctx: StateContext<TransfersStateModel>,
    {
      newUpload,
      url,
      formData,
      fileUrl,
      sendBearerTokens,
      fileKey,
    }: StartUpload,
  ) {
    const upload = newTransfer(newUpload);

    // Ok, there's media to upload, track upload info on store.
    ctx.setState(
      produce(draft => {
        draft.transfers.push(upload);
      }),
    );

    // Check if upload should be performed using a regular http req
    if (!fileUrl) {
      return this.regularHttpRequest(url, upload, formData, ctx);
    }

    return this.fileUploadUsingTransferManager(
      formData,
      fileUrl,
      upload,
      ctx,
      url,
      sendBearerTokens,
      fileKey,
    );
  }

  @Action(StartDirectUpload)
  startDirectUpload(
    ctx: StateContext<TransfersStateModel>,
    { transfer, url, data }: StartDirectUpload,
  ) {
    const upload = newTransfer(transfer);

    // Ok, there's media to upload, track upload info on store.
    ctx.setState(
      produce(draft => {
        draft.transfers.push(upload);
      }),
    );

    // Check if upload should be performed using a regular http req. We'd do this if we are running
    // in the browser. We can still do a direct upload from here!
    if (!Capacitor.isNativePlatform() || !isString(data)) {
      return this.regularHttpRequest(url, upload, data, ctx);
    }

    return this.directUploadUsingTransferManager(data, upload, url);
  }

  /**
   * The retryDirectUpload action does everything that startDirectUpload does except
   * creating a new transfer and pushing it into state. Creating a new transfer and pushing it
   * into state is unnecessary because we already have that transfer and it's already in state.
   * @param ctx
   * @param transfer
   * @param url
   * @param data
   */
  @Action(RetryDirectUpload)
  retryDirectUpload(
    ctx: StateContext<TransfersStateModel>,
    { transfer, url, data }: RetryDirectUpload,
  ) {
    // Check if upload should be performed using a regular http req. We'd do this if we are running
    // in the browser. We can still do a direct upload from here!
    if (!Capacitor.isNativePlatform() || !isString(data)) {
      return this.regularHttpRequest(url, transfer, data, ctx);
    }

    return this.directUploadUsingTransferManager(data, transfer, url);
  }

  private regularHttpRequest(
    url: string,
    upload: Transfer,
    formData: UploadData,
    ctx: StateContext<TransfersStateModel>,
  ) {
    this.rollbar.debug('[Transfers] Performing regular Http Request', {
      url,
      upload,
    });
    return this.performRegularHttpRequest(url, formData).pipe(
      catchError(error => ctx.dispatch(new ErrorUpload(upload.id, error))),
      map((event: HttpEvent<ProgressEvent>) => {
        if (event.type === HttpEventType.UploadProgress) {
          const updatedUpload = {
            ...upload,
            progress: Math.round((event.loaded * 100) / event.total),
            progressTimestamp: Date.now(),
          };
          ctx.dispatch(new UpdateProgress(updatedUpload));
        }
        if (event.type === HttpEventType.Response) {
          this.rollbar.debug('[Transfers] regular Http Request finished', {
            upload,
            event,
          });
          ctx.dispatch(new FinishUpload(upload.id, event.body || ''));
        }
      }),
    );
  }

  private performRegularHttpRequest(url: string, formData: UploadData) {
    if (isBlob(formData)) {
      return this.http.put(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: { 'X-No-Retry': 'true' },
      });
    }

    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data',
      'X-No-Retry': 'true',
    });

    return this.http.post(url, formData, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  @Action(ErrorUpload)
  errorUpload(ctx: StateContext<TransfersStateModel>, result: ErrorUpload) {
    // Query the transfer so that we can retrieve the actual Upload errors.
    const t = ctx
      .getState()
      .transfers.find(transfer => transfer.id === result.id);
    this.rollbar.error('Upload Error', {
      upload_id: result.id,
      background_transfer_event: result.response,
      transfer: t,
    });

    ctx.setState(
      patch({
        transfers: updateItem<Transfer>(
          transfer => transfer.id === result.id,
          patch({
            error: result.response,
            state: TransferState.Error,
          }),
        ),
      }),
    );
  }

  @Action(UpdateProgress)
  updateProgress(
    ctx: StateContext<TransfersStateModel>,
    { updatedUpload }: UpdateProgress,
  ) {
    // Replace Upload with new data
    const updatedTransfer = { ...updatedUpload, state: TransferState.Sending };
    ctx.setState(
      patch({
        transfers: updateItem<Transfer>(
          upload => upload.id === updatedUpload.id,
          patch(updatedTransfer),
        ),
      }),
    );
  }

  @Action(RemoveTransfer)
  removeTransfer(
    ctx: StateContext<TransfersStateModel>,
    { id }: RemoveTransfer,
  ) {
    this.rollbar.debug('[Transfers] Removing transfer', { id });
    ctx.setState(
      produce(draft => {
        draft.transfers = draft.transfers.filter(upload => upload.id !== id);
      }),
    );
  }

  @Action(RemoveAllTransfers)
  removeAllTransfer(ctx: StateContext<TransfersStateModel>) {
    this.rollbar.debug('[Transfers] Removing all transfers');
    ctx.setState({ transfers: [] });
  }

  @Action(FinishUpload)
  finishUpload(
    ctx: StateContext<TransfersStateModel>,
    { id, response: result }: FinishUpload,
  ) {
    // Check if there are other uploads still in progress
    const state = TransferState.Completed;
    ctx.setState(
      patch({
        transfers: updateItem<Transfer>(
          upload => upload.id === id,
          patch({
            progress: 100,
            result,
            state,
          }),
        ),
      }),
    );
  }

  // Handle the updates that come to us via the upload manager.
  private handleTransferManagerEvent(event: UploadEvent) {
    this.rollbar.debug('handleTransferManagerEvent', { event });

    switch (event.state) {
      case UploadState.UPLOADING:
        this.store.dispatch(
          new UpdateProgress({
            progress: event.progress, //
            id: event.id,
            progressTimestamp: Date.now(),
          }),
        );
        break;

      case UploadState.UPLOADED:
        let response: unknown;

        try {
          // It's possible that the response we get isn't really JSON. It might be
          // empty. If it is, that's OK. Just set the response to an empty object
          // so that we have _something_ to work with down below.
          if (!event.serverResponse || event.serverResponse === '') {
            response = {};
          } else {
            response = JSON.parse(event.serverResponse);
          }
        } catch (e) {
          this.rollbar.error('Upload response error', {
            error: e,
            upload_event: event,
          });
          response = {};
        }

        this.store.dispatch(new FinishUpload(event.id, response));
        break;

      case UploadState.FAILED:
        this.store.dispatch(new ErrorUpload(event.id, event));
        break;
    }

    // We must acknowledge the event so that duplicates do not occur.
    if (event.eventId) {
      this.rollbar.debug('Acknowledge event', { event });
      this.uploader.acknowledgeEvent(event.eventId);
    }
  }

  /**
   * Sends an upload request via the native Transfer Manager.
   *
   * There are two specific types of transfers that this function is designed to handle. Those
   * are direct (PUT) uploads and the other are form (POST) uploads. PUT uploads are used to send files
   * directly to a storage service like S3. It PUTs the entire file as the body with the content-type
   * set to the content-type of the file. While a POST upload sends a regular form request to a server
   * and attaches the file as a field within the request. This is useful for sending a thumbnail or a smaller
   * asset directly to a service/endpoint.
   *
   * @param formData
   *  The FormData object. Can also be a string
   * @param fileUrl
   * @param upload
   * @param _ctx
   * @param url
   * @param sendBearerTokens
   * @param fileKey
   * @private
   */
  private fileUploadUsingTransferManager(
    formData: UploadData,
    fileUrl: string,
    upload: Transfer,
    _ctx: StateContext<TransfersStateModel>,
    url: string,
    sendBearerTokens = true,
    fileKey: string,
  ) {
    this.rollbar.debug('fileUploadUsingTransferManager', {
      fileUrl,
      url,
      fileKey,
    });
    // This shouldn't be needed anymore...
    if (fileUrl.startsWith('file://')) {
      fileUrl = fileUrl.replace('file://', '');
    }

    const formParameters = {};
    if (isFormData(formData)) {
      formData.forEach((value, key) => {
        formParameters[key] = value;
      });
    }

    const payload: FTMPayloadOptions = {
      fileKey,
      filePath: fileUrl,
      parameters: formParameters,
      id: upload.id,
      headers: sendBearerTokens
        ? { Authorization: `Bearer ${this.tokenService.get()}` }
        : {},
      notificationTitle: 'Upload',
      serverUrl: url,
      requestMethod: 'POST',
    };

    this.rollbar.debug('[Transfers] Upload start (new method)', {
      fileUrl,
      payload,
    });

    this.uploader.startUpload(payload);
  }

  private initializeBackgroundUploader() {
    if (!this.platform.is('capacitor')) {
      return;
    }

    this.platform.ready().then(() => {
      this.uploader = this.backgroundUpload.init({
        config: {
          parallelUploadsLimit: 3,
          requestTimeout: 120,
          resourceTimeout: 3600,
        } as FTMOptions['config'],
        callBack: (event: UploadEvent) => {
          this.ngZone.run(() => {
            this.handleTransferManagerEvent(event);
          });
        },
      });
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<TransfersStateModel>) {
    this.storage.get('__transfer_state').then(v => {
      this.rollbar.debug('[TransferState] got __transfer_state', v);
      if (v && Array.isArray(v.transfers)) {
        this.rollbar.debug('[TransferState] setting state');
        ctx.setState(v);
      }
    });
  }

  ngxsOnChanges(changes: NgxsSimpleChange<TransfersStateModel>) {
    if (changes.firstChange) {
      this.rollbar.debug('[TransferState] first change, returning');
      return;
    }

    if (changes.currentValue.transfers.length === 0) {
      this.rollbar.debug('[TransferState] transfers are empty, removing');
      this.storage.remove('__transfer_state');
      return;
    }

    this.storage.set('__transfer_state', changes.currentValue);
  }

  // PUT a file directly to a URL. Do not pass go. Do not use form parameters.
  private directUploadUsingTransferManager(
    filePath: string,
    upload: Transfer,
    serverUrl: string,
  ) {
    // This is needed.
    if (filePath.startsWith('file://')) {
      filePath = filePath.replace('file://', '');
    }

    const payload: FTMPayloadOptions = {
      filePath,
      id: upload.id,
      notificationTitle: 'Upload',
      serverUrl,
      requestMethod: 'PUT',
    };

    this.rollbar.debug('[Transfers] Start direct Upload', {
      filePath,
      payload,
      serverUrl,
      upload,
    });
    this.uploader.startUpload(payload);
  }
}
