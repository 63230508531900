import { PagedApiData, Transphormer } from '../../interfaces';

export interface ListingParams {
  filterName?: string;
  activeDays?: number | string;
  uploadDays?: number | string;
  haveNotUploadDays?: number | string;
  joinedDays?: number | string;
  name?: string;
  customer_for_life?: boolean | '';
  is_premium?: boolean | '';
  has_unread_messages?: boolean;
  has_unread_assessment?: boolean;
  sex?: number | string;
  last_message_sent?: number | string;
  last_message_received?: number | string;
  has_sent_assessment_within_days?: number | string;
  has_not_sent_assessment_within_days?: number | string;
  downgraded_from_premium?: number | string;
  upgraded_to_premium?: number | string;
  is_in_current_challenge?: boolean | '';
  no_message_ever?: boolean | '';
  direct_assignment?: boolean;
}

export type PaginatedLinkedApplications = PagedApiData<Transphormer[]> & {
  total_transphormers: number;
};

export const defaultFilterValue: ListingParams = {};
