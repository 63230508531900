import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HealthStepDayData,
  HealthStepsDayRecord,
  StepsDayFilters,
} from '../types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiUrl, jsonApiUrl } from '../../../helpers';
import { map } from 'rxjs/operators';
import {
  JsonApiObject,
  JsonApiResponse,
} from '../../assessments-v2/services/assessments/types';
import { WrappedApiResponse } from '../../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class StepDaysService {
  constructor(private http: HttpClient) {}

  // Get steps from the remote server.
  public get(filter?: StepsDayFilters): Observable<HealthStepsDayRecord[]> {
    //const defaultFilter = { source: 'app', recordDate: '2023-01-26' }; //'yyyyMMdd(todaysDate())'};
    const filterParams = this.getFilterParams(filter);
    // Get data from the server. This returns all of it. There is no pagination.
    return this.http
      .get<JsonApiResponse<JsonApiObject<HealthStepsDayRecord[]>>>(
        jsonApiUrl(`steps-days?${filterParams}`),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        map(response => response.data),
        map(data => this.normalizeStepDaysData(data)),
      );
  }

  // Save steps data to the server. This will also return all of it, just like get() above does.
  public store(
    payload: HealthStepDayData[],
  ): Observable<HealthStepsDayRecord[]> {
    return this.http
      .post<
        WrappedApiResponse<HealthStepsDayRecord[]>
      >(apiUrl('steps'), payload)
      .pipe(map(response => response.data));
  }

  // Update steps data to the server. This will also return all of it, just like get() above does.
  public update(
    record: HealthStepsDayRecord,
  ): Observable<HealthStepsDayRecord[]> {
    return this.http
      .patch<JsonApiResponse<JsonApiObject<HealthStepsDayRecord[]>>>(
        jsonApiUrl(`steps-days/${record.uuid}`),
        {
          id: `${record.uuid}`,
          data: {
            type: 'steps-days',
            attributes: { ...record },
          },
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        map(response => response.data),
        map(data => this.normalizeStepDaysData(data)),
      );
  }

  private getFilterParams(filter?: StepsDayFilters): string {
    if (!filter) return '';

    let params = '';
    for (const [key, value] of Object.entries(filter)) {
      if (value) {
        params = ''.concat(params, `&filter[${key}]=${value}`);
      }
    }

    return params;
  }

  private normalizeStepDaysData(
    data: JsonApiObject<HealthStepsDayRecord[]>,
  ): HealthStepsDayRecord[] {
    const stepDays: HealthStepsDayRecord[] = Object.values(data).map(
      (stepDay: JsonApiObject<HealthStepsDayRecord>) => {
        const props = stepDay['attributes'];
        return {
          ...props,
          uuid: stepDay.id,
          recordDate: stepDay['attributes']['recordDate'],
        };
      },
    );

    return stepDays;
  }
}
