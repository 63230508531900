export function rotateImage(
  data: string,
  callback: (imageData: string) => void,
  type = 'image/jpeg',
  quality = 0.5,
  degrees = 90,
) {
  const img = new Image();
  img.onload = function () {
    const canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');
    // Swaps width and height to accommodate 90-degree rotation.
    canvas.width = img.height;
    canvas.height = img.width;
    ctx.translate(canvas.width / 2, canvas.height / 2);

    // Rotates the canvas according to the specified grades
    ctx.rotate((-degrees * Math.PI) / 180);

    // draw the image
    ctx.drawImage(img, -img.width / 2, -img.height / 2);
    const dataUrl = canvas.toDataURL(type, quality);
    callback(dataUrl);
    ctx.restore();
    canvas.remove();
  };
  img.src = data;
}

export function resizeBase64Image(
  data: string,
  callback: (imageData: string) => void,
  width = 1200,
  _height = null,
  type = 'image/jpeg',
  quality = 0.75,
) {
  const img = new Image();
  img.onload = function () {
    const canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');
    const scale = width / img.width;
    const iwScaled = img.width * scale;
    const ihScaled = img.height * scale;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
    const dataUrl = canvas.toDataURL(type, quality);
    callback(dataUrl);
    img.remove();
    canvas.remove();
  };
  img.src = data;
}
