// Angular Imports
import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  Provider,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
// Ionic Imports
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// Ionic Native
import 'cordova-plugin-purchase/www/store.d';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { BackgroundUpload } from '@awesome-cordova-plugins/background-upload/ngx';

// Interceptors
import { RefreshTokenInterceptor } from './services/interceptors/refresh-token/refresh-token.interceptor';
import { CachingInterceptorService } from './services/interceptors/caching/caching-interceptor.service';
import { ApiDeprecationInterceptor } from './services/interceptors/api-deprication/api-deprecation.interceptor';
import { AuthTokenInterceptor } from './services/interceptors/auth-token-interceptor/auth-token.interceptor';
import { AutomaticRetryInterceptor } from './services/interceptors/automatic-retry-interceptor/automatic-retry-interceptor';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// NGXS
import { NgxsModule } from '@ngxs/store';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsRollbarTelemetryModule } from './store/ngxs-rollbar-telemetry/ngxs-rollbar-telemetry';
import { NgxsDataPluginModule } from '@angular-ru/ngxs';
import { NGXS_DATA_STORAGE_PLUGIN } from '@angular-ru/ngxs/storage';

// States
import { SubscriptionState } from './store/states/subscription.state';
import { TransfersState } from './store/states/transfers.state';
import { UnreadState } from './store/unread/unread.store';
import { AccountSettingsState } from './store/states/account-settings.state';
import { HealthState } from './modules/health/state/health.state';
import { PedometerState } from './modules/health/state/pedometer.state';
import { VideosState } from './modules/video/state/videos.state';
import { BodyMetricsState } from './modules/body-metrics/state/body-metrics/body-metrics.state';
import { WeekInReviewState } from './modules/week-in-review/state/week-in-review.state';
import { StepsLeaderboardState } from './modules/steps-leaderboard/state/steps-leaderboard.state';
import { WorkoutsState } from './modules/training/state/workout/workouts.state';
import { NutritionState } from './modules/nutrition/state/nutrition/nutrition.state';
import { ActivityState } from './modules/training/state/activity/activity.state';

// Application Components
import { AppComponent } from './app.component';
import { AttPrePromptComponent } from './modules/att/components/att-pre-prompt/att-pre-prompt.component';
import { AppRoutingModule } from './app-routing.module';
import { LogoModule } from './components/logo/logo.module';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './rollbar';
import { NavMenuModule } from './components/nav-menu/nav-menu.module';

// Application Services
import { AccountService, InAppPurchaseService } from './services';

// @todo: This shouldn't be here. -njv
import { AdvisorService } from './services/advisor/advisor.service';

// Misc
import { environment } from '../environments/environment';
import { HealthModule } from './modules/health/health.module';
import {
  PusherAuthenticated,
  PusherAuthenticatedFactory,
  PusherUnauthenticated,
  PusherUnauthenticatedFactory,
} from './services/pusher-factory';
import { ErrorInterceptor } from './services/interceptors/error/error.interceptor';
import { StepsLeaderboardModule } from './modules/steps-leaderboard/steps-leaderboard.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { TokenService } from './services/token/token.service';
import { SubscribeModalModule } from './modules/onboarding-2022/onboarding-modals/subscribe/subscribe-modal.module';

// Launch Darkly
import { NgxLaunchDarklyModule } from './modules/launchdarkly/ngx-launchdarkly.module';
import {
  LAUNCH_DARKLY_API_KEY,
  LAUNCH_DARKLY_OPTIONS,
  LaunchDarklyService,
} from './modules/launchdarkly/ngx-launchdarkly.service';
import { LDOptions } from 'launchdarkly-js-client-sdk';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { NgChartsModule } from 'ng2-charts';
import { AssessmentConfigState } from './modules/assessments-v2/services/assessments/state/assessment-config.state';
import { TranslateModule } from '@ngx-translate/core';
import { PurchasePlugin } from './purchase';
import { Drivers } from '@ionic/storage';

const nativeImports = [
  BackgroundUpload,
  Badge,
  Deploy,
  File,
  FileTransfer,
  MediaCapture,
];

const customProviders: Provider = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CachingInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiDeprecationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AutomaticRetryInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: RollbarService, useFactory: rollbarFactory },
  { provide: PusherUnauthenticated, useFactory: PusherUnauthenticatedFactory },
  {
    provide: PusherAuthenticated,
    useFactory: PusherAuthenticatedFactory,
    deps: [TokenService],
  },
];

const customImports: NgModule['imports'] = [];

if (!environment.production) {
  // customImports.push(NgxsLoggerPluginModule.forRoot());
  customImports.push(NgxsReduxDevtoolsPluginModule.forRoot({ name: 'MTST' }));
} else {
  // customImports.push(NgxsLoggerPluginModule.forRoot());
}

if (environment.production) {
  customProviders.push({
    provide: ErrorHandler,
    useClass: RollbarErrorHandler,
  });
}

@NgModule({
  declarations: [AppComponent, AttPrePromptComponent],
  imports: [
    BrowserModule,
    environment.production ? BrowserAnimationsModule : NoopAnimationsModule,
    TranslateModule.forRoot(),
    SortablejsModule.forRoot({ animation: 150 }),
    NgxsModule.forRoot(
      [
        AccountSettingsState,
        ActivityState,
        HealthState,
        PedometerState,
        StepsLeaderboardState,
        AssessmentConfigState,
        SubscriptionState,
        TransfersState,
        UnreadState,
        VideosState,
        NutritionState,
        BodyMetricsState,
        WeekInReviewState,
        WorkoutsState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          injectContainerState: false,
        },
      },
    ),
    NgxsDataPluginModule.forRoot(NGXS_DATA_STORAGE_PLUGIN),
    NgxLaunchDarklyModule,
    NgxsResetPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxsRollbarTelemetryModule.forRoot(),
    NavMenuModule,
    IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB],
    }),
    HealthModule,
    StepsLeaderboardModule,
    SubscribeModalModule,
    customImports,
    NgChartsModule,
  ],
  providers: [
    ...nativeImports,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HttpClientModule,
    LogoModule,
    LaunchReview,
    ...customProviders,
    // Force the app to load the token from storage. We will know 100%
    // if we are logged in or not before the application initializes!
    {
      provide: APP_INITIALIZER,
      useFactory: (tokenService: TokenService) => () => tokenService.init(),
      deps: [TokenService],
      multi: true,
    },
    {
      provide: LAUNCH_DARKLY_API_KEY,
      useValue: environment.launchDarklyApiKey,
    },
    {
      provide: LAUNCH_DARKLY_OPTIONS,
      useValue: <LDOptions>{
        bootstrap: 'localStorage',
      },
    },
    {
      provide: PurchasePlugin,
      useFactory: () => {
        return window.CdvPurchase.store;
      },
    },
    AdvisorService,
    AccountService,
    InAppPurchaseService,
    LaunchDarklyService,
  ],
  bootstrap: [AppComponent],
  exports: [HttpClientModule],
})
export class AppModule {}
