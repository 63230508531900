import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../authentication/authentication.service';
import { LoadingController, NavController } from '@ionic/angular';
import { ToastService } from '../../toast-service/toast-service.service';
import { RollbarService } from '../../../rollbar';
import * as Rollbar from 'rollbar';
import { TokenService } from '../../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenInterceptor implements HttpInterceptor {
  public constructor(
    public authService: AuthenticationService,
    public tokenService: TokenService,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public toastService: ToastService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // setting number of refresh attempts
    // let attempts = 0;
    // const maxAttempts = 2;

    const ignoredUrls = ['auth/login', 'auth/register', 'subscription/notify'];

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        // if attempts are exhausted then preventing any further actions
        // if (attempts > maxAttempts) {
        //   this.forceLogout();
        //   return NEVER;
        // }

        // if request fails with unauthorized action
        if (
          err.status === 401 &&
          req.url.includes(environment.apiUrl) &&
          !(ignoredUrls.filter(i => req.url.endsWith(i)).length !== 0)
        ) {
          // checking if action is for our api system not for third party
          // if response is unauthenticated response
          if (err.error && err.error.message === 'Unauthenticated.') {
            // attempts++;
            this.rollbar.debug('Unauthenticated response for request', {
              req,
              reqHeaders: req.headers
                .keys()
                .map(key => ({ [key]: req.headers.get(key) })),
              err,
              errHeaders: err.headers
                .keys()
                .map(key => ({ [key]: err.headers.get(key) })),
            });
          }
        }

        return throwError(err);
      }),
    );
  }

  /**
   * Force logout the user from system if refresh token attempt fails
   */
  public async forceLogout() {
    // If we
    if (!this.tokenService.get() !== null) {
      return;
    }
    this.toastService.flash('Invalid session. You have been logged out.');

    // hiding any loader if in view
    from(this.loadingController.dismiss())
      .pipe(catchError(() => NEVER))
      .subscribe();

    this.authService.logout();
  }
}
