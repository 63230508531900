import { TrackableItem } from '../../interfaces';
import { FoodEditingStateModel } from './food-editing.state';

export const defaultState = <FoodEditingStateModel>{
  usesDefaultServings: true,
  requiresServingSize: true,
  mayEditConsumption: false,
  editingServing: false,
  foodItem: <TrackableItem>{
    name: '',
    serving_size: {
      amount: null,
      unit: 'serving',
      carbs: 0,
      calories: 0,
      protein: 0,
      fats: 0,
      fiber: 0,
    },
    default_consumption_value: {
      unit: 'serving',
      amount: 1,
    },
    servings: [
      { measure: 'serving', qty: 1, serving_weight: 1, seq: 0 },
      { measure: 'ounces', qty: 1, serving_weight: 1, seq: 1 },
      { measure: 'grams', qty: 1, serving_weight: 1, seq: 2 },
      { measure: 'fl. ounces', qty: 1, serving_weight: 1, seq: 3 },
      { measure: 'ml', qty: 1, serving_weight: 1, seq: 4 },
      { measure: 'cup', qty: 1, serving_weight: 1, seq: 5 },
      { measure: 'tbsp', qty: 1, serving_weight: 1, seq: 6 },
      { measure: 'pound', qty: 1, serving_weight: 1, seq: 7 },
    ],
  },
  serving: {
    calories: 0,
    fats: 0,
    protein: 0,
    carbs: 0,
    fiber: 0,
    unit: '',
    amount: 0,
  },
  consumed: {
    amount: null,
    unit: null,
  },
  picture_url: null,
};
