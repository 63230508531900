import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';

import { LoadHistoryData } from './health.actions';
import { UserService } from '../../../services';
import { SetActiveDataType, SetActiveDatePeriod } from './pedometer.actions';
import { DatePeriod, HealthAggregationBucket, HealthDataType } from '../types';
import { endOfDay, startOfDay } from 'date-fns';
import { endOf, startOf } from '../../../helpers/date';

export const PEDOMETER_STATE_TOKEN = new StateToken<PedometerStateModel>(
  'pedometer',
);
export const PEDOMETER_STATE_EMPTY: PedometerStateModel = {
  activeDataType: 'steps',
  activeDatePeriod: 'day',
  endDate: endOfDay(new Date()),
  startDate: startOfDay(new Date()),
};

export interface PedometerStateModel {
  activeDataType: HealthDataType;
  activeDatePeriod: DatePeriod;
  endDate: Date;
  startDate: Date;
}

export function getBucketForPeriod(
  datePeriod: DatePeriod,
): HealthAggregationBucket {
  switch (datePeriod) {
    case 'day':
      return 'hour';
    case 'week':
      return 'day';
    case 'month':
      return 'day';
    case 'year':
      return 'month';
  }
}

@State<PedometerStateModel>({
  name: PEDOMETER_STATE_TOKEN,
  defaults: PEDOMETER_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class PedometerState implements NgxsAfterBootstrap {
  constructor(
    private userService: UserService,
    private store: Store,
  ) {}

  @Selector()
  static activeDataType(state: PedometerStateModel) {
    return state.activeDataType;
  }

  @Selector()
  static activeDatePeriod(state: PedometerStateModel) {
    return state.activeDatePeriod;
  }

  @Selector()
  static startDate(state: PedometerStateModel) {
    return state.startDate;
  }

  @Selector()
  static endDate(state: PedometerStateModel) {
    return state.endDate;
  }

  ngxsAfterBootstrap(ctx?: StateContext<unknown>): void {
    this.userService.user$.pipe(distinctUntilChanged()).subscribe(user => {
      if (user === null) {
        ctx.setState(PEDOMETER_STATE_EMPTY);
        return;
      }
    });
  }

  @Action(SetActiveDataType)
  setActiveCounter(
    ctx: StateContext<PedometerStateModel>,
    { dataType }: SetActiveDataType,
  ) {
    const state = ctx.getState();
    ctx.patchState({ activeDataType: dataType });
    return this.store.dispatch(
      new LoadHistoryData(
        state.startDate,
        state.endDate,
        dataType,
        getBucketForPeriod(state.activeDatePeriod),
      ),
    );
  }

  @Action(SetActiveDatePeriod)
  setActiveDatePeriod(
    ctx: StateContext<PedometerStateModel>,
    { datePeriod, endDate, startDate }: SetActiveDatePeriod,
  ) {
    const state = ctx.getState();

    if (!endDate && !startDate) {
      startDate = startOf(datePeriod);
      endDate = endOf(datePeriod);
    }

    ctx.patchState({
      activeDatePeriod: datePeriod,
      endDate,
      startDate,
    });
    return this.store.dispatch(
      new LoadHistoryData(
        startDate,
        endDate,
        state.activeDataType,
        getBucketForPeriod(datePeriod),
      ),
    );
  }
}
