import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Trainer } from '../../interfaces';
import { apiUrl } from '../../helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrainerService {
  constructor(private http: HttpClient) {}

  updateTrainer(trainerId: number, data): Observable<Trainer> {
    return this.http.put<Trainer>(apiUrl(`trainer/${trainerId}`), data);
  }

  getTrainerByTransphormerId(id: number): Observable<Trainer> {
    return this.http.get<Trainer>(apiUrl(`trainer-for-transphormer/${id}`));
  }
}
