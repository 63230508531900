import { CmcSupplementConfig, MealItem } from '../../interfaces';

export class LoadSupplementConfiguration {
  static readonly type = '[Supplements] LoadConfig';
}

export class ApplySupplementConfiguration {
  static readonly type = '[Supplements] ApplyConfig';
  constructor(public supplements: MealItem[]) {}
}

export class LoadCMCSupplementConfiguration {
  static readonly type = '[Supplements] LoadCMCConfig';
}

export class ApplyCMCSupplementConfiguration {
  static readonly type = '[Supplements] ApplyCMCConfig';
  constructor(public cmcSupplements: CmcSupplementConfig[]) {}
}
