import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformDirective } from './platform.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PlatformDirective],
  exports: [PlatformDirective],
})
export class PlatformDirectiveModule {}
