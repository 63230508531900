import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Swap, WorkoutSession, WrappedApiResponse } from '../../../interfaces';
import { apiUrl as _apiUrl } from '../../../helpers';
import { handleApiError } from '../../../helpers/operators';
import { map } from 'rxjs/operators';

const apiUrl = endpoint => _apiUrl(endpoint, 'v2.8');

@Injectable({
  providedIn: 'root',
})
export class SwapsService {
  constructor(protected http: HttpClient) {}

  public swapMovement(
    workoutSessionId: number | string | null = null,
    originalMovementId: number,
    newMovementId: number,
  ): Observable<Swap> {
    function sessionOrUuid(id: number | string | null) {
      if (id === null) {
        return {};
      }

      if (!Number(id)) {
        return { workout_session_uuid: id };
      }

      return { workout_session_id: id };
    }

    return this.http
      .post<WrappedApiResponse<Swap>>(apiUrl(`movement-swap`), {
        original_movement_id: originalMovementId,
        new_movement_id: newMovementId,
        ...sessionOrUuid(workoutSessionId),
      })
      .pipe(
        map(r => r.data),
        handleApiError(),
      );
  }

  public fetchSwaps() {
    return this.http
      .get<WrappedApiResponse<Swap[]>>(apiUrl('movement-swaps'))
      .pipe(
        map(r => r.data),
        handleApiError(),
      );
  }

  public removeSwap(swap: number) {
    return this.http
      .delete<
        WrappedApiResponse<WorkoutSession>
      >(apiUrl(`movement-swaps/${swap}`))
      .pipe(handleApiError());
  }
}
