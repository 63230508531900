import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BottomMenuService {
  public visible$ = new BehaviorSubject(false);
  public transfers$ = new BehaviorSubject(false);
  public lastState = false;
  public keyboardOpen = false;

  hide() {
    this.visible$.next(false);
  }

  show() {
    this.visible$.next(true);
  }

  hideTransfers() {
    this.transfers$.next(false);
  }

  showTransfers() {
    this.transfers$.next(true);
  }

  hideBottomMenuInResponseToKeyboardOpening() {
    if (this.keyboardOpen) {
      return;
    }

    this.keyboardOpen = true;
    this.lastState = this.visible$.getValue();

    if (this.lastState === false) {
      return;
    }

    this.visible$.next(false);
  }

  showBottomMenuInResponseToKeyboardDismissing() {
    if (!this.keyboardOpen) {
      return;
    }

    this.keyboardOpen = false;
    this.visible$.next(this.lastState);
  }
}
