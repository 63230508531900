import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { IonicModule } from '@ionic/angular';
import { ExpandedMenuComponent } from './components/expanded-menu/expanded-menu.component';
import { ActiveRouteDirective } from './directives/active-route.directive';
import { ConvertUnitModule } from '../../pipes/convert-unit/convert-unit.module';
import { RouterModule } from '@angular/router';
import { PlatformDirectiveModule } from '../../directives/platform-directive.module';
import { NgxLaunchDarklyModule } from '../../modules/launchdarkly/ngx-launchdarkly.module';
import { LogoModule } from '../logo/logo.module';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { PendingMessagesModule } from '../../modules/chat/components/pending-messages/pending-messages.module';
import { ChatStateSharedModule } from '../../modules/chat/state/chat-state-shared.module';

@NgModule({
  declarations: [
    HeaderMenuComponent,
    BottomNavComponent,
    ExpandedMenuComponent,
    ActiveRouteDirective,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ConvertUnitModule,
    RouterModule,
    PlatformDirectiveModule,
    NgxLaunchDarklyModule,
    LogoModule,
    ChatStateSharedModule,
    PendingMessagesModule,
  ],
  exports: [HeaderMenuComponent, BottomNavComponent, ExpandedMenuComponent],
})
export class NavMenuModule {}
