import { Injectable } from '@angular/core';
import mixpanel, { Dict } from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  take,
  tap,
} from 'rxjs/operators';
import { LaunchDarklyService } from '../../modules/launchdarkly/ngx-launchdarkly.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Sex, Transphormer } from '../../interfaces';
import { Select } from '@ngxs/store';
import { AccountSettingsState } from '../../store/states/account-settings.state';
import { AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class MixPanelService {
  user$ = this.user.user$;
  uniqueId$ = new BehaviorSubject<string>(null);
  canAccess$ = this.ldFlagService
    .flag('mixpanel--enable')
    .pipe(takeUntilDestroyed(), filter(Boolean), distinctUntilChanged());

  @Select(AccountSettingsState.appTrackingTransparency)
  attIsAllowed$: Observable<AppTrackingStatusResponse>;

  constructor(
    public user: UserService,
    private ldFlagService: LaunchDarklyService,
  ) {
    this.canAccess$.subscribe(() => this.init());
  }
  /**
   * Initializes Mixpanel with the provided token and options.
   *
   * This function initializes all the information for the logged in user
   */
  init() {
    if (!environment.mixpanel.token) {
      console.error('Mixpanel token is not available.');
      return;
    }

    this.user$
      .pipe(
        filter(user => !!user),
        distinctUntilKeyChanged('id'),
      )
      .subscribe(_user => {
        mixpanel.init(environment.mixpanel.token, {
          track_pageview: 'full-url',
          loaded: async () =>
            this.uniqueId$.next(`${mixpanel.get_distinct_id()}`),
        });
      });

    combineLatest([
      this.attIsAllowed$.pipe(
        filter(Boolean),
        distinctUntilKeyChanged('status'),
      ),
      this.user$.pipe(filter(Boolean), distinctUntilKeyChanged('id')),
      this.uniqueId$.pipe(filter(Boolean), distinctUntilChanged()),
    ])
      .pipe(
        tap(([att, user]) => {
          const isRestricted = ['denied', 'restricted'].includes(att.status);

          if (isRestricted) {
            mixpanel.reset();
            mixpanel.identify(v4());
          } else {
            this.trackAuthorizedUser(user);
          }
        }),
      )
      .subscribe();
  }

  trackAuthorizedUser(user: Transphormer) {
    mixpanel.people.set({
      '$name': user.display_name,
      '$email': user.email,
      '$avatar': user.profile_picture_url,
      'Account Type': user.is_trainer ? 'Trainer' : 'Transphormer',
      'Premium': user.is_paid_user,
      'Sex': user.sex === Sex.Male ? 'Male' : 'Female',
      'Age': user.age,
      'DOB': user.dob,
      'Activity Level': user.activity_level,
      'Preference Macro Counting': user.preference_macro_counting,
      'Advisor': user.linked_trainer?.trainer?.transphormer?.display_name,
      'Advisor ID': user.linked_trainer?.trainer?.transphormer?.id,
      'Is trial User?': user.is_trial_user,
      'Campaign ID': user.advisor?.legionnaire_url,
      'Subscription Tier': user.subscription?.subscription_id,
      'Goal': user.transphormation_goal,
      // Add anything else about the user here
    });

    mixpanel.identify(`${user.id}`);
  }

  track(
    event_name: string,
    properties: Dict = undefined,
    callback = () => void 0,
  ) {
    // Before we send a Track to Mix Panel, we must first ensure that we have successfully connected to Mix Panel.
    this.uniqueId$
      .pipe(
        take(1),
        tap(id => {
          if (!id) {
            callback();
          }
        }),
        filter(Boolean),
      )
      .subscribe(() => mixpanel.track(event_name, properties, callback));
  }

  // We need to make sure mixpanel was initialized BEFORE trying to reset it
  reset() {
    this.uniqueId$
      .pipe(take(1), filter(Boolean))
      .subscribe(() => mixpanel.reset());
  }
}
