import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../../services';
import { mergeMap } from 'rxjs/operators';
import { KeysOfType, OrganizationConfig } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class OrganizationPermissionsGuard {
  constructor(private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const feature: KeysOfType<OrganizationConfig, boolean> =
      route.data.orgPermission;

    return this.userService.user$.pipe(
      mergeMap(user => {
        // Does the user have the allowed permission? If it doesn't exist for
        // any reason, just assume true.
        return of(user?.organization?.config?.[feature] ?? true);
      }),
    );
  }
}
