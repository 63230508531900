export enum UnitTypes {
  Imperial = 1,
  Metric,
}

export enum UnitLabel {
  ImperialWeight = 'lbs',
  ImperialWeightSingular = 'lb',
  ImperialLength = 'ins',
  ImperialDistance = 'mi',
  MetricWeight = 'kg',
  MetricLength = 'cm',
  MetricDistanceK = 'km',
  MetricDistance = 'm',
}
