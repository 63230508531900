import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { EMPTY, Observable } from 'rxjs';
import { LinkApplication } from '../../interfaces';
import { apiUrl } from '../../helpers';
import { catchFormError, handleApiError } from '../../helpers/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TrainerTransphormerLinkService {
  constructor(
    protected http: HttpClient,
    protected userService: UserService,
  ) {}

  public requestStatus(): Observable<LinkApplication> {
    const options = {
      params: new HttpParams({ fromObject: { wants_link: 'true' } }),
    };
    return this.http
      .get<LinkApplication>(apiUrl('trainer-transphormer'), options)
      .pipe(
        catchError(error => {
          if (error.code === 404) {
            return EMPTY;
          }
          throw error;
        }),
        handleApiError(),
      );
  }

  public applyLinkingByTransphormer(
    advisorId: number,
    transphormerId: number,
  ): Observable<LinkApplication> {
    return this.http
      .post<LinkApplication>(apiUrl('trainer-transphormer'), {
        trainer: advisorId,
        transphormer: transphormerId,
      })
      .pipe(catchFormError());
  }

  public destroyLink(id: number): Observable<void> {
    return this.http
      .delete<void>(apiUrl(`trainer-transphormer/${id}`))
      .pipe(handleApiError());
  }
}
