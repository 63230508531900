import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxLaunchDarklyModule } from '../../../launchdarkly/ngx-launchdarkly.module';
import { WorkoutComponentsModule } from '../workout-components.module';
import { AdvisorWorkoutViewComponent } from './advisor-workout-view.component';
import { UniversalComponentsModule } from '../../../universal/universal-components.module';
import { AdvisorStandardExerciseComponent } from '../advisor-standard-exercise/advisor-standard-exercise.component';

@NgModule({
  declarations: [AdvisorWorkoutViewComponent, AdvisorStandardExerciseComponent],
  imports: [
    CommonModule,
    IonicModule,
    NgxLaunchDarklyModule,
    WorkoutComponentsModule,
    UniversalComponentsModule,
  ],
  exports: [AdvisorWorkoutViewComponent],
})
export class WorkoutViewLegacyModule {}
