import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import {
  DataAction,
  Payload,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { createEntityCollections } from '@angular-ru/cdk/entity';
import { MessageListPayload } from '../../interfaces/message';

@StateRepository()
@State({
  name: 'pending_message_list',
  defaults: {
    ...createEntityCollections(),
  },
})
@Injectable()
export class PendingMessageListEntitiesState extends NgxsDataEntityCollectionsRepository<MessageListPayload> {
  ngxsOnInit(): void {
    this.reset();
  }

  @DataAction()
  public addPendingMessage(
    @Payload('messages') message: MessageListPayload,
  ): void {
    this.addOne(message);
  }

  @DataAction()
  public updatePendingMessage(
    @Payload('messages') message: MessageListPayload,
  ): void {
    this.upsertOne(message);
  }

  @DataAction()
  public removePendingMessage(
    @Payload('id') id: MessageListPayload['id'],
  ): void {
    this.removeOne(id);
  }
}
