import {
  CaloriesMacros,
  CmcSupplementConfig,
  CmcSupplementTracker,
  MacroType,
  NutritionConfiguration,
  TrackedItem,
} from './interfaces';
import {
  isInCalorieWindow,
  isInWindow,
} from './components/nutrition-detail/functions';
import { FORMATTED_NUMBER } from '../../helpers/regex';

interface UsedGoalValue {
  currentValue: number;
  goalValue: number;
  type: string;
}

export interface UsedValueDetails extends UsedGoalValue {
  isInWindow: boolean;
  isOver: boolean;
  isPerfect: boolean;
  isUnder: boolean;
  difference: number;
  percentage: number;
  percentageRanged: number;
  absoluteDifference: number;
}

export function calculateUsedValueDetails(
  type: MacroType,
  currentValue,
  config: NutritionConfiguration,
) {
  const goalValue = config.macros[type];
  const difference = goalValue - currentValue;
  const absoluteDifference = Math.abs(difference);

  let currentValueInWindow;

  if (type === 'calories') {
    currentValueInWindow = isInCalorieWindow(currentValue, config);
  } else {
    currentValueInWindow = isInWindow(type, currentValue, config);
  }

  return {
    currentValue,
    goalValue,
    type,
    difference,
    absoluteDifference,
    isInWindow: currentValueInWindow,
    percentage: !!goalValue ? currentValue / goalValue : 0,
    percentageRanged: !!goalValue ? Math.min(currentValue / goalValue, 1) : 0,
    isUnder: difference > 0,
    isOver: difference < 0,
    isPerfect: goalValue > 0 && difference === 0,
  };
}

// Groups: 1-Numerator 2-Denominator
const FRACTIONAL_REGEX =
  /^\s*[0]*([1-9][0-9]*)\s*\/{1}\s*[0]*([1-9][0-9]*)\s*?$/;

// Groups: 1-Integer 2-Numerator 3-Denominator
const MIXED_REGEX =
  /^\s*[0]*([1-9][0-9]*)\s+[0]*([1-9][0-9]*)\s*\/{1}\s*[0]*([1-9][0-9]*)\s*?$/;

export function valueToNumber(value: string | number): number | typeof NaN {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value !== 'string') {
    return NaN;
  }

  // Try matching decimal format
  let arr = value.match(FORMATTED_NUMBER);
  if (arr) {
    return arr[1] ? parseFloat(`${arr[1]}`) : parseFloat(`0${arr[2]}0`);
  }
  // Try matching fractional format
  arr = value.match(FRACTIONAL_REGEX);
  if (arr) {
    return Math.round(1000 * (+arr[1] / +arr[2])) / 1000;
  }
  // Try matching mixed format
  arr = value.match(MIXED_REGEX);
  if (arr) {
    return +arr[1] + Math.round(1000 * (+arr[2] / +arr[3])) / 1000;
  }
  // Wrong format
  return NaN;
}

export function calculateQuickCalories(macros: CaloriesMacros): number {
  macros = objectMap(macros, v => (isNaN(v) ? 0 : v));

  const beforeRounding =
    +macros.protein * 4 +
    (+macros.fiber <= +macros.carbs ? +macros.carbs * 4 : 0) +
    +macros.fats * 9 +
    (+macros.carbs < +macros.fiber ? +macros.fiber * 4 : 0);

  return Math.floor(beforeRounding * 100) / 100;
}

// @todo DRY objectMap.
export function cleanMacros(original: CaloriesMacros): CaloriesMacros {
  return Object.keys(original)
    .map(key => ({ key, value: parseFloat(original[key]) || 0 }))
    .reduce<CaloriesMacros>(
      (o, v) => ({ ...o, [v.key]: v.value }),
      {} as CaloriesMacros,
    );
}

export function objectMap<T>(original: T, callable: CallableFunction) {
  return Object.keys(original)
    .map(key => ({ key, value: callable(original[key]) }))
    .reduce<T>((o, v) => ({ ...o, [v.key]: v.value }), {} as T);
}

type Entry<T> = {
  [K in keyof T]: T[K];
}[keyof T];

export function objectFilter<T extends object>(
  original: T,
  callable: (value: Entry<T>) => boolean,
) {
  return Object.keys(original)
    .map(key => {
      if (callable(original[key])) {
        return { key, value: callable(original[key]) };
      } else {
        return undefined;
      }
    })
    .reduce<T>((o, v) => {
      if (v) {
        return { ...o, [v.key]: original[v.key] };
      } else {
        return o;
      }
    }, {} as T);
}

export function ensureServingSizeValueAreNotNull(t: TrackedItem) {
  const { carbs, calories, protein, fats, fiber } = t.serving_size;

  return {
    ...t,
    serving_size: {
      ...t.serving_size,
      carbs: carbs ?? 0,
      calories: calories ?? 0,
      protein: protein ?? 0,
      fats: fats ?? 0,
      fiber: fiber ?? 0,
    },
  };
}

export function sortSupplements(
  a: CmcSupplementConfig,
  b: CmcSupplementConfig,
) {
  if (a.group === b.group) {
    return a.weight < b.weight ? -1 : 1;
  } else {
    return a.group < b.group ? -1 : 1;
  }
}

export function mergeSupplementsConfig(
  supplements: CmcSupplementTracker[],
  config: CmcSupplementConfig[],
) {
  const updatedConfig: (CmcSupplementConfig | CmcSupplementTracker)[] = [];
  config.forEach((sc: CmcSupplementConfig) => {
    const s = supplements.find(s => s.id === sc.id);
    updatedConfig.push(s ? { ...s, times: sc.times } : { taken: 0, ...sc });
  });
  return updatedConfig;
}
