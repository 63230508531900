import { Inject, Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { NavController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { apiUrl, jsonApiUrl } from '../../../helpers';
import { first, switchMap } from 'rxjs/operators';
import { TokenService } from '../../token/token.service';
import { RollbarService } from '../../../rollbar';
import * as Rollbar from 'rollbar';

export const IS_AUTHENTICATED = new HttpContextToken<boolean>(() => true);

@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  public deviceType: string;

  public constructor(
    public platform: Platform,
    public tokenService: TokenService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public navCtrl: NavController,
  ) {
    this.deviceType = this.getDeviceType();
  }

  getDeviceType(): string {
    if (!this.platform.is('capacitor')) {
      return 'web';
    }
    return this.platform.is('android') ? 'android' : 'ios';
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // If the URL of the request isn't a URL that we are expecting, go don't mess with it. Just pass it along.
    // We "slice-and-join" here because it's possible for the API URL to be different based
    // on the version provided. This effectively just removes the version and goes to the "root".
    // e.g. http://<base>/api.
    if (
      !(
        req.url.startsWith(apiUrl('').split('/').slice(0, -2).join('/')) ||
        req.url.startsWith('https://uploads.mytransphormation.com/') ||
        req.url.startsWith(jsonApiUrl().split('/').slice(0, -2).join('/'))
      )
    ) {
      return next.handle(req);
    }

    // Is this link authenticated?
    if (!req.context.get(IS_AUTHENTICATED)) {
      return next.handle(req);
    }

    const headers = {
      'Accept': 'application/json',
      'X-TZ-Offset': (new Date().getTimezoneOffset() * -1).toString(),
      'Device-Type': this.deviceType,
    };

    return this.tokenService.accessToken$.pipe(
      first(),
      switchMap(accessToken => {
        // If we don't get an access token, just pretend this request didn't happen.
        // Is this wrong?
        if (!accessToken) {
          this.rollbar.error('Access token missing on authenticated request', {
            req,
          });
          throw new Error('Access token missing on authenticated request');
        }

        headers['Authorization'] = `Bearer ${accessToken}`;
        const clonedRequest = req.clone({
          setHeaders: headers,
        });
        // Pass the cloned request instead of the original request to the next handle
        return next.handle(clonedRequest);
      }),
    );
  }

  private async navigateToLogin() {
    await this.navCtrl.navigateRoot('/login');
  }
}
