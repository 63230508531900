import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import {
  FoodSearchResultItem,
  FoodSourceItem,
  TrackableSourceItem,
  TrackedItem,
} from '../../interfaces';
import { FoodSourceServiceInterface } from '../food-manager/interfaces';
import { catchError, map } from 'rxjs/operators';
import { convertCustomTrackedItemToTrackableItem } from './functions';
import { apiUrl } from '../../../../helpers';
import { handleApiError } from '../../../../helpers/operators';

export interface CustomFoodTemplate {
  id: number;
  name: string;
  calories: number;
  carbs: number;
  fats: number;
  protein: number;
  fiber?: number;
  serving_unit: string;
  serving_amount: number;
  picture_url?: string;
}

@Injectable()
export class CustomFoodTemplateService implements FoodSourceServiceInterface {
  afterTrack(_item: TrackedItem): void {
    //
  }

  defaultResults(): Observable<TrackableSourceItem[]> {
    return EMPTY;
  }

  getSearchDetails(_item: FoodSourceItem): Observable<TrackableSourceItem[]> {
    return EMPTY;
  }

  searchForTerm(term: string, _options?): Observable<FoodSearchResultItem[]> {
    return this.searchTemplate(term).pipe(
      map(cfr => {
        return cfr.map(item => {
          return {
            type: 'custom',
            id: item.id,
            data: item,
          };
        });
      }),
    );
  }

  constructor(protected http: HttpClient) {}

  public getCustomFoods(): Observable<CustomFoodTemplate[]> {
    return this.http
      .get<CustomFoodTemplate[]>(apiUrl('nutritions/custom-food'))
      .pipe(handleApiError());
  }

  public storeCustomFood(
    data: CustomFoodTemplate,
  ): Observable<CustomFoodTemplate> {
    return this.http
      .post<CustomFoodTemplate>(apiUrl('nutritions/custom-food'), {
        ...data,
        unit: data.serving_unit,
        amount: data.serving_amount,
      })
      .pipe(handleApiError());
  }

  public updateCustomFood(
    id: number,
    data: CustomFoodTemplate,
  ): Observable<CustomFoodTemplate> {
    return this.http
      .put<CustomFoodTemplate>(apiUrl(`nutritions/custom-food/${id}`), {
        ...data,
        unit: data.serving_unit,
        amount: data.serving_amount,
      })
      .pipe(handleApiError());
  }

  public getTrackableItem({ result }) {
    return convertCustomTrackedItemToTrackableItem(result);
  }

  public deleteCustomFood(id: number) {
    return this.http
      .delete(apiUrl(`nutritions/custom-food/${id}`))
      .pipe(handleApiError());
  }

  public searchTemplate(name: string): Observable<CustomFoodTemplate[]> {
    return this.http.get<CustomFoodTemplate[]>(
      apiUrl('nutritions/custom-food'),
      { params: { name } },
    );
  }

  public searchTemplateNew(name: string): Observable<CustomFoodTemplate[]> {
    return this.http
      .get<
        CustomFoodTemplate[]
      >(apiUrl('nutrition/search/custom-foods'), { params: { name } })
      .pipe(catchError(() => of([])));
  }
}
