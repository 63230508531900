import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IsAdvisorGuard {
  constructor(public userService: UserService) {}

  canLoad(
    _route: Route,
    _segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.user$.pipe(map(user => user.is_trainer));
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userService.user$.pipe(map(user => user.is_trainer));
  }
}
