import { EventEmitter, Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

interface GlobalEvent {
  name: string;
  data;
}

export enum GlobalEvents {
  NewTransphormerMessages = 'new-transphormer-messages', // fired when there are new messages for transphormer
  NewAdvisorMessages = 'new-advisor-message', // fired when advisor has nwe messages
  NewAnnouncement = 'new-announcement', // fired when new announcements are sent
}

@Injectable({
  providedIn: 'root',
})
export class GlobalEmitterService {
  public emitter: EventEmitter<GlobalEvent> = new EventEmitter<GlobalEvent>();

  public emit(name: string, data?) {
    this.emitter.emit({
      name,
      data,
    });
  }

  public listen(eventName: GlobalEvents | string) {
    return this.emitter.pipe(filter(event => event.name === eventName));
  }
}
