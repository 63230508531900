import { UnitTypes } from './unit-types.enum';
import { Units } from './unit-type';
import { NutritionPlan } from './nutrition';
import { Tag } from '../modules/training/services/excercise/exercise.service';
import { Advisor, AdvisorSearchResult, ProfileType } from './types';
import {
  OffsetRecord,
  RestDayRecord,
  SessionExistence,
  SwapDayRecord,
} from '../modules/training/types';
import { SurveyApiResponse } from '../modules/stepped-survey/types';

export enum Sex {
  Male = '1',
  Female = '2',
}

export interface PersonalInfo {
  height: number;
  weight: number;
  sex: Sex;
  goal_weight: number;
  goal_weight_unit: UnitTypes;
  goal_weight_units: Units;
  date_of_birth: string;
  first_name?: string;
  last_name?: string;
  unit_type: UnitTypes;
  transphormation_goal?: TransphormationGoalTypes;
}

export interface BaseInfo {
  training_level: TrainingLevelsTypes;
  gym_workout_selection: ProgramTypes;
  transphormation_goal?: TransphormationGoalTypes;
  activity_level: ActiveLevelTypes;
  likely_to_do: NutritionPlan;
  meals_per_day: 3 | 4 | 5;
  preference_macro_counting?: PreferenceMacroCounting;
}

export interface ContactInfo {
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  phone?: string;
  instagram?: string;
  twitter?: string;
}

export interface OtherInfo {
  profile_type?: ProfileType;
}

export interface OnBoarding
  extends PersonalInfo,
    BaseInfo,
    ContactInfo,
    OtherInfo {
  advisor?: AdvisorSearchResult;
  delegatedAdvisor?: Advisor;
}

export enum TrainingLevel {
  Beginner = 3,
  Intermediate = 1,
  Advanced = 2,
}

export const TrainingLevels: ProgramTypeObj[] = [
  {
    name: 'Beginner',
    value: TrainingLevel.Beginner,
  },
  {
    name: 'Intermediate',
    value: TrainingLevel.Intermediate,
  },
  {
    name: 'Advanced',
    value: TrainingLevel.Advanced,
  },
];
export type TrainingLevelsTypes = number;
export type ProgramTypes = number;

export enum MacroExtraCaloriesPref {
  Carbs = 'Carbs',
  Fats = 'Fats',
  Both = 'Both',
}

export type PreferenceMacroCounting = keyof typeof MacroExtraCaloriesPref;

export enum Goal {
  Lose = 'Primarily lose bodyfat',
  Maintain = 'Maintain',
  Gain = 'Gain lean muscle',
}

export const TransphormationGoal: TransphormationGoalTypes[] = [
  Goal.Lose,
  Goal.Maintain,
  Goal.Gain,
];
export const TrainingProgramTypes: ProgramTypeObj[] = [
  {
    name: 'EMOM',
    value: 1,
  },
  {
    name: 'Traditional',
    value: 2,
  },
  {
    name: 'Cross Training',
    value: 3,
  },
  {
    name: '12 Week Foundations of Training',
    value: 4,
  },
  {
    name: 'Strength Training',
    value: 9,
  },
  {
    name: 'Cardio',
    value: 11,
  },
  {
    name: 'CrossFit',
    value: 12,
  },
  {
    name: 'No equipment',
    value: 5,
  },
  {
    name: 'Dumbbells, Kettlebells, and/or bands',
    value: 6,
  },
];

export interface ProgramTypeObj {
  name: string;
  value: number;
}

export type TransphormationGoalTypes = Goal.Lose | Goal.Maintain | Goal.Gain;
export const ActiveLevels = ['Sedentary', 'Light Activity', 'Very Active'];
export type ActiveLevelTypes = 'Sedentary' | 'Light Activity' | 'Very Active';

type AvailableTypes = 'track' | 'home' | 'custom';

export interface WorkoutSessionApi {
  id?: number;
  uuid?: string;
  workout_id: number;
  workout_date: string;
  transphormer_id?: number;
  attachment_url_full?: string;
  attachment_url_thumbnail?: string;
  completed: boolean;
  switched_to_home?: boolean;
  workout_data?: WorkoutExerciseData[] | null;
  workout?: Workout;
  notes: WorkoutNote | null;
  type?: 'track' | 'home' | 'custom' | 'temp';
  available_types?: AvailableTypes[];
  session_exercises: WorkoutSessionExercise[];
  created_at?: string;
  updated_at?: string;
  cooldown_completed?: boolean;
  warmup_completed?: boolean;
  swap_session?: SwapSession;
  is_swappable?: boolean;
}

export interface WorkoutSessionV3 {
  id?: number;
  uuid?: string;
  workout_id: number;
  workout_date: string;
  transphormer_id?: number;
  expanded: boolean;
  completed: boolean;
  attachment_url_full?: string;
  attachment_url_thumbnail?: string;
  expansions: { [key: string]: boolean };
  workout_data?: WorkoutExerciseData[] | null;
  workout?: Workout;
  notes: string;
  type?: 'track' | 'custom';
  session_exercises: WorkoutSessionExercise[];
  created_at?: string;
  updated_at?: string;
  cooldown_completed?: boolean;
  warmup_completed?: boolean;
  swap_days: Swap[];
}

export interface WorkoutSessionV3WithMetadata extends WorkoutSessionV3 {
  // Does this session exist?
  status: SessionExistence;
}

export const isWorkoutSession = (
  ws: WorkoutSessionV3,
): ws is WorkoutSessionV3 => {
  return (
    ws.uuid !== undefined && ws.workout_id && ws.workout_date !== undefined
  );
};

export interface WorkoutSessionDescriptor {
  id: string;
}

export interface SwapSession {
  id: number;
  workout_date: string;
}

export interface WorkoutSession extends WorkoutSessionApi {
  available_types_detail?: DetailTypes[];
  is_swappable?: boolean;
  surveys?: { [key: string]: SurveyApiResponse };
}

export interface DetailTypes {
  type: string;
  label: string;
  order?: number;
}

export interface Swap {
  created_at: string;
  deleted_at: string | null;
  updated_at: string | null;
  id: number;
  new_movement: Exercise;
  new_movement_id: number;
  original_movement: Exercise;
  transphormer_id?: number;
  original_movement_id: number;
  workout_session_id?: number;
  workout_session_uuid?: string;
}

export interface WorkoutExerciseData {
  exercise_group_id: number;
  exercise_id: number;
  group_movement_id: number;
  workout_session_id?: number;
  workout_info: WorkoutInfo[] | null;
  logged_on: string | null;
  completed_on: string | null;
}

export interface RepsAtWeightDataPoint {
  type: 'repsAtWeight';
  reps: number;
  weight: number;
}
export interface RepsDataPoint {
  type: 'reps';
  reps: number;
}
export interface RoundsPlusRepsDataPoint {
  type: 'roundsPlusReps';
  reps: number;
  rounds: number;
}
export interface CalsDataPoint {
  type: 'cals';
  cals: number;
}
export interface WeightDataPoint {
  type: 'weight';
  weight: number;
}
export interface TimeDataPoint {
  type: 'time';
  seconds: number;
}
export interface CompletionDataPoint {
  type: 'completion';
  completed: boolean;
}

export type ActivityDataValue = (
  | RepsDataPoint
  | RepsAtWeightDataPoint
  | RoundsPlusRepsDataPoint
  | CalsDataPoint
  | WeightDataPoint
  | TimeDataPoint
  | CompletionDataPoint
) & { notes?: string };
export type ActivityDataPoint = CoreComplexExerciseData & ActivityDataValue;

export function addTypeToActivityData(
  adp: Partial<CoreComplexExerciseData>,
): Partial<ActivityDataPoint> {
  if (adp.hasOwnProperty('completed') || adp.group_type === 'completed') {
    return {
      ...adp,
      type: 'completion',
    };
  } else if (
    (adp.hasOwnProperty('reps') && adp.hasOwnProperty('rounds')) ||
    adp.group_type === 'roundsPlusReps'
  ) {
    return {
      ...adp,
      type: 'roundsPlusReps',
    };
  } else if (
    (adp.hasOwnProperty('reps') && adp.hasOwnProperty('weight')) ||
    adp.group_type === 'repsAtWeight'
  ) {
    return {
      ...adp,
      type: 'repsAtWeight',
    };
  } else if (adp.hasOwnProperty('reps') || adp.group_type === 'reps') {
    return {
      ...adp,
      type: 'reps',
    };
  } else if (adp.hasOwnProperty('seconds') || adp.group_type === 'time') {
    return {
      ...adp,
      type: 'time',
    };
  } else if (adp.hasOwnProperty('weight') || adp.group_type === 'weight') {
    return {
      ...adp,
      type: 'weight',
    };
  } else if (adp.hasOwnProperty('cals') || adp.group_type === 'cals') {
    return {
      ...adp,
      type: 'cals',
    };
  }
  return adp;
}

export interface CoreComplexExerciseData {
  workout_session_uuid: string;
  group_id: number;
  group_type?: string;
  id?: number;
  uuid: string;
}

export interface ComplexExerciseServerData {
  id: string;
  createdAt: string;
  data: CoreComplexExerciseData;
  group_id: number;
  updatedAt: string;
  workout_session_uuid: string;
}

export interface WorkoutInfo {
  reps?: number | null;
  weight: number | null;
}

export interface Activity {
  workouts: Workout[];
  sessions: WorkoutSession[];
  // steps?: any;
  // time?: any;
  trainingConfig?: TrainingConfig;
}

export interface Workout {
  type?: 'custom' | 'regular';
  id: number;
  cooldown?: WorkoutVideo;
  warmup?: WorkoutVideo;
  warmup_completed?: boolean;
  cooldown_completed?: boolean;
  training_id: number;
  workout_date: string;
  is_rest_day?: number;
  name?: string;
  day_no?: number;
  week: number;
  day: number;
  exercise_groups: WorkoutGroup[];
  training?: Training;
  trainingConfig?: TrainingConfig;
}

export interface WorkoutVideo {
  length: number;
  poster: string;
  title: string;
  type: string;
  url: string;
}

export enum WorkoutGroupType {
  straightSet = 1,
  superSet = 2,
  none = 3,
}

export interface WorkoutGroup {
  id: number;
  workout_id: number;
  exercise_group_template_id?: number;
  name?: string;
  instructions?: string;
  group_time?: number;
  total_sets?: number;
  repeat?: number;
  type?: WorkoutGroupType;
  order_number: number;
  created_at: string;
  updated_at: string;
  group_scores?: Partial<CoreComplexExerciseData>;
  group_movement_id?: number;
  scoring_type:
    | 'reps'
    | 'repsAtWeight'
    | 'roundsPlusReps'
    | 'cals'
    | 'completion'
    | 'weight'
    | 'time'
    | 'movement';
  exercises: WorkoutExercise[];
  isComplete?: boolean;
}

export interface Training {
  id: number;
  program_type: number;
  training_level: number;
  training_id: number;
  current_day?: number;
  subscription_type: string;
  description?: string;
  start_date: string;
  end_date: string;
  name: string;
  author?: string;
  cover_image: string;
  cover_image_optimized: string;
  type?: 'trainings' | 'training-configs';
  workouts: Workout[];
  status: Status;
  length?: number;
  numberOfDays?: number;
}

export interface Status {
  progress?: number;
  context?: string;
  icon: string;
  theme: string;
}

interface BaseExercise {
  name: string;
}

export interface Exercise extends BaseExercise {
  id: number;
  modification_notes: string;
  video: string;
  video_url: string;
  link?: string;
  description: string;
  isEditing?: boolean;
  tags?: Tag[];
  expanded?: boolean;
  alternate_exercises?: Exercise[];
}

export interface WorkoutExercise extends BaseExercise {
  expanded?: boolean;
  completed?: boolean;
  id: number;
  exercise_group_id: number;
  exercise_group_template_exercise_id?: number;
  exercise_id: number;
  total_sets: null;
  reps?: string;
  record_type?: number;
  instructions?: string;
  order_number: number;
  created_at: string;
  updated_at: string;
  swap: Swap;
  time_period: number;
  exercise: Exercise;
  last_exercise_records?: string;
  movementData?: WorkoutInfo[];
  exertion_settings?: ExertionSettings;
}

export interface ExertionSettings {
  value: string;
  type: ExertionSettingsEnum;
}

export enum ExertionSettingsEnum {
  RPE = 1,
  RIR = 2,
  '%' = 3,
}

export interface WorkoutExercisePivot extends Exercise {
  pivot: {
    instructions: string;
    record_type: ExerciseTypes;
    id: number;
    total_reps: number | null;
  };
}

export interface WorkoutNote {
  id: number;
  transphormer_id: number;
  workout_session_id: number;
  notes: string;
}

export type ExerciseTypes = 'Weight' | 'Weight and Reps' | 'Nothing';

export interface WorkoutSessionExercise {
  id: number;
  workout_session_id: number;
  exercise_id: number;
  exercise_group_id: number;
  group_movement_id: number;
  set: number;
  reps: number | null;
  weight: number | null;
}

export interface WorkoutSessionExerciseHistory {
  id?: string;
  attributes?: HistoryMovementsAttributes[];
  workout_date: string;
  workout_session_exercises: WorkoutSessionExercise[];
}

export interface WorkoutSummary {
  completed?: boolean;
  day: string;
  future: boolean;
}

export interface ModificationData<T = Date> {
  rest_days?: RestDayRecord<T>[];
  swap_days?: SwapDayRecord<T>[];
  offsets?: OffsetRecord<T>[];
}

export interface TrainingConfigApi {
  id: number;
  training_id: number;
  start_date: string;
  end_date: string;
  transphormer_id: number;
  next_config_id?: number;
  previous_config_id?: number;
  repeats: boolean;
  modifications?: ModificationData<string>;
  training?: Training;
}

export interface TrainingDateIndexRecord {
  end: Date;
  id: number | string;
  next: number | string;
  start: Date;
}

export interface ActivityConfigIndexRecord {
  end: Date;
  id: number | string;
  start: Date;
  uuid: string;
}

export interface TrainingConfig<T = Date> {
  id: number;
  training_id: number;
  start_date: T;
  end_date: T;
  transphormer_id: number;
  next_config_id?: number;
  previous_config_id?: number;
  repeats: boolean;
  modifications?: ModificationData<T>;
  training?: Training;
  projected_start_date?: Date;
  projected_end_date?: Date;
  finished?: boolean;
  canPickBackUp?: boolean;
}

export interface HistoryMovement {
  attributes: HistoryMovementsAttributes;
  id: string;
  type: string;
}

export interface HistoryMovementsAttributes {
  createdAt: string;
  description: string;
  link: string;
  modificationNotes: string;
  name: string;
  trackForXrm: boolean;
  updatedAt: string;
  video: string;
  id?: string;
}

export interface HistoryStats {
  createdAt: string;
  trackForXrm: boolean;
  link: string;
  name: string;
  description: string;
  modificationNotes: string;
  video: string;
  id: number;
  updatedAt: string;
}
