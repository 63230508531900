import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { MacrosBarComponent } from './macros-bar/macros-bar.component';
import { DashboardMacroComponent } from './macro/dashboard-macro.component';
import { MealChooserComponent } from './meal-chooser/meal-chooser.component';
import { SupplementsComponentsModule } from './cmc-supplements/supplements.module';
import { ScannerOverlayComponent } from './scanner-overlay/scanner-overlay.component';

@NgModule({
  imports: [IonicModule, CommonModule, SupplementsComponentsModule],
  declarations: [
    DashboardMacroComponent,
    MacrosBarComponent,
    MealChooserComponent,
    ScannerOverlayComponent,
  ],
  exports: [
    DashboardMacroComponent,
    MacrosBarComponent,
    MealChooserComponent,
    ScannerOverlayComponent,
  ],
})
export class NutritionComponentsModule {}
