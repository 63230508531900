<ion-grid class="top">
  <ion-row class="ion-align-items-center">
    <ion-col>
      <ng-container *ngIf="!showCurrentPlan; else showCurrent">
        <ion-checkbox
          class="uppercase"
          justify="space-between"
          (ionChange)="selectTier.emit(this.tier)"
          [checked]="selectedTierId === this.tier.id"
          mode="ios"
        >
          {{ tier.name }}
        </ion-checkbox>
      </ng-container>

      <ng-template #showCurrent>
        <ion-grid>
          <ion-row>
            <ion-col>
              <h2 class="uppercase">{{ tier.name }}</h2>
            </ion-col>
            <ng-container *ngIf="selectedTierId === this.tier.id">
              <ion-col class="ion-align-self-center ion-text-end">
                <ion-label class="fw-semibold">Current Plan</ion-label>
              </ion-col>
            </ng-container>
          </ion-row>
          <ng-container *ngIf="selectedTierId === this.tier.id">
            <ion-row>
              <ion-col class="ion-align-self-center">
                <span>{{ autoRenewText }}</span>
              </ion-col>
            </ion-row>
          </ng-container>
        </ion-grid>
      </ng-template>
    </ion-col>
  </ion-row>
</ion-grid>

<ng-container *ngFor="let price of tier.prices">
  <ion-chip class="ion-margin-end uppercase">
    <ng-container *ngIf="price?.isBestValue">
      <ion-icon name="star-outline"></ion-icon>
      <ion-label>BEST VALUE: &nbsp;</ion-label>
    </ng-container>
    {{ price.title }}
  </ion-chip>
</ng-container>

<ion-grid class="bottom ion-margin-top">
  <ng-container *ngIf="tier.additionalInfo">
    <ion-row class="ion-align-items-top">
      <ion-text class="additional-info fw-semibold ion-padding-bottom">
        {{ tier.additionalInfo }}
      </ion-text>
    </ion-row>
  </ng-container>

  <ion-row
    class="ion-align-items-top ion-margin-bottom"
    *ngFor="let pointer of tier.pointers"
  >
    <ion-col size="1" class="ion-no-padding ion-text-center">
      <ion-icon [name]="pointer.icon" color="primary"></ion-icon>
    </ion-col>

    <ion-col class="ion-no-padding">
      <ion-text class="fw-semibold">{{ pointer.name }}</ion-text>
    </ion-col>
  </ion-row>
</ion-grid>
