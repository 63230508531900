import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  LoadLeaderboard,
  LoadLeaderboards,
  LoadMoreLeaderboards,
  Reset,
} from './steps-leaderboard.actions';
import { StepsLeaderboardService } from '../services/steps-leaderboard.service';
import { StepsLeaderboard, StepsLeaderboardRecord } from '../types';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WrappedApiPaginatedResponse } from '../../../interfaces';
import produce from 'immer';

export const STEPS_LEADERBOARD_STATE_TOKEN =
  new StateToken<StepsLeaderboardStateModel>('stepsLeaderboard');
export const STEPS_LEADERBOARD_STATE_EMPTY: StepsLeaderboardStateModel = {
  isLoading: false,
  leaderboardData: {},
  leaderboards: {
    data: null,
    meta: null,
  },
};

interface LeaderboardData
  extends WrappedApiPaginatedResponse<StepsLeaderboardRecord[]> {
  info: StepsLeaderboard;
  isLoading: boolean;
  status: 'unloaded' | 'loading' | 'loaded' | 'error';
}

export interface ExtendedLeaderboard extends LeaderboardData {
  paginationData: LeaderboardTraversalData;
}

export interface StepsLeaderboardStateModel {
  isLoading: boolean;
  leaderboardData: { [key: number]: LeaderboardData };
  leaderboards: WrappedApiPaginatedResponse<StepsLeaderboard[]>;
}

export interface LeaderboardTraversalData {
  id: number;
  filter_type: string;
  next?: StepsLeaderboard;
  previous?: StepsLeaderboard;
  month?: StepsLeaderboard;
  week?: StepsLeaderboard;
}

@State<StepsLeaderboardStateModel>({
  name: STEPS_LEADERBOARD_STATE_TOKEN,
  defaults: STEPS_LEADERBOARD_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class StepsLeaderboardState {
  constructor(private stepsLeaderboardService: StepsLeaderboardService) {}

  @Selector([StepsLeaderboardState])
  static isLoading(state: StepsLeaderboardStateModel) {
    return state.isLoading;
  }

  /**
   * Dynamic selector to grab a particular ID.
   * @param id
   */
  static leaderboardById(id: number) {
    return createSelector(
      [StepsLeaderboardState],
      (state: StepsLeaderboardStateModel) => {
        const info = state.leaderboards.data.find(l => l.id === id);
        return {
          ...state.leaderboardData[id],
          info: state.leaderboards.data.find(l => l.id === id),
          paginationData: createPaginatedData(info, state.leaderboards.data),
        };
      },
    );
  }

  /**
   * Dynamic selector to grab a particular ID.
   * @param id
   */
  static latestLeaderboardByFilterType(filterType: string) {
    return createSelector(
      [StepsLeaderboardState],
      (state: StepsLeaderboardStateModel) => {
        if (state.leaderboards.data.find(l => l.filter_type === filterType)) {
          return state.leaderboards.data.find(
            l => l.filter_type === filterType,
          );
        } else {
          return state.leaderboards.data[0];
        }
      },
    );
  }

  @Selector([StepsLeaderboardState])
  static latestLeaderboard(state: StepsLeaderboardStateModel) {
    return state.leaderboards.data[0] || null;
  }

  @Selector([StepsLeaderboardState])
  static availableFilterTypes(state: StepsLeaderboardStateModel) {
    return state.leaderboards.data
      .map(l => ({ filter_type: l.filter_type, filter_name: l.filter_name }))
      .reduce((a, c) => {
        if (
          a.findIndex(
            item =>
              item.filter_name === c.filter_name &&
              item.filter_type === c.filter_type,
          ) !== -1
        ) {
          return a;
        } else {
          return [...a, c];
        }
      }, []);
  }

  @Selector([StepsLeaderboardState.availableFilterTypes, StepsLeaderboardState])
  static latestOfFilterTypes(
    filterTypes: { filter_name: string; filter_type: string }[],
    state: StepsLeaderboardStateModel,
  ) {
    return filterTypes.map(ft => {
      return {
        ...state.leaderboards.data.find(
          li => li.filter_type === ft.filter_type,
        ),
        title: ft.filter_name,
      };
    });
  }

  static latestByFilterType(filter_type: string) {
    return createSelector(
      [StepsLeaderboardState],
      (state: StepsLeaderboardStateModel) => {
        return state.leaderboards.data
          .reverse()
          .find(i => i.filter_type === filter_type);
      },
    );
  }

  @Selector()
  static leaderboards(state: StepsLeaderboardStateModel) {
    return state.leaderboards;
  }

  @Action(LoadLeaderboard)
  loadLeaderboard(
    ctx: StateContext<StepsLeaderboardStateModel>,
    { id, page, append }: LoadLeaderboard,
  ) {
    const existing = ctx.getState().leaderboardData;

    if (!existing[id] || existing[id].status !== 'loaded') {
      ctx.setState(
        produce(draft => {
          draft.leaderboardData[id] = {
            status: 'loading',
            isLoading: true,
            data: [],
            meta: null,
          };
        }),
      );
    }

    return this.stepsLeaderboardService.getLeaderboard(id, page).pipe(
      tap(leaderboard => {
        ctx.setState(
          produce(draft => {
            draft.leaderboardData[id].isLoading = false;
            draft.leaderboardData[id].status = 'loaded';
            draft.leaderboardData[id].meta = leaderboard.meta;
            if (append) {
              draft.leaderboardData[id].data = [
                ...draft.leaderboardData[id].data,
                ...leaderboard.data,
              ];
            } else {
              draft.leaderboardData[id].data = leaderboard.data;
            }
          }),
        );
      }),
    );
  }

  @Action(LoadLeaderboards)
  loadLeaderboards(ctx: StateContext<StepsLeaderboardStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.stepsLeaderboardService
      .getLeaderboards()
      .pipe(
        tap(leaderboards => ctx.patchState({ leaderboards, isLoading: false })),
      );
  }

  @Action(LoadMoreLeaderboards)
  loadMoreLeaderboards(ctx: StateContext<StepsLeaderboardStateModel>) {
    const { current_page, last_page } = ctx.getState().leaderboards.meta;

    if (current_page < last_page) {
      return this.stepsLeaderboardService
        .getLeaderboards(current_page + 1)
        .pipe(
          tap(leaderboards =>
            ctx.setState(
              produce((draft: StepsLeaderboardStateModel) => {
                draft.leaderboards.data.push(...leaderboards.data);
                draft.leaderboards.meta = leaderboards.meta;
              }),
            ),
          ),
        );
    }
  }

  @Action(Reset)
  reset(ctx: StateContext<StepsLeaderboardStateModel>) {
    ctx.setState(STEPS_LEADERBOARD_STATE_EMPTY);
    return of(STEPS_LEADERBOARD_STATE_EMPTY);
  }
}

const sortByDate = (a: StepsLeaderboard, b: StepsLeaderboard) =>
  a.start_date > b.start_date ? 1 : -1;

function createPaginatedData(
  currentItem: StepsLeaderboard,
  dataset: StepsLeaderboard[],
): LeaderboardTraversalData {
  const paginationData: LeaderboardTraversalData = {
    id: currentItem.id,
    filter_type: currentItem.filter_type,
  };

  const weeklyItemsOfMyType = dataset
    .filter(
      i => i.filter_type === currentItem.filter_type && i.schedule === 'week',
    )
    .sort(sortByDate);

  const monthlyItemsOfMyType = dataset
    .filter(
      i => i.filter_type === currentItem.filter_type && i.schedule === 'month',
    )
    .sort(sortByDate);

  if (currentItem.schedule === 'week') {
    paginationData.next = weeklyItemsOfMyType.find(
      i => i.start_date > currentItem.start_date,
    );
    paginationData.previous = [...weeklyItemsOfMyType]
      .reverse()
      .find(i => i.start_date < currentItem.start_date);

    paginationData.month = monthlyItemsOfMyType.find(
      i =>
        i.start_date <= currentItem.start_date &&
        i.end_date >= currentItem.start_date,
    );
  }

  if (currentItem.schedule === 'month') {
    paginationData.next = monthlyItemsOfMyType.find(
      i => i.start_date > currentItem.start_date,
    );
    paginationData.previous = [...monthlyItemsOfMyType]
      .reverse()
      .find(i => i.start_date < currentItem.start_date);

    paginationData.week =
      weeklyItemsOfMyType.find(
        i =>
          i.start_date <= currentItem.start_date &&
          i.end_date > currentItem.start_date,
      ) ||
      weeklyItemsOfMyType.find(i => i.start_date >= currentItem.start_date);
  }

  return paginationData;
}
