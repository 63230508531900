<ion-content>
  <div class="full-height flexible-container ion-padding">
    <div class="div_header">
      <div class="title">{{ copyTitle }}</div>
      <div class="dim" tappable (click)="close()">
        <ion-icon mode="md" name="close"></ion-icon>
      </div>
    </div>
    <div *ngIf="meals.length > 0; else mealEmpty">
      <app-calendar-mini
        [today]="today"
        [selectedDate]="toDate"
        (dateSelected)="dateChange($event)"
      ></app-calendar-mini>
      <app-meal-chooser
        *ngIf="meals.length === 1"
        [selectedMeal]="currentMeal"
        (mealChanged)="currentMeal = $event"
        title="To which meal?"
      ></app-meal-chooser>
      <div class="flex mt-auto">
        <ion-button fill="clear" (click)="close()"> Cancel </ion-button>
        <ion-button
          [disabled]="meals.length === 1 && !currentMeal"
          (click)="copy()"
        >
          Save
        </ion-button>
      </div>
    </div>
    <ng-template #mealEmpty>
      <div class="empty-state">No Meals to be copied.</div>
    </ng-template>
  </div>
</ion-content>
