import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  addDays,
  addWeeks,
  endOfWeek,
  format,
  getDay,
  isSameDay,
  startOfWeek,
} from 'date-fns';

@Component({
  selector: 'app-calendar-mini',
  templateUrl: './calendar-mini.component.html',
  styleUrls: ['./calendar-mini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarMiniComponent {
  @Output()
  public dateSelected: EventEmitter<Date> = new EventEmitter<Date>();

  // What day is "today"?
  private _today = new Date();

  // What is the currently selected date?
  public _selectedDate = addDays(new Date(), 1);

  // The current offset used to show the calendar at the top.
  private _weekOffset = 0;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @Input()
  set selectedDate(value: Date | string) {
    this._selectedDate = value ? new Date(value) : this._selectedDate;
  }

  get selectedDate() {
    return this._selectedDate;
  }

  @Input()
  set today(value: Date) {
    this._today = value;
    this.week = getDay(this._today) === 6 ? 1 : 0;
  }

  get today(): Date {
    return this._today;
  }

  @Input()
  set week(value: number) {
    this._weekOffset = value;
    this.changeDetectorRef.markForCheck();
  }

  get week() {
    return this._weekOffset;
  }

  // Returns the today offset by the current week offset.
  get baseDate() {
    return addWeeks(this.today, this._weekOffset);
  }

  // Returns the array of days shown to the user in the calendar listing.
  get days() {
    return Array.from(Array(7).keys()).map((_, i) => ({
      weekday: i % 7,
      day: addDays(startOfWeek(new Date(this.baseDate)), i),
    }));
  }

  public weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  public get startDate() {
    return format(startOfWeek(new Date(this.baseDate)), 'MMM d');
  }

  public get endDate() {
    return format(endOfWeek(new Date(this.baseDate)), 'MMM d');
  }

  public get formattedSelectedDate() {
    return format(new Date(this._selectedDate), 'MMM d');
  }

  async selectDate(day: Date) {
    this.selectedDate = day;
    this.dateSelected.emit(new Date(this._selectedDate));
  }

  public equalDate(date) {
    return isSameDay(new Date(date), new Date(this.today));
  }

  trackByDay(index: number, day: { weekday: number; day: string }) {
    return day.weekday;
  }

  isSelected(day: Date) {
    return isSameDay(day, this._selectedDate);
  }
}
