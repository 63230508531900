import { addDays, addSeconds, isAfter, subDays, subSeconds } from 'date-fns';
import {
  TrainingConfig,
  TrainingDateIndexRecord,
} from '../../../../interfaces';
import { isSameDayOrAfter, isSameDayOrBefore } from '../../../../helpers/date';
import { OffsetRecord } from '../../types';

/**
 * Given a config and a collection of configs, return the plan that comes next
 * either by direct association (next_config_id) or by date.
 * @param plan
 * @param configs
 */
export function nextConfigAfterPlan(
  plan: TrainingConfig,
  configs,
): null | TrainingConfig {
  if (plan.next_config_id) {
    return configs.find(c => c.id === plan.next_config_id);
  }

  return configs
    .filter(c => isAfter(c.start_date, plan.start_date))
    .sort((a, b) => (a.start_date < b.start_date ? -1 : 1))[0];
}

/**
 * Given a date and an index, return the index record that is valid for the
 * date or undefined.
 * @param date
 * @param dateIndex
 */
export function getTrainingConfigForDateWithIndex(date: Date, dateIndex) {
  return dateIndex.find(
    di =>
      isSameDayOrAfter(date, di.start) &&
      (di.end ? isSameDayOrBefore(date, di.end) : true),
  );
}

/** Filter functions to make the larger functions readable. Used for filtering configs. **/
const havingStartDate = i => i.start_date !== null;
const havingNoStartDate = i => i.start_date === null;

const configHasOffsets = (tc: Pick<TrainingConfig, 'modifications'>): boolean =>
  tc.modifications?.offsets &&
  Array.isArray(tc.modifications.offsets) &&
  tc.modifications.offsets.length > 0;

// A training config has an initial offset if there is an offset record with no recorded date.
const trainingConfigHasInitialOffset = (tc: TrainingConfig): boolean =>
  tc.modifications?.offsets &&
  Array.isArray(tc.modifications.offsets) &&
  offsetsHasInitialOffset(tc.modifications.offsets);

// Does an OffsetRecord[] have an initial offset record?
const offsetsHasInitialOffset = (offsets: OffsetRecord[] = []) =>
  (offsets ?? []).filter(i => i.recorded === undefined || i.recorded === null)
    .length > 0;

// Does a TrainingConfig have an initial offset record?
const trainingConfigHasNonInitialOffsets = (tc: TrainingConfig): boolean =>
  tc.modifications?.offsets &&
  Array.isArray(tc.modifications.offsets) &&
  hasNonInitialOffsets(tc.modifications.offsets);

// Does an OffsetRecord[] have non-initial offset records?
const hasNonInitialOffsets = (o: OffsetRecord[]) =>
  nonInitialOffsets(o).length > 0;
const nonInitialOffsets = (o): OffsetRecord[] => o.filter(i => !!i.recorded);

/**
 * Returns the initial offset value for a training config. Returns 0 if there is none.
 * @param tc
 */
const trainingConfigInitialOffset = (tc: TrainingConfig): number =>
  getInitialOffset(tc.modifications?.offsets || []);
const getInitialOffset = (offsets: OffsetRecord[]) =>
  offsets.reduce((a, c) => a + (c.recorded ? 0 : c.offset), 0);

// The projected length of a training config is the number of workout days minus any initial offset days.
const getTrainingConfigLength = (tc: TrainingConfig): number =>
  tc.training.length - trainingConfigInitialOffset(tc);

/**
 * Create a date index that allows for easy lookup of what date is authoritative for a given set of configs.
 *
 * The result is a list of index records with `start` and `end` attributes for each configuration as well as
 * a pointer to the next plan, if one is provided by the config. If no records contain a `start_date` then
 * no index will be created as we need at least one `start_date` config to generate a reference.
 *
 * @param trainingConfigs
 */
export function createTrainingDateIndex(
  trainingConfigs: TrainingConfig[],
): TrainingDateIndexRecord[] {
  const index: TrainingDateIndexRecord[] = [];

  // Split the configs up into two groups. Process the ones with start dates first and then the ones without them.
  // We need this because the ones having the start dates will provide the reference entries we need to make sure
  // that the items that do not have a start/end date will project properly.
  [
    ...trainingConfigs.filter(havingStartDate),
    ...trainingConfigs.filter(havingNoStartDate),
  ].forEach(tc => {
    const indexEntry: TrainingDateIndexRecord = {
      id: tc.id,
      start: null,
      end: null,
      next: null,
    };

    // We have encountered a training plan which is null. This can be because it was removed at the server level.
    // In order to protect the consistency of the index, just pretend it doesn't exist.
    if (tc.training === null) {
      return;
    }

    let referenceEntry, nextScheduledPlan;

    if (!tc.start_date) {
      // If there is no start date, we look for a plan already in the index that references this plan.
      referenceEntry = index.find(di => di.next === tc.id);

      // Not much we can do if there is no start date and no referencing entry.
      if (!referenceEntry) {
        // Note that this isn't really an error. Just because a config is orphaned doesn't mean that
        // there is a problem. Most users will probably have some of these to start off with.
        // tslint:disable-next-line:no-console
        return;
      }

      // If the reference entry has an end (and it should), we set the start by adding 1 second to the
      // end of the reference entry.
      if (referenceEntry['end']) {
        indexEntry['start'] = addSeconds(referenceEntry['end'], 1);
      }

      // Find the next scheduled plan that follows. This would cover instances where a plan has a start_date
      // already set in the future (for example, a user wants to start a plan on a particular day).
      nextScheduledPlan = nextConfigAfterPlan(
        { ...tc, start_date: indexEntry['start'] },
        trainingConfigs,
      );
    } else {
      // If we have a start date, then use that.
      indexEntry['start'] = tc.start_date;
      nextScheduledPlan = nextConfigAfterPlan(tc, trainingConfigs);
    }

    // The end will be either the returned end_date, the next planned start date (if in the future)
    // or the planned end. If a plan already has an end date, then the plan was already completed.
    if (tc.end_date) {
      indexEntry['end'] = tc.end_date;
    } else {
      // Project the end date by just getting the length of the config and substracting a second.
      indexEntry['end'] = subSeconds(
        addDays(indexEntry['start'], getTrainingConfigLength(tc)),
        1,
      );
    }

    // Let's figure out what is next. `null` is OK, too.
    indexEntry['next'] = tc.next_config_id || nextScheduledPlan?.id || null;

    // At this point we have an index entry. If there are non-initial offsets, we need to take those into account.
    if (trainingConfigHasNonInitialOffsets(tc)) {
      // Iterate over the offsets and for each offset create additional index records.
      tc.modifications.offsets.forEach(offset => {
        indexEntry['end'] = subSeconds(
          addDays(indexEntry['start'], offset.offset),
          1,
        );
        // The record will start on the recorded date and then end accordingly.
        index.push({
          id: tc.id,
          next: null,
          start: offset.recorded,
          end: subSeconds(
            addDays(
              offset.recorded,
              getTrainingConfigLength(tc) - offset.offset,
            ),
            1,
          ),
        });
      });
    }

    index.push(indexEntry);
  });

  return index;
}

// Get the end date for a config based on the number of workouts.
export const calculateEndDateForConfig = c =>
  subSeconds(addDays(c.start_date, c.training.length), 1);

// State helpers. For a given config, is it finished? Can it be picked back up?
const configIsFinished = (c: TrainingConfig) =>
  (c.end_date && c.end_date === calculateEndDateForConfig(c)) || false;
const configCanBePickedBackUp = (c: TrainingConfig, date) =>
  !configIsFinished(c) && isAfter(c.end_date, subDays(date, 7));

// Helper function to add additional state to the config for a given date.
export function enrichConfigWithDates(c, date = new Date()) {
  const projectedEndDate = calculateEndDateForConfig(c),
    finished = configIsFinished(c),
    canPickBackUp = configCanBePickedBackUp(c, date);
  return {
    ...c,
    projected_end_date: projectedEndDate,
    finished,
    canPickBackUp,
  };
}

// For a given offset configuration, calculate the correct date that given date would be.
export const getDayOffset = (
  offsets: OffsetRecord[] = [],
  date: Date = new Date(),
) =>
  offsets
    .filter(
      i =>
        i.recorded === undefined ||
        i.recorded === null ||
        isSameDayOrBefore(i.recorded, date),
    )
    .reduce((a, c) => a + c.offset, 0);

export {
  trainingConfigHasInitialOffset,
  trainingConfigHasNonInitialOffsets,
  offsetsHasInitialOffset,
  getInitialOffset,
  hasNonInitialOffsets,
  nonInitialOffsets,
  configHasOffsets,
};
