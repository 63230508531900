import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TrackedMeal } from '../../../interfaces';
import { Store } from '@ngxs/store';
import { CopyMealsToDay } from '../../../state/nutrition/nutrition.actions';
import { mySQLFormattedDate } from '../../../../../helpers';
import { ToastService } from '../../../../../services';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { addDays } from 'date-fns';

@Component({
  selector: 'app-copy-meals-day',
  templateUrl: './copy-meals-day.component.html',
  styleUrls: ['./copy-meals-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyMealsDayComponent implements OnInit {
  @Input()
  set initialSelection(value) {
    this.toDate = value ?? addDays(new Date(), 1);
  }

  public toDate: Date;

  @Input() public today = new Date();

  @Input() public meals: TrackedMeal[] = [];

  @Input() currentMeal = null;

  public buttonText = 'Copy all Meals';

  constructor(
    private modalController: ModalController,
    private changeDetection: ChangeDetectorRef,
    private store: Store,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    if (this.meals.length === 0) {
      this.buttonText = 'Do nothing';
    } else if (this.meals.length === 1) {
      this.buttonText = 'Copy Meal';
      this.currentMeal = this.meals[0].meal_count;
    } else {
      this.buttonText = 'Copy all Meals';
    }
  }

  public close() {
    this.modalController.dismiss();
  }

  public equalDate() {
    if (!this.toDate) {
      return true;
    }
    return mySQLFormattedDate(this.today) === mySQLFormattedDate(this.toDate);
  }

  get copyTitle() {
    if (this.meals.length > 1) {
      return 'Copy day to';
    }
    if (this.meals.length === 1 && this.meals[0].food_items.length > 1) {
      return 'Copy meal to';
    }
    return 'Copy item to';
  }

  dateChange(date: Date) {
    this.toDate = date;
    this.changeDetection.markForCheck();
  }

  public copy() {
    const meals = this.currentMeal
      ? this.meals.map(m => ({
          ...m,
          food_items: m.food_items.map(fi => ({
            ...fi,
            meal: this.currentMeal,
          })),
          meal_count: this.currentMeal,
        }))
      : this.meals;

    this.store
      .dispatch(new CopyMealsToDay(meals, this.toDate))
      .pipe(
        catchError(e => {
          this.toastService.flash('Unable to copy: ' + e.message);
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.modalController.dismiss({ toDate: this.toDate }).then();
      });
  }
}
