import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { FirebaseService } from '../firebase/firebase.service';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';
import { UserService } from '../user/user.service';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { AccountSetting } from '../../interfaces/account';
import { UpdateAccountSettings } from '../../store/actions/account-settings.actions';
import {
  AccountSettingsState,
  AccountSettingsStateModel,
} from '../../store/states/account-settings.state';
import { SubscriptionActivated } from '../../store/app.actions';
import { LoadDay } from '../../modules/training/state/activity/activity.actions';
import { jsonApiUrl } from '../../helpers';
import { LoadRecentData } from '../../modules/health/state/health.actions';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private accountSettings: AccountSettingsStateModel;
  private accountSettingsChanges$: Observable<AccountSettingsStateModel>;

  constructor(
    protected http: HttpClient,
    protected platform: Platform,
    protected userService: UserService,
    private firebase: FirebaseService,
    private store: Store,
    private tokenService: TokenService,
    private actions$: Actions,
    @Inject(RollbarService) public rollbar: Rollbar,
  ) {
    // Prepare stream of accountSettings changes
    this.accountSettingsChanges$ = this.store
      .select(AccountSettingsState)
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.tokenService.hasAccessToken$.pipe(filter(i => !i))),
      );

    this.tokenService.hasAccessToken$
      .pipe(filter(i => i))
      .subscribe(() => this.onLogin());

    if (this.platform.is('capacitor')) {
      // Upon activation of an account, always go ahead and turn on the live stream notifications.
      this.actions$
        .pipe(ofActionCompleted(SubscriptionActivated))
        .subscribe(() => {
          this.store.dispatch(
            new UpdateAccountSettings({
              ...this.accountSettings,
              receive_livestream_notification: true,
            }),
          );
          this.subscribeToLiveStream(true);
        });
    }
  }

  private onLogin() {
    this.store.dispatch(new LoadDay());
    this.store.dispatch(new LoadRecentData());

    // Listen to account settings changes and update local accountSettings variable
    this.accountSettingsChanges$.subscribe(
      accountSettings => (this.accountSettings = accountSettings),
    );

    this.syncAccountReminders(this.accountSettings);
  }

  public deleteAccount(transphormerId: string) {
    return this.http.post(
      jsonApiUrl(`account-deletions`),
      {
        data: {
          type: 'account-deletions',
          attributes: {
            transphormerId,
          },
        },
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
        }),
      },
    );
  }

  public async subscribeToLiveStream(subscribe: boolean) {
    // If the user doesn't exist, bail out!
    if (!this.userService.user) {
      return;
    }

    const canSeeLivestreams =
      !this.userService.user?.organization ||
      this.userService.user?.organization.config.can_view_1p_livestream;

    if (this.userService.isPaidUser() && subscribe && canSeeLivestreams) {
      console.log('[Notification]: Livestream Subscribed');
      await this.firebase.subscribeToLivestreamNotification();
    } else {
      console.log('[Notification]: Livestream Unsubscribed');
      await this.firebase.unsubscribeToLivestreamNotification();
    }
  }

  public async setMotivationReminder(subscribe: boolean) {
    if (subscribe) {
      console.log('[Notification]: Motivation Subscribed');
      await this.firebase.subscribeToMotivationNotification();
    } else {
      console.log('[Notification]: Motivation Unsubscribed');
      await this.firebase.unsubscribeToMotivationNotification();
    }
  }

  public async syncAccountReminders(settings: AccountSetting) {
    try {
      await this.subscribeToLiveStream(
        settings.receive_livestream_notification,
      );
      await this.setMotivationReminder(
        settings.receive_livestream_notification,
      );
    } catch (e) {
      this.rollbar.error('syncAccountReminders error', e);
    }
  }
}
