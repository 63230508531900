import {
  Product,
  Y2020_YEARLY_PRODUCT,
} from '../../../services/in-app-purchase/products';
import {
  PaymentTier,
  PaymentTierPrice,
  PaymentTierWithPricing,
  PaymentTierWithSkus,
  Tiers,
} from '../interfaces';

export const extractProductDetails = (
  products: Product[],
): PaymentTierPrice[] =>
  products.map((p: Product) => {
    return {
      tierId: null,
      title:
        p.billingPeriodUnit === 'every month'
          ? `${p.price} / Mo`
          : `${p.price} / Yr`,
      subtitle:
        p.billingPeriodUnit === 'every month'
          ? 'Billed Monthly'
          : `Billed Yearly`,
      sku: p.id,
      isBestValue:
        p.billingPeriodUnit === 'every year' && p.id === Y2020_YEARLY_PRODUCT,
    };
  });

/**
 * Will return the Tier with its prices sorted, best value price as last.
 * @param prices
 */
export function sortBestValue(prices: PaymentTierPrice[]): PaymentTierPrice[] {
  const bestValue = prices.find(p => p.isBestValue === true);
  if (!bestValue) {
    return prices;
  }

  const bestValueIndex = prices.indexOf(bestValue);
  const newPList = prices.splice(bestValueIndex - 1, 1);
  newPList.push(bestValue);

  return newPList;
}

export function getTierLevel(id: number) {
  switch (id) {
    case Tiers.Premium:
      return 2;
    case Tiers.Basic:
      return 1;
    case Tiers.Free:
      return 0;
  }
}

export function sortTiersByLevel<T extends PaymentTier>(tiers: T[]): T[] {
  return tiers.sort((t, b) => (t.tierLevel < b.tierLevel ? -1 : 1));
}

export function getTierPointers(id: number) {
  switch (id) {
    case Tiers.Premium:
      return PremiumPointers;
    case Tiers.Basic:
      return BasicPointers;
    case Tiers.Free:
      return FreePointers;
  }
}

export function addPricingToPaymentTier(
  tier: PaymentTierWithSkus,
  productDetails: PaymentTierPrice[],
): PaymentTierWithPricing {
  const tierPrices = productDetails.filter(pd => tier.skus.includes(pd.sku));
  return { ...tier, prices: sortBestValue(tierPrices) };
}

const PremiumPointers = [
  {
    icon: 'checkmark-circle-outline',
    name: 'Dedicated Advisor to help YOU earn results',
  },
  {
    icon: 'checkmark-circle-outline',
    name: '1-on-1 Chat with your Advisor',
  },
  {
    icon: 'checkmark-circle-outline',
    name: 'Weekly Feedback to keep progress going',
  },
  {
    icon: 'checkmark-circle-outline',
    name: 'Community Features to interact with peers',
  },
];

const BasicPointers = [
  { icon: 'checkmark-circle-outline', name: 'Nutrition + Water Tracker' },
  { icon: 'checkmark-circle-outline', name: 'Step counter + Walk Timer' },
  {
    icon: 'checkmark-circle-outline',
    name: 'Variety of Workout Programs: Gym & Home',
  },
  { icon: 'checkmark-circle-outline', name: 'Conveniently Track Progress' },
  {
    icon: 'checkmark-circle-outline',
    name: 'Wide range of healthier recipes',
  },
  {
    icon: 'checkmark-circle-outline',
    name: 'Quick Daily Education and Motivation',
  },
  {
    icon: 'checkmark-circle-outline',
    name: 'Access to Transformation Challenges',
  },
];

const FreePointers = [
  { icon: 'checkmark-circle-outline', name: '' },
  { icon: 'checkmark-circle-outline', name: '' },
  { icon: 'checkmark-circle-outline', name: '' },
  { icon: 'checkmark-circle-outline', name: '' },
  { icon: 'checkmark-circle-outline', name: '' },
  { icon: 'checkmark-circle-outline', name: '' },
];

export const privacyText = `
Payment will be charged to your iTunes Account. Payment will be charged to your Google Play Account. The subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period. Your account will be charged for renewal within 24-hours prior to the end of the current period. Subscriptions may be managed via Account Settings after purchase.
`;
