import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionsService } from './permissions.service';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard {
  constructor(
    private permissionsService: PermissionsService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const requiredPermission = route.data.requiredPermission;
    const baseRoute = route.data.baseRoute ?? ['/dashboard'];

    if (!requiredPermission) {
      this.rollbar.warn('No required permission was defined for this route.', {
        route: route.url,
        routeData: route.data,
      });
      return of(true);
    }

    return this.permissionsService.hasAccessTo(requiredPermission).pipe(
      map(hasAccess => {
        if (!hasAccess) {
          this.rollbar.warn(
            `The user does not have permission for ${requiredPermission}`,
          );
          this.router.navigate(baseRoute);
        }
        return hasAccess;
      }),
    );
  }
}
