// Apparently you cannot "re-use" a product on Google and I did not want to have to through all of the
// fuss of re-creating each of the products to make everything match. Live and learn, I guess. So each
// platform has it's own set of products.
/* eslint-disable @typescript-eslint/no-unused-vars */
import 'cordova-plugin-purchase';

export const APPLE_MONTHLY_PRODUCT = 'com.firstphorm.apps.premium_monthly';
export const APPLE_QUARTERLY_PRODUCT = 'com.firstphorm.apps.premium_quarterly';
export const APPLE_YEARLY_PRODUCT = 'com.firstphorm.apps.premium_yearly';
export const APPLE_MONTHLY_PRODUCT_BASIC = 'subscription.basic_monthly';
export const APPLE_YEARLY_PRODUCT_BASIC = 'subscription.basic_yearly';

export const GOOGLE_MONTHLY_PRODUCT =
  'com.firstphorm.apps.subscription_monthly';
export const GOOGLE_QUARTERLY_PRODUCT =
  'com.firstphorm.apps.subscription_quarterly';
export const GOOGLE_YEARLY_PRODUCT = 'com.firstphorm.apps.subscription_yearly';
export const GOOGLE_MONTHLY_PRODUCT_BASIC = 'subscription.basic';
export const GOOGLE_YEARLY_PRODUCT_BASIC = 'subscription.basic';

export const Y2020_MONTHLY_PRODUCT = 'subscription.2020.premium_monthly';
export const Y2020_MONTHLY_PRODUCT_BASIC = 'subscription.basic_monthly';
export const Y2020_QUARTERLY_PRODUCT = 'subscription.2020.premium_quarterly';
export const Y2020_YEARLY_PRODUCT = 'subscription.2020.premium_yearly';
export const Y2020_YEARLY_PRODUCT_BASIC = 'subscription.basic_yearly';

export const ADMIN_OVERRIDE = 'app.admin.conversion_from_paid_user_override';

export const AppleProducts = [
  APPLE_MONTHLY_PRODUCT,
  APPLE_QUARTERLY_PRODUCT,
  APPLE_YEARLY_PRODUCT,
  Y2020_MONTHLY_PRODUCT,
  Y2020_QUARTERLY_PRODUCT,
  Y2020_YEARLY_PRODUCT,
];

export const GoogleProducts = [
  GOOGLE_MONTHLY_PRODUCT,
  GOOGLE_QUARTERLY_PRODUCT,
  GOOGLE_YEARLY_PRODUCT,
  Y2020_MONTHLY_PRODUCT,
  Y2020_QUARTERLY_PRODUCT,
  Y2020_YEARLY_PRODUCT,
];

export const BasicProducts = [
  Y2020_YEARLY_PRODUCT_BASIC,
  Y2020_MONTHLY_PRODUCT_BASIC,
];

export const PremiumProducts = [
  GOOGLE_MONTHLY_PRODUCT,
  GOOGLE_QUARTERLY_PRODUCT,
  GOOGLE_YEARLY_PRODUCT,
  APPLE_MONTHLY_PRODUCT,
  APPLE_QUARTERLY_PRODUCT,
  APPLE_YEARLY_PRODUCT,
  Y2020_MONTHLY_PRODUCT,
  Y2020_QUARTERLY_PRODUCT,
  Y2020_YEARLY_PRODUCT,
];

const activeProducts = {
  monthly: Y2020_MONTHLY_PRODUCT,
  // quarterly: Y2020_QUARTERLY_PRODUCT,
  yearly: Y2020_YEARLY_PRODUCT,
  // monthly_basic: Y2020_MONTHLY_PRODUCT_BASIC,
  // yearly_basic: Y2020_YEARLY_PRODUCT_BASIC,
};

const ActiveAppleProducts = activeProducts;
const ActiveGoogleProducts = activeProducts;

export { ActiveAppleProducts, ActiveGoogleProducts };

export class Product {
  constructor(private product: CdvPurchase.Product) {}

  get id(): string {
    return this.product.id;
  }

  get term(): string {
    this;
    return (
      (this.product.pricing.billingPeriod &&
        this.product.pricing.billingCycles &&
        this.product.pricing.billingCycles +
          ' ' +
          this.product.pricing.billingPeriod) ||
      null
    );
  }

  get price(): string {
    return this.product.pricing.price || null;
  }

  get getOffer(): CdvPurchase.Offer {
    return this.product.getOffer();
  }

  /**
   * this will probably replace term
   */
  get billingPeriodUnit() {
    return `${CdvPurchase.Utils.formatBillingCycleEN(this.product.pricing)}`;
  }

  //Check that the receipt doesn't include any transaction for the given product
  get hasIntro() {
    return (
      !CdvPurchase.store.findInVerifiedReceipts(this.product).isIntroPeriod &&
      this.product.getOffer('Introductory').pricingPhases[0].priceMicros === 0
    );
  }

  get introPrice() {
    return this.product.offers[0].pricingPhases[0].price;
  }

  get introPriceMicros() {
    return this.product.offers[0].pricingPhases[0].priceMicros;
  }

  get priceMicros() {
    return this.product.pricing.priceMicros;
  }

  get plan(): string {
    if (this.product.pricing.billingPeriod === 'P1M') {
      return this.product.pricing.billingCycles === 1 ? 'monthly' : 'quarterly';
    }
    if (this.product.pricing.billingPeriod === 'P1Y') {
      return 'yearly';
    }
  }

  get state(): string {
    return CdvPurchase.store.get(this.product.id) ? 'valid' : 'invalid';
  }
}

export const testingProducts: () => CdvPurchase.Product[] = () => {
  const monthlyProduct: CdvPurchase.Product = new CdvPurchase.Product(
    {
      id: 'subscription.2020.premium_monthly',
      type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
      platform: CdvPurchase.Platform.APPLE_APPSTORE,
    },
    {
      canPurchase: (
        product: CdvPurchase.Product | CdvPurchase.Offer,
      ): boolean => true,
      owned: (product: CdvPurchase.Product): boolean => false,
    },
  );
  monthlyProduct.title = 'my title';
  monthlyProduct.description = 'A type of monthly recurring membership';
  monthlyProduct.addOffer(
    new CdvPurchase.Offer(
      {
        id: 'my monthly offer',
        pricingPhases: [
          {
            price: '$12.99',
            priceMicros: 12990000,
            currency: 'USD',
            billingPeriod: 'P1M',
            paymentMode: CdvPurchase.PaymentMode.PAY_AS_YOU_GO,
            recurrenceMode: CdvPurchase.RecurrenceMode.INFINITE_RECURRING,
          },
        ],
        product: monthlyProduct,
      },
      {
        canPurchase: (
          product: CdvPurchase.Product | CdvPurchase.Offer,
        ): boolean => true,
        order: (
          offer: CdvPurchase.Offer,
          additionalData?: CdvPurchase.AdditionalData,
        ): Promise<CdvPurchase.IError | undefined> =>
          new Promise<undefined>(resolve => resolve(undefined)),
      },
    ),
  );

  const yearlyProduct: CdvPurchase.Product = new CdvPurchase.Product(
    {
      id: 'subscription.2020.premium_yearly',
      type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
      platform: CdvPurchase.Platform.APPLE_APPSTORE,
    },
    {
      canPurchase: (
        product: CdvPurchase.Product | CdvPurchase.Offer,
      ): boolean => true,
      owned: (product: CdvPurchase.Product): boolean => false,
    },
  );
  yearlyProduct.title = 'my second title';
  yearlyProduct.description = 'A type of yearly recurring membership';
  yearlyProduct.addOffer(
    new CdvPurchase.Offer(
      {
        id: 'my yearly offer',
        pricingPhases: [
          {
            price: '$129.99',
            priceMicros: 129900000,
            currency: 'USD',
            billingPeriod: 'P1Y',
            paymentMode: CdvPurchase.PaymentMode.PAY_AS_YOU_GO,
            recurrenceMode: CdvPurchase.RecurrenceMode.INFINITE_RECURRING,
          },
        ],
        product: yearlyProduct,
      },
      {
        canPurchase: (
          product: CdvPurchase.Product | CdvPurchase.Offer,
        ): boolean => true,
        order: (
          offer: CdvPurchase.Offer,
          additionalData?: CdvPurchase.AdditionalData,
        ): Promise<CdvPurchase.IError | undefined> =>
          new Promise<undefined>(resolve => resolve(undefined)),
      },
    ),
  );

  const monthlyProductBasic: CdvPurchase.Product = new CdvPurchase.Product(
    {
      id: APPLE_MONTHLY_PRODUCT_BASIC,
      type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
      platform: CdvPurchase.Platform.APPLE_APPSTORE,
    },
    {
      canPurchase: (
        product: CdvPurchase.Product | CdvPurchase.Offer,
      ): boolean => true,
      owned: (product: CdvPurchase.Product): boolean => false,
    },
  );
  monthlyProductBasic.title = 'my title';
  monthlyProductBasic.description = 'A type of monthly recurring membership';
  monthlyProductBasic.addOffer(
    new CdvPurchase.Offer(
      {
        id: 'my monthly offer',
        pricingPhases: [
          {
            price: '$9.99',
            priceMicros: 9990000,
            currency: 'USD',
            billingPeriod: 'P1M',
            paymentMode: CdvPurchase.PaymentMode.PAY_AS_YOU_GO,
            recurrenceMode: CdvPurchase.RecurrenceMode.INFINITE_RECURRING,
          },
        ],
        product: monthlyProductBasic,
      },
      {
        canPurchase: (
          product: CdvPurchase.Product | CdvPurchase.Offer,
        ): boolean => true,
        order: (
          offer: CdvPurchase.Offer,
          additionalData?: CdvPurchase.AdditionalData,
        ): Promise<CdvPurchase.IError | undefined> =>
          new Promise<undefined>(resolve => resolve(undefined)),
      },
    ),
  );

  const yearlyProductBasic: CdvPurchase.Product = new CdvPurchase.Product(
    {
      id: APPLE_YEARLY_PRODUCT_BASIC,
      type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
      platform: CdvPurchase.Platform.APPLE_APPSTORE,
    },
    {
      canPurchase: (
        product: CdvPurchase.Product | CdvPurchase.Offer,
      ): boolean => true,
      owned: (product: CdvPurchase.Product): boolean => false,
    },
  );
  yearlyProductBasic.title = 'my second title';
  yearlyProductBasic.description = 'A type of yearly recurring membership';
  yearlyProductBasic.addOffer(
    new CdvPurchase.Offer(
      {
        id: 'my yearly offer',
        pricingPhases: [
          {
            price: '$99.99',
            priceMicros: 999900000,
            currency: 'USD',
            billingPeriod: 'P1Y',
            paymentMode: CdvPurchase.PaymentMode.PAY_AS_YOU_GO,
            recurrenceMode: CdvPurchase.RecurrenceMode.INFINITE_RECURRING,
          },
        ],
        product: yearlyProductBasic,
      },
      {
        canPurchase: (
          product: CdvPurchase.Product | CdvPurchase.Offer,
        ): boolean => true,
        order: (
          offer: CdvPurchase.Offer,
          additionalData?: CdvPurchase.AdditionalData,
        ): Promise<CdvPurchase.IError | undefined> =>
          new Promise<undefined>(resolve => resolve(undefined)),
      },
    ),
  );

  return [
    monthlyProduct,
    yearlyProduct,
    yearlyProductBasic,
    monthlyProductBasic,
  ];
};
