import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ZoomImgModule } from '../../../../components/zoom-img/zoom-img.module';
import { NutritionDetailComponent } from './nutrition-detail.component';
import { NutritionModule } from '../../nutrition.module';
import { NutritionDetailNoSupplementsComponent } from '../nutrition-detail-no-supplements/nutrition-detail-no-supplements.component';
import { LogoModule } from '../../../../components/logo/logo.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ZoomImgModule,
    NutritionModule,
    LogoModule,
  ],
  declarations: [
    NutritionDetailComponent,
    NutritionDetailNoSupplementsComponent,
  ],
  exports: [NutritionDetailComponent, NutritionDetailNoSupplementsComponent],
})
export class NutritionDetailModule {}
