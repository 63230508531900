import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { AnalyticEvents, DetailedAnalyticEvents } from './analytic-events.enum';
import { UserService } from '../user/user.service';
import { Sex, Transphormer } from '../../interfaces';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';
import { MixPanelService } from '../mix-panel/mix-panel.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    public router: Router,
    public platform: Platform,
    public user: UserService,
    private mixpanelService: MixPanelService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  /**
   * Sets relative user/transphormer information to be used for identifying
   */
  public async setUserInformation(transphormer: Transphormer) {
    await Promise.all([
      FirebaseAnalytics.setUserId({ userId: transphormer.id.toString() }),
      FirebaseAnalytics.setUserProperty({
        name: 'age',
        value: transphormer.age.toString(),
      }),
      FirebaseAnalytics.setUserProperty({
        name: 'gender',
        value: transphormer.sex === Sex.Male ? 'male' : 'female',
      }),
    ]);
  }

  /**
   * Initialize the service
   */
  public init() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    // Tracking page views
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.updateCurrentRoute(event.url));

    // Setting session information on session initialized events
    this.user.user$.subscribe(user => user && this.setUserInformation(user));
  }

  /**
   * Sets the current page to the firebase for analytics like user engagement and screen view
   */
  public async updateCurrentRoute(routeUrl: string): Promise<void> {
    await FirebaseAnalytics.setScreenName({
      screenName: routeUrl.replace(/(\d+)/g, ':id'),
    });
  }

  /**
   * Sends custom events to Firebase Analytics with optional metadata.
   * @param eventName - The name of the custom event to log.
   * @param metaData - Additional metadata to include with the event.
   */
  public async logEvent(eventName: string, metaData = {}): Promise<void> {
    this.mixpanelService.track(DetailedAnalyticEvents[eventName], metaData);
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    this.rollbar.debug('Sending custom events to Firebase Analytics');
    await FirebaseAnalytics.logEvent({ name: eventName, params: metaData });
  }

  /**
   * Sends a product purchase event to Firebase Analytics.
   * This method is used to log and track product purchases within the application.
   * @param revenue - The revenue generated from the purchase (default: 0).
   * @param description - A brief description of the purchase (default: 'Subscription Purchase').
   * @returns A Promise that resolves once the event is logged to Firebase Analytics.
   */
  async FASendProductPurchase(
    revenue = 0,
    description = 'Subscription Purchase',
  ): Promise<void> {
    this.rollbar.debug('Sending Purchase Event to Firebase Analytics');
    await this.logEvent(AnalyticEvents.Purchase, {
      revenue,
      description,
      currency: 'USD',
    });
  }

  /**
   * Sends Complete Registration event to Firebase Analytics.
   * This method is used to log and track the completion of user registrations.
   */
  async FASendRegistrationComplete(): Promise<void> {
    this.rollbar.debug('Sending Complete Registration to Firebase Analytics');
    await this.logEvent(AnalyticEvents.CompleteRegistration);
  }

  /**
   * Sends Complete Tutorial event to Firebase Analytics.
   * This method is used to log and track the completion of user tutorials.
   */
  async FASendCompleteTutorial(): Promise<void> {
    this.rollbar.debug('Sending Complete Tutorial to Firebase Analytics');
    await this.logEvent(AnalyticEvents.CompleteTutorial);
  }
}
