import { PendingMessagesComponent } from './pending-messages.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PendingMessagesComponent],
  imports: [CommonModule, IonicModule, RouterModule],
  exports: [PendingMessagesComponent],
})
export class PendingMessagesModule {}
