import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveStreamItemComponent } from './live-stream-item/live-stream-item.component';
import { VideoListItemComponent } from './video-list-item/video-list-item.component';
import { IonicModule } from '@ionic/angular';
import { PublishDatePipe } from '../pipes/publish-date.pipe';
import { VideoDatePipe } from '../pipes/video-date.pipe';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { AssetVideoPlayerComponent } from './asset-video-player/asset-video-player.component';
import { SearchComponent } from './search/search.component';
import { LiveStreamMessageEntryComponent } from './live-stream-message-entry/live-stream-message-entry.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AssetVideoPlayerComponent,
    VideoPlayerComponent,
    LiveStreamItemComponent,
    VideoListItemComponent,
    SearchComponent,
    PublishDatePipe,
    VideoDatePipe,
    LiveStreamMessageEntryComponent,
  ],
  exports: [
    AssetVideoPlayerComponent,
    VideoPlayerComponent,
    LiveStreamItemComponent,
    VideoListItemComponent,
    PublishDatePipe,
    VideoDatePipe,
    LiveStreamMessageEntryComponent,
  ],
  imports: [ReactiveFormsModule, CommonModule, IonicModule],
})
export class VideoComponentsModule {}
