import { Token } from '../services/base/base.service';
import { OnBoarding, WorkoutSession } from './workouts';
import { CustomMacro } from './nutrition';
import { UnitTypes } from './unit-types.enum';
import { Units } from './unit-type';
import { ChallengeInfo } from './app-info';
import { Referral } from './referral';
import { parseISO } from 'date-fns';
import { Label } from '../services/labels/interfaces';

export type KeysOfType<Type, KeyType> = {
  [P in keyof Type]: Type[P] extends KeyType ? P : never;
}[keyof Type];

export interface RegisterResponse {
  transphormer: Transphormer;
  token: Token;
}

export type ProfileType = 'public' | 'private';
export type ApplicationStatus = 'waiting' | 'accepted';

export interface LinkApplication {
  id: number;
  trainer_id: number;
  transphormer_id: number;
  status: ApplicationStatus;
  trainer: Trainer;
  team_id?: number;
  team?: Trainer;
  transphormer: Transphormer;
  assigned_team_id?: number;
  assigned_team?: {
    name: string;
    id: number;
    logo: string | null;
  };
  battle_buddy_trainer_id?: number;
}

export interface CameraPhotos {
  picture_front: string;
  picture_back: string;
  picture_side: string;
  transphormer_id: number;
  id: number;
  video_url: string;
  text?: string;
  created_at: string;
}

export interface Update extends CameraPhotos {
  challenge_id: null | number;
  challenge?: ChallengeInfo;
  is_first_challenge_photo?: boolean;
  is_last_challenge_photo?: boolean;
  in_registration_window?: boolean;
  in_completion_window?: boolean;
  is_first_completion_photo?: boolean;
  is_last_completion_photo?: boolean;
  is_first_registration_photo?: boolean;
  is_last_registration_photo?: boolean;
}

export interface Weight<T = Date> extends Units {
  weight: number;
  logged_on: T;
  id: number;
  created_at: T;
  updated_at: T;
  transphormer_id: number;
  is_base_weight: boolean;
  unit_type: UnitTypes;
}

export type WeighIn<T = Date> = Weight<T>;

export interface Organization {
  id: number;
  uuid: string;
  name: string;
  logo: string;
  color: string;
  org_contact_name: string;
  org_contact_email: string;
  org_contact_phone: string;

  config: OrganizationConfig;
  advisorList: Advisor[];
  // subConfig: SubscriptionConfig;
}

export interface OrganizationConfig {
  can_drop_advisor: boolean;
  can_view_1p_livestream: boolean;
  can_use_lms: boolean;
  can_refer: boolean;
  can_participate_global_leaderboard: boolean;
  can_participate_org_leaderboard: boolean;
  show_title_bar_small_text: boolean;
  title_bar_small_text: string;
  can_upload_photos: boolean;
  can_upload_assessments: boolean;
}

export interface TrainerData {
  id: number;
  facebook: string | null;
  instagram: string | null;
  linkedin: string | null;
  pintrest: string | null;
  twitter: string | null;
  youtube: string | null;
  facebook_group: string;
  certifications: string[];
  years_training: string;
}

export interface Transphormer extends OnBoarding {
  id: number;
  display_name: string;
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  height_in_inches?: string;
  trainer: Trainer | null;
  is_trainer: boolean;
  latest_weight?: Weight;
  starting_weight?: Weight;
  starting_weight_value?: number;
  latest_weight_value?: number;
  latest_weight_diff: number;
  latest_image?: CameraPhotos | null;
  latest_workout?: WorkoutSession | null;
  age: number;
  created_at: string;
  is_paid_user: boolean;
  is_trial_user: boolean;
  cfl_refid?: string;
  cfl_legionnaire?: string;
  profile_complete: boolean;
  linked_trainer?: LinkApplication | null | undefined;
  first_image_date: string | false | null;
  custom_macros?: CustomMacro | null;
  unit_type: UnitTypes;
  profile_picture_url?: string;
  address: string;
  city: string;
  state: string;
  rest_day_config: null | number;
  zipcode: string;
  country: string;
  phone: string;
  instagram: string;
  twitter: string;
  steps_optin: Date | string;
  steps_optout: Date | string;
  profile_type: ProfileType;
  is_my_cfl?: boolean;
  challenges: TransphormerChallenge[];
  subscription: SubscriptionInfo | null;
  referral?: Referral;
  latest_assessment_date?: string;
  timezone_identifier?: string;
  organization?: Organization;
  unread_assessments?: boolean;
  chatUuid?: string;
}

export interface SubscriptionInfo {
  active: string;
  expires_at: string;
  is_expired: boolean;
  platform: string;
  subscription_id: string;
  type: string;
  sku: string;
  willRenew: boolean;
}

export interface TransphormerChallenge {
  challenge_id: number;
  registration_update_id: number | null;
  completion_update_id: number | null;
  completion_update?: CameraPhotos;
  registration_update?: CameraPhotos;
  challenge: ChallengeInfo;
}

export interface StepsStatus {
  data: {
    id: number;
    steps_optin: string;
    steps_optout: string;
    steps: number[];
  };
}

export interface Trainer {
  assign_to_staff_advisor?: boolean;
  id: number;
  transphormer_id: number;
  legionnaire_url: string;
  referral_url: string;
  transphormer?: Transphormer;
  labels: Label[];
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  facebook: string | null;
  instagram: string | null;
  linkedin: string | null;
  pintrest: string | null;
  twitter: string | null;
  youtube: string | null;
  facebook_group: string | null;
  certifications: string;
  current_training_clients: string;
  years_training: string;
  creates_nutrition_plan: boolean;
  passed_basic_training: boolean;
  recommends_supplements: boolean;
  has_legionnaire_coach: boolean;
  creates_workout_plans: boolean;
  legionnaire_coach: string;
  how_train_clients: string;
  type: string;
  teams: Team[];
  battle_buddies?: BattleBuddies;
}

export interface Team {
  id: number;
  name?: string;
}

export interface BattleBuddies {
  my_battle_buddy: null | BattleBuddyAssignments;
  battle_buddies_to: Array<BattleBuddyAssignments>;
}

export interface BattleBuddyAssignments {
  advisor: { transphormer_id: number };
  buddy?: { transphormer_id: number };
  advisor_id: number;
  buddy_id: number;
  end: string;
  start: string;
  message: null | string;
}

export interface AnnouncementSender {
  id: number;
  type: string;
  display_name: string;
  first_name: string;
  last_name: string;
  profile_picture_url: string;
}

export interface AdvisorSearchResult extends Trainer {
  will_reassign: boolean;
  is_team: boolean;
  delegatedAdvisor?: Advisor;
}

export type Advisor = Trainer;

export interface Timestamps {
  created_at: string;
  updated_at: string;
}

export interface DateTimestamps {
  created_at: Date;
  updated_at: Date;
}

export interface IndexedArray<T> {
  [key: number]: T;
}

export function dateMorph<T extends object>(
  i: T extends { logged_on: string; created_at: string; updated_at: string }
    ? T
    : never,
): { logged_on: Date; created_at: Date; updated_at: Date } {
  return {
    logged_on: parseISO(i.logged_on),
    created_at: parseISO(i.created_at),
    updated_at: parseISO(i.updated_at),
  };
}

export function dateMorphAt<T extends object>(
  i: T extends { created_at: string; updated_at: string } ? T : never,
): { created_at: Date; updated_at: Date } {
  return {
    created_at: parseISO(i.created_at),
    updated_at: parseISO(i.updated_at),
  };
}
