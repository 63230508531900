import { Inject, Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ModalController } from '@ionic/angular';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  tap,
} from 'rxjs/operators';
import {
  AppTrackingStatusResponse,
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { UserService } from '../user/user.service';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { AttPrePromptComponent } from '../../modules/att/components/att-pre-prompt/att-pre-prompt.component';
import { rollbarConfig, RollbarService } from '../../rollbar';
import { LaunchDarklyService } from '../../modules/launchdarkly/ngx-launchdarkly.service';
import * as Rollbar from 'rollbar';
import { BranchioService } from '../branchio/branchio.service';
import { Select, Store } from '@ngxs/store';
import { SetTrackingTransparencyStatus } from '../../store/actions/account-settings.actions';
import { AccountSettingsState } from '../../store/states/account-settings.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class AppTrackingTransparencyService {
  @Select(AccountSettingsState.appTrackingTransparency)
  attIsAllowed$: Observable<AppTrackingStatusResponse>;

  constructor(
    private userService: UserService,
    private launchDarkly: LaunchDarklyService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private branchIoService: BranchioService,
    private modalController: ModalController,
    private store: Store,
  ) {
    this.userService.user$
      .pipe(
        takeUntilDestroyed(),
        filter(i => !!i),
        distinctUntilKeyChanged('id'),
        switchMap(_a => {
          if (Capacitor.isNativePlatform()) {
            return this.launchDarkly
              .flag('att-prompt--pre-prompt-content-text')
              .pipe(
                distinctUntilChanged(),
                tap(text => {
                  this.setupAppTrackingTransparency(text);
                }),
              );
          } else {
            this.store.dispatch(
              new SetTrackingTransparencyStatus({ status: 'notDetermined' }),
            );
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  private async setupAppTrackingTransparency(
    promptText: string,
  ): Promise<void> {
    if (!Capacitor.isNativePlatform()) return;

    const deviceInfo = await Device.getInfo();
    //ensures we are on iOS14+
    if (
      deviceInfo.operatingSystem === 'ios' &&
      +deviceInfo.iOSVersion.toString().substring(0, 2) >= 14
    ) {
      const statusResponse: AppTrackingStatusResponse =
        await AppTrackingTransparency.getStatus();
      this.store.dispatch(new SetTrackingTransparencyStatus(statusResponse));

      if (statusResponse.status === 'notDetermined') {
        this.modalController
          .create({
            component: AttPrePromptComponent,
            componentProps: {
              promptText,
            },
          })
          .then(modal => {
            modal.present().then();
            return modal.onDidDismiss();
          })
          .then(async result => {
            if (result) {
              this.rollbar.configure({ reportLevel: 'info' });
              const result = await AppTrackingTransparency.requestPermission();
              this.branchIoService.handleATTAuthorizationStatus(result.status);
              this.rollbar.info('track-permission-result', result);
              this.rollbar.configure({
                reportLevel: rollbarConfig.reportLevel,
              });
            }
          });
      }
    } else if (deviceInfo.operatingSystem === 'android') {
      this.store.dispatch(
        new SetTrackingTransparencyStatus({ status: 'authorized' }),
      );
    }
  }
}
