import { Injectable } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { NutritionState } from '../../state/nutrition/nutrition.state';
import { isQuickAddItem, TrackedItem, TrackedMeal } from '../../interfaces';
import { CopyMealsDayComponent } from '../../components/nutrition-v2/copy-meals-day/copy-meals-day.component';
import { Store } from '@ngxs/store';
import { MealTemplateNameComponent } from '../../components/nutrition-v2/meal-template-name/meal-template-name.component';
import { MealTemplatesService } from '../meal-templates/meal-templates.service';
import { ErrorsService, ToastService } from '../../../../services';
import { RequestCachingService } from '../../../../services/interceptors/caching/request-caching.service';
import { RecipeService } from '../recipe/recipe.service';
import { addDays } from 'date-fns';
import { _yyyyMMdd } from '../../../../helpers/date';
import { AddMeal, AddRecipe } from '../../state/my-foods/my-foods.actions';

@Injectable()
export class MealActionsService {
  constructor(
    public actionSheetCtrl: ActionSheetController,
    private modalCtrl: ModalController,
    private mealTemplateService: MealTemplatesService,
    private recipeService: RecipeService,
    private toastService: ToastService,
    private errorService: ErrorsService,
    private cacheService: RequestCachingService,
    private store: Store,
  ) {}

  public async openMealActionsMenu(extraActions = []) {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        ...extraActions,
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });

    return actionSheet.present();
  }

  public copyMeal(copiedMeals: TrackedMeal[], dismissCb?: CallableFunction) {
    const activeDate = _yyyyMMdd(
        this.store.selectSnapshot(NutritionState.activeDate),
      ),
      now = new Date(),
      tomorrow = addDays(now, 1);

    this.modalCtrl
      .create({
        component: CopyMealsDayComponent,
        cssClass: 'global-modal-copy-meals',
        componentProps: {
          initialSelection: activeDate < now ? now : tomorrow,
          meals: copiedMeals || [],
        },
        backdropDismiss: false,
      })
      .then(modal => {
        modal.present().then();
        modal.onDidDismiss().then(evt => {
          if (dismissCb) {
            dismissCb(evt);
          }
        });
      });
  }

  /**
   * Open modal to ask for meal template name
   */
  public async openMealTemplateNameModal(
    meal: TrackedMeal,
    type = 'Meal',
  ): Promise<string | boolean> {
    const modal = await this.modalCtrl.create({
      component: MealTemplateNameComponent,
      componentProps: {
        trackedMeal: meal,
        type,
      },
      cssClass: 'meal-template-name-modal',
    });

    await modal.present();

    const result = await modal.onDidDismiss();

    return !result.data ? false : result.data;
  }

  /**
   * Save meal template to server
   */
  public async saveAsMealTemplate(meal: TrackedMeal, name: string = null) {
    if (!name) {
      name = (await this.openMealTemplateNameModal(meal)) as string;
    }

    if (!name) {
      return;
    }

    this.mealTemplateService
      .createFromTrackedItems(
        <string>name,
        removeQuickAddedMeals(meal.food_items),
      )
      .subscribe(
        createdMeal => {
          this.store.dispatch(new AddMeal(createdMeal));
          this.toastService.flash(`Saved ${name}.`);
          this.cacheService.clearAll();
        },
        e => {
          this.toastService.flash(this.errorService.firstError(e));
        },
      );
  }

  async saveAsRecipe(
    meal: TrackedMeal,
    servingUnit = 'serving',
    name = null,
    servings: number,
    picture: string,
  ) {
    if (!name) {
      name = await this.openMealTemplateNameModal(meal, 'Recipe');
    }

    if (!name) {
      return;
    }

    this.recipeService
      .createFromTrackedItems(
        <string>name,
        removeQuickAddedMeals(meal.food_items),
        servingUnit,
        servings,
        picture,
      )
      .subscribe(
        createdRecipe => {
          this.store.dispatch(new AddRecipe(createdRecipe));
          this.toastService.flash(`Saved ${name}.`);
        },
        e => {
          this.toastService.flash(this.errorService.firstError(e));
        },
      );
  }
}

function removeQuickAddedMeals(items: TrackedItem[]): TrackedItem[] {
  return items.filter((i: TrackedItem) => !isQuickAddItem(i));
}
