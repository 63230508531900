import {
  FoodSearchResult,
  TrackedItem,
  TrackedMealTemplate,
} from '../../interfaces';

export class SearchForTerm {
  static readonly type = '[NutritionSearch] Search';

  constructor(public term: string) {}
}

export class ClearSearch {
  static readonly type = '[NutritionSearch] Clear Search';
}

export class InitializeState {
  static readonly type = '[NutritionSearch] Init';

  constructor(public userId: number) {}
}

export class SetupEmptyState {
  static readonly type = '[NutritionSearch] Setup Empty State';
}

export class ScanBarcode {
  static readonly type = '[NutritionSearch] Scan Barcode';
}

export class ClearTrackableItem {
  static readonly type = '[NutritionSearch] Clear Trackable item';
}

export class SelectSearchResult {
  static readonly type = '[NutritionSearch] Select Search Result';

  constructor(public item: FoodSearchResult) {}
}

export class TrackRecentItem {
  static readonly type = '[NutritionSearch] Track Recent Item';

  constructor(public item: TrackedItem | TrackedMealTemplate) {}
}

export class CreateCustomItem {
  static readonly type = '[NutritionSearch] Create Custom Item';
}
