export interface PaymentTierPrice {
  selected?: boolean;
  isBestValue?: boolean;
  title?: string;
  subtitle?: string;
  sku?: string;
  tierId: number;
}

export interface PaymentTierPointer {
  icon: string;
  name: string;
}

export interface PaymentTierResponse {
  attributes: {
    createdAt?: string;
    machineLabel: string;
    metadata?: string;
    name: string;
    updatedAt?: string;
  };
  id: number;
}

export interface PaymentTier {
  name: string;
  selected?: boolean;
  additionalInfo?: string;
  pointers?: PaymentTierPointer[];
  id: number;
  tierLevel: number;
}

export interface PaymentTierWithSkus extends PaymentTier {
  skus: string[];
}

export interface PaymentTierWithPricing extends PaymentTier {
  prices: PaymentTierPrice[];
}

export interface TierSkuResponse {
  id: number;
  attributes: {
    sku: string;
    tierId: number;
  };
}

export enum Tiers {
  Free = 0,
  Basic = 2,
  Premium = 1,
}

export interface AvailableSubscriptionInfo {
  subscription_id: string;
  type: string;
  name: string;
  description: string;
  featureList: string[];
}
