import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { LoadVideos, Reset } from './videos.actions';
import { VideosService } from '../../../services';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PaginationMetadata, Video } from '../../../interfaces';

export const VIDEOS_STATE_TOKEN = new StateToken<VideosStateModel>('videos');
export const VIDEOS_STATE_EMPTY: VideosStateModel = {
  activeStream: null,
  isLoading: false,
  videos: {
    data: [],
    meta: null,
  },
};

export interface VideosStateModel {
  activeStream: Video;
  isLoading: boolean;
  videos: {
    data: Video[];
    meta: PaginationMetadata;
  };
}

@State<VideosStateModel>({
  name: VIDEOS_STATE_TOKEN,
  defaults: VIDEOS_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class VideosState {
  constructor(private videosService: VideosService) {}

  @Selector()
  static activeStream(state: VideosStateModel) {
    return state.activeStream;
  }

  @Selector()
  static isLoading(state: VideosStateModel) {
    return state.isLoading;
  }

  @Selector()
  static videos(state: VideosStateModel) {
    return state.videos;
  }

  @Action(LoadVideos)
  loadVideos(
    ctx: StateContext<VideosStateModel>,
    { limit, page, append }: LoadVideos,
  ) {
    ctx.patchState({ isLoading: true });
    return this.videosService.getVideos(limit, page).pipe(
      tap(videos => {
        const currentData = ctx.getState().videos.data;
        return ctx.patchState({
          activeStream: videos.active_stream,
          isLoading: false,
          videos: {
            data: append ? [...currentData, ...videos.data] : videos.data,
            meta: videos.meta,
          },
        });
      }),
    );
  }

  @Action(Reset)
  reset(ctx: StateContext<VideosStateModel>) {
    ctx.setState(VIDEOS_STATE_EMPTY);
    return of(VIDEOS_STATE_EMPTY);
  }
}
