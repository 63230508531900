import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from '../storage.service';
import { Preferences } from '@capacitor/preferences';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  // Holds the value of the access token as a behavior subject. This allows us
  // to "hold" the value when we need it synchronously or as an observable for
  // things like interceptors.
  #accessToken$ = new BehaviorSubject<string>(null);

  /**
   * @todo Remove StorageService in a couple of updates, it is deprecated.
   */
  constructor(private storage: StorageService) {}

  /**
   * Only to be called to initialize the app. Don't call this anywhere else!
   */
  async init(): Promise<string | null> {
    // Check to see if we have it in the Preferences system. If we do, return it
    // since that is the authoritative value.
    const preferencesValue = await Preferences.get({ key: 'access_token' });

    if (preferencesValue?.value) {
      this.#accessToken$.next(preferencesValue.value);
      return preferencesValue?.value;
    }

    const storageValue = await this.storage.get('access_token');
    this.#accessToken$.next(storageValue);

    if (storageValue) {
      // Migrate the "old" value to the new storage system.
      await Preferences.set({
        key: 'access_token',
        value: storageValue,
      });

      // Clear the old value out.
      await this.storage.remove('access_token');
    }

    return storageValue;
  }

  get accessToken$(): Observable<string | null> {
    return this.#accessToken$.asObservable();
  }

  get hasAccessToken$(): Observable<boolean> {
    return this.accessToken$.pipe(map(token => !!token));
  }

  get() {
    return this.#accessToken$.value;
  }

  /**
   * The only place you should ever call this is the authentication service.
   */
  setToken(value: string) {
    this.#accessToken$.next(value);
    Preferences.set({ key: 'access_token', value });
  }

  /**
   * The only place you should ever call this is the authentication service.
   */
  clearToken() {
    this.storage.remove('access_token');
    Preferences.remove({ key: 'access_token' });
    this.#accessToken$.next(null);
  }
}
