import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-create-filter',
  templateUrl: './create-filter.component.html',
  styleUrls: ['./create-filter.component.scss'],
})
export class CreateFilterComponent {
  public form: UntypedFormGroup;
  spinner: false;

  constructor(public modalCtrl: ModalController) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(250),
      ]),
    });
  }

  public submit() {
    this.modalCtrl.dismiss(this.form.get('name').value);
  }

  close() {
    this.modalCtrl.dismiss(false).then();
  }
}
