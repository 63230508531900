import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Product } from '../../../../services/in-app-purchase/products';
import { BehaviorSubject } from 'rxjs';

export interface ProductButtonDetails {
  title: string;
  subtitle: string;
}

export const extractProductButtonDetails = (products: Product[]) =>
  products.map((p: Product) => {
    const monthlyProductCostPerYear =
      products.find((p: Product) => p.billingPeriodUnit === 'every month')
        .priceMicros * 12;
    const yearlyProductCost = products.find(
      p => p.billingPeriodUnit === 'every year',
    ).priceMicros;
    const monthlyProductDiscount = (
      (monthlyProductCostPerYear - yearlyProductCost) /
      1000000
    ).toFixed(2);
    return {
      title:
        p.billingPeriodUnit === 'every month'
          ? `${p.price}/Mo`
          : `${p.price}/Yr`,
      subtitle:
        p.billingPeriodUnit === 'every month'
          ? 'Billed Monthly'
          : `$${monthlyProductDiscount} Savings Per Year `,
    };
  });

@Component({
  selector: 'app-subscribe-v2',
  templateUrl: './subscribe-v2.component.html',
  styleUrls: ['./subscribe-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeV2Component {
  @Input() set products(products: Product[]) {
    if (products) {
      this.products$.next(products);
      this.productButtons$.next(extractProductButtonDetails(products));
    }
  }
  @Input() selectedProduct = null;
  @Input() isPurchasing = false;

  @Output() upgrade: EventEmitter<null> = new EventEmitter<null>();
  @Output() selectProduct: EventEmitter<Product> = new EventEmitter<Product>();

  productButtons$ = new BehaviorSubject<ProductButtonDetails[]>([]);
  products$ = new BehaviorSubject<Product[]>([]);
}
