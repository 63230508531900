import { UnreadStateModel } from './unread.store';

export class ResetAllCountersToZero {
  static readonly type = '[Unread] Reset All Counters To Zero';
}

export class MarkAllAnnouncementsRead {
  static readonly type = '[Unread] Mark All Announcements Read';
}

export class DecrementUnreviewedAssessments {
  static readonly type = '[Unread] Decrement Unreviewed Assessments';
}

export class DecrementUnreadAdvisorMessages {
  static readonly type = '[Unread] Decrement Unread Advisor Messages';
}

export class ResetUnreadTransphormerMessages {
  static readonly type = '[Unread] Reset Unread Transphormer Messages';
}

export class UpdateCounters {
  static readonly type = '[Unread] Update Counters';

  constructor(public counters: Partial<UnreadStateModel>) {}
}

export class IncrementUnreadAdvisorMessages {
  static readonly type = '[Unread] Increment Unread Advisor Messages';
}
