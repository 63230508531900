import { AnnouncementSender, Organization, Transphormer } from './types';
import { ListingParams } from '../services';
import { Transfer, TransferState } from './transfers';
import { SafeUrl } from '@angular/platform-browser';
import { AnnouncementContext } from '../helpers';

/**
 * What a Transphormer sees when they load one or more announcements.
 */
export interface TransphormerAnnouncement<D = Date> {
  id: number;
  transphormer_id: number;
  announcement?: Announcement<D>;
  read_at: D | null;
  updated_at: D | null;
  created_at: D | null;
}

export type AnnouncementStatus =
  | 'draft'
  | 'pending'
  | 'processing'
  | 'ready'
  | 'published'
  | 'unpublished'
  | 'archived';

export enum AdvisorAnnouncementStatus {
  PUBLISHED = 'published',
  PENDING = 'pending',
  DRAFT = 'draft',
}

export interface Announcement<D = Date> {
  id: number;
  uuid?: string;
  attachments?: AnnouncementAttachment[];
  content: string;
  heading: string;
  image?: string;
  created_at: D;
  publish_at?: D;
  sender: AnnouncementSender;
  trainer_id: number;
  video?: string;
}

// Advisor-only fields
export interface AdvisorAnnouncement<D = Date> extends Announcement<D> {
  filters?: ListingParams;
  status?: AnnouncementStatus;
  transferId: string;
  transfer: Transfer;
  transphormer_announcement?: TransphormerAnnouncement;
  transphormer_announcements?: TransphormerAnnouncement[];
  transphormer_announcements_count?: number;
  transphormers?: Transphormer[];
  updated_at: D;
  sent: D;
  organization?: Pick<Organization, 'uuid' | 'name' | 'logo' | 'color'>;
}

// Describes the "old" announcement attachments here.
export type AnnouncementAttachment<DateType = Date> =
  | VideoAnnouncementAttachment<DateType>
  | ImageAnnouncementAttachment<DateType>
  | DirectUploadImageAttachment<DateType>
  | DirectUploadVideoAttachment<DateType>;

export interface BaseAttachmentData<DateType> {
  id: number;
  announcement_uuid: string;
  announcement_id?: number;
  status: 'pending' | 'ready';
  path: string;
  metadata?: unknown;
  type: 'video' | 'image';
  processing_started: DateType;
  processing_finished: DateType;
  public_url: string | SafeUrl;
  created_at?: DateType; // Not in items returned in listing.
  updated_at?: DateType; // Not in items returned in listing.
}

export interface VideoAnnouncementAttachment<DateType = Date>
  extends BaseAttachmentData<DateType> {
  type: 'video';
  metadata: VideoMetadata | null;
}

export interface ImageAnnouncementAttachment<DateType = Date>
  extends BaseAttachmentData<DateType> {
  type: 'image';
  metadata: null;
}

interface GraphicMedia {
  path: string;
  size: number;
  width: number;
  height: number;
  public_url?: string;
}

interface VideoMedia {
  ratio: number;
  duration: number;
  play_time: string;
  orientation: 'landscape' | 'portrait';
  original_width?: number;
  original_height?: number;
  original_rotation?: number;
}

export interface VideoMetadata {
  poster: GraphicMedia;
  original: GraphicMedia & VideoMedia;
  optimized: GraphicMedia & VideoMedia;
}

interface DirectUploadFileMetadata {
  path?: string;
  upload_id: string;
  public_url: string;
}

export interface DirectUploadImageAttachment<DateType = Date>
  extends BaseAttachmentData<DateType> {
  type: 'image';
  metadata: {
    poster: DirectUploadFileMetadata;
    original: DirectUploadFileMetadata;
  };
}

export interface DirectUploadVideoAttachment<DateType = Date>
  extends BaseAttachmentData<DateType> {
  type: 'video';
  metadata: {
    poster: DirectUploadFileMetadata;
    original: DirectUploadFileMetadata;
  };
}

export const isDirectUploadTransfer = (
  upload: Transfer,
): upload is Transfer<AnnouncementContext> => {
  return (
    upload.hasOwnProperty('context') && upload.context.hasOwnProperty('upload')
  );
};

export const isAttachmentUploadTransfer = (
  upload: Transfer,
): upload is Transfer<AnnouncementContext> => {
  return (
    upload.hasOwnProperty('context') && upload.context.hasOwnProperty('upload')
  );
};

export interface Attachment {
  type: 'video' | 'image' | 'document';
  fileUrl?: string;
  file?: File;
  status?: 'pending' | 'ready';
  previewUrl?: string | SafeUrl;
  transferState?: TransferState;
}
