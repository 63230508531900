import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  LDClient,
  LDContext,
  LDFlagSet,
  LDOptions,
} from 'launchdarkly-js-client-sdk';
import { LDFlagChangeset, LDFlagValue } from 'launchdarkly-js-sdk-common';
import { objectMap } from '../nutrition/functions';
import { map } from 'rxjs/operators';
import { defaultFlags } from './defaults';

export const LAUNCH_DARKLY_INITIALIZER = 'LAUNCH_DARKLY_INITIALIZER';
export const LAUNCH_DARKLY_API_KEY = 'LAUNCH_DARKLY_API_KEY';
export const LAUNCH_DARKLY_OPTIONS = 'LAUNCH_DARKLY_OPTIONS';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private _ldClient: LDClient;
  private _flags: LDFlagSet;

  readonly flagChange = new BehaviorSubject<LDFlagSet>(null);

  private _setFlags(flags: LDFlagChangeset) {
    this._flags = { ...this._flags, ...flags };
    this.flagChange.next(this._flags);
  }

  constructor(
    @Inject(LAUNCH_DARKLY_INITIALIZER)
    ldInitializer: (
      envKey: string,
      context: LDContext,
      options?: LDOptions,
    ) => LDClient,
    @Inject(LAUNCH_DARKLY_API_KEY) ldClientApiKey: string,
    @Optional() @Inject(LAUNCH_DARKLY_OPTIONS) ldOptions: LDOptions | undefined,
  ) {
    this._flags = {};
    this._ldClient = ldInitializer(
      ldClientApiKey,
      <LDContext>{
        type: 'user',
        key: 'anon',
        anonymous: true,
      },
      <LDOptions>{
        bootstrap: { ...defaultFlags },
        ...ldOptions,
      },
    );

    this.flagChange.next({ ...defaultFlags });

    this._ldClient.on('initialized', () =>
      this._setFlags(this._ldClient.allFlags()),
    );

    this._ldClient.on('change', value => {
      this._setFlags(
        objectMap(
          value || {},
          (flag: { current: LDFlagValue; previous: LDFlagValue }) =>
            flag.current,
        ),
      );
    });

    // Do not error when the client errors. We want the application to continue to operate.
    // this._ldClient.on('error', (error) => this.flagChange.error(error));
  }

  flag(flag: string) {
    return this.flagChange.pipe(map(i => (i ? i[flag] : false)));
  }

  /**
   * Process a user change. You should call this whenever the current user
   * updates.
   *
   * @param user
   */
  changeUser(user: LDContext | 'Anonymous') {
    const currentUser = this._ldClient.getContext();

    // If someone passes in 'Anonymous', just convert it to the anon user.
    if (user === 'Anonymous') {
      user = <LDContext>{
        kind: 'user',
        key: 'anon',
        anonymous: true,
      };
    }

    // If we have no user OR if the user key has changed, then update the user
    // in Launch Darkly.
    if (!currentUser || currentUser.key !== user.key) {
      this._ldClient.identify(user);
    }
  }
}
