import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { TrackedItem } from '../../interfaces';
import { produce } from 'immer';
import { v1 } from 'uuid';
import {
  Add,
  AddServing,
  ChangeMode,
  Clear,
  Import,
  Remove,
  RemoveServing,
  SetImage,
  SetName,
  SetServing,
  SetServingMode,
  SetServingUnit,
  Update,
} from './scratchpad.actions';

export const SCRATCHPAD_STATE_TOKEN = new StateToken<ScratchpadStateModel>(
  'nutritionScratchpad',
);

// It has an activeDate and multiple NutritionResponses in data for each date it got from the API
export interface ScratchpadStateModel {
  scratchPad: TrackedItem[];
  name: string;
  mode: ScratchpadMode;
  servingMode: ServingMode;
  servingsUnit: string;
  scalingServing: number;
  numberServings: number;
  picture?: string;
  picture_url?: string;
}

export type ScratchpadMode = 'recipe' | 'meal';
export type ServingMode = 'serving' | 'scaling';

@State<ScratchpadStateModel>({
  name: SCRATCHPAD_STATE_TOKEN,
  defaults: {
    name: '',
    scratchPad: [],
    mode: 'recipe',
    servingMode: 'serving',
    servingsUnit: 'serving',
    scalingServing: 1,
    numberServings: 1,
    picture: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class ScratchpadState {
  @Action(Add)
  scratchpadAdd(ctx: StateContext<ScratchpadStateModel>, { trackedItem }: Add) {
    ctx.setState(
      produce((draft: ScratchpadStateModel) => {
        draft.scratchPad.push({ ...trackedItem, localId: v1() });
      }),
    );
  }

  @Action(Import)
  scratchpadImport(ctx: StateContext<ScratchpadStateModel>, { items }: Import) {
    ctx.setState(
      produce((draft: ScratchpadStateModel) => {
        draft.scratchPad = items.map(i => ({ ...i, localId: v1() }));
      }),
    );
  }

  @Action(SetName)
  setName(ctx: StateContext<ScratchpadStateModel>, { name }: SetName) {
    ctx.patchState({ name });
  }

  @Action(SetImage)
  setImage(ctx: StateContext<ScratchpadStateModel>, { picture }: SetImage) {
    ctx.patchState({ picture });
  }

  @Action(Clear)
  scratchpadClear(ctx: StateContext<ScratchpadStateModel>) {
    ctx.patchState({
      name: '',
      scratchPad: [],
      mode: 'recipe',
      servingMode: 'serving',
      servingsUnit: 'serving',
      scalingServing: 1,
      numberServings: 1,
      picture: null,
      picture_url: null,
    });
  }

  @Action(Remove)
  scratchpadRemove(
    ctx: StateContext<ScratchpadStateModel>,
    { trackedItem }: Remove,
  ) {
    ctx.setState(
      produce(draft => {
        draft.scratchPad = draft.scratchPad.filter(
          i => i.localId !== trackedItem.localId,
        );
      }),
    );
  }

  @Action(Update)
  scratchpadUpdate(
    ctx: StateContext<ScratchpadStateModel>,
    { trackedItem }: Update,
  ) {
    ctx.setState(
      produce(draft => {
        const index = draft.scratchPad.findIndex(
          i => i.localId === trackedItem.localId,
        );
        draft.scratchPad[index] = trackedItem;
      }),
    );
  }

  @Action(ChangeMode)
  changeMode(ctx: StateContext<ScratchpadStateModel>, { mode }: ChangeMode) {
    ctx.patchState({ mode });
  }

  @Action([AddServing, RemoveServing])
  changeServing(
    ctx: StateContext<ScratchpadStateModel>,
    change: AddServing | RemoveServing,
  ) {
    ctx.setState(
      produce(draft => {
        const valueToUpdate =
          draft.servingMode === 'serving' ? 'numberServings' : 'scalingServing';
        draft[valueToUpdate] = Math.min(
          144,
          Math.max(1, draft[valueToUpdate] + change.amount),
        );
      }),
    );
  }

  @Action([SetServing])
  setServing(ctx: StateContext<ScratchpadStateModel>, { amount }: SetServing) {
    if (ctx.getState().servingMode === 'serving') {
      ctx.patchState({ numberServings: Math.min(144, Math.max(1, amount)) });
    } else {
      ctx.patchState({ scalingServing: Math.min(144, Math.max(1, amount)) });
    }
  }

  @Action([SetServingUnit])
  setServingUnit(
    ctx: StateContext<ScratchpadStateModel>,
    { unit }: SetServingUnit,
  ) {
    ctx.patchState({ servingsUnit: unit });
  }

  @Action(SetServingMode)
  setServingModel(
    ctx: StateContext<ScratchpadStateModel>,
    { mode }: SetServingMode,
  ) {
    ctx.patchState({ servingMode: mode });
  }
}
