import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';

import {
  LoadAccountSettings,
  SetTrackingTransparencyStatus,
  UpdateAccountSettings,
} from '../actions/account-settings.actions';
import { AccountSetting } from '../../interfaces/account';
import { apiUrl } from '../../helpers';
import { UserService } from '../../services/user/user.service';
import { handleApiError } from '../../helpers/operators';
import { EMPTY } from 'rxjs';

export const ACCOUNT_SETTINGS_STATE_TOKEN =
  new StateToken<AccountSettingsStateModel>('accountSettings');
export type AccountSettingState = AccountSetting & { STATE_LOADED: boolean };
export const ACCOUNT_SETTINGS_STATE_EMPTY: AccountSettingState = {
  nutrition_reminder: false,
  photo_reminder: null,
  receive_email: false,
  wants_motivation: true,
  receive_livestream_notification: false,
  display_daily_tasks: true,
  receive_notification: false,
  receive_week_in_review_email: false,
  receive_week_in_review_notification: false,
  transphormer_id: null,
  weigh_reminder: null,
  what_time: null,
  use_checklists: null,
  workout_reminder: false,
  app_tracking_transparency: { status: 'notDetermined' },
  STATE_LOADED: false,
};
export type AccountSettingsStateModel = AccountSettingState;

@State<AccountSettingsStateModel>({
  name: ACCOUNT_SETTINGS_STATE_TOKEN,
  defaults: ACCOUNT_SETTINGS_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class AccountSettingsState {
  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {}

  @Selector()
  static displayMotivationOnDashboard(
    state: AccountSettingsStateModel,
  ): boolean {
    return state.wants_motivation;
  }

  @Selector()
  static displayDailyTasksOnDashboard(
    state: AccountSettingsStateModel,
  ): boolean {
    return state.display_daily_tasks;
  }

  @Selector()
  static nutritionReminder(state: AccountSettingsStateModel) {
    return state.nutrition_reminder;
  }

  @Selector()
  static appTrackingTransparency(state: AccountSettingsStateModel) {
    return state.app_tracking_transparency;
  }

  @Action(SetTrackingTransparencyStatus)
  setTrackingTransparencySettings(
    ctx: StateContext<AccountSettingsStateModel>,
    { status },
  ) {
    return ctx.patchState({
      app_tracking_transparency: status,
    });
  }

  @Action(LoadAccountSettings)
  loadAccount(ctx: StateContext<AccountSettingsStateModel>) {
    return this.http.get<AccountSetting>(apiUrl('account/show')).pipe(
      handleApiError(),
      map(accountSettings => {
        if (this.userService.user === null) {
          throw new Error('User is undefined.');
        }

        return { ...accountSettings };
      }),
      tap((accountSettings: AccountSettingState) =>
        ctx.setState({ ...accountSettings, STATE_LOADED: true }),
      ),
      catchError(() => {
        ctx.setState(ACCOUNT_SETTINGS_STATE_EMPTY);

        return EMPTY;
      }),
    );
  }

  @Action(UpdateAccountSettings)
  updateAccount(
    ctx: StateContext<AccountSettingsStateModel>,
    { accountSettings }: UpdateAccountSettings,
  ) {
    return this.http
      .post<AccountSetting>(apiUrl('account/update'), accountSettings)
      .pipe(tap(_ => ctx.patchState({ ...accountSettings })));
  }
}
