import { Injectable } from '@angular/core';
import { jsonApiUrl } from '../../../../helpers';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonApiResponse } from '../../../assessments-v2/services/assessments/types';
import {
  PaymentTier,
  PaymentTierPrice,
  PaymentTierResponse,
  PaymentTierWithSkus,
  Tiers,
  TierSkuResponse,
} from '../../interfaces';
import { map } from 'rxjs/operators';
import { adaptSkuList, adaptTiers } from './functions';
import { Observable } from 'rxjs';
import { sortTiersByLevel } from '../../helpers';

const JSON_API_HEADERS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/vnd.api+json',
  }),
};

export class TierSku {
  tierList: PaymentTier[];
  skuList: PaymentTierPrice[];
}

@Injectable({
  providedIn: 'root',
})
export class TierService {
  // A list of the tiers that we have available. Used if you want to group
  // products together.
  readonly tierListWithSkus$: Observable<PaymentTierWithSkus[]>;

  constructor(private http: HttpClient) {
    this.tierListWithSkus$ = this.getTiers().pipe(
      map(({ tierList, skuList }) =>
        tierList.map(tier => ({
          ...tier,
          skus: skuList
            .filter(sku => sku.tierId === tier.id)
            .map(sku => sku.sku),
        })),
      ),
      map(tierList => sortTiersByLevel(tierList)),
    );
  }

  getTiers(): Observable<TierSku> {
    return this.http
      .get<
        JsonApiResponse<PaymentTierResponse[], unknown, TierSkuResponse[]>
      >(jsonApiUrl(`tiers?include=tierSkus`), JSON_API_HEADERS)
      .pipe(
        map(res => {
          const tierList = adaptTiers(res.data);
          return { skuList: adaptSkuList(res.included, tierList), tierList };
        }),
      );
  }

  getTier(tierId: Tiers) {
    return this.http.get<
      JsonApiResponse<PaymentTierResponse[], unknown, PaymentTierPrice[]>
    >(jsonApiUrl(`tiers/${tierId}?include=tierSkus`), JSON_API_HEADERS);
  }

  getLoggedInTier(): Observable<JsonApiResponse<PaymentTier>> {
    return this.http.get<JsonApiResponse<PaymentTier>>(
      jsonApiUrl(`tiers?filter[currentOnly]=true`),
      JSON_API_HEADERS,
    );
  }
}
