import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoadBodyMetricsData } from '../../../../state/body-metrics/body-metrics.actions';
import { BodyMetricsState } from '../../../../state/body-metrics/body-metrics.state';

@Injectable({
  providedIn: 'root',
})
export class PreloadBodyMetricsGuard {
  public constructor(
    public route: Router,
    public store: Store,
  ) {}

  public canActivate(
    _next: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Load body metrics data on state before proceeding to body metrics routes
    return this.store.selectOnce(BodyMetricsState.status).pipe(
      switchMap(status =>
        status === 'unloaded' || status === 'error'
          ? // If state status is 'unloaded' || 'error' then load the body metrics data, else continue with the next route
            this.store.dispatch(new LoadBodyMetricsData()).pipe(
              switchMap(() =>
                // Check if state 'status' has been loaded properly or errored
                this.store
                  .selectOnce(BodyMetricsState.status)
                  .pipe(
                    switchMap(_status =>
                      of(_status === 'loaded' || _status === 'error'),
                    ),
                  ),
              ),
            )
          : of(true),
      ),
    );
  }
}
