import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { WearableEditorComponent } from './components/wearable-editor/wearable-editor.component';
import { WearableSuccessComponent } from './components/wearable-success/wearable-success.component';
import { LogoModule } from '../../components/logo/logo.module';
import { WearablePermissionsModalComponent } from './components/wearable-permissions-modal/wearable-permissions-modal.component';
import { WearableDeviceIdMismatchComponent } from './components/wearables-device-id-mismatch/wearable-device-id-mismatch.component';

@NgModule({
  imports: [IonicModule, LogoModule, CommonModule, ReactiveFormsModule],
  declarations: [
    WearableEditorComponent,
    WearableSuccessComponent,
    WearablePermissionsModalComponent,
    WearableDeviceIdMismatchComponent,
  ],
  exports: [WearableEditorComponent, WearableSuccessComponent],
  providers: [],
})
export class WearablesModule {}
