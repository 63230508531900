export class LoadLeaderboard {
  static readonly type = '[Steps Leaderboard] Load leaderboard page';
  constructor(
    public id: number,
    public page: number = 1,
    public append: boolean = true,
  ) {}
}

export class LoadLeaderboards {
  static readonly type = '[Steps Leaderboard] Load all leaderboards';
}

export class LoadMoreLeaderboards {
  static readonly type = '[Steps Leaderboard] Load more leaderboards';
}

export class Reset {
  static readonly type = '[Steps Leaderboard] Reset state';
}
