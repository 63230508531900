import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FoodItemsService } from '../food-items/food-items.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  FoodItem,
  FoodSearchResultItem,
  FoodSourceItem,
  MealTemplate,
  TrackableItem,
  TrackableSourceItem,
  TrackedItem,
  TrackedMeal,
} from '../../interfaces';
import { FoodSourceServiceInterface } from '../food-manager/interfaces';
import { apiUrl } from '../../../../helpers';
import { catchFormError, handleApiError } from '../../../../helpers/operators';
import { calculateMacrosForMeal } from '../../state/nutrition/functions';
import { ScrewedUpMTItem } from '../../interfaces/legacy';

function fixupNewTrackedItemsToOldFormat(items: TrackedItem[]) {
  return items.map(item => {
    return {
      ...item,
      is_custom: item.source === 'custom',
      alt_servings: item.servings,
      amount_consumed: item.consumed,
      nutritioninx_food_name:
        item.source === 'nutritionix' && item.source_type === 'common'
          ? item.source_id
          : null,
      nutritioninx_item_id:
        item.source === 'nutritionix' && item.source_type === 'branded'
          ? item.source_id
          : null,
      is_branded_food:
        item.source === 'nutritionix' && item.source_type === 'branded',
    };
  });
}

@Injectable()
export class MealTemplatesService implements FoodSourceServiceInterface {
  constructor(
    protected http: HttpClient,
    public foodItem: FoodItemsService,
  ) {}

  /**
   * List all meal templates
   */
  public getMealTemplates(): Observable<MealTemplate<FoodItem>[]> {
    return this.http
      .get<MealTemplate<ScrewedUpMTItem>[]>(apiUrl('nutritions/meal-templates'))
      .pipe(
        map(mealTemplates =>
          mealTemplates.map(mealTemplate => ({
            ...mealTemplate,
            items: mealTemplate.items.map(mealItem =>
              mealItem.serving_size
                ? (mealItem as FoodItem)
                : this.foodItem.convertToFoodItem(mealItem),
            ),
          })),
        ),
        handleApiError(),
      );
  }

  public createFromTrackedItems(name: string, items: TrackedItem[]) {
    const data = { name, items: fixupNewTrackedItemsToOldFormat(items) };

    return this.http
      .post<
        MealTemplate<ScrewedUpMTItem>
      >(apiUrl('nutritions/meal-templates'), data)
      .pipe(
        map(mt => ({
          ...mt,
          ...calculateMacrosForMeal(<TrackedMeal>{
            food_items: mt.items as unknown as TrackedItem[],
          }),
          items: mt.items.map(mi =>
            mi.serving_size ? mi : this.foodItem.convertToFoodItem(mi),
          ),
        })),
        catchFormError(),
        handleApiError(),
      );
  }

  public update(
    id: number,
    name: string,
    items: TrackedItem[] = null,
  ): Observable<MealTemplate<FoodItem>> {
    const data = items
      ? { name, items: fixupNewTrackedItemsToOldFormat(items) }
      : { name };
    return this.http
      .put<
        MealTemplate<ScrewedUpMTItem>
      >(apiUrl(`nutritions/meal-templates/${id}`), data)
      .pipe(
        handleApiError(),
        map(mt => ({
          ...mt,
          ...calculateMacrosForMeal({ food_items: mt.items }),
          items: mt.items.map(mi =>
            mi.serving_size ? mi : this.foodItem.convertToFoodItem(mi),
          ),
        })),
      );
  }

  public delete(id: number): Observable<void> {
    return this.http
      .delete<void>(apiUrl(`nutritions/meal-templates/${id}`))
      .pipe(handleApiError());
  }

  publicAroundThisTimeTrackedItems() {
    return this.http.get<MealTemplate<TrackedItem[]>>(
      apiUrl('nutritions/meal-templates/around-this-time'),
    );
  }

  afterTrack(_item: TrackedItem): void {
    //
  }

  defaultResults(): Observable<TrackableSourceItem[]> {
    return EMPTY;
  }

  getSearchDetails(_item: FoodSourceItem): Observable<TrackableSourceItem[]> {
    return EMPTY;
  }

  searchForTerm(term: string, _options?): Observable<FoodSearchResultItem[]> {
    const requestParams = {
      params: {
        name: term || '',
      },
    };

    return this.http
      .get<
        MealTemplate<TrackableItem[]>[]
      >(apiUrl('nutrition/search/meals'), requestParams)
      .pipe(
        catchError(() => of([])),
        switchMap(results => {
          if (!!results === false) {
            return of([]);
          }
          return of(results);
        }),
      );
  }

  searchByTerm(
    term: string,
    _options?,
  ): Observable<MealTemplate<TrackableItem>[]> {
    const requestParams = {
      params: {
        name: term || '',
      },
    };

    return this.http
      .get<
        MealTemplate<TrackableItem[]>[]
      >(apiUrl('nutrition/search/meal-templates'), requestParams)
      .pipe(
        handleApiError(),
        switchMap(results => {
          if (!!results === false) {
            return of([]);
          }
          return of(results);
        }),
      );
  }
}
