import { Pipe, PipeTransform } from '@angular/core';
import {
  ExertionSettings,
  ExertionSettingsEnum,
} from '../../../../app/interfaces';

@Pipe({
  name: 'exertionType',
})
export class ExertionTypePipe implements PipeTransform {
  transform(setting: ExertionSettings): string {
    return ExertionSettingsEnum[setting?.type] || '';
  }
}
