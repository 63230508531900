import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AuthenticationService, UserService } from '../../../../services';
import { NutritionPlan, Transphormer } from '../../../../interfaces';
import { Select, Store } from '@ngxs/store';
import { UnreadState } from '../../../../store/unread/unread.store';
import { UserStorageService } from '../../../../services/user-storage/user-storage.service';
import { AssessmentConfigState } from '../../../../modules/assessments-v2/services/assessments/state/assessment-config.state';
import { LoadAdvisorConfig } from '../../../../modules/assessments-v2/services/assessments/state/assessment-config.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import Rollbar from 'rollbar';
import { RollbarService } from '../../../../rollbar';
import { SubscribeComponent } from '../../../../modules/onboarding-2022/onboarding-modals/subscribe/subscribe.component';
import { WearablesConfigService } from '../../../../modules/wearables/services/wearables-config/wearables-config.service';
import { MixPanelService } from '../../../../services/mix-panel/mix-panel.service';

@UntilDestroy()
@Component({
  selector: 'app-expanded-menu',
  templateUrl: './expanded-menu.component.html',
  styleUrls: ['./expanded-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedMenuComponent implements OnInit {
  public transphormersMatchingRoutes: string[] = [
    'my-transphormers',
    'transphormer',
  ];

  readonly defaultProfileIcon = 'assets/icon/avatar.png';

  transphormer$: Observable<Transphormer & { is_cake_day: boolean }>;
  isCollapsed$ = new BehaviorSubject(false);

  @Select(UnreadState.unreadAdvisorMessages)
  unreadAdvisorMessages$: Observable<number>;
  @Select(UnreadState.unreadTransphormerAnnouncements)
  unreadTransphormerAnnouncements$: Observable<number>;
  @Select(AssessmentConfigState.daysTillAssessmentAllowed)
  daysTillAllowed$: Observable<number>;
  @Select(UnreadState.unreviewedAdvisorAssessments)
  unreviewedAdvisorAssessments$: Observable<number>;
  isTrialUser$ = this.user.isTrialUser$;
  remainingTrialDays$ = this.user.remainingTrialDays$;
  stepsParticipation$ = this.wearableConfigService.hasWearableConnected$;

  constructor(
    public navCtrl: NavController,
    public auth: AuthenticationService,
    public user: UserService,
    public userStorage: UserStorageService,
    public modalController: ModalController,
    private store: Store,
    private wearableConfigService: WearablesConfigService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private mixpanelService: MixPanelService,
  ) {}

  ngOnInit() {
    this.transphormer$ = this.user.user$.pipe(
      untilDestroyed(this),
      filter(i => !!i),
      map(u => ({ ...u, is_cake_day: this.user.isCakeDay() })),
      tap(
        u =>
          u?.linked_trainer?.trainer?.id &&
          this.store.dispatch(
            new LoadAdvisorConfig(u.linked_trainer.trainer.id),
          ),
      ),
    );
    this.userStorage
      .get<boolean>('expanded-settings-menu', false)
      .subscribe((setting: boolean) => {
        this.isCollapsed$.next(setting);
      });
  }

  stopPropagation($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
  }

  /**
   * Navigates to new url as root page preventing event bubbling up. This allows
   * for the addition of buttons within a regular navigation item that might
   * otherwise "take over" a tap on the item itself.
   *
   * @param $event
   * @param url
   */
  public stopPropagationAndNavigate($event: Event, url: string) {
    this.stopPropagation($event);
    this.navCtrl.navigateRoot(url).then();
    this.rollbar.debug('Expanded menu navigate dismissed.');
    this.modalController.dismiss().then();
  }

  /**
   * Log user out of system and redirect to login screen
   */
  public logout() {
    try {
      this.auth.logout().subscribe(() => this.navCtrl.navigateRoot('/login'));
    } catch (e) {
      console.error(e);
    }
  }

  public collapseSettingsItem() {
    this.isCollapsed$.next(!this.isCollapsed$.getValue());
    this.userStorage.set(
      'expanded-settings-menu',
      this.isCollapsed$.getValue(),
    );
  }

  dismissUponItemClick($event) {
    let target = $event.target,
      depth = 0;

    while (target && target.tagName !== 'ION-ITEM' && depth < 8) {
      target = target.parentElement;
      depth++;
    }

    if (
      target &&
      target.tagName === 'ION-ITEM' &&
      !target.classList.contains('expand')
    ) {
      this.modalController.dismiss().then();
    }
  }

  dismiss() {
    this.rollbar.debug('Expanded menu dismissed.');
    this.modalController.dismiss().then();
  }

  async goPremium() {
    const modal = await this.modalController.create({
      component: SubscribeComponent,
      canDismiss: true,
    });
    return await modal.present();
  }

  getColor(days: number): string {
    if (days >= 5) {
      return 'primary';
    } else if (days >= 3) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  get darkModeIcon$() {
    return this.userStorage
      .getStream<
        'system' | 'light' | 'dark'
      >('user-dark-mode-preference', 'system')
      .pipe(
        map(v => {
          switch (v) {
            case 'light':
              return 'sunny-outline';
            case 'dark':
              return 'moon-outline';
            default:
              return 'invert-mode-outline';
          }
        }),
      );
  }

  get darkModeText$() {
    return this.userStorage
      .getStream<
        'system' | 'light' | 'dark'
      >('user-dark-mode-preference', 'system')
      .pipe(
        map(v => {
          switch (v) {
            case 'light':
              return 'Light';
            case 'dark':
              return 'Dark';
            default:
              return 'System';
          }
        }),
      );
  }

  changeDarkModePreference(event) {
    event.preventDefault();
    event.stopPropagation();

    this.userStorage
      .get<'system' | 'light' | 'dark'>('user-dark-mode-preference', 'system')
      .pipe(
        map(v => {
          switch (v) {
            case 'light':
              return 'dark';
            case 'dark':
              return 'system';
            default:
              return 'light';
          }
        }),
      )
      .subscribe(v => {
        this.userStorage.set('user-dark-mode-preference', v);
      });
  }

  trackingClickOption(itemOption: string) {
    this.mixpanelService.track('Menu Item Clicked', {
      'Item Name': itemOption,
    });
  }

  canShowFood(transphormer: Transphormer & { is_cake_day: boolean }) {
    return (
      transphormer.is_paid_user &&
      transphormer.likely_to_do === NutritionPlan.CalorieMacroCounting
    );
  }
}
