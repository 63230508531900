import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnimationController, ModalController } from '@ionic/angular';
import { filter, tap } from 'rxjs/operators';
import { ExpandedMenuComponent } from '../../components/expanded-menu/expanded-menu.component';
import { MixPanelService } from '../../../../services/mix-panel/mix-panel.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private expandedMenu: HTMLIonModalElement | null = null;

  leaveAnimation = (baseEl: HTMLElement) => {
    return this.enterAnimation(baseEl).direction('reverse');
  };

  enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationController
      .create()
      .addElement(root.querySelector('ion-backdrop'))
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationController
      .create()
      .addElement(root.querySelector('.modal-wrapper'))
      .keyframes([
        { offset: 0, opacity: '.4', transform: 'translateY(20%)' },
        { offset: 1, opacity: '0.99', transform: 'translateY(0)' },
      ]);

    return this.animationController
      .create()
      .addElement(baseEl)
      .duration(200)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  constructor(
    private modalController: ModalController,
    private animationController: AnimationController,
    private router: Router,
    private mixpanelService: MixPanelService,
  ) {
    router.events
      .pipe(
        filter(next => next instanceof NavigationEnd),
        tap(() => this.close()),
      )
      .subscribe();
  }

  get isOpen() {
    return this.expandedMenu;
  }

  async open() {
    // either close the menu if already open or show the menu if closed
    if (this.isOpen) {
      this.close();
    } else {
      this.mixpanelService.track('Open Navigation Menu');
      const popover = await this.modalController.create({
        component: ExpandedMenuComponent,
        animated: true,
        enterAnimation: this.enterAnimation,
        leaveAnimation: this.leaveAnimation,
        backdropDismiss: false,
        showBackdrop: false,
        id: 'nav-menu-expanded',
      });

      popover.present();
      popover.onDidDismiss().then(() => {
        this.expandedMenu = null;
      });
      this.expandedMenu = popover;
    }
  }

  async close() {
    if (this.isOpen) {
      this.mixpanelService.track('Close Navigation Menu');
      await this.expandedMenu.dismiss();
      this.expandedMenu = null;
      return true;
    }

    return true;
  }
}
