import { NGXS_PLUGINS, NgxsPlugin } from '@ngxs/store';
import {
  Inject,
  Injectable,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';

export const NGXS_LOGGER_PLUGIN_OPTIONS = new InjectionToken(
  'NGXS_LOGGER_PLUGIN_OPTIONS',
);

@Injectable()
export class RollbarTelemetryPlugin implements NgxsPlugin {
  constructor(
    @Inject(NGXS_LOGGER_PLUGIN_OPTIONS) private options,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  handle(state, action, next) {
    // @todo DRY objectMap.
    this.rollbar.captureEvent(
      {
        type: action.constructor.type,
        params: Object.keys(action)
          .map(function (key) {
            return { key, value: action[key] };
          })
          .reduce((o, v) => ({ ...o, [v.key]: v.value }), {}),
      },
      'debug',
    );
    return next(state, action);
  }
}

@NgModule()
export class NgxsRollbarTelemetryModule {
  static forRoot(config?): ModuleWithProviders<NgxsRollbarTelemetryModule> {
    return {
      ngModule: NgxsRollbarTelemetryModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: RollbarTelemetryPlugin,
          multi: true,
        },
        {
          provide: NGXS_LOGGER_PLUGIN_OPTIONS,
          useValue: config,
        },
      ],
    };
  }
}
