import { HealthData } from '@awesome-cordova-plugins/health/ngx';
import * as _ from 'lodash';
import {
  bucketToDuration,
  HealthAggregationBucket,
  HealthStepDayData,
  HealthStepsDayRecord,
} from '../types';
import {
  add,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatISO,
  parseISO,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import {
  endOf,
  isSameOrAfter,
  isSameOrBefore,
  startOf,
  yyyyMMdd,
} from '../../../helpers/date';

// 'hour' | 'day' | 'week' | 'month' | 'year';

export function getEmptyDateBuckets(
  startDate: Date,
  endDate: Date,
  bucket: HealthAggregationBucket,
): HealthData[] {
  const buckets: HealthData[] = [];

  // eslint-disable-next-line prefer-const
  let { startOfBucket: start, endOfBucket: end } = bucketStartEndTimes(
    bucket,
    startDate,
    endDate,
  );

  while (endOf(start, bucket) <= end) {
    buckets.push({
      startDate: startOf(start, bucket),
      endDate: endOf(start, bucket),
      unit: '',
      sourceName: 'Manual initialization',
      sourceBundleId: 'MTST',
      value: '0',
    });
    start = add(start, bucketToDuration(bucket, 1));
  }
  return buckets;
}

function bucketStartEndTimes(
  bucket: HealthAggregationBucket,
  startDate: Date,
  endDate: Date,
): { startOfBucket: Date; endOfBucket: Date } {
  switch (bucket) {
    case 'hour':
      return {
        startOfBucket: startOfHour(startDate),
        endOfBucket: endOfHour(endDate),
      };
    case 'day':
      return {
        startOfBucket: startOfDay(startDate),
        endOfBucket: endOfDay(endDate),
      };
    case 'week':
      return {
        startOfBucket: startOfWeek(startDate),
        endOfBucket: endOfWeek(endDate),
      };
    case 'month':
      return {
        startOfBucket: startOfMonth(startDate),
        endOfBucket: endOfMonth(endDate),
      };
    case 'year':
      return {
        startOfBucket: startOfYear(startDate),
        endOfBucket: endOfYear(endDate),
      };
  }
}

export function aggregateHealthDataByBucket(
  data: Pick<HealthData, 'startDate' | 'endDate' | 'value'>[],
  startDate: Date,
  endDate: Date,
  bucket: HealthAggregationBucket,
  fillDatePeriod = true,
) {
  if (!data || !data.length) {
    return [];
  }
  const { startOfBucket, endOfBucket } = bucketStartEndTimes(
    bucket,
    startDate,
    endDate,
  );

  const dataWithinStartAndEndDates = data.filter(value => {
    return (
      isSameOrBefore(startOfBucket, value.startDate) &&
      isSameOrAfter(endOfBucket, value.endDate)
    );
  });

  const groupedResults: HealthData[] = _([
    ...(fillDatePeriod ? getEmptyDateBuckets(startDate, endDate, bucket) : []),
    ...dataWithinStartAndEndDates,
  ])
    .map(entry => ({ ...entry, value: +entry.value }))
    .groupBy(entry => formatISO(startOf(entry.startDate, bucket)))
    .map((objs, key) => {
      return {
        startDate: startOf(parseISO(key), bucket),
        endDate: endOf(parseISO(key), bucket),
        unit: '',
        sourceName: 'Manual aggregation',
        sourceBundleId: 'MTST',
        value: '' + _.sumBy(objs, 'value'),
      } as HealthData;
    })
    .value();

  return groupedResults;
}

export function indexStepData(
  steps: HealthStepsDayRecord[],
): Record<string, HealthStepsDayRecord> {
  return steps.reduce((a, v) => {
    if (!a[v.recordDate] || a[v.recordDate] < v.value) {
      a[v.recordDate] = v;
    }
    return a;
  }, {});
}

export function transformHealthDataToHealthStepsDayData(
  data: Pick<HealthData, 'startDate' | 'value'>,
): HealthStepDayData {
  return {
    source: 'app',
    value: Math.round(+data.value),
    recordDate: yyyyMMdd(new Date(data.startDate)),
    record_date: yyyyMMdd(new Date(data.startDate)),
  };
}
