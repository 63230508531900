import {
  Directive,
  DoCheck,
  Input,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

@Directive({
  selector: '[appPlatform]',
})
export class PlatformDirective implements DoCheck {
  @Input() appPlatform: Platforms;

  private myView: ViewRef = null;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private platform: Platform,
  ) {}

  ngDoCheck() {
    if (this.platform.is(this.appPlatform)) {
      if (this.myView) {
        return;
      }
      this.myView = this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      if (this.myView) {
        this.myView.destroy();
      }
    }
  }
}
