import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { SetsRepsComponent } from './sets-reps/sets-reps.component';
import { ComplexExerciseComponent } from './complex-exercise/complex-exercise.component';
import { RelaxDayComponent } from './relax-day/relax-day.component';
import { NoteExerciseComponent } from './note-exercise/note-exercise.component';
import { StandardExerciseComponent } from './standard-exercise/standard-exercise.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UniversalComponentsModule } from '../../universal/universal-components.module';
import { SafeUrlModule } from '../../../pipes/safe-url/safe-url.module';
import { MovementSkeletonComponent } from './movement-skeleton/movement-skeleton.component';
import { WorkoutVideoComponent } from './workout-video/workout-video.component';
import { WorkoutComponent } from './workout/workout.component';
import { RouterModule } from '@angular/router';
import { MovementExplorerComponent } from './movement-explorer/movement-explorer.component';
import { MovementViewPage } from '../pages/movement-view/movement-view.page';
import { MovementStatsDetailComponent } from './movement-stats-detail/movement-stats-detail.component';
import { MovementViewSkeletonComponent } from '../pages/movement-view/components/movement-view-skeleton/movement-view-skeleton.component';
import { WorkoutViewComponent } from './workout-view/workout-view.component';
import { ProgramCardComponent } from './program-card/program-card.component';
import { TrainingPlanLevelChipComponent } from './training-plan-level-chip/training-plan-level-chip.component';
import { NgxLaunchDarklyModule } from '../../launchdarkly/ngx-launchdarkly.module';
import { SurveyResponseReviewComponent } from '../../stepped-survey/components/survey-response-review/survey-response-review.component';
import { LogoModule } from '../../../components/logo/logo.module';
import { WearablesModule } from '../../wearables/wearables.module';
import { WORKOUT_PIPES } from '../pipes';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UniversalComponentsModule,
    SafeUrlModule,
    RouterModule,
    NgxLaunchDarklyModule,
    WearablesModule,
    LogoModule,
  ],
  declarations: [
    ComplexExerciseComponent,
    SetsRepsComponent,
    RelaxDayComponent,
    NoteExerciseComponent,
    StandardExerciseComponent,
    MovementSkeletonComponent,
    WorkoutVideoComponent,
    WorkoutComponent,
    MovementViewPage,
    MovementExplorerComponent,
    MovementStatsDetailComponent,
    MovementViewSkeletonComponent,
    WorkoutViewComponent,
    ...WORKOUT_PIPES,
    ProgramCardComponent,
    TrainingPlanLevelChipComponent,
    SurveyResponseReviewComponent,
  ],
  exports: [
    ComplexExerciseComponent,
    SetsRepsComponent,
    RelaxDayComponent,
    NoteExerciseComponent,
    ProgramCardComponent,
    StandardExerciseComponent,
    MovementSkeletonComponent,
    WorkoutVideoComponent,
    WorkoutComponent,
    MovementViewPage,
    MovementExplorerComponent,
    MovementStatsDetailComponent,
    WorkoutViewComponent,
    ...WORKOUT_PIPES,
    TrainingPlanLevelChipComponent,
    SurveyResponseReviewComponent,
  ],
})
export class WorkoutComponentsModule {}
