import { Injectable } from '@angular/core';
import { Token } from '../base/base.service';
import { HttpClient, HttpContext } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  share,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { StorageService } from '../storage.service';
import { RegisterResponse } from '../../interfaces';
import { catchFormError } from '../../helpers/operators';
import { apiUrl } from '../../helpers';
import { TokenService } from '../token/token.service';
import { environment } from '../../../environments/environment';
import { IS_AUTHENTICATED } from '../interceptors/auth-token-interceptor/auth-token.interceptor';
import { Capacitor } from '@capacitor/core';

function addPlatformDataToRequest(data) {
  switch (Capacitor.getPlatform()) {
    case 'ios':
      data.operatingSystem = 'ios';
      break;
    case 'android':
      data.operatingSystem = 'android';
      break;
    default:
      break;
  }

  data.timezone_identifier = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return data;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends EventTarget {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private tokenService: TokenService,
  ) {
    super();
  }

  /**
   * Service to register user in the system
   *
   * @param email
   * @param password
   * @param first_name
   * @param last_name
   * @param campaignId
   * @param tref_id
   */
  public register(
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    campaignId: string = null,
    tref_id: number = null,
  ): Observable<void> {
    const result$ = of({
      email,
      password,
      first_name,
      last_name,
      campaignId,
      timezone_identifier: undefined,
      tref_id,
      ...this.clientIdSecret(),
      operatingSystem: 'web',
    })
      // Add the platform to the request.
      .pipe(
        switchMap(data => {
          return data.campaignId === null
            ? from(this.storageService.get('campaignId')).pipe(
                map(campaignId => {
                  if (campaignId === null) {
                    return data;
                  }

                  return { ...data, campaignId };
                }),
              )
            : of(data);
        }),
        switchMap(data => {
          return from(this.storageService.get('referrerId')).pipe(
            map(tref_id => {
              if (tref_id === null) {
                return data;
              }

              return { ...data, tref_id };
            }),
          );
        }),
        map(data => addPlatformDataToRequest(data)),
        switchMap(data =>
          this.http.post<RegisterResponse>(apiUrl('auth/register'), data, {
            context: new HttpContext().set(IS_AUTHENTICATED, false),
          }),
        ),
        tap((result: RegisterResponse) => {
          this.dispatchEvent(new Event('prelogin'));
          this.tokenService.setToken(result.token.access_token);
          this.dispatchEvent(new Event('postlogin'));
        }),
        map(() => void 0),
        share(),
      );

    result$.subscribe({ error: () => void 0 });

    return result$;
  }

  /**
   * Authenticates user and logs the user in.
   */
  public login(emailOrToken: string, password?: string): Observable<Token> {
    const authMethod$ = password
      ? this.loginEmailPassword(emailOrToken, password)
      : this.loginOtaToken(emailOrToken);

    const result$ = authMethod$.pipe(
      tap(result => {
        this.dispatchEvent(new Event('prelogin'));
        this.tokenService.setToken(result.access_token);
        this.dispatchEvent(new Event('postlogin'));
      }),
      shareReplay(1),
    );

    result$.subscribe({ error: () => void 0 });

    return result$;
  }

  public loginEmailPassword(
    email: string,
    password: string,
  ): Observable<Token> {
    const data = {
      ...this.clientIdSecret(),
      username: email,
      password: password,
      grant_type: 'password',
    };

    return this.http.post<Token>(apiUrl('auth/login'), data, {
      context: new HttpContext().set(IS_AUTHENTICATED, false),
    });
  }

  /**
   * Process a OTA (One-Time Authentication) token.
   *
   * @param token
   *  An "authenticated url" that will be sent to the user. This is a form of the
   *  auth/login, but will include additional information and timestamps.
   */
  public loginOtaToken(token: string): Observable<Token> {
    return this.http.post<Token>(
      apiUrl(token),
      { ...this.clientIdSecret() },
      {
        context: new HttpContext().set(IS_AUTHENTICATED, false),
      },
    );
  }

  /**
   * Logs the user out of the system
   */
  public logout(): Observable<void> {
    let logoutAction$: Observable<void>;

    // If we lack a token,
    if (this.tokenService.get() === null) {
      logoutAction$ = of(void 0);
    } else {
      logoutAction$ = this.http.post(apiUrl('auth/logout'), {}).pipe(
        // Because I want the rest of the pipeline to fire off, catch the error
        // here so that it will continue to run the rest of the pipeline below.
        catchError(error => {
          console.warn('Error when logging out', { error });
          return of(void 0);
        }),
        switchMap(() => of(void 0)),
      );
    }

    // Let anyone who cares know that we are starting the process of logging out.
    this.dispatchEvent(new Event('prelogout'));

    // When we call logout, we want the system to reflect that the user is logged out, but not every part
    // of the application may update, because there may be some places where we "missed" changing stuff.
    // So really, since clearing the token out is what matters, we clear the token out and issue a couple of
    // additional dispatches.
    const result$ = logoutAction$.pipe(
      tap(() => this.tokenService.clearToken()),
      tap(() => this.dispatchEvent(new Event('postlogout'))),
      share(),
    );

    result$.subscribe({ error: () => void 0 });

    return result$;
  }

  public forgotPassword(email: string): Observable<unknown> {
    const data = {
      email: email,
      ...this.clientIdSecret(),
    };

    return this.http
      .post(apiUrl('auth/forgot-password'), data, {
        context: new HttpContext().set(IS_AUTHENTICATED, false),
      })
      .pipe(catchFormError());
  }

  private clientIdSecret = () => ({
    client_id: environment.clientId,
    client_secret: environment.clientSecret,
  });
}
