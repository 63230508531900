import { Transfer } from '../../interfaces';
import { FilePath } from '../states/transfers.state';

export class StartUpload {
  static readonly type = '[Transfers] Start Upload';

  constructor(
    public newUpload: Transfer,
    public url: string,
    public formData: FormData,
    public fileUrl: string = null,
    public sendBearerTokens = true,
    public fileKey: string | null = 'media',
  ) {}
}

export class StartDirectUpload {
  static readonly type = '[Transfers] Start Direct Upload';

  constructor(
    public transfer: Transfer,
    public url: string,
    public data: FilePath | Blob,
    public sendBearerTokens = false,
  ) {}
}

export class RetryDirectUpload {
  static readonly type = '[Transfers] Retry Direct Upload';

  constructor(
    public transfer: Transfer,
    public url: string,
    public data: FilePath | Blob,
  ) {}
}

export class UpdateProgress {
  static readonly type = '[Transfers] Update Progress';

  constructor(public updatedUpload: Partial<Transfer>) {}
}

export class FinishUpload {
  static readonly type = '[Transfers] Finish Upload';

  constructor(
    public id: string,
    public response,
  ) {}
}

export class ErrorUpload {
  static readonly type = '[Transfers] Error Upload';

  constructor(
    public id: string,
    public response,
  ) {}
}

export class RemoveTransfer {
  static readonly type = '[Transfers] Remove Transfer';

  constructor(public id: string) {}
}

export class RemoveAllTransfers {
  static readonly type = '[Transfers] Remove All Transfer';
}
