import {
  AmountConsumed,
  CaloriesMacros,
  ServingSize,
  TrackableItem,
  TrackedItem,
} from '../../interfaces';

/**
 * Theory of operation:
 *
 * Essentially, when editing a food, we have several operations that a user can take. The first operation
 * is to actually "set" the food item that is being edited. This is done by calling SetEditingFood.
 *
 * Once a food as been set up, it will have some modifiers added to it which impact what the user may or
 * may not do to the food item under operation. Under some circumstances, the foodItem has all of the necessary
 * data needed to actually convert the item to a "TrackableItem". In that case, in that case the
 * `isReadyToTrack` flag will be `true`.
 *
 * If the item lacks some of the necessary information it will have other flags set on the state so that
 * the form system knows, for instance, if the user must supply serving information. This would happen if
 * the food to be tracked is empty.
 *
 * 1. SetEditingFood.
 */

export class SetEditingFood {
  static readonly type = '[FoodEditing] Set Editing Food';

  constructor(public food: TrackableItem | TrackedItem) {}
}

export class ClearEditingFood {
  static readonly type = '[FoodEditing] Clear Editing Food';
}

export class UpdateConsumedAmounts {
  static readonly type = '[FoodEditing] Update Consumed Amounts';

  constructor(public consumed: AmountConsumed) {}
}

export class BeginEditingServing {
  static readonly type = '[FoodEditing] Begin Editing Serving';
}

export class EndEditingServing {
  static readonly type = '[FoodEditing] End Editing Serving';
}

export class UpdateMacros {
  static readonly type = '[FoodEditing] Update Macros';

  constructor(public macros: CaloriesMacros) {}
}

export class UpdateCalories {
  static readonly type = '[FoodEditing] Update Calories';

  constructor(public calories: number) {}
}

export class UpdateServing {
  static readonly type = '[FoodEditing] Update Serving';

  constructor(public servingSize: ServingSize) {}
}

export class UpdateName {
  static readonly type = '[FoodEditing] Update Name';

  constructor(public name: string) {}
}

export class UpdateImage {
  static readonly type = '[FoodEditing] Update Image';

  constructor(public picture_url: string) {}
}

export class SetFoodMealNumber {
  static readonly type = '[FoodEditing] Set Food Meal Number';

  constructor(public meal: number) {}
}

export class UpdateFoodMealNumber {
  static readonly type = '[FoodEditing] Update Food Meal Number';

  constructor(public meal: number) {}
}
