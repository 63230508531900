import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NavController } from '@ionic/angular';
import { BottomMenuService, ToastService, UserService } from '../services';
import { filter, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileCompleteGuard {
  constructor(
    private navCtrl: NavController,
    private toastSvc: ToastService,
    private user: UserService,
    private bottomMenu: BottomMenuService,
  ) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.user.user$.pipe(
      filter(u => !!u),
      mergeMap(user => {
        if (user === null) {
          this.navCtrl.navigateRoot('login');
          return of(false);
        }
        if (user && user.profile_complete) {
          return of(user.is_paid_user).pipe(
            tap(value => {
              if (value) {
                this.bottomMenu.show();
              } else {
                // If the user is not premium, hide the menu.
                this.bottomMenu.hide();
              }
            }),
            // Don't emit anything if the value is false. This means that
            // the subscription-only field is true and that the user is not
            // premium. We don't want this observable to emit anything because
            // then it would finalize and our bottom menu would be hidden until
            // the user navigated again. So, if the value is false, don't emit
            // and allow the user.user$ observable to re-emit once the user
            // goes premium, which will *then* allow the bottom-menu to be shown.
            filter(v => !!v),
          );
        }
        this.toastSvc.flash('You must finish your profile.');
        this.navCtrl.navigateRoot('onboarding');
        return of(false);
      }),
    );
  }
}
