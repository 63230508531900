import {
  Transfer,
  TransferState,
  TransferStreamType,
  TransferType,
} from '../../interfaces';

import { v1 } from 'uuid';
import { FileUploadInfo } from '../../helpers';

export function newTransfer(newUpload: Transfer): Transfer {
  // Add new upload to the state.
  return {
    id: newUpload.id || v1(),
    progress: 0,
    state: TransferState.Pending,
    progressTimestamp: Date.now(),
    streamType: TransferStreamType.Upload,
    retryCount: 0,
    ...newUpload,
  };
}

export function createTransfer<T>(
  type: TransferType,
  source: FileUploadInfo,
): Transfer<T> {
  // Add new upload to the state.
  return {
    ...source,
    type,
    // The Transfer expects this to be here.
    fileUrl: source.path,
    id: v1(),
    progress: 0,
    state: TransferState.Pending,
    progressTimestamp: Date.now(),
    streamType: TransferStreamType.Upload,
    retryCount: 0,
  };
}
