import { Injectable } from '@angular/core';
import { LaunchDarklyService } from '../modules/launchdarkly/ngx-launchdarkly.service';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';
import { UserStorageService } from './user-storage/user-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  private mq = window.matchMedia('(prefers-color-scheme: dark)');

  systemDarkModePreference$ = merge(
    of(this.mq),
    fromEvent<MediaQueryList>(this.mq, 'change'),
  ).pipe(map(result => result.matches));

  /**
   * Emit a value for if we should display in dark mode or not. Uses the initial value
   * and the emits if the value changes again due to system preferences (e.g. time).
   */
  isDarkModeChosen$ = this.userStorageService
    .getStream('user-dark-mode-preference', 'system')
    .pipe(
      switchMap(v =>
        v === 'system' ? this.systemDarkModePreference$ : of(v === 'dark'),
      ),
      distinctUntilChanged(),
    );

  /*
   * Emits true if 1) the flag for dark mode is enabled AND 2) if the user has dark mode enabled.
   * Will emit false if everything is disabled!
   */
  darkModeEnabled$ = this.launchDarkly
    .flag('theme--add-ability-to-switch-between-dark-and-light-modes')
    .pipe(
      switchMap(enabled => (!enabled ? of(false) : this.isDarkModeChosen$)),
      shareReplay(1),
    );

  constructor(
    private launchDarkly: LaunchDarklyService,
    private userStorageService: UserStorageService,
  ) {}
}
