import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { TokenService } from '../../token/token.service';

export interface EntryContent<T = unknown> {
  response: HttpResponse<T>;
  expiration: number;
}

@Injectable({
  providedIn: 'root',
})
export class RequestCachingService {
  constructor(private tokenService: TokenService) {
    this.tokenService.hasAccessToken$.subscribe(token => {
      if (token) {
        this.clearAll();
      }
    });
  }

  public cache = new Map();

  public get<T = unknown>(
    request: HttpRequest<T>,
  ): HttpResponse<T> | undefined {
    const key = this.createKey(request.url, request.method);

    const cachedValue: EntryContent<T> = this.cache.get(key);

    if (!cachedValue) {
      return undefined;
    }

    if (cachedValue.expiration >= Date.now()) {
      return cachedValue.response;
    }

    return undefined;
  }

  public clearKey(url: string, requestType: string) {
    const key = this.createKey(url, requestType);
    this.cache.delete(key);
  }

  public createKey(url: string, requestType: string): string {
    return `${url}|${requestType}`;
  }

  public put(
    request: HttpRequest<unknown>,
    response: HttpResponse<unknown>,
    expirationTime: number,
  ): void {
    const key = this.createKey(request.url, request.method);
    this.cache.set(key, this.entryContent(response, expirationTime));
  }

  public entryContent(
    response: HttpResponse<unknown>,
    expiration: number,
  ): EntryContent {
    return <EntryContent>{
      expiration,
      response,
    };
  }

  public clearAll() {
    this.cache.clear();
  }
}
