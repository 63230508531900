import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvertLengthPipe } from './pipes/convert-length/convert-length.pipe';
import { ConvertWeightPipe } from './pipes/convert-weight/convert-weight.pipe';
import { ConvertDistancePipe } from './pipes/convert-distance/convert-distance.pipe';
import { MetricUnitPipe } from './pipes/metric-unit.pipe';

@NgModule({
  declarations: [
    ConvertLengthPipe,
    ConvertWeightPipe,
    ConvertDistancePipe,
    MetricUnitPipe,
  ],
  imports: [CommonModule],
  exports: [
    ConvertLengthPipe,
    ConvertWeightPipe,
    ConvertDistancePipe,
    MetricUnitPipe,
  ],
})
export class ConvertUnitModule {}
