import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { createEntityCollections } from '@angular-ru/cdk/entity';
import { MessageAttachment3 } from '../../interfaces/message';

@Injectable({
  providedIn: 'root',
})
@StateRepository()
@State({
  name: 'message_attachments',
  defaults: createEntityCollections(),
})
@Injectable()
export class MessageAttachmentsState extends NgxsDataEntityCollectionsRepository<MessageAttachment3> {}
