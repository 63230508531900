import { choices, macros, values } from './nutrition-values';
import { CategoryTypes, MacroInfo } from '../../interfaces';
import {
  Goal,
  Sex,
  TransphormationGoalTypes,
  Transphormer,
} from '../../../../interfaces';

export class SimpleNutritionCalculator {
  static MAX_MALE_PROFILE = 6;
  static MAX_FEMALE_PROFILE = 7;

  public sex: string;
  public index = [];
  public userProfile: number;

  public constructor(
    protected transphormer: Pick<
      Transphormer,
      | 'sex'
      | 'latest_weight'
      | 'transphormation_goal'
      | 'activity_level'
      | 'goal_weight_units'
      | 'preference_macro_counting'
      | 'likely_to_do'
      | 'is_paid_user'
      | 'custom_macros'
    >,
    protected weight: number,
    protected numberOfMeals: number | null = null,
  ) {
    this.sex = this.transphormer.sex === Sex.Male ? 'male' : 'female';

    const baseProfile = this.getBaseProfile(this.sex, this.weight);
    this.userProfile = this.getModifiedProfile(
      baseProfile,
      this.transphormer.transphormation_goal,
      this.sex,
    );

    for (const type in choices) {
      if (choices[type] instanceof Array) {
        this.index[type] = choices[type].map(i => {
          return i.name;
        });
      }
    }
  }

  public getModifiedProfile(
    base: number,
    goal: TransphormationGoalTypes,
    sex: string,
  ) {
    if (goal === Goal.Lose) {
      return Math.max(0, base - 1);
    } else if (goal === Goal.Gain) {
      const maxColumn =
        sex === 'male'
          ? SimpleNutritionCalculator.MAX_MALE_PROFILE
          : SimpleNutritionCalculator.MAX_FEMALE_PROFILE;
      return Math.min(maxColumn, base + 1);
    }
    return base;
  }

  public getBaseProfile(sex: string, weight: number): number {
    if (sex === 'male') {
      if (weight >= 260) {
        return 6;
      } else if (weight >= 220) {
        return 5;
      } else if (weight >= 200) {
        return 4;
      } else if (weight >= 185) {
        return 3;
      } else if (weight >= 160) {
        return 2;
      } else if (weight >= 145) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (weight >= 200) {
        return 7;
      } else if (weight >= 180) {
        return 6;
      } else if (weight >= 160) {
        return 5;
      } else if (weight >= 150) {
        return 4;
      } else if (weight >= 140) {
        return 3;
      } else if (weight >= 130) {
        return 2;
      } else if (weight >= 120) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  public getMacros(): MacroInfo {
    return macros[this.sex][this.userProfile];
  }

  public nutritionValue(category: CategoryTypes, name: string): number {
    const data = values[this.numberOfMeals || 3][this.sex][category];
    const itemNo = this.index[category].findIndex(item => {
      return item === name;
    });
    return data[itemNo][this.userProfile];
  }
}
