import { NgModule } from '@angular/core';
import { BlockContainerComponent } from './block-container/block-container.component';
import { BlockContentComponent } from './block-content/block-content.component';
import { BlockDropdownComponent } from './block-dropdown/block-dropdown.component';
import { BlockButtonComponent } from './block-button/block-button.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TransphormerProfilePictureModule } from '../../components/transphormer-profile-picture/transphormer-profile-picture.module';
import { BlockDragItemComponent } from './block-drag-item/block-drag-item.component';
import { BlockDragDropComponent } from './block-drag-drop/block-drag-drop.component';
import { CircleItemComponent } from './circle-item/circle-item.component';
import { FueModule } from '../fue/fue.module';
import { BlockVideoComponent } from './block-video/block-video.component';
import { VideoComponentsModule } from '../video/components/video-components.module';
import { GoalBarComponent } from './goal-bar/goal-bar.component';
import { TimerDisplayComponent } from './timer-display/timer-display.component';
import { ReactiveFormsModule } from '@angular/forms';
import { QuestionPopupComponent } from './rate-review/question-popup.component';
import { CircleStatusComponent } from '../training/pages/activity/components/circle-status/circle-status.component';
import { TodayOrDatePipe } from './pipes/today-or-date.pipe';
import { LogoModule } from '../../components/logo/logo.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { ReactionsModule } from '../reactions/reactions.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    TransphormerProfilePictureModule,
    SortablejsModule,
    FueModule,
    VideoComponentsModule,
    ReactiveFormsModule,
    LogoModule,
    ReactionsModule,
  ],
  declarations: [
    BlockContainerComponent,
    BlockContentComponent,
    BlockDropdownComponent,
    BlockButtonComponent,
    BlockDragDropComponent,
    BlockDragItemComponent,
    CircleItemComponent,
    GoalBarComponent,
    BlockVideoComponent,
    TimerDisplayComponent,
    QuestionPopupComponent,
    CircleStatusComponent,
    TodayOrDatePipe,
  ],
  exports: [
    BlockContainerComponent,
    TodayOrDatePipe,
    BlockContentComponent,
    BlockDropdownComponent,
    BlockButtonComponent,
    BlockDragItemComponent,
    BlockDragDropComponent,
    CircleItemComponent,
    GoalBarComponent,
    BlockVideoComponent,
    TimerDisplayComponent,
    QuestionPopupComponent,
    CircleStatusComponent,
  ],
  providers: [],
})
export class UniversalComponentsModule {}
