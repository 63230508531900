import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiData } from '../../../../interfaces';
import {
  FoodItem,
  FoodSearchResultItem,
  FoodSourceItem,
  TrackableSourceItem,
  TrackedItem,
  WeightedFoodItemIndexElement,
} from '../../interfaces';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { apiUrl } from '../../../../helpers';
import { FoodSourceServiceInterface } from '../food-manager/interfaces';
import { ScrewedUpMTItem } from '../../interfaces/legacy';
import { handleApiError } from '../../../../helpers/operators';

@Injectable()
export class FoodItemsService implements FoodSourceServiceInterface {
  constructor(protected http: HttpClient) {}

  public convertToFoodItem(trackedItem: ScrewedUpMTItem): FoodItem {
    return {
      id: trackedItem.id,
      name: trackedItem.name,
      meal: trackedItem.meal,
      calories: trackedItem.calories,
      carbs: trackedItem.carbs,
      protein: trackedItem.protein,
      fats: trackedItem.fats,
      fiber: trackedItem.fiber || 0,
      is_custom: trackedItem.is_custom,
      is_branded_food: trackedItem.is_branded_food,
      nutritionix_food_name: trackedItem.nutritioninx_food_name,
      nutritionix_item_id: trackedItem.nutritioninx_item_id,
      is_custom_food_template: trackedItem.is_custom_food_template,
      amount_consumed: {
        unit: trackedItem.consumed_unit,
        amount: trackedItem.consumed_amount,
      },
      serving_size: trackedItem.serving_information
        ? {
            calories: trackedItem.serving_information.calories,
            carbs: trackedItem.serving_information.carbs,
            fiber: trackedItem.serving_information.fiber || 0,
            protein: trackedItem.serving_information.protein,
            fats: trackedItem.serving_information.fats,
            unit: trackedItem.serving_information.serving_unit,
            amount: trackedItem.serving_information.serving_amount,
          }
        : null,
      alt_servings: trackedItem.serving_information
        ? trackedItem.serving_information.alt_servings
        : null,
      total_count: trackedItem.total_count || 0,
      quick_add: trackedItem.consumed_unit === 'quick add',
      thumbnail: trackedItem.thumbnail,
    };
  }

  public recentlyTrackedItems(): Observable<TrackedItem[]> {
    return this.http
      .get<ApiData<TrackedItem[]>>(apiUrl(`nutrition/recent`))
      .pipe(map(result => result.data));
  }

  public searchWeightedTrackedItemsForTerm(
    term: string,
    _options?,
  ): Observable<WeightedFoodItemIndexElement[]> {
    const requestParams = {
      params: {
        name: term || '',
      },
    };

    return this.http
      .get<
        WeightedFoodItemIndexElement[]
      >(apiUrl('nutrition/search/weights'), requestParams)
      .pipe(
        handleApiError(),
        switchMap(results => {
          if (!!results === false) {
            return of([]);
          }
          return of(results);
        }),
      );
  }

  /**
   * Returns around this time food items.
   */
  public aroundThisTimeTrackedItems(): Observable<{
    trackedItems: TrackedItem[];
    meals: TrackedItem[];
  }> {
    return this.http
      .get<{
        trackedItems: TrackedItem[];
        meals: TrackedItem[];
      }>(apiUrl(`nutrition/around-this-time`))
      .pipe(
        handleApiError(),
        map(a => {
          // The nutrition state was expecting "tracked-items" but the specification is for `trackedItems`.
          // So this little hack here just makes sure that if we are passing `trackedItems`.
          if (
            a['tracked-items'] !== undefined &&
            a.trackedItems === undefined
          ) {
            a.trackedItems = a['tracked-items'];
          }

          return a;
        }),
      );
  }

  afterTrack(_item: TrackedItem): void {
    //
  }

  defaultResults(): Observable<TrackableSourceItem[]> {
    return EMPTY;
  }

  getSearchDetails(_item: FoodSourceItem): Observable<TrackableSourceItem[]> {
    return undefined;
  }

  searchForTerm(_term: string, _options?): Observable<FoodSearchResultItem[]> {
    return EMPTY;
  }
}
