export const environment = {
  production: true,
  apiUrl: "https://app.mytransphormation.com/api/v2/",
  jsonApiUrl: "https://app.mytransphormation.com/jsonapi/v3/",
  clientId: "1",
  clientSecret: "PuKExwA7hVT2inrO4V723zgLroJBvWoQeBsQGd1T",
  pusher: {
    key: "9b877de658dea56a7336",
    cluster: 'us2',
    host: null,
  },
  mux_data_key: "vdjjvfnanbtsak6c0tsdv654c",
  nutritionix: {
    appId: "21dd02a2",
    appKey: "11263f9ac688ea0500f7f2ee85c2a910",
    url: "https://trackapi.nutritionix.com/v2/",
  },
  rollbar: {
    accessToken: '16eff080fa8145259ec2c796f0fcb2cf',
    uniqueDomain: '',
  },
  mixpanel: {
    token: 'MIXPANEL_ACCESS_TOKEN',
  },
  gitHash: '',
  releaseName: 'production/2024-09-25.0',
  launchDarklyApiKey: '61df2fe323ccec133c6f3aed',
  feedChat: {
    apiKey: '6wgv48a43wp2',
    appId: '1258989',
  },
};
