import {
  Swap,
  Workout,
  WorkoutExercise,
  WorkoutExerciseData,
  WorkoutGroup,
  WorkoutInfo,
  WorkoutSessionV3,
} from '../../../interfaces';
import produce from 'immer';
import { parseInt } from 'lodash';

function isEntered(wiElement) {
  return (
    wiElement !== '' &&
    wiElement !== undefined &&
    wiElement !== null &&
    parseInt(wiElement, 10) >= 0
  );
}

export function createWorkoutInfo(
  recordType: number,
  loopCount: number | null,
): WorkoutInfo[] {
  if (recordType === 1 && !loopCount) {
    loopCount = 1;
  }

  if (!loopCount) {
    return [];
  }

  const workouts: WorkoutInfo[] = [];
  for (let i = 1; i <= loopCount; i++) {
    workouts.push({
      weight: null,
      reps: null,
    });
  }

  return workouts;
}

export const addSetRepsToMovement = (
  movement: WorkoutExercise,
  group: WorkoutGroup,
  workoutData: WorkoutExerciseData[],
) => {
  // Check if exercise date is complete
  const index = workoutData.findIndex(
    workoutExercise =>
      workoutExercise.exercise_id === movement.exercise.id &&
      (!workoutExercise.group_movement_id ||
        workoutExercise.group_movement_id === movement.id) &&
      workoutExercise.exercise_group_id === group.id,
  );

  let movementData: WorkoutInfo[];

  if (index <= -1) {
    movementData = createWorkoutInfo(
      movement.record_type,
      (group.total_sets || 1) * (movement.total_sets || 1),
    );
  } else {
    // Load the existing data.
    movementData = workoutData[index].workout_info;
  }

  const completed =
    0 ===
    movementData.reduce((a, wi) => {
      return (
        a +
        (('reps' in wi ? isEntered(wi['reps']) : true) &&
        ('weight' in wi ? isEntered(wi['weight']) : true)
          ? 0
          : 1)
      );
    }, 0);

  return {
    ...movement,
    completed,
    movementData,
  };
};

export const addMovementExpansion = (
  movement: WorkoutExercise,
  groupId,
  expandedExercises,
) => ({
  ...movement,
  expanded: expandedExercises[`${groupId}:${movement.id}`] ?? false,
});

export const applySetsAndRepsToWorkout = (
  workout: Workout,
  session: WorkoutSessionV3,
): Workout => {
  return produce((draft: Workout) => {
    // If there aren't any movements, there is nothing to do.
    if (!draft.exercise_groups) {
      return;
    }

    draft.exercise_groups = draft.exercise_groups.map(eg => {
      const exercises = eg.exercises
        .map(movement =>
          addSetRepsToMovement(movement, eg, session.workout_data),
        )
        .map(movement => {
          return addMovementExpansion(
            movement,
            eg.id,
            session.expansions || {},
          );
        });

      return {
        ...eg,
        exercises,
        isComplete:
          eg.exercises.filter(movement => !movement.completed).length !== 0,
      };
    });
  }, workout)();
};

export function applySwapsToWorkout(workout: Workout, swaps: Swap[]): Workout {
  // I am transcoding this (ish) from PHP, since that is where it originally lived.
  // Don't do anything if there are no swaps.
  if (swaps.length === 0) {
    return workout;
  }

  return {
    ...workout,
    exercise_groups: workout.exercise_groups.map(eg => {
      return {
        ...eg,
        exercises: eg.exercises.map(groupMovement => {
          let matchingSwaps = swaps.filter(
            s => s.original_movement_id === groupMovement.exercise_id,
          );

          // If nothing matches, skip.
          if (matchingSwaps.length === 0) {
            return groupMovement;
          }

          // If there are multiple options, choose the most specific one.
          if (matchingSwaps.length > 1) {
            // Whichever one has a session ID should be used.
            matchingSwaps = matchingSwaps.filter(
              s =>
                s.workout_session_id !== null ||
                s.workout_session_uuid !== null,
            );
          }

          const swap = matchingSwaps[0];

          return {
            ...groupMovement,
            name: swap.new_movement.name,
            exercise_id: swap.new_movement_id,
            exercise: swap.new_movement,
            swap: swap as Swap,
          };
        }),
      };
    }),
  };
}
