import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PaginationMetadata } from '../../../interfaces';

import { LoadReviews, Reset } from './week-in-review.actions';
import { WeekInReviewService } from '../services/week-in-review.service';
import { WeekInReview } from '../types';
import produce from 'immer';
import { createIndexedMap } from '../../../helpers';

export const WEEK_IN_REVIEW_STATE_TOKEN =
  new StateToken<WeekInReviewStateModel>('weekInReview');
export const WEEK_IN_REVIEW_STATE_EMPTY: WeekInReviewStateModel = {
  isLoading: false,
  reviewsByDate: {},
  dateIndex: [],
  metadata: null,
};

export interface WeekInReviewStateModel {
  isLoading: boolean;
  dateIndex: string[];
  reviewsByDate: { [key: string]: WeekInReview };
  metadata: PaginationMetadata;
}

@State<WeekInReviewStateModel>({
  name: WEEK_IN_REVIEW_STATE_TOKEN,
  defaults: WEEK_IN_REVIEW_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class WeekInReviewState {
  constructor(private weekInReviewService: WeekInReviewService) {}

  @Selector()
  static isLoading(state: WeekInReviewStateModel) {
    return state.isLoading;
  }

  static reviewForDate(date: string) {
    return createSelector([WeekInReviewState], state => {
      const currentIndexLength = state.dateIndex.length;
      const currentIndex = state.dateIndex.indexOf(date);

      return {
        ...state.reviewsByDate[date],
        nextId:
          currentIndex - 1 === currentIndexLength
            ? null
            : state.dateIndex[currentIndex + 1],
        previousId:
          currentIndex === 0 ? null : state.dateIndex[currentIndex - 1],
      };
    });
  }

  static hasMoreUnloadedData(date: string) {
    return createSelector(
      [WeekInReviewState],
      function (state: WeekInReviewStateModel) {
        return (
          state.dateIndex.indexOf(date) < 2 &&
          state.metadata.current_page < state.metadata.last_page
        );
      },
    );
  }

  @Selector()
  static latestDate(state: WeekInReviewStateModel) {
    return state.dateIndex[state.dateIndex.length - 1];
  }

  @Action(LoadReviews)
  loadReviews(
    ctx: StateContext<WeekInReviewStateModel>,
    { page }: LoadReviews,
  ) {
    ctx.patchState({ isLoading: true });
    return this.weekInReviewService.get(page).pipe(
      tap(reviews => {
        reviews.data = reviews.data.map(review => ({
          ...review,
          date_start: review.date_start.slice(0, 10),
          date_end: review.date_end.slice(0, 10),
        }));

        ctx.setState(
          produce((draft: WeekInReviewStateModel) => {
            draft.isLoading = false;
            draft.reviewsByDate = {
              ...draft.reviewsByDate,
              ...createIndexedMap(reviews.data, 'date_start'),
            };
            draft.metadata = reviews.meta;
            draft.dateIndex = Object.values<WeekInReview>(draft.reviewsByDate)
              .sort((r1, r2) => (r1.date_start > r2.date_start ? 1 : -1))
              .map(r => r.date_start);
          }),
        );
      }),
    );
  }

  @Action(Reset)
  reset(ctx: StateContext<WeekInReviewStateModel>) {
    ctx.setState(WEEK_IN_REVIEW_STATE_EMPTY);
  }
}
