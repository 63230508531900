import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StatusBar } from '@capacitor/status-bar';

@Component({
  selector: 'app-scanner-overlay',
  template: ` <ion-content scrollY="false">
    <div class="floating-close">
      <ion-icon
        (click)="close()"
        color="medium"
        name="close-outline"
      ></ion-icon>
    </div>

    <div class="scanner-box"></div>
  </ion-content>`,
  styles: [
    `
      .scanner-box {
        --width: 250px;
        border: 2px solid var(--ion-color-primary);
        width: var(--width);
        height: calc(var(--width) * 0.75);
        position: absolute;
        top: calc(50% - (var(--width) / 2));
        left: calc(50% - (var(--width) / 2));
      }

      .floating-close {
        font-size: calc(var(--default-font-size) * 2);
        position: absolute;
        top: calc(16px);
        z-index: 100;
        right: 20px;
      }
    `,
  ],
})
export class ScannerOverlayComponent implements OnInit, OnDestroy {
  constructor(private modalController: ModalController) {}

  ngOnInit() {
    StatusBar.hide();
  }

  ngOnDestroy() {
    StatusBar.show();
  }

  close() {
    this.modalController.dismiss();
  }
}
