import { NutritionConfiguration } from '../../interfaces';
import { NutritionStateModel } from './nutrition.state';
import { mySQLFormattedDate } from '../../../../helpers';

export const NUTRITION_DEFAULT_DAY_CONFIG: NutritionConfiguration = {
  meals: [],
  macros: { calories: 0, protein: 0, carbs: 0, fats: 0 },
  water_goal: 0,
  cmcSupplements: [],
};

export const NUTRITION_DEFAULT_STATE: NutritionStateModel = {
  data: {},
  activeNutrition: null,
  activeDate: mySQLFormattedDate(),
  daysById: {},
  todayRecord: null,
  todayDate: null,
  dateToIdMap: {},
};
