import { HttpParams } from '@angular/common/http';

export interface UTMblock {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

const UTMkeys = [
  'utm_source' /** Identifies which site sent the traffic, and is a required parameter. */,
  'utm_medium' /** Identifies what type of link was used, such as cost per click or email. */,
  'utm_campaign' /** Identifies a specific product promotion or strategic campaign.   */,
  'utm_term' /** Identifies search terms. */,
  'utm_content' /** Identifies what specifically was clicked to bring the user to the site, such as a banner ad or a text link.  */,
];

export function addUTMCodes(
  link,
  content: UTMblock,
  override = true,
  clear = true,
) {
  content = { utm_source: '1p-app', ...content };
  let myParams = new HttpParams();
  // Get the URL values
  const urlValues = link.indexOf('?')
    ? link.substring(link.indexOf('?')).replace('?', '')
    : '';
  Object.keys(content).forEach(
    val => (myParams = myParams.set(`${val}`, `${content[val]}`)),
  );

  urlValues.split('&').forEach(val => {
    if (UTMkeys.indexOf(val.split('=')[0]) >= 0) {
      // This is a UTM value
      myParams = override
        ? content[val.split('=')[0]]
          ? myParams.set(
              `${val.split('=')[0]}`,
              `${content[val.split('=')[0]]}`,
            )
          : myParams
        : myParams.set(`${val.split('=')[0]}`, `${val.split('=')[1]}`);
    } else {
      // This is not a UTM value
      myParams = !clear
        ? myParams.set(`${val.split('=')[0]}`, `${val.split('=')[1]}`)
        : myParams;
    }
  });

  const cleanUrl =
    link.indexOf('?') >= 0 ? link.substring(0, link.indexOf('?')) : link;

  return `${cleanUrl}?${myParams.toString()}`;
}
