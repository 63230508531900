import {
  AdvisorConfigQuestion,
  JsonApiObject,
  JsonApiResponse,
} from '../../../assessments-v2/services/assessments/types';
import { apiUrl, jsonApiUrl } from '../../../../helpers';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AssessmentQuestion } from '../../../../interfaces';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { handleApiError } from '../../../../helpers/operators';
import { Injectable } from '@angular/core';

export interface CustomQuestion<T = string> {
  id: number;
  question: string;
  advisor_id: null | number;
  deleted_at: null | T;
  weight: number;
  value?: string;
  created_at: T;
  updated_at: T;
}

interface TransphormerQuestionsResponse<T = string> {
  'assessment-questions': CustomQuestion<T>[];
}

interface AssessmentQuestionResponse {
  version: number;
  questions: AssessmentQuestion[];
}

export type CombinedQuestions = AssessmentQuestion | CustomQuestion;

export interface CombinedQuestionsResponse {
  version: number;
  questions: CombinedQuestions[];
}

@Injectable({
  providedIn: 'root',
})
export class AssessmentQuestionsService {
  public _questionCache$: BehaviorSubject<AdvisorConfigQuestion[]> =
    new BehaviorSubject([]);

  constructor(private http: HttpClient) {}

  createNewAssessmentQuestion(
    question: Partial<AdvisorConfigQuestion>,
    advisorId: number,
  ) {
    return this.http
      .post<JsonApiResponse<JsonApiObject<AdvisorConfigQuestion<string>>>>(
        jsonApiUrl(`assessment-questions`),
        {
          data: {
            type: 'assessment-questions',
            attributes: {
              ...question,
            },
            relationships: {
              advisor: {
                data: {
                  type: 'trainers',
                  id: `${advisorId}`,
                },
              },
            },
          },
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        map(r => r.data),
        map(r => handleDates(jsonObjectToObject(r))),
        switchMap((r: AdvisorConfigQuestion) =>
          this._questionCache$.pipe(
            take(1),
            map(qc => {
              this._questionCache$.next([...qc, r]);
            }),
          ),
        ),
      );
  }

  updateAssessmentQuestion(
    questionId: number,
    question: AdvisorConfigQuestion,
  ) {
    this.http
      .patch<JsonApiResponse<JsonApiObject<AdvisorConfigQuestion<string>>>>(
        jsonApiUrl(`assessment-questions/${questionId}`),
        {
          data: {
            type: 'assessment-questions',
            id: `${questionId}`,
            attributes: {
              question,
            },
          },
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        map(result => result.data),
        map(r => handleDates(jsonObjectToObject(r))),
        // take all of the questions we just loaded and update the cache.
        switchMap((r: AdvisorConfigQuestion) =>
          this._questionCache$.pipe(
            take(1),
            map(qc => {
              const updated = [...qc.filter(q => q.id !== r.id), r];
              this._questionCache$.next(updated);
            }),
          ),
        ),
      )
      .subscribe();

    return this._questionCache$.asObservable();
  }

  removeAssessmentQuestion(questionId: number) {
    this.http
      .delete<JsonApiResponse<JsonApiObject<AdvisorConfigQuestion<string>>>>(
        jsonApiUrl(`assessment-questions/${questionId}`),
      )
      .pipe(
        tap(() => {
          const questions = this._questionCache$.value;

          this._questionCache$.next(
            questions.filter(q => +q.id !== questionId),
          );
        }),
      )
      .subscribe();
  }

  getAvailableQuestions(): Observable<AdvisorConfigQuestion[]> {
    this.http
      .get<JsonApiResponse<JsonApiObject<AdvisorConfigQuestion<string>>[]>>(
        jsonApiUrl(`assessment-questions`),
      )
      .pipe(
        handleApiError(),
        map(result => result.data),
        filter(i => !!i),
        map(r =>
          r.map<AdvisorConfigQuestion>(i => handleDates(jsonObjectToObject(i))),
        ),
        // take all of the questions we just loaded and update the cache.
        switchMap((r: AdvisorConfigQuestion[]) =>
          this._questionCache$.pipe(
            take(1),
            map(qc => {
              const updated = [
                ...qc.filter(i => r.findIndex(j => j.id === i.id) === -1),
                ...r,
              ];
              this._questionCache$.next(updated);
            }),
          ),
        ),
      )
      .subscribe();

    return this._questionCache$.asObservable();
  }

  getTransphormerAssessmentQuestions(
    transphormerId: number,
  ): Observable<TransphormerQuestionsResponse['assessment-questions']> {
    return this.http
      .get<
        JsonApiResponse<JsonApiObject<TransphormerQuestionsResponse>>
      >(jsonApiUrl(`transphormers/${transphormerId}`))
      .pipe(
        map(result => result.data.attributes['assessment-questions'] || []),
      );
  }

  assessmentQuestions(): Observable<{
    version: number;
    questions: AssessmentQuestion[];
  }> {
    return this.http.get<AssessmentQuestionResponse>(
      apiUrl('assessment-questions'),
    );
  }

  combineAdvisorAndBaseQuestions(
    transphormerId: number,
  ): Observable<CombinedQuestionsResponse> {
    return combineLatest([
      this.assessmentQuestions(),
      this.getTransphormerAssessmentQuestions(transphormerId),
    ]).pipe(
      map(([bq, aq]) => ({
        ...bq,
        questions: [...bq.questions, ...aq],
      })),
    );
  }
}

function jsonObjectToObject({ attributes, id }) {
  return { id, ...attributes };
}

export function handleDates<T extends { createdAt: string; updatedAt: string }>(
  i: T,
): T & {
  createdAt: Date;
  updatedAt: Date;
} {
  return {
    ...i,
    createdAt: parseISO(i.createdAt),
    updatedAt: parseISO(i.updatedAt),
  };
}
