import {
  BaseMacroType,
  CaloriesMacros,
  CmcSupplementTracker,
  MacroCountingInfo,
  MacroInfo,
  MacroReportingResponse,
  NutritionConfiguration,
} from '../../interfaces';
import { TrackedFood } from '../../interfaces/legacy';
import { format } from 'date-fns';

export interface MealInfo extends MacroInfo {
  meal: string;
  fiber: number;
  foods?: TrackedFood[];
}

export interface NutritionDayReport {
  type: 'cmc' | 'pc' | 'mmp';
  track_date: string | Date;
  short_date: string;
  carbs: number;
  carbsGoal: number;
  fats: number;
  fatsGoal: number;
  calories: number;
  caloriesGoal: number;
  protein: number;
  proteinGoal: number;
  fiber: number;
  water?: number;
  waterGoal?: number;
  fatsWindow: boolean;
  carbsWindow: boolean;
  proteinWindow: boolean;
  caloriesWindow: boolean;
  includesQuickAdd: boolean;
  meals: MealInfo[];
  supplements?: CmcSupplementTracker[];
  supplementsProgress?: number;
}

export function sumMacro(macro: string, day: MacroCountingInfo, meal?: number) {
  return Math.round(
    day.tracked_items.reduce(
      (a, v: TrackedFood) =>
        !meal ? a + v[macro] : v.meal !== meal ? a : a + v[macro],
      0,
    ),
  );
}

export function sumAll(day, meal?): CaloriesMacros {
  const val: CaloriesMacros = {
    calories: 0,
    carbs: 0,
    fats: 0,
    fiber: 0,
    protein: 0,
  };
  const items = ['calories', 'fats', 'protein', 'carbs', 'fiber'];
  items.forEach(i => {
    val[i] = sumMacro(i, day, meal);
  });
  return val;
}

export interface MacroWindowRange {
  below: number;
  above: number;
  calorieWindow?: number;
}

export function isMacroWindowRange(v): v is MacroWindowRange {
  return !!v.below && !!v.above;
}

type MacroWindow = number | MacroWindowRange;

export interface MacroWindowConfiguration {
  fats: MacroWindow;
  protein: MacroWindow;
  carbs: MacroWindow;
}

const defaultMacroWindows: MacroWindowConfiguration = {
  fats: 3,
  protein: { below: 5, above: 50 },
  carbs: 5,
};

export function getWindowForMacro(
  macro: BaseMacroType,
  config: NutritionConfiguration,
): MacroWindow {
  if (!config || !config.macroWindows) {
    return defaultMacroWindows[macro];
  }

  return config.macroWindows[macro];
}

function getMacroWindowValue(
  macro: BaseMacroType,
  config: NutritionConfiguration,
): number {
  const result = getWindowForMacro(macro, config);

  if (!isMacroWindowRange(result)) {
    return result;
  }

  if (result.calorieWindow) {
    return result.calorieWindow;
  }

  return result.below;
}

export function isInCalorieWindow(
  value,
  config: NutritionConfiguration = null,
) {
  if (!config || !config.macros || !config.macros['calories']) {
    return false;
  }

  const windowValue =
    getMacroWindowValue('fats', config) * 9 +
    getMacroWindowValue('carbs', config) * 4 +
    getMacroWindowValue('protein', config) * 4;

  return Math.abs(value - config.macros['calories']) < windowValue;
}

export function isInWindow(
  macro: BaseMacroType,
  value: number,
  config: NutritionConfiguration = null,
) {
  // If there is no configuration, then we cannot do anything about it.
  if (!config || !config.macros || !config.macros[macro]) {
    return false;
  }

  const macroGoal = config.macros[macro];
  const macroWindow = getWindowForMacro(macro, config);

  if (!isMacroWindowRange(macroWindow)) {
    return Math.abs(value - macroGoal) <= getWindowForMacro(macro, config);
  }

  return (
    value >= macroGoal - macroWindow.below && // Above the "below" value
    value <= macroGoal + macroWindow.above
  ); // Less than or equal to the above value.
}

export function processDay(day: MacroReportingResponse): NutritionDayReport {
  const mealNumbers = [];
  const meals: MealInfo[] = [];
  let includesQuickAdd = false;

  const trackedItems = day.tracked_items.map(item => {
    const trackedItem = { ...item };
    trackedItem.fiber = trackedItem.fiber || 0;
    if (mealNumbers.indexOf(trackedItem.meal) === -1) {
      mealNumbers.push(trackedItem.meal);
    }
    if (trackedItem.name === 'Quick Add') {
      includesQuickAdd = true;
    }
    return trackedItem;
  });

  mealNumbers.forEach(number => {
    meals.push({
      meal: number,
      foods: trackedItems.filter(item => item.meal === number),
      ...sumAll(day, number),
    });
  });

  const allTotals = sumAll(day);

  return {
    type: day.type,
    track_date: day.track_date,
    short_date: format(new Date(day.track_date), 'M/d'),
    ...allTotals,
    water: day.water_amount,
    waterGoal: (day.configuration && day.configuration.water_goal) || 0,
    caloriesWindow: isInCalorieWindow(allTotals.calories, day.configuration),
    fatsWindow: isInWindow('fats', allTotals.fats, day.configuration),
    carbsWindow: isInWindow('carbs', allTotals.carbs, day.configuration),
    proteinWindow: isInWindow('protein', allTotals.protein, day.configuration),
    fatsGoal: getGoalFromConfig(day.configuration, 'fats'),
    carbsGoal: getGoalFromConfig(day.configuration, 'carbs'),
    proteinGoal: getGoalFromConfig(day.configuration, 'protein'),
    caloriesGoal: getGoalFromConfig(day.configuration, 'calories'),
    includesQuickAdd,
    meals: meals.sort((a, b) => (a.meal < b.meal ? -1 : 1)),
    supplements:
      day.type === 'cmc' && day.data?.supplements
        ? day.data.supplements.map(s => ({
            ...day.configuration.cmcSupplements.find(sc => sc.id === s.id),
            taken: s.taken,
          }))
        : [],
    supplementsProgress:
      day.type === 'cmc'
        ? getSupplementsProgress(day.data?.supplements || [])
        : null,
  };
}

function getGoalFromConfig(
  config: NutritionConfiguration,
  macro: 'calories' | 'carbs' | 'fats' | 'protein',
) {
  if (!config || !config.macros || !config.macros[macro]) {
    return null;
  }

  return config.macros[macro];
}

function getSupplementsProgress(supplements: CmcSupplementTracker[]) {
  if (supplements.length === 0) {
    return null;
  }
  const total = supplements.reduce((prev, cur) => prev + cur.times, 0);
  const taken = supplements.reduce((prev, cur) => prev + cur.taken, 0);
  return (taken * 100) / total;
}
