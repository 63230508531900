import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import {
  Barcode,
  BarcodeFormat,
  BarcodeScanner,
  PermissionStatus,
  ScanResult,
} from '@capacitor-mlkit/barcode-scanning';

import { Parse } from './zebra';
import { ScannerOverlayComponent } from '../../components/scanner-overlay/scanner-overlay.component';

@Injectable()
export class BarcodeScannerService {
  #scannerModal: HTMLIonModalElement;

  formats: BarcodeFormat[] = [
    BarcodeFormat.Ean8,
    BarcodeFormat.Ean13,
    BarcodeFormat.UpcA,
  ];

  constructor(
    public platform: Platform,
    public modal: ModalController,
  ) {}

  /**
   * Start scanner
   */
  public scan(): Promise<string> {
    if (!this.platform.is('capacitor')) {
      return Promise.reject('Not available in web platform');
    }

    return new Promise((resolve, reject) => {
      BarcodeScanner.checkPermissions()
        .then((r: PermissionStatus) => {
          // If we have been granted permission, just keep going. Otherwise, we need to force the ask.
          // Possible Values for r.camera
          // 'limited' | 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied';
          if (r.camera === 'granted') {
            return r;
          }
          // You can now use requestPermissions?
          return BarcodeScanner.requestPermissions();
        })
        .then(async () => {
          // Shows the overlay for the scanner
          await BarcodeScanner.addListener('barcodeScanned', async result => {
            // This is needed for testing.
            if (this.#scannerModal) {
              this.#scannerModal.dismiss();
            }

            if (result.barcode) {
              resolve(this.getUPCA(result.barcode));
            }

            resolve(null);
          }).catch(() => reject('Bad scan. Try again.'));

          this.showScanner().then(_v => {
            resolve(null);
          });
        });
    });
  }

  /**
   * Get UPC-A converted string from UPC-E and EAN_13.
   * For EAN_8 returning direct string value.
   *
   * @param barcode Barcode objet received from BarcodeScanner plugin.
   */
  public getUPCA(barcode: Barcode): string | null {
    if (!barcode) {
      return null;
    }

    if (
      barcode.format === BarcodeFormat.Ean8 ||
      barcode.format === BarcodeFormat.UpcA ||
      barcode.format === BarcodeFormat.Ean13
    ) {
      return barcode.rawValue;
    } else {
      const barcodeInstance = Parse(barcode.rawValue);
      return barcodeInstance.toUPCA().code;
    }

    return barcode.rawValue;
  }

  async showScanner() {
    document.querySelector('body').classList.add('scanner-active');

    await BarcodeScanner.startScan();

    return this.modal
      .create({
        component: ScannerOverlayComponent,
        backdropDismiss: false,
        canDismiss: true,
        cssClass: 'scanner-overlay',
        animated: false,
      })
      .then(o => {
        this.#scannerModal = o;
        o.present();
        return o.onDidDismiss().then(({ role }) => {
          if (role === undefined) {
            this.hideScanner();
            BarcodeScanner.stopScan();
          }
          return null;
        });
      });
  }

  private async hideScanner() {
    document.querySelector('body').classList.remove('scanner-active');

    // Remove all listeners
    await BarcodeScanner.removeAllListeners();
  }

  public isResultCancelled(result: ScanResult): ScanResult {
    return result.barcodes?.length > 0 ? result : null;
  }
}
