export interface PermissionsResponse {
  permissions: PermissionsData[];
}

/**
 * This list of permissions is generated here for ease of use within the application. The "true list" of permissions
 * will always come from the server. This enum is here so that references to these values can be tracked easily,
 * unused values can be easily identified, and that types can be generated to aid developers.
 *
 * All uses of permissions in the app should write their permissions here and reference them from here.
 */
export enum PermissionsData {
  MOTIVATIONS_MANAGE = 'motivations.manage',
  DAILY_TASKS_VIEW = 'daily-tasks.view',
  DAILY_TASKS_COMPLETE = 'daily-tasks.complete',
  DAILY_TASKS_EDIT = 'daily-tasks.edit',
  WEEKLY_TASKS_VIEW = 'weekly-tasks.view',
  REFERRAL_CAN_REFER = 'referral.can-refer',
  REFERRAL_GENERATE_LINK = 'referral.generate-link',
  REFERRAL_VIEW = 'referral.view',
  REFERRAL_CREATE = 'referral.create',
  NUTRITION_TRACK_FOOD = 'nutrition.track-food',
  NUTRITION_TRACK_WATER = 'nutrition.track-water',
  NUTRITION_SHARE_RECIPE = 'nutrition.share-recipe',
  NUTRITION_IMPORT_RECIPE = 'nutrition.import-recipe',
  PRODUCTS_VIEW = 'products.view',
  ACTIVITY_STEP_TRACKER_SYNC = 'activity.step-tracker.sync',
  ACTIVITY_WALK_TIMER = 'activity.walk-timer',
  ACTIVITY_CUSTOM_WORKOUT_CREATE = 'activity.custom-workout.create',
  ACTIVITY_TWO_PLANS = 'activity.two-plans',
  STEP_LEADERBOARD_ACCESS = 'step-leaderboard.access',
  STEPS_CONNECT_WEARABLE = 'steps.connect-wearable',
  ADVISOR_VIEW = 'advisor.view',
  ADVISOR_CHAT = 'advisor.chat',
  ADVISOR_SEARCH = 'advisor.search',
  ADVISOR_REQUEST = 'advisor.request',
  ADVISOR_DROP = 'advisor.drop',
  ANNOUNCEMENTS_VIEW = 'announcements.view',
  ADVISOR_TOOLS_ACCESS = 'advisor-tools.access',
  ASSESSMENT_CREATE = 'assessment.create',
  WEEK_IN_REVIEW_ACCESS = 'week-in-review.access',
  WEEK_IN_REVIEW_GENERATE = 'week-in-review.generate',
  PHOTOS_CREATE = 'photos.create',
  PHOTOS_VIEW = 'photos.view',
  PHOTOS_DELETE = 'photos.delete',
  BODY_METRICS_VIEW = 'body-metrics.view',
  BODY_METRICS_CREATE = 'body-metrics.create',
  WEIGH_INS_VIEW = 'weigh-ins.view',
  WEIGH_INS_CREATE = 'weigh-ins.create',
  MOTIVATION_VIEW = 'motivation.view',
  LIVE_STREAM_VIEW = 'live-stream.view',
  LIVE_STREAM_COMMENT = 'live-stream.comment',
  LIVE_STREAM_LIST = 'live-stream.list',
  SUBSCRIPTION_VIEW = 'subscription.view',
  SUBSCRIPTION_MANAGE = 'subscription.manage',
  LMS_ACCESS = 'lms.access',
  CHALLENGE_PARTICIPATE = 'challenge.participate',
  CHALLENGE_LIST = 'challenge.list',
  CHALLENGE_MANAGE = 'challenge.manage',
}
