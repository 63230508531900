import { Pipe, PipeTransform } from '@angular/core';
import { ExertionSettings, ExertionSettingsEnum } from '../../../interfaces';

@Pipe({
  name: 'exertionValue',
})
export class ExertionValuePipe implements PipeTransform {
  transform(setting: ExertionSettings, index?: number): string {
    const exertionValues = [...setting.value.toString().split(',')];

    const display = `${exertionValues[index % exertionValues.length] || 0}`;

    return `${display} ${ExertionSettingsEnum[setting?.type] || ''}`;
  }
}
