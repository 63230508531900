import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

/**
 * This service is a temporary service to funnel all (except auth-related) existing LocalStorage calls through a proper
 * service. User service should be remade using NGXS store and observables, a lot of information is tied tightly to
 * userService.user. Calls to this service should likely be moved to an NGXS store and managed via NGXS.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;
  private readyPromise: Promise<Storage>;

  constructor(private storage: Storage) {
    this.init();
  }

  private async ready() {
    if (this.readyPromise) {
      return this.readyPromise;
    }

    return this._storage;
  }

  init() {
    this.readyPromise = this.storage
      .create()
      .then(s => (this._storage = s))
      .finally(() => (this.readyPromise = null));
  }

  async clear() {
    return await this._storage.clear();
  }

  public async get(key: string) {
    await this.ready();
    return this._storage.get(key);
  }

  public async set(key: string, value) {
    await this.ready();
    return this._storage.set(key, value);
  }

  public async remove(key: string) {
    await this.ready();
    return this._storage.remove(key);
  }
}
