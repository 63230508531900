import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsMaleDirective } from './is-male.directive';
import { IsFemaleDirective } from './is-female.directive';

@NgModule({
  declarations: [IsMaleDirective, IsFemaleDirective],
  exports: [IsFemaleDirective, IsMaleDirective],
  imports: [CommonModule],
})
export class SexDirectiveModule {}
