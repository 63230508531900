import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BarcodeScannerService,
  CustomFoodTemplateService,
  CustomMacroService,
  FoodItemsService,
  MacroCalculatorService,
  MealTemplatesService,
  NutritionixService,
} from './services';
import { NgxsModule } from '@ngxs/store';
import { SupplementsState } from './state/supplements/supplements.state';
import { NutritionSearchState } from './state/nutrition-search/nutrition-search.state';
import { FoodEditingState } from './state/food-editing/food-editing.state';
import { MealActionsService } from './services/meal-actions/meal-actions.service';
import { NutritionComponentsModule } from './components/components.module';
import { MacrosBarComponent } from './components/macros-bar/macros-bar.component';
import { MyFoodsState } from './state/my-foods/my-foods.state';
import { EditCustomMacrosModule } from './components/edit-custom-macros/edit-custom-macros.module';
import { ScratchpadState } from './state/scratchpad/scratchpad.state';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    NutritionComponentsModule,
    EditCustomMacrosModule,
    NgxsModule.forFeature([
      MyFoodsState,
      NutritionSearchState,
      SupplementsState,
      FoodEditingState,
      ScratchpadState,
    ]),
  ],
  exports: [MacrosBarComponent],
  providers: [
    BarcodeScannerService,
    CustomFoodTemplateService,
    CustomMacroService,
    FoodItemsService,
    MealTemplatesService,
    MacroCalculatorService,
    NutritionixService,
    MealActionsService,
  ],
})
export class NutritionModule {}
