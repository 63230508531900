import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastService } from '../services';
import { map } from 'rxjs/operators';
import { TokenService } from '../services/token/token.service';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard {
  constructor(
    private toastSvc: ToastService,
    private tokenService: TokenService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.tokenService.accessToken$.pipe(
      map(token => {
        if (token === null) {
          this.toastSvc.flash('Please log in.');
          return this.router.createUrlTree(['/login']);
        }
        return true;
      }),
    );
  }
}
