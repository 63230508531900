import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { TokenService } from '../services/token/token.service';

@Injectable({
  providedIn: 'root',
})
export class Unauthenticated {
  constructor(
    private route: Router,
    private tokenService: TokenService,
  ) {}

  canActivate() {
    return this.tokenService.accessToken$.pipe(
      map(token => {
        if (token !== null) {
          return this.route.parseUrl('/dashboard');
        }
        return true;
      }),
    );
  }
}
