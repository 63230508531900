<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-center ion-text-uppercase"
      >Manage Subscription</ion-title
    >
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-bottom" #subscribeContent>
  <ng-container *ngxLaunchDarkly="'!subscriptions--tiered'">
    <app-subscribe-v2
      [products]="displayProducts$ | async"
      [isPurchasing]="isPurchasing$ | async"
      [selectedProduct]="selectedProduct$ | async"
      (upgrade)="upgrade()"
      (selectProduct)="[selectedSku$.next($event.id), scrollToBottom()]"
    ></app-subscribe-v2>
  </ng-container>

  <ng-container *ngxLaunchDarkly="'subscriptions--tiered'">
    <!-- Allow the user to subscribe to a paid product if that's not already the case -->
    <ng-container *ngIf="tierList$ | async as tierList">
      <app-subscriptions-tiered
        [tiers]="tierList"
        (upgrade)="upgrade()"
        (selectProduct)="selectedSku$.next($event.sku)"
      ></app-subscriptions-tiered>
    </ng-container>
  </ng-container>
</ion-content>
