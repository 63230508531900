import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { yyyyMMdd } from '../../../helpers/date';

@Injectable({
  providedIn: 'root',
})
export class ActivityDayGuard {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (route.url.length === 1) {
      return this.router.createUrlTree(['activity', yyyyMMdd(new Date())]);
    }

    return true;
  }
}
