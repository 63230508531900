import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ActivityDataPoint,
  addTypeToActivityData,
  ComplexExerciseServerData,
  WorkoutSessionV3,
  WrappedApiResponse,
} from '../../../interfaces';
import { apiUrl, jsonApiUrl } from '../../../helpers';
import { handleApiError } from '../../../helpers/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  JsonApiObject,
  JsonApiResponse,
} from '../../assessments-v2/services/assessments/types';

const convert = (
  original: JsonApiObject<ComplexExerciseServerData>,
): Partial<ActivityDataPoint> => {
  return {
    workout_session_uuid: original.attributes.workout_session_uuid,
    group_id: original.attributes.group_id,
    id: +original.id,
    ...addTypeToActivityData(original.attributes.data),
  };
};

@Injectable({
  providedIn: 'root',
})
export class WorkoutsService {
  constructor(protected http: HttpClient) {}

  public updateUuid(
    workoutSession: WorkoutSessionV3,
  ): Observable<WorkoutSessionV3> {
    const {
      uuid,
      workout_data,
      completed,
      notes,
      warmup_completed,
      cooldown_completed,
    } = workoutSession;
    const payload = {
      data: workout_data,
      notes,
      completed,
      workout_id: workoutSession.workout_id,
      workout_date: workoutSession.workout_date,
    };
    if (warmup_completed) {
      payload['warmup_completed'] = !!warmup_completed;
    }
    if (cooldown_completed) {
      payload['cooldown_completed'] = !!cooldown_completed;
    }

    return this.http
      .put<
        WrappedApiResponse<WorkoutSessionV3>
      >(apiUrl(`session/${uuid}`, 'v2.8'), payload)
      .pipe(
        handleApiError(),
        map(r => r.data),
      );
  }

  public getActivityDataForSession(
    sessionId: string,
  ): Observable<Partial<ActivityDataPoint>[] | null> {
    return this.http
      .get<
        JsonApiResponse<JsonApiObject<ComplexExerciseServerData>[]>
      >(jsonApiUrl(`activity-data-points?filter[workout_session_uuid]=${sessionId}`))
      .pipe(
        handleApiError(),
        map(d =>
          d?.data && d?.data.length
            ? d.data.map(cd => ({
                workout_session_uuid: cd.attributes.workout_session_uuid,
                group_id: cd.attributes.group_id,
                id: +cd.id,
                ...addTypeToActivityData(cd.attributes.data),
              }))
            : null,
        ),
      );
  }

  public updateActivityDataForSession({
    workout_session_uuid,
    id,
    group_id,
    ...data
  }): Observable<Partial<ActivityDataPoint> | null> {
    return this.http
      .patch<JsonApiResponse<JsonApiObject<ComplexExerciseServerData>>>(
        jsonApiUrl(`activity-data-points/${id}`),
        {
          data: {
            type: 'activity-data-points',
            id: `${id}`,
            attributes: {
              workout_session_uuid,
              group_id,
              data,
            },
          },
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        handleApiError(),
        map(d => (d?.data ? convert(d.data) : null)),
      );
  }

  public saveActivityDataForSession({
    workout_session_uuid,
    group_id,
    ...data
  }): Observable<Partial<ActivityDataPoint> | null> {
    return this.http
      .post<JsonApiResponse<JsonApiObject<ComplexExerciseServerData>>>(
        jsonApiUrl('activity-data-points'),
        {
          data: {
            type: 'activity-data-points',
            attributes: {
              workout_session_uuid,
              group_id,
              data,
            },
          },
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        handleApiError(),
        map(d => (d?.data ? convert(d.data) : null)),
      );
  }

  public removeActivityData(id: number) {
    return this.http
      .delete<unknown>(jsonApiUrl(`activity-data-points/${id}`), {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
        }),
      })
      .pipe(handleApiError());
  }

  public getUuid(sessionId: string): Observable<WorkoutSessionV3 | null> {
    return this.http
      .get<
        WrappedApiResponse<WorkoutSessionV3>
      >(apiUrl(`session/${sessionId}`, 'v2.8'))
      .pipe(
        handleApiError(),
        map(r => (r ? r.data : null)),
      );
  }
}
