import { Component, Inject, NgZone } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { FirebaseService } from './services/firebase/firebase.service';
import { BranchioService } from './services/branchio/branchio.service';
import { rollbarConfig, RollbarService } from './rollbar';
import * as Rollbar from 'rollbar';
import { RollbarNative } from '../../capacitor/rollbar-native-plugin';

import {
  AccountService,
  AnalyticsService,
  APPLICATION_DEBUGGING,
  APPLICATION_DEBUGGING_EXPIRATION,
  AuthenticationService,
  BottomMenuService,
  InAppPurchaseService,
  OnboardingService,
  StorageService,
  UserPreferencesService,
  UserService,
} from './services';
import { LogEventDecoratorService } from './decorators/log-event-decorator.service';
import { FormErrorDecoratorService } from './decorators/form-error-decorator.service';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { Transphormer } from './interfaces';
import { UnreadActionsService } from './components/nav-menu/services/uread-actions/unread-actions.service';
import {
  combineLatest,
  firstValueFrom,
  from,
  interval,
  merge,
  Observable,
  Subscription,
} from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  take,
  takeUntil,
} from 'rxjs/operators';
import { distinctUntilKeysChanged } from './helpers/operators';
import { UserStorageService } from './services/user-storage/user-storage.service';
import { SplitPaneService } from './services/split-pane/split-pane.service';
import { SplashScreenService } from './services/splash-screen-service/splash-screen-service';
import { LaunchDarklyService } from './modules/launchdarkly/ngx-launchdarkly.service';
import { SubscriptionDeactivated } from './store/actions/subscription.actions';
import { SplitRoutingService } from './modules/advisor/services/split-routing.service';
import {
  createDocumentThemeColor,
  lightOrDark,
  unsetDocumentTheme,
} from './services/user/functions';
import { environment } from '../environments/environment';
import { App } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard } from '@capacitor/keyboard';
import { LoadGlobalMovementSwaps } from './modules/training/state/workout/workouts.actions';
import { LoadAccountSettings } from './store/actions/account-settings.actions';
import { LDContext } from 'launchdarkly-js-client-sdk';
import { DarkModeService } from './services/dark-mode.service';
import { Preferences } from '@capacitor/preferences';
import { TokenService } from './services/token/token.service';
import { StateResetAll } from 'ngxs-reset-plugin';
import { MixPanelService } from './services/mix-panel/mix-panel.service';
import { WearablesConfigService } from './modules/wearables/services/wearables-config/wearables-config.service';
import { AppTrackingTransparencyService } from './services/app-tracking-transparency/app-tracking-transparency.service';

const HIDE_TRANSFERS_PAGES = [];

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  private unreadPuller$: Subscription;
  private autoUpdate$: Subscription;

  #keyboardShow: PluginListenerHandle;
  #keyboardHide: PluginListenerHandle;

  bottomMenuVisible$: Observable<boolean>;
  transfersVisible$: Observable<boolean>;
  isSplit$: Observable<boolean>;
  displayRightSide$: Observable<boolean>;

  constructor(
    private platform: Platform,
    private firebase: FirebaseService,
    private branchIoService: BranchioService,
    @Inject(RollbarService) private rollbar: Rollbar,
    public auth: AuthenticationService,
    private purchaseService: InAppPurchaseService,
    private userService: UserService,
    private decoratorService: LogEventDecoratorService,
    private dsTwo: FormErrorDecoratorService,
    public analyticsService: AnalyticsService,
    private prefs: UserPreferencesService,
    public bottomMenu: BottomMenuService,
    public splitPane: SplitPaneService,
    splashScreenService: SplashScreenService,
    private unreadActionsService: UnreadActionsService,
    private store: Store,
    private nav: NavController,
    private ngZone: NgZone,
    private router: Router,
    private accountService: AccountService,
    private userStorage: UserStorageService,
    private storageService: StorageService,
    private launchDarkly: LaunchDarklyService,
    private actions$: Actions,
    private splitRouting: SplitRoutingService,
    private onboardingService: OnboardingService,
    public darkModeService: DarkModeService,
    private tokenService: TokenService,
    private wearableConfigService: WearablesConfigService,
    private mixPanel: MixPanelService,
    private appTracking: AppTrackingTransparencyService,
  ) {
    // There is probably an even better way, yet, to handle the full initialization.
    // In this case, we have APP_INITIALIZER that ensures that the access_token is
    // fully loaded. Rather than check for it _here_, we can depend on the route
    // guards to redirect back to `/login` if the user is not logged in. In any case,
    // we leave the `setup()` call here so that the Transphormer gets loaded from the
    // cache.
    this.userService.setup();

    // Initialize the unread actions service, wiring up the
    this.unreadActionsService.init();

    this.auth.addEventListener('postlogin', () => {
      console.debug('postlogin Event');
    });

    // When the
    this.auth.addEventListener('postlogout', () => {
      console.debug('postlogout Event');
      // When we log out, we must... forget the user.
      this.nav.navigateRoot('/login');
      this.userService.user = null;
      // Get rid of everything in storage.
      this.userStorage.unload();
      this.storageService.clear();
      this.mixPanel.reset();
      Preferences.clear();
      // Make sure all the counters are removed.
      this.store.dispatch(new StateResetAll());
    });

    this.actions$
      .pipe(ofActionCompleted(SubscriptionDeactivated))
      .subscribe(() => {
        // Stolen from:
        // https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
        const currentUrl = this.router.url;
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate([currentUrl]);
          });
      });

    this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        this.bottomMenu.transfers$.next(
          !HIDE_TRANSFERS_PAGES.includes(event.url),
        );
      }
    });

    this.displayRightSide$ = this.router.events.pipe(
      filter(
        (event): event is NavigationStart => event instanceof NavigationStart,
      ),
      map(event => event.url.indexOf('right:') > 0),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.router.events
      .pipe(takeUntil(splashScreenService.hide$))
      .subscribe(async event => {
        if (event instanceof NavigationStart) {
          await this.platform.ready();
          await splashScreenService.hide();
        }
      });

    this.userService.user$
      .pipe(distinctUntilKeysChanged(['id', 'email', 'is_paid_user']))
      .subscribe(user => {
        if (user?.profile_complete === true) {
          userStorage.preload();
        }
        this.setupLaunchDarklyForUser(user);
        this.setupRollbarForUser(user);
        this.setupUnreadStateForUser(user);
      });

    // Actions to take whenever the user is loaded, and they have a complete profile.
    this.userService.user$
      .pipe(
        distinctUntilKeysChanged(['id', 'profile_complete']),
        filter(user => user !== null && user.profile_complete),
      )
      .subscribe(u => {
        this.wearableConfigService.init(u);
      });

    // Only do this stuff if the user is null.
    this.userService.user$.pipe(filter(user => user === null)).subscribe(_ => {
      this.wearableConfigService.clear();
    });

    // If the organization theme flag is on AND we have a color set, then set the colors. Otherwise, remove the style
    // properties that otherwise may have been applied. This keeps the theme clean from changes and reset the app after
    // login or other events.
    combineLatest([
      this.launchDarkly.flag('organization-theme').pipe(distinctUntilChanged()),
      this.userService.user$.pipe(
        map(u => (u?.organization ? u.organization.color : null)),
        // Use this to test!
        // map(() => '#f05523'),
        distinctUntilChanged(),
      ),
      this.darkModeService.darkModeEnabled$,
    ]).subscribe(([flag, color, darkModeEnabled]) => {
      if (darkModeEnabled || !flag || !color) {
        this.revertColorsToDefault();
      } else {
        this.setColorsBasedOnOrganizationPreference(color);
      }
    });

    this.bottomMenuVisible$ = this.bottomMenu.visible$.asObservable();
    this.isSplit$ = this.splitPane.visible$.asObservable();
    this.transfersVisible$ = this.bottomMenu.transfers$.asObservable();

    this.tokenService.accessToken$
      .pipe(
        distinctUntilChanged(),
        filter(i => i !== null),
      )
      .subscribe(async () => {
        // Reload the user's profile.
        await firstValueFrom(this.onboardingService.fetchOnBoard(), {
          defaultValue: undefined,
        });

        this.store.dispatch(new LoadAccountSettings());

        // @todo This needs to be in a module guard somewhere more appropriate.
        store.dispatch(new LoadGlobalMovementSwaps());

        // Ensure that the user profile updates automatically every 15 minutes.
        this.startAutoUpdatingUser();

        // Is this even necessary? I guess it is so that we can pull the counts when the
        // user first loads the app. Couldn't this be rolled into the auto-updater?
        this.unreadActionsService.refreshCounts();

        // When the user reloads the app, make sure that Firebase is connected.
        this.firebase.registerUser();
      });

    // If we are "logged out", that is, we have no access token, take some actions.
    this.tokenService.accessToken$
      .pipe(
        distinctUntilChanged(),
        filter(i => i === null),
      )
      .subscribe(() => {
        this.stopAutoUpdatingUser();
        this.firebase.unregisterUser();
      });

    // Allow us to enable debugging remotely.
    merge(
      // Only allow true values from the preferences. We don't need to toggle it from here as it defaults to false.
      from(this.prefs.getAsync<boolean>(APPLICATION_DEBUGGING, false)).pipe(
        filter(i => i),
      ),
      this.launchDarkly
        .flag('debugging--enable-debugging-for-user')
        .pipe(distinctUntilChanged()),
    ).subscribe(value => {
      this.rollbar.configure({
        reportLevel: value ? 'debug' : rollbarConfig.reportLevel,
      });
    });

    this.initializeApp();
  }

  private startAutoUpdatingUser() {
    const autoUpdateInterval$ = interval(15 * 60 * 1000);
    this.autoUpdate$ = autoUpdateInterval$.subscribe(() => {
      this.onboardingService.fetchOnBoard().subscribe();
    });
  }

  private stopAutoUpdatingUser() {
    if (!this.autoUpdate$) {
      return;
    }
    this.autoUpdate$.unsubscribe();
  }

  private setupRollbarForUser(user: Transphormer) {
    const person =
      user !== null
        ? {
            id: user.id,
            email: user.email,
            paid: user.is_paid_user,
          }
        : undefined;
    this.rollbar.configure({
      payload: { person },
    });
    if (Capacitor.isPluginAvailable('RollbarNative')) {
      RollbarNative.setUser({ user: person });
    }
  }

  private setupLaunchDarklyForUser(user: Transphormer) {
    if (!user || !user.profile_complete) {
      return this.launchDarkly.changeUser('Anonymous');
    }

    const groups = [];

    // Set up some custom groups based on the user's context. We want to be
    // able to target advisors, specific types of advisors, and users under
    // a specific advisor.
    if (user.is_trainer) {
      groups.push('advisor');
      if (user.advisor.type) {
        groups.push('advisor:' + user.advisor.type);
      }
    }

    // This is who the user's direct advisor is.
    if (user.linked_trainer) {
      groups.push('direct-advisor:' + user.linked_trainer.trainer_id);
    }

    // This is who the user's team is, if they have one.
    if (user.linked_trainer?.team_id) {
      groups.push('team-id:' + user.linked_trainer.team_id);
    }

    // This is who the user's team is, if they have one.
    if (user.linked_trainer?.assigned_team_id) {
      groups.push('assigned-team-id:' + user.linked_trainer.assigned_team_id);
    }

    this.launchDarkly.changeUser(<LDContext>{
      kind: 'user',
      key: `${user.id}`,
      email: user.email,
      name: user.display_name,
      groups,
    });
  }

  async initializeApp() {
    await this.platform.ready();
    await this.setupAppVersionInfoInRollbar();
    // I am not 100% sure why this is necessary, but wrapping this in a try/catch doesn't
    // actually catch any errors. If it errors, everything else after it won't run. That's
    // not very cash money.
    this.purchaseService.init().catch(e => console.error(e));

    if (this.platform.is('capacitor')) {
      await this.branchIoService.init();
      this.analyticsService.init();
      this.platform.resume.subscribe(async () => {
        this.analyticsService.init();
        this.userService.checkAndUpdateTZ();
        this.unreadActionsService.refreshCounts();
      });
      this.analyticsService.init();
      await this.setupBottomMenuHidingWhenKeyboardHiddenOrShown();
    }

    interval(5 * 60 * 1000).subscribe(() => {
      this.disableDebuggingIfTimeExpired();
    });
  }

  disableDebuggingIfTimeExpired() {
    if (!this.prefs.get(APPLICATION_DEBUGGING, false)) {
      return;
    }

    const expirationDate = this.prefs.get(
      APPLICATION_DEBUGGING_EXPIRATION,
      null,
    );

    if (expirationDate === null || new Date() < new Date(expirationDate)) {
      return;
    }

    this.rollbar.debug('Disabling debugging due to timer expired.');
    this.prefs.set(APPLICATION_DEBUGGING, false);
    this.prefs.set(APPLICATION_DEBUGGING_EXPIRATION, null);
    // Convert it back to the original...
    this.rollbar.configure({ reportLevel: rollbarConfig.reportLevel });
  }

  public async setupAppVersionInfoInRollbar() {
    const version = Capacitor.isNativePlatform()
      ? (await App.getInfo()).version
      : environment.gitHash;
    this.rollbar.configure({
      payload: {
        code_version: version,
      },
    });
  }

  private setupUnreadStateForUser(user: Transphormer) {
    if (user === null || !user.profile_complete) {
      if (this.unreadPuller$) {
        this.unreadPuller$.unsubscribe();
        this.unreadPuller$ = null;
      }
    } else {
      if (this.unreadPuller$) {
        return;
      }
      this.unreadPuller$ = interval(5 * 60 * 1000).subscribe(() => {
        this.unreadActionsService.refreshCounts();
      });
    }
  }

  private async setupBottomMenuHidingWhenKeyboardHiddenOrShown() {
    this.#keyboardShow = await Keyboard.addListener('keyboardWillShow', () =>
      this.ngZone.run(() => {
        this.bottomMenu.hideBottomMenuInResponseToKeyboardOpening();
      }),
    );

    this.#keyboardHide = await Keyboard.addListener('keyboardWillHide', () =>
      this.ngZone.run(() => {
        this.bottomMenu.showBottomMenuInResponseToKeyboardDismissing();
      }),
    );
  }

  /**
   * Reset certain global styles of the application to its default settings.
   *
   * The function performs the following operations:
   *
   * The CSS variables --ion-color-brand-title-bar, --ion-color-brand and --title-bar-color
   * are removed from document.documentElement.style, thus, reverting their values to defaults.
   *
   * After this, if the platform on which the application is running is native,
   * like iOS or Android (checked using Capacitor.isNativePlatform()), it waits for the platform to be ready.
   *
   * Once the platform is ready, it subscribes to an Observable darkModeEnabled$ provided by the darkModeService.
   *
   * It then takes the first emitted value of whether the dark mode is enabled or not.
   *
   * If the platform is Android, it sets the status bar background color to black if the dark mode is enabled,
   * else it is set to white.
   *
   * Finally, it sets the style of the StatusBar. If the dark mode is enabled, the style is set to Dark, else it is set to Light.
   */
  private revertColorsToDefault() {
    unsetDocumentTheme('organization');

    if (!Capacitor.isNativePlatform()) {
      return;
    }

    this.platform.ready().then(() => {
      this.darkModeService.darkModeEnabled$
        .pipe(take(1))
        .subscribe(darkMode => {
          if (this.platform.is('android')) {
            StatusBar.setBackgroundColor({
              color: darkMode ? '#000000' : '#ffffff',
            });
          }
          StatusBar.setStyle({
            style: darkMode ? Style.Dark : Style.Light,
          });
        });
    });
  }

  private setColorsBasedOnOrganizationPreference(color: string) {
    // Based off of the color we have, choose
    createDocumentThemeColor('organization', color);

    if (!Capacitor.isNativePlatform()) {
      return;
    }

    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        StatusBar.setBackgroundColor({ color });
      }
      StatusBar.setStyle({
        style: lightOrDark(color) === 'dark' ? Style.Dark : Style.Light,
      });
    });
  }
}
