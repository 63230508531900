import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import {
  ApplyCMCSupplementConfiguration,
  ApplySupplementConfiguration,
  LoadCMCSupplementConfiguration,
  LoadSupplementConfiguration,
} from './supplements.actions';
import { Injectable } from '@angular/core';
import { CmcSupplementConfig, MealItem } from '../../interfaces';
import { UserStorageService } from '../../../../services/user-storage/user-storage.service';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

export const SUPPLEMENTS_STATE_TOKEN = new StateToken<SupplementsStateModel>(
  'supplements',
);

export interface SupplementsStateModel {
  allSupplements: MealItem[];
  supplements: MealItem[];
  cmcSupplements: CmcSupplementConfig[];
}

@State<SupplementsStateModel>({
  name: SUPPLEMENTS_STATE_TOKEN,
  defaults: {
    allSupplements: [
      {
        name: 'Ignition',
        type: 'supplement',
        selectedNutrition: null,
        use: true,
      },
      {
        name: 'Phormula-1',
        type: 'supplement',
        selectedNutrition: null,
        use: true,
      },
      {
        name: 'Full Mega',
        type: 'supplement',
        selectedNutrition: null,
        use: true,
      },
      {
        name: 'Opti-reds',
        type: 'supplement',
        selectedNutrition: null,
        use: true,
      },
      {
        name: 'Opti-greens',
        type: 'supplement',
        selectedNutrition: null,
        use: true,
      },
      {
        name: 'Micro-factor',
        type: 'supplement',
        selectedNutrition: null,
        use: true,
      },
    ],
    supplements: null,
    cmcSupplements: [],
  },
})
@Injectable()
export class SupplementsState {
  constructor(private userStorage: UserStorageService) {}

  @Selector()
  static allSupplements(state: SupplementsStateModel) {
    return state.allSupplements;
  }

  @Selector()
  static supplements(state: SupplementsStateModel) {
    return state.supplements || state.allSupplements;
  }

  @Selector()
  static cmcSupplements(state: SupplementsStateModel) {
    return state.cmcSupplements;
  }

  @Action([LoadCMCSupplementConfiguration, LoadSupplementConfiguration])
  loadSupplementConfig(ctx: StateContext<SupplementsStateModel>) {
    return combineLatest([
      this.userStorage.get<MealItem[]>(
        'supplements',
        ctx.getState().allSupplements,
      ),
      this.userStorage.get<CmcSupplementConfig[]>(
        'cmc-supplements',
        ctx.getState().cmcSupplements,
      ),
    ]).pipe(
      tap(([supplements, cmcSupplements]) => {
        ctx.patchState({ supplements, cmcSupplements });
      }),
    );
  }

  @Action(ApplySupplementConfiguration)
  applySupplementConfig(
    ctx: StateContext<SupplementsStateModel>,
    { supplements }: ApplySupplementConfiguration,
  ) {
    ctx.patchState({ supplements });
    this.userStorage.set('supplements', supplements);
  }

  @Action(ApplyCMCSupplementConfiguration)
  applyCMCSupplementConfig(
    ctx: StateContext<SupplementsStateModel>,
    { cmcSupplements }: ApplyCMCSupplementConfiguration,
  ) {
    ctx.patchState({ cmcSupplements });
    cmcSupplements?.length
      ? this.userStorage.set('cmc-supplements', cmcSupplements)
      : this.userStorage.delete('cmc-supplements');
  }
}
