import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AddFood,
  AddMeal,
  AddRecipe,
  Clear,
  DeleteFood,
  DeleteMeal,
  DeleteRecipe,
  LoadFoods,
  UpdateCustomFood,
  UpdateFood,
  UpdateMeal,
  UpdateRecipe,
} from './my-foods.actions';
import { merge } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import {
  CustomFoodTemplate,
  CustomFoodTemplateService,
} from '../../services/custom-food-template/custom-food-template.service';
import { MealTemplatesService, RecipeService } from '../../services';
import {
  CaloriesMacros,
  FoodItem,
  MealTemplate,
  Recipe,
} from '../../interfaces';
import produce from 'immer';
import { distinctUntilKeysChanged } from '../../../../helpers/operators';
import { UserService } from '../../../../services';
import { calculateMacrosForMeal } from '../nutrition/functions';

export const MY_FOODS_STATE_TOKEN = new StateToken<MyFoodsStateModel>('myFood');

// It has an activeDate and multiple NutritionResponses in data for each date it got from the API
export interface MyFoodsStateModel {
  customFoods: CustomFoodTemplate[];
  recipes: Recipe[];
  meals: MealTemplate<FoodItem>[];
  status: 'unloaded' | 'loading' | 'loaded' | 'error';
  loading: boolean;
}

@State<MyFoodsStateModel>({
  name: MY_FOODS_STATE_TOKEN,
  defaults: {
    status: 'unloaded',
    loading: false,
    customFoods: [],
    recipes: [],
    meals: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class MyFoodsState {
  constructor(
    private userService: UserService,
    private customFoodTemplateService: CustomFoodTemplateService,
    private mealTemplateService: MealTemplatesService,
    private recipeService: RecipeService,
  ) {}

  @Action(Clear)
  clear(ctx: StateContext<MyFoodsStateModel>) {
    ctx.setState({
      status: 'unloaded',
      loading: false,
      customFoods: [],
      recipes: [],
      meals: [],
    });
  }

  @Action(AddRecipe)
  addRecipe(ctx: StateContext<MyFoodsStateModel>, { recipe }: UpdateRecipe) {
    ctx.setState(
      produce(draft => {
        draft.recipes.push(recipe);
      }),
    );
  }

  @Action(UpdateRecipe)
  updateRecipe(ctx: StateContext<MyFoodsStateModel>, { recipe }: UpdateRecipe) {
    ctx.setState(
      produce(draft => {
        const index = draft.recipes.findIndex(i => i.id === recipe.id);
        draft.recipes[index] = recipe;
      }),
    );
  }

  @Action(AddMeal)
  addMeal(ctx: StateContext<MyFoodsStateModel>, { meal }: AddMeal) {
    ctx.setState(
      produce(draft => {
        draft.meals.push(meal);
      }),
    );
  }

  @Action(UpdateMeal)
  updateMeal(ctx: StateContext<MyFoodsStateModel>, { meal }: UpdateMeal) {
    ctx.setState(
      produce(draft => {
        const index = draft.meals.findIndex(i => i.id === meal.id);
        draft.meals[index] = meal;
      }),
    );
  }

  @Action(AddFood)
  addFood(ctx: StateContext<MyFoodsStateModel>, { food }: UpdateFood) {
    ctx.setState(
      produce(draft => {
        draft.customFoods.push(food);
      }),
    );
  }

  @Action(UpdateFood)
  updateFood(ctx: StateContext<MyFoodsStateModel>, { food }: UpdateFood) {
    ctx.setState(
      produce(draft => {
        const index = draft.customFoods.findIndex(i => i.id === food.id);
        draft.customFoods[index] = food;
      }),
    );
  }

  @Action(UpdateCustomFood)
  updateCustomFood(
    ctx: StateContext<MyFoodsStateModel>,
    { customFood }: UpdateCustomFood,
  ) {
    ctx.setState(
      produce(draft => {
        const index = draft.customFoods.findIndex(i => i.id === customFood.id);
        draft.customFoods[index] = customFood;
      }),
    );
  }

  @Action(DeleteRecipe)
  deleteRecipe(ctx: StateContext<MyFoodsStateModel>, { recipe }: DeleteRecipe) {
    ctx.setState(
      produce(draft => {
        draft.recipes = draft.recipes.filter(i => i.id !== recipe.id);
      }),
    );

    return this.recipeService.delete(recipe);
  }

  @Action(DeleteMeal)
  deleteMeal(ctx: StateContext<MyFoodsStateModel>, { meal }: DeleteMeal) {
    this.mealTemplateService.delete(meal.id).subscribe(() => {
      ctx.setState(
        produce(draft => {
          draft.meals = draft.meals.filter(i => i.id !== meal.id);
        }),
      );
    });
  }

  @Action(DeleteFood)
  deleteFood(ctx: StateContext<MyFoodsStateModel>, { food }: DeleteFood) {
    this.customFoodTemplateService.deleteCustomFood(food.id).subscribe(() => {
      ctx.setState(
        produce(draft => {
          draft.customFoods = draft.customFoods.filter(i => i.id !== food.id);
        }),
      );
    });
  }

  @Action(LoadFoods)
  loadFoods(ctx: StateContext<MyFoodsStateModel>) {
    const { status } = ctx.getState();

    if (status === 'loaded') {
      return;
    }

    ctx.patchState({
      loading: true,
      status: 'loading',
    });

    return merge(
      this.customFoodTemplateService
        .getCustomFoods()
        .pipe(tap(result => ctx.patchState({ customFoods: result }))),
      this.mealTemplateService.getMealTemplates().pipe(
        map(result =>
          result.map(mt => ({
            ...mt,
            ...calculateMacrosForMeal({
              food_items: mt.items as CaloriesMacros[],
            }),
          })),
        ),
        tap(result => ctx.patchState({ meals: result })),
      ),
      this.recipeService
        .getRecipes()
        .pipe(tap(result => ctx.patchState({ recipes: result }))),
    ).pipe(
      finalize(() => ctx.patchState({ loading: false, status: 'loaded' })),
    );
  }

  ngxsAfterBootstrap(ctx?: StateContext<MyFoodsStateModel>): void {
    this.userService.user$
      .pipe(
        distinctUntilKeysChanged([
          'id',
          'likely_to_do',
          'profile_complete',
          'custom_macros',
          'meals_per_day',
        ]),
      )
      .subscribe(user => {
        if (user === null || !user.profile_complete) {
          ctx.setState({
            status: 'unloaded',
            loading: false,
            customFoods: [],
            recipes: [],
            meals: [],
          });
          return;
        }
      });
  }
}
