import { ZoomAssetComponent } from './zoom-asset.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgxLaunchDarklyModule } from '../../modules/launchdarkly/ngx-launchdarkly.module';
import { VideoComponentsModule } from '../../modules/video/components/video-components.module';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PinchZoomModule,
    NgxLaunchDarklyModule,
    VideoComponentsModule,
    NgOptimizedImage,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ZoomAssetComponent],
  exports: [ZoomAssetComponent],
})
export class ZoomImgModule {}
