import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, NavController, Platform } from '@ionic/angular';
import * as Rollbar from 'rollbar';

import { ConvertWeightPipe } from '../../../../pipes/convert-unit/pipes/convert-weight/convert-weight.pipe';
import { BaseService, RollbarService, UserService } from '../../dependencies';
import {
  ErrorFormat,
  NutritionPlan,
  UnitTypes,
  Weight,
} from '../../../../interfaces';
import { mapWeightUnit } from '../../../../helpers/map-unit-helper';
import { CustomMacro } from '../../interfaces';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { AddWeighIn } from '../../../body-metrics/state/body-metrics/body-metrics.actions';
import { tap } from 'rxjs/operators';
import { enrichWeighIn } from '../../../body-metrics/services/body-metric/functions';
import { yyyyMMdd } from '../../../../helpers/date';

@Injectable()
export class CustomMacroService extends BaseService {
  constructor(
    public http: HttpClient,
    protected platform: Platform,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    @Inject(RollbarService) public rollbar: Rollbar,
    public user: UserService,
    private actions$: Actions,
  ) {
    super(http, platform, user, rollbar);
    CustomMacroService.SET_PLATFORM(platform);
    CustomMacroService.SET_ROLLBAR(rollbar);
    this.listenAndNotifyOnWeightAdd();
  }

  private listenAndNotifyOnWeightAdd() {
    this.actions$
      .pipe(
        ofActionSuccessful(AddWeighIn),
        tap(({ mr }) => {
          if (!this.user.hasTrainer()) {
            const weightConversion = new ConvertWeightPipe(this.user);
            const convertedWeight = weightConversion.transform(
              enrichWeighIn({ weight: mr.value, unit_type: mr.unit } as Weight),
            );
            this.notifyToUpdateMacro(parseFloat(convertedWeight));
          }
        }),
      )
      .subscribe();
  }
  /**
   * Returns list of previously created macros
   */
  public history(): Promise<CustomMacro[] | ErrorFormat> {
    return this.http
      .get<CustomMacro[]>(
        CustomMacroService.Url('nutritions/custom-macros'),
        CustomMacroService.BaseOptions(true, true),
      )
      .toPromise()
      .then(result =>
        result.map(macro => {
          if (typeof macro.edit_date === 'string') {
            macro.edit_date = new Date(macro.edit_date);
          }
          return macro;
        }),
      )
      .catch(CustomMacroService.HandleError);
  }

  /**
   * Creates new custom macro record
   *
   * @param reset_to_original
   * @param protein
   * @param carbs
   * @param fats
   * @param date
   */
  public create(
    reset_to_original: boolean,
    protein: number,
    carbs: number,
    fats: number,
    date: Date,
  ): Promise<CustomMacro | ErrorFormat> {
    const data = {
      reset_to_original,
      carbs,
      fats,
      protein,
      date: yyyyMMdd(date),
    };
    return this.http
      .post<CustomMacro>(
        CustomMacroService.Url('nutritions/custom-macros'),
        data,
        CustomMacroService.BaseOptions(),
      )
      .toPromise()
      .then(result => {
        result.edit_date = new Date(result.edit_date);
        return result;
      })
      .catch(CustomMacroService.HandleError);
  }

  /**
   * Action to update existing custom macro record
   * @param id
   * @param reset_to_original
   * @param protein
   * @param carbs
   * @param fats
   */
  public update(
    id: number,
    reset_to_original: boolean,
    protein: number,
    carbs: number,
    fats: number,
  ): Promise<CustomMacro | ErrorFormat> {
    const data = {
      reset_to_original,
      carbs,
      fats,
      protein,
    };
    return this.http
      .patch<CustomMacro>(
        CustomMacroService.Url(`nutritions/custom-macros/${id}`),
        data,
        CustomMacroService.BaseOptions(),
      )
      .toPromise()
      .then(result => {
        result.edit_date = new Date(result.edit_date);
        return result;
      })
      .catch(CustomMacroService.HandleError);
  }

  /**
   * Returns the latest custom macro
   */
  public latestMacro(): Promise<CustomMacro | null | ErrorFormat> {
    return this.http
      .get<CustomMacro | null>(
        CustomMacroService.Url('nutritions/custom-macros/latest'),
        CustomMacroService.BaseOptions(),
      )
      .toPromise()
      .then<CustomMacro | null>(result => {
        if (result.id) {
          result.edit_date = new Date(result.edit_date);
          result.base_weight = mapWeightUnit(result.base_weight);
          return result;
        } else {
          return null;
        }
      })
      .catch(CustomMacroService.HandleError);
  }

  /**
   * Notifying the user to update custom macro values if transphormer is doing calorie counting and has set custom
   * macro with weigh diff to base weight more than 10
   * @param newWeight
   */
  public async notifyToUpdateMacro(newWeight: number) {
    const transphormer = this.user.user;

    // checking if transphormer is doing calories counting and is not in standard macro
    if (transphormer.likely_to_do !== NutritionPlan.CalorieMacroCounting) {
      return;
    }

    let latestMacro = <CustomMacro>await this.latestMacro();
    latestMacro = latestMacro || <CustomMacro>{ reset_to_original: true };

    if (latestMacro.reset_to_original) {
      return;
    }

    const convertWeight = new ConvertWeightPipe(this.user);

    const diff =
      newWeight - parseFloat(convertWeight.transform(latestMacro.base_weight));

    if (this.checkDiffBasedOnUnit(Math.abs(diff))) {
      return;
    }

    const alert = await this.alertCtrl.create({
      header: 'Update your macros',
      message: `You have ${diff > 0 ? 'gained' : 'lost'} ${this.user.unitType() === UnitTypes.Imperial ? '10' : '5'} ${this.user.unitTypeLabel('weight')} or more. You should update your macros.`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Later',
          cssClass: 'global-danger',
        },
        {
          text: "Let's do it",
          handler: () => {
            // @todo This should go to the profile.
            this.navCtrl.navigateRoot('nutrition-config?openCustomMacro=true');
          },
        },
      ],
    });

    await alert.present();
  }

  /**
   * Check if difference is less than expected value.
   * @param diff
   */
  private checkDiffBasedOnUnit(diff: number): boolean {
    if (this.user.unitType() === UnitTypes.Imperial) {
      return diff < 10;
    }

    return diff < 5;
  }
}
