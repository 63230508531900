import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ChatListItem,
  ChatListItemResponse,
  ChatListSortByOptions,
  LatestMessageItemResponse,
} from '../../interfaces/chat';
import { jsonApiUrl } from '../../../../helpers';
import {
  JsonApiObject,
  JsonApiResponse,
} from '../../../assessments-v2/services/assessments/types';
import {
  MessageListPayload,
  MessageListResponse,
  PagedMetadata,
  WrappedApiResponse,
} from '../../../../interfaces';
import { Transphormer } from '../../../../interfaces';
import { catchFormError, handleApiError } from '../../../../helpers/operators';
import { map } from 'rxjs/operators';

const JSON_HEADERS = {
  headers: new HttpHeaders({ 'Content-Type': 'application/vnd.api+json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(protected http: HttpClient) {}

  getChatList(
    sortByOption: ChatListSortByOptions = ChatListSortByOptions.LAST_MSG,
    sortByOrder = '-',
    appliedFilters: Map<string, string>,
    pagedData: PagedMetadata,
  ): Observable<
    JsonApiResponse<
      JsonApiObject<ChatListItemResponse[]>,
      { page: string },
      JsonApiObject<LatestMessageItemResponse[]>
    >
  > {
    const filterParams = this.getFilterParams(appliedFilters);
    const pageParams = this.getPagedParams(pagedData);
    return this.http.get<
      JsonApiResponse<
        JsonApiObject<ChatListItemResponse[]>,
        { page: string },
        JsonApiObject<LatestMessageItemResponse[]>
      >
    >(
      jsonApiUrl(
        `chats?include=latestMessage,latestMessage.attachments${pageParams}&sort=${sortByOrder}${sortByOption}${filterParams}`,
      ),
    );
  }

  getMessageList(
    uuid: ChatListItem['id'],
    pagedData: PagedMetadata,
  ): Observable<
    JsonApiResponse<JsonApiObject<MessageListResponse[]>, { page: string }>
  > {
    const pageParams = `page[number]=${pagedData.currentPage}&page[size]=${pagedData.perPage || 100}`;
    return this.http.get<
      JsonApiResponse<JsonApiObject<MessageListResponse[]>, { page: string }>
    >(
      jsonApiUrl(
        `chats/${uuid}/messages?${pageParams}&include=sender,attachments`,
      ),
    );
  }

  getSingleTransphormerChat(
    tid: Transphormer['id'],
  ): Observable<JsonApiResponse<JsonApiObject<ChatListItemResponse[]>>> {
    return this.http.get<
      JsonApiResponse<JsonApiObject<ChatListItemResponse[]>>
    >(jsonApiUrl(`chats?filter[transphormerId]=${tid}`), {
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.api+json' }),
    });
  }

  markRead(chatUuid: ChatListItem['id']) {
    return this.http.post<JsonApiResponse<JsonApiObject<ChatListItemResponse>>>(
      jsonApiUrl(`chats/${chatUuid}/mark-read`),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
        }),
      },
    );
  }

  markUnread(chatUuid: ChatListItem['id']) {
    return this.http.post<JsonApiResponse<JsonApiObject<ChatListItemResponse>>>(
      jsonApiUrl(`chats/${chatUuid}/mark-unread`),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
        }),
      },
    );
  }

  private getPagedParams(pagedData: PagedMetadata): string {
    return `&page[number]=${pagedData.currentPage}&page[size]=${pagedData.perPage || 100}`;
  }

  private getFilterParams(appliedFilters: Map<string, string>): string {
    let params = '';
    for (const [key, value] of appliedFilters) {
      if (value) {
        params = ''.concat(params, `&filter[${key}]=${value}`);
      }
    }

    return params;
  }

  sendMessage(
    message: Pick<
      MessageListPayload,
      'message' | 'messageAttachments' | 'chatUuid'
    >,
  ): Observable<MessageListResponse> {
    const data = {
      data: {
        type: 'messages',
        attributes: {
          message: message.message,
          messageAttachments: message.messageAttachments ?? [],
        },
        relationships: {
          chat: { data: { type: 'chats', id: message.chatUuid } },
        },
      },
    };

    return this.http
      .post<MessageListResponse>(
        jsonApiUrl('messages?include=attachments'),
        data,
        JSON_HEADERS,
      )
      .pipe(handleApiError());
  }

  /**
   * Calls out the JSON Api end-point to retrieve the logged-in user's chat uuid.
   */
  getMyChatUuid(userId: number) {
    return this.http
      .get<
        WrappedApiResponse<{ attributes: unknown; id: string }[]>
      >(jsonApiUrl(`chats?filter[transphormerId]=${userId}`))
      .pipe(
        catchFormError(),
        map(a => a.data?.[0]?.id),
      );
  }
}
