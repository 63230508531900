import { ReactionAttributes, ReactionType } from '../types';

export class AddReaction {
  static readonly type = '[Reactions] Add Reaction';

  constructor(public reaction: ReactionAttributes) {}
}

export class RetrieveAllReactions {
  static readonly type = '[Reactions] Retrieve Reactions By Type';

  constructor(
    public id: number,
    public type: string,
  ) {}
}

export class RetrieveMyReactions {
  static readonly type = '[Reactions] Retrieve Reactions By Me';

  constructor(
    public userId: number,
    public id: number,
    public type: string,
  ) {}
}

export class RemoveReaction {
  static readonly type = '[Reactions] Remove Reaction';

  constructor(
    public type: ReactionType,
    public assetId: number,
    public myId: number,
  ) {}
}

export class SetMyReaction<T extends ReactionAttributes = ReactionAttributes> {
  static readonly type = '[Reactions] Set Reaction';

  constructor(
    public type: ReactionType,
    public assetId: number,
    public reaction: null | Pick<T, 'value' | 'transphormer_id'>,
  ) {}
}

export class RemoveReactionUpdate {
  static readonly type = '[Reactions] Remove Reaction Update';
  constructor(public reaction: ReactionAttributes) {}
}
