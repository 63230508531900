import { Inject, Injectable } from '@angular/core';
import { Health, HealthData } from '@awesome-cordova-plugins/health/ngx';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import {
  HEALTH_PERMISSIONS,
  HealthAggregation,
  HealthAggregationBucket,
  HealthDataType,
  HealthStepDayData,
} from '../types';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { transformHealthDataToHealthStepsDayData } from './functions';
import { Capacitor } from '@capacitor/core';
import { RollbarService } from '../../../rollbar';
import Rollbar from 'rollbar';

export const SYNC_DAYS = 30;

enum StepsError {
  P = 'Protected health data is inaccessible',
}

@Injectable()
export class HealthService {
  constructor(
    private health: Health,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  async isUserAuthorized(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // This is a hack because the current cordova-plugin always returns null for isAuthorized in ios.
      // Therefore there's now way of knowing if we are already authorized on the ios device.
      if (Capacitor.getPlatform() === 'ios') {
        // The hack for ios is checking the query to see if it returns an empty arry. If the array is empty, we will
        // show Health as unauthorized.
        this.health
          .query({
            startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // 1 day ago
            endDate: new Date(), // now
            dataType: 'steps',
            limit: 1000,
            ascending: true,
          })
          .then(success => {
            resolve(success.length > 0);
          })
          .catch(e => {
            reject(`${e}`);
          });
      } else {
        this.health
          .isAuthorized(HEALTH_PERMISSIONS)
          .then(success => {
            resolve(success);
          })
          .catch(e => reject(e));
      }
    });
  }

  public queryAggregatedData(
    startDate: Date,
    endDate: Date,
    dataType: HealthDataType,
    bucket: HealthAggregationBucket,
  ): Observable<HealthAggregation> {
    return from(this.isUserAuthorized()).pipe(
      catchError(e => {
        // Don't log this error because it happens everytime the user locks
        // their phone with the app still open.
        if (e !== StepsError.P) {
          this.rollbar.error(e);
        }
        return of(false);
      }),
      switchMap(isAuth => {
        if (!Capacitor.isNativePlatform() || !isAuth) {
          return of({}) as Observable<HealthAggregation>;
        }

        return from(
          new Promise((resolve, reject) => {
            this.health
              .queryAggregated({
                startDate: startDate,
                endDate: endDate,
                dataType,
                bucket,
              })
              .then(success => {
                resolve(success);
              })
              .catch(error => {
                reject(error);
              });
          }),
        ).pipe(
          catchError(() => of([] as HealthData[])),
          map(
            data =>
              ({
                bucket,
                data,
              }) as HealthAggregation,
          ),
        );
      }),
    );
  }

  public getStepsFromLocalHealthStore(days = SYNC_DAYS) {
    // We can't sync from a non-native platform, so abandon ship.
    if (!Capacitor.isNativePlatform()) {
      return of([] as HealthStepDayData[]);
    }

    // Send to server and update local data with server data
    const startDate = startOfDay(subDays(new Date(), days - 1));
    const endDate = endOfDay(new Date());

    return this.queryAggregatedData(startDate, endDate, 'steps', 'day').pipe(
      filter(steps => !!steps.bucket),
      map(steps => {
        return steps.data
          .filter(
            item =>
              startDate.toISOString() <= item.startDate.toISOString() &&
              item.startDate.toISOString() <= endDate.toISOString(),
          )
          .map(item => transformHealthDataToHealthStepsDayData(item));
      }),
    );
  }
}
