import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { ProfileCompleteGuard } from './guards/profile-complete.guard';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { Unauthenticated } from './guards/unauthenticated.guard';
import { IsAdvisorGuard } from './guards/is-advisor.guard';
import { CreateFilterComponent } from './components/create-filter/create-filter.component';
import { PreloadBodyMetricsGuard } from './modules/body-metrics/pages/body-metrics/guards/preload-body-metrics/preload-body-metrics.guard';
import { AssessmentToUserAssessmentsGuard } from './guards/assessment-to-user-assessments/assessment-to-user-assessments.guard';
import { ActivityDayGuard } from './modules/training/guards/activity-day.guard';
import { SubscribersOnlyGuard } from './guards/subscribers-only/subscribers-only.guard';
import { OrganizationPermissionsGuard } from './guards/organizations/organization-permissions.guard';
import { PermissionsGuard } from './modules/permissions/permissions.guard';
import { PermissionsData } from './modules/permissions/permissions';
import { AdvisorChatGuard } from './guards/chat.guard';

export const routes: Routes = [
  // Unauthenticated Routes
  { path: '', redirectTo: 'register', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [Unauthenticated],
    loadChildren: () =>
      import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'register',
    canActivate: [Unauthenticated],
    loadChildren: () =>
      import('./pages/register/register.module').then(
        m => m.RegisterPageModule,
      ),
  },
  {
    path: 'forgot-password',
    canActivate: [Unauthenticated],
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordPageModule,
      ),
  },

  // Authenticated Routes - Allowed if onboarding.
  {
    path: 'onboarding',
    canActivate: [IsLoggedInGuard],
    loadChildren: () =>
      import(
        './modules/onboarding-2022/pages/onboarding/onboarding.module'
      ).then(m => m.OnboardingPageModule),
  },
  // { path: 'choose-advisor', canActivate: [IsLoggedInGuard], loadChildren: () => import('./pages/choose-advisor/choose-advisor.module').then(m => m.ChooseAdvisorPageModule) },

  // Authenticated Routes, profile completed.
  {
    path: 'dashboard',
    canActivate: [
      IsLoggedInGuard,
      SubscribersOnlyGuard,
      ProfileCompleteGuard,
      PreloadBodyMetricsGuard,
    ],
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        m => m.DashboardPageModule,
      ),
  },
  {
    path: 'challenge-terms',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/challenge-terms/challenge-terms.module').then(
        m => m.ChallengeTermsPageModule,
      ),
  },
  {
    path: 'camera',
    canActivate: [
      IsLoggedInGuard,
      SubscribersOnlyGuard,
      ProfileCompleteGuard,
      OrganizationPermissionsGuard,
    ],
    data: { orgPermission: 'can_upload_photos' },
    loadChildren: () =>
      import('./pages/camera/camera.module').then(m => m.CameraPageModule),
  },

  // Advisor Routes
  {
    path: 'advisor',
    canActivate: [
      IsLoggedInGuard,
      SubscribersOnlyGuard,
      ProfileCompleteGuard,
      IsAdvisorGuard,
    ],
    children: [
      {
        path: 'transphormer/:id',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/my-transphormers/details/details.module').then(
                m => m.DetailsPageModule,
              ),
          },
          {
            path: 'photos',
            loadChildren: () =>
              import('./pages/my-transphormers/photos/photos.module').then(
                m => m.PhotosPageModule,
              ),
          },
          {
            path: 'nutrition-detail',
            loadChildren: () =>
              import(
                './pages/my-transphormers/nutrition-detail/nutrition-detail.module'
              ).then(m => m.NutritionDetailPageModule),
          },
          {
            path: 'referral-list',
            loadChildren: () =>
              import(
                './pages/my-transphormers/referral-list/referral-list.module'
              ).then(m => m.ReferralListPageModule),
          },
          {
            path: 'workout',
            loadChildren: () =>
              import(
                './pages/my-transphormers/transphormer-workout/transphormer-workout.module'
              ).then(m => m.TransphormerWorkoutPageModule),
          },
          {
            path: 'notes',
            loadChildren: () =>
              import('./pages/notes/notes.module').then(m => m.NotesModule),
          },
          {
            path: 'metrics',
            loadChildren: () =>
              import('./pages/my-transphormers/metrics/metrics.module').then(
                m => m.MetricsPageModule,
              ),
          },
          {
            path: 'body-metrics',
            loadChildren: () =>
              import(
                './pages/my-transphormers/body-metrics/body-metrics.module'
              ).then(m => m.BodyMetricsPageModule),
          },
          {
            path: 'assessments',
            loadChildren: () =>
              import(
                './modules/assessments-v2/pages/advisor/assessments/assessments.module'
              ).then(m => m.AssessmentsPageModule),
          },
          {
            path: 'advisor-messages',
            loadChildren: () =>
              import(
                './modules/chat/pages/advisor-messages/advisor-messages.module'
              ).then(m => m.AdvisorMessagesPage3Module),
          },
          {
            path: 'daily-task',
            loadChildren: () =>
              import(
                './pages/my-transphormers/daily-task/daily-task.module'
              ).then(m => m.DailyTaskPageModule),
          },
        ],
      },
      {
        path: 'assessment-config',
        loadChildren: () =>
          import(
            './modules/assessments-v2/pages/advisor/assessment-config/assessment-config.module'
          ).then(m => m.AssessmentConfigPageModule),
      },
      {
        path: 'my-transphormers',
        loadChildren: () =>
          import('./pages/my-transphormers/my-transphormers.module').then(
            m => m.MyTransphormersPageModule,
          ),
      },
      {
        path: 'user-engagement',
        loadChildren: () =>
          import(
            './modules/feed/pages/user-engagement/user-engagement.module'
          ).then(m => m.UserEngagementPageModule),
      },
      {
        path: 'assessments',
        loadChildren: () =>
          import('./pages/my-transphormers/my-transphormers.module').then(
            m => m.MyTransphormersPageModule,
          ),
        data: {
          settings: {
            has_unread_assessment: true,
          },
        },
      },
      {
        path: 'assessment/:id',
        canActivate: [AssessmentToUserAssessmentsGuard],
        loadChildren: () =>
          import(
            './modules/assessments-v2/pages/advisor/assessment-detail/assessment-detail.module'
          ).then(m => m.AssessmentDetailPageModule),
      },
      {
        path: 'invite',
        loadChildren: () =>
          import('./pages/invite/invite.module').then(m => m.InvitePageModule),
      },
      {
        path: 'message-center',
        loadChildren: () =>
          import('./pages/message-center/message-center.module').then(
            m => m.MessageCenterModule,
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import(
            './pages/trainer-registration/edit-advisor-profile.module'
          ).then(m => m.EditAdvisorProfileModule),
      },
      {
        path: 'announcements',
        loadChildren: () =>
          import(
            './modules/announcements/trainer-routes/trainer-routes.module'
          ).then(m => m.TrainerRoutesModule),
      },
    ],
  },
  {
    path: 'my-transphormers/create-filter',
    canActivate: [IsLoggedInGuard, IsAdvisorGuard],
    component: CreateFilterComponent,
  },

  {
    path: 'body-metrics',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import(
        './modules/body-metrics/pages/body-metrics/body-metrics.module'
      ).then(m => m.BodyMetricsPageModule),
  },
  {
    path: 'live-streaming',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    data: { feature: 'live-streaming' },
    loadChildren: () =>
      import('./modules/video/pages/live-streaming/live-streaming.module').then(
        m => m.LiveStreamingPageModule,
      ),
  },
  {
    path: 'photo-listing',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/photo-listing/photo-listing.module').then(
        m => m.PhotoListingPageModule,
      ),
  },
  {
    path: 'messages',
    canActivate: [
      IsLoggedInGuard,
      SubscribersOnlyGuard,
      ProfileCompleteGuard,
      AdvisorChatGuard,
    ],
    data: {
      feature: 'messages',
    },
    loadChildren: () =>
      import('./modules/chat/pages/messages-page/messages-3.page.module').then(
        m => m.Messages3PageModule,
      ),
  },
  {
    path: 'announcements',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import(
        './modules/announcements/pages/announcements/announcements.page.module'
      ).then(m => m.AnnouncementsPageModule),
  },
  {
    path: 'info-contact',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/info-contact/info-contact.module').then(
        m => m.InfoContactPageModule,
      ),
  },
  {
    path: 'subscription-manager',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import(
        './modules/tiers/pages/subscription-manager/subscription-manager.module'
      ).then(m => m.SubscriptionManagerModule),
  },
  {
    path: 'nutrition-config',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/nutrition-config/nutrition-config.module').then(
        m => m.NutritionConfigPageModule,
      ),
  },
  {
    path: 'info-personal',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/info-personal/info-personal.module').then(
        m => m.InfoPersonalPageModule,
      ),
  },
  {
    path: 'reminders',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/reminders/reminders.module').then(
        m => m.RemindersPageModule,
      ),
  },
  {
    path: 'notifications',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        m => m.NotificationsPageModule,
      ),
  },

  // Authenticated Routes - Allowed if onboarding.
  {
    path: 'password-change',
    canActivate: [IsLoggedInGuard],
    loadChildren: () =>
      import('./pages/password-change/password-change.module').then(
        m => m.PasswordChangePageModule,
      ),
  },

  // Macro routes.
  {
    path: 'nutrition',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard],
    loadChildren: () =>
      import('./modules/nutrition/nutrition.pages.module').then(
        m => m.NutritionPagesModule,
      ),
  },
  {
    path: 'help-support',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/help-support/help-support.module').then(
        m => m.HelpSupportPageModule,
      ),
  },

  {
    path: 'help-support/account-deletion',
    loadChildren: () =>
      import(
        './modules/account-removal/account-deletion/account-deletion.module'
      ).then(m => m.AccountDeletionPageModule),
  },

  // Live streaming
  {
    path: 'live-stream',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    data: { feature: 'live-streaming' },
    loadChildren: () =>
      import('./modules/video/pages/live-stream/live-stream.module').then(
        m => m.LiveStreamPageModule,
      ),
  },
  {
    path: 'live-stream/:id',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    data: { feature: 'live-streaming' },
    loadChildren: () =>
      import('./modules/video/pages/video-player/video-player.module').then(
        m => m.VideoPlayerPageModule,
      ),
  },

  {
    path: 'trainer-requests',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/trainer-requests/trainer-requests.module').then(
        m => m.TrainerRequestsPageModule,
      ),
  },
  {
    path: 'assessments',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    data: { feature: 'assessments' },
    loadChildren: () =>
      import(
        './modules/assessments-v2/pages/transphormer/assessment/assessment.module'
      ).then(m => m.AssessmentPageModule),
  },
  {
    path: 'help',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/help/help.module').then(m => m.HelpPageModule),
  },
  {
    path: 'test',
    loadChildren: () =>
      import('./pages/test/test.module').then(m => m.TestPageModule),
  },

  // Products
  {
    path: 'products',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./modules/products/products.pages.module').then(
        m => m.ProductsPagesModule,
      ),
  },
  {
    path: 'pedometer',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./modules/health/pages/pedometer/pedometer.module').then(
        m => m.PedometerPageModule,
      ),
  },

  {
    path: 'steps-leaderboard',
    canActivate: [
      IsLoggedInGuard,
      SubscribersOnlyGuard,
      ProfileCompleteGuard,
      PermissionsGuard,
    ],
    data: {
      requiredPermission: PermissionsData.STEP_LEADERBOARD_ACCESS,
      baseRoute: ['/pedometer'],
    },
    loadChildren: () =>
      import('./modules/steps-leaderboard/steps-leaderboard.routing').then(
        m => m.StepsLeaderboardRoutingModule,
      ),
  },
  {
    path: 'referrals',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./pages/referrals/referrals.module').then(
        m => m.ReferralsPageModule,
      ),
  },

  {
    path: 'motivation',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./modules/motivation/motivation.routing').then(
        m => m.MotivationPageRoutingModule,
      ),
  },
  {
    path: 'week-in-review',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./modules/week-in-review/week-in-review.module').then(
        m => m.WeekInReviewPageModule,
      ),
  },
  {
    path: 'workout/:workout-id',
    loadChildren: () =>
      import('./modules/training/pages/workout/workout.module').then(
        m => m.WorkoutPageModule,
      ),
  },
  {
    path: 'activity',
    canActivate: [
      ProfileCompleteGuard,
      IsLoggedInGuard,
      SubscribersOnlyGuard,
      ActivityDayGuard,
    ],
    loadChildren: () =>
      import('./modules/training/pages/activity/activity-day.module').then(
        m => m.ActivityDayPageModule,
      ),
  },
  {
    path: 'activity/:day',
    canActivate: [IsLoggedInGuard, ProfileCompleteGuard, SubscribersOnlyGuard],
    loadChildren: () =>
      import('./modules/training/pages/activity/activity-day.module').then(
        m => m.ActivityDayPageModule,
      ),
  },
  {
    path: 'movements',
    canActivate: [IsLoggedInGuard, ProfileCompleteGuard, SubscribersOnlyGuard],
    loadChildren: () =>
      import('./modules/training/pages/movements/movements.module').then(
        m => m.MovementsPageModule,
      ),
  },
  {
    path: 'activity-settings',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./modules/training/pages/settings/settings.module').then(
        m => m.SettingsPageModule,
      ),
  },
  {
    path: 'setting-chooser',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./modules/training/pages/settings/settings.module').then(
        m => m.SettingsPageModule,
      ),
  },
  {
    path: 'default',
    outlet: 'right',
    loadChildren: () =>
      import('./split-screen/default-second-page/default-second.module').then(
        m => m.DefaultSecondModule,
      ),
  },
  {
    path: 'advisor',
    outlet: 'right',
    canActivate: [
      IsLoggedInGuard,
      SubscribersOnlyGuard,
      ProfileCompleteGuard,
      IsAdvisorGuard,
    ],
    children: [
      {
        path: 'transphormer/:id',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/my-transphormers/details/details.module').then(
                m => m.DetailsPageModule,
              ),
          },
          {
            path: 'photos',
            loadChildren: () =>
              import('./pages/my-transphormers/photos/photos.module').then(
                m => m.PhotosPageModule,
              ),
          },
          {
            path: 'nutrition-detail',
            loadChildren: () =>
              import(
                './pages/my-transphormers/nutrition-detail/nutrition-detail.module'
              ).then(m => m.NutritionDetailPageModule),
          },
          {
            path: 'referral-list',
            loadChildren: () =>
              import(
                './pages/my-transphormers/referral-list/referral-list.module'
              ).then(m => m.ReferralListPageModule),
          },
          {
            path: 'workout',
            loadChildren: () =>
              import(
                './pages/my-transphormers/transphormer-workout/transphormer-workout.module'
              ).then(m => m.TransphormerWorkoutPageModule),
          },
          {
            path: 'notes',
            loadChildren: () =>
              import('./pages/notes/notes.module').then(m => m.NotesModule),
          },
          {
            path: 'metrics',
            loadChildren: () =>
              import('./pages/my-transphormers/metrics/metrics.module').then(
                m => m.MetricsPageModule,
              ),
          },
          {
            path: 'body-metrics',
            loadChildren: () =>
              import(
                './pages/my-transphormers/body-metrics/body-metrics.module'
              ).then(m => m.BodyMetricsPageModule),
          },
          {
            path: 'assessments',
            loadChildren: () =>
              import(
                './modules/assessments-v2/pages/advisor/assessments/assessments.module'
              ).then(m => m.AssessmentsPageModule),
          },
          {
            path: 'advisor-messages',
            loadChildren: () =>
              import(
                './modules/chat/pages/advisor-messages/advisor-messages.module'
              ).then(m => m.AdvisorMessagesPage3Module),
          },
          {
            path: 'daily-task',
            loadChildren: () =>
              import(
                './pages/my-transphormers/daily-task/daily-task.module'
              ).then(m => m.DailyTaskPageModule),
          },
        ],
      },
      {
        path: 'my-transphormers',
        loadChildren: () =>
          import('./pages/my-transphormers/my-transphormers.module').then(
            m => m.MyTransphormersPageModule,
          ),
      },
      {
        path: 'assessments',
        loadChildren: () =>
          import('./pages/my-transphormers/my-transphormers.module').then(
            m => m.MyTransphormersPageModule,
          ),
        data: {
          settings: {
            has_unread_assessment: true,
          },
        },
      },
      {
        path: 'assessment/:id',
        canActivate: [AssessmentToUserAssessmentsGuard],
        loadChildren: () =>
          import(
            './modules/assessments-v2/pages/advisor/assessment-detail/assessment-detail.module'
          ).then(m => m.AssessmentDetailPageModule),
      },
      {
        path: 'invite',
        loadChildren: () =>
          import('./pages/invite/invite.module').then(m => m.InvitePageModule),
      },
      {
        path: 'message-center',
        loadChildren: () =>
          import('./pages/message-center/message-center.module').then(
            m => m.MessageCenterModule,
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import(
            './pages/trainer-registration/edit-advisor-profile.module'
          ).then(m => m.EditAdvisorProfileModule),
      },
    ],
  },
  {
    path: 'knowledge',
    loadChildren: () =>
      import('./modules/knowledge/knowledge.module').then(
        m => m.KnowledgePageModule,
      ),
  },
  {
    path: 'today',
    canActivate: [IsLoggedInGuard, SubscribersOnlyGuard, ProfileCompleteGuard],
    loadChildren: () =>
      import('./modules/feed/pages/feed/feed.module').then(
        m => m.FeedPageModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // MTST-11189 - Disable preloading. This may not only speed the app up,
      // but it will also prevent the unnecessary processing of features which
      // make unguarded calls to a production service.
      // preloadingStrategy: environment.production
      //   ? PreloadAllModules
      //   : NoPreloading,
      preloadingStrategy: NoPreloading,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
