import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EditCustomMacrosComponent } from './edit-custom-macros.component';
import { RouterModule } from '@angular/router';
import { CustomMacrosInfoComponent } from '../custom-macros-info/custom-macros-info.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    IonicModule,
  ],
  declarations: [EditCustomMacrosComponent, CustomMacrosInfoComponent],
  exports: [EditCustomMacrosComponent, CustomMacrosInfoComponent],
})
export class EditCustomMacrosModule {}
