import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeComponent } from './subscribe.component';
import { IonicModule } from '@ionic/angular';
import { PlatformDirectiveModule } from '../../../../directives/platform-directive.module';
import { ExternalLinkModule } from '../../../../directives/external-link/external-link.module';
import { LogoModule } from '../../../../components/logo/logo.module';
import { ProductButtonComponent } from '../../components/product-button/product-button.component';
import { SubscribeV2Component } from '../subscribe-v2/subscribe-v2.component';
import { NgxLaunchDarklyModule } from '../../../launchdarkly/ngx-launchdarkly.module';
import { SubscriptionContentComponent } from '../subscription-content/subscription-content.component';
import { TiersModule } from '../../../tiers/tiers.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PlatformDirectiveModule,
    ExternalLinkModule,
    NgxLaunchDarklyModule,
    LogoModule,
    TiersModule,
  ],
  declarations: [
    SubscribeComponent,
    ProductButtonComponent,
    SubscribeV2Component,
    SubscriptionContentComponent,
  ],
  exports: [SubscribeComponent, SubscriptionContentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SubscribeModalModule {}
