import { HealthData } from '@awesome-cordova-plugins/health/ngx';
import { Transphormer } from '../../interfaces';

export type DatePeriod = 'day' | 'week' | 'month' | 'year';
export type HealthAggregationBucket =
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year';
export type HealthAggregatedDataType = 'steps' | 'distance';
export type HealthDataType = HealthAggregatedDataType;

export function bucketToDuration(
  bucket: HealthAggregationBucket,
  value: number,
): Duration {
  switch (bucket) {
    case 'hour':
      return { hours: value };
    case 'day':
      return { days: value };
    case 'week':
      return { weeks: value };
    case 'month':
      return { months: value };
    case 'year':
      return { years: value };
  }
}

export interface HealthAggregation {
  bucket: HealthAggregationBucket;
  data: Pick<HealthData, 'startDate' | 'value'>[] | HealthData[];
}

export interface HealthCounters {
  steps: HealthAggregation;
}

export interface HealthStepDayData {
  recordDate?: string;
  record_date?: string;
  value: number;
  source: string;
  uuid?: string;
}

export interface HealthStepsDayRecord extends HealthStepDayData {
  transphormerId: Transphormer['id'];
}

export type StepsDayFilters =
  | Pick<HealthStepsDayRecord, 'source'>
  | 'recordDate';

export const HEALTH_PERMISSIONS = [{ read: ['steps'] }];
