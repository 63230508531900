import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AddBodyMetric,
  AddWeighIn,
  DeleteBodyMetric,
  DeleteWeighIn,
  LoadBodyMetricsData,
  Reset,
  SaveConfiguration,
  UpdateBodyMetric,
  UpdateWeighIn,
} from './body-metrics.actions';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { apiUrl, createIndexedMap, createSortIndex } from '../../../../helpers';
import {
  catchError,
  debounce,
  finalize,
  map,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  dateMorph,
  UnitType,
  WeighIn,
  Weight,
  WrappedApiResponse,
} from '../../../../interfaces';
import {
  BodyMetricData,
  BodyMetricsStateModel,
  Configuration,
  Index,
} from '../../types';
import { EMPTY, interval, of, Subject } from 'rxjs';
import {
  descending,
  enrichBodyMetric,
  enrichWeighIn,
} from '../../services/body-metric/functions';
import {
  BodyMetric,
  MetricTypeName,
  MetricTypeNames,
  MetricTypes,
} from '../../types/body-metric';
import { handleApiError } from '../../../../helpers/operators';
import produce from 'immer';
import { OnboardingService } from '../../../../services';
import { genericRetryStrategy } from '../../../../services/interceptors/automatic-retry-interceptor/automatic-retry-interceptor';
import { isAfter } from 'date-fns';

export type CombinedMetric = MetricTypeName & { metric: BodyMetric };

export const BODY_METRICS_STATE_TOKEN = new StateToken<BodyMetricsStateModel>(
  'body_metrics',
);
export const BODY_METRICS_STATE_EMPTY: BodyMetricsStateModel = {
  configurations: [],
  bodyMetrics: [],
  weighIns: [],
  bodyMetricIndex: null,
  weighInIndex: null,
  status: 'unloaded',
  isLoading: false,
};

function convertWeighIns(weighIns: Weight<string>[]): Weight[] {
  return weighIns.map(convertWeighIn);
}

function convertWeighIn(weighIn: Weight<string>): Weight {
  return { ...weighIn, ...dateMorph(weighIn) };
}

function convertBodyMetrics(bodyMetrics: BodyMetric<string>[]): BodyMetric[] {
  return bodyMetrics.map(convertBodyMetric);
}

function convertBodyMetric(bodyMetric: BodyMetric<string>): BodyMetric {
  return { ...bodyMetric, ...dateMorph(bodyMetric) };
}

@State<BodyMetricsStateModel>({
  name: BODY_METRICS_STATE_TOKEN,
  defaults: BODY_METRICS_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class BodyMetricsState {
  public nextConfig$ = new Subject<Configuration[]>();

  constructor(
    private http: HttpClient,
    private onBoardingService: OnboardingService,
  ) {
    // Configurations update queue
    this.nextConfig$
      .pipe(
        debounce(() => interval(2500)),
        switchMap(configurations =>
          this.http
            .put<Index>(apiUrl('body-metrics/configuration', 'v2.8'), {
              configurations,
            })
            .pipe(
              retryWhen(
                genericRetryStrategy({
                  maxRetryAttempts: 150,
                  scalingDuration: 2500,
                }),
              ),
              handleApiError(),
            ),
        ),
      )
      .subscribe();
  }

  @Selector([BodyMetricsState.getConfiguration])
  static configuration(configurations: Configuration[]) {
    if (!configurations) {
      return [];
    }
    return MetricTypeNames.map(
      mtn =>
        ({
          type: mtn.value,
          use: configurations.find(c => c.type === mtn.value).use ?? true,
        }) as Configuration,
    );
  }

  @Selector([BodyMetricsState])
  static getConfiguration(state: BodyMetricsStateModel) {
    return state.configurations;
  }

  @Selector()
  static latestWeighIn(state: BodyMetricsStateModel) {
    return <WeighIn>state.weighIns[state.weighInIndex.latestId] || null;
  }

  @Selector()
  static weighIns(state: BodyMetricsStateModel) {
    return state.weighIns;
  }

  static weighInsAfterAsArray(after: Date = null) {
    return createSelector(
      [BodyMetricsState],
      (state: BodyMetricsStateModel) => {
        if (state.status !== 'loaded') {
          return [];
        }
        return after
          ? state.weighInIndex.records
              .filter(weighInId =>
                isAfter(state.weighIns[weighInId].logged_on, after),
              )
              .map(weighInId => state.weighIns[weighInId])
          : state.weighInIndex.records.map(
              weighInId => state.weighIns[weighInId],
            );
      },
    );
  }

  @Selector()
  static weighInIndex(state: BodyMetricsStateModel) {
    return state.weighInIndex;
  }

  @Selector()
  static latestConfiguredBodyMetrics(
    state: BodyMetricsStateModel,
  ): CombinedMetric[] {
    const usedConfigurations = state.configurations.filter(
      configuration => configuration.use,
    );

    return usedConfigurations.map(configuration => ({
      ...MetricTypeNames.find(
        metricTypeName => metricTypeName.value === configuration.type,
      ),
      metric:
        state.bodyMetrics[state.bodyMetricIndex[configuration.type].latestId],
    }));
  }

  @Selector()
  static latestBodyMetricByType(state: BodyMetricsStateModel) {
    if (state.status !== 'loaded') {
      return null;
    }
    return (bodyMetricType: MetricTypes) =>
      state.bodyMetrics[state.bodyMetricIndex[bodyMetricType].latestId];
  }

  @Selector()
  static isLoading(state: BodyMetricsStateModel) {
    return state.isLoading;
  }

  @Selector()
  static status(state: BodyMetricsStateModel) {
    return state.status;
  }

  static bodyMetricsAfterAsArray(bodyMetricType: MetricTypes, after: Date) {
    return createSelector(
      [BodyMetricsState],
      (state: BodyMetricsStateModel) => {
        if (state.status !== 'loaded') {
          return [];
        }
        return after
          ? state.bodyMetricIndex[bodyMetricType].records
              .filter(bodyMetricId =>
                isAfter(state.bodyMetrics[bodyMetricId].logged_on, after),
              )
              .map(bodyMetricId => state.bodyMetrics[bodyMetricId])
          : state.bodyMetricIndex[bodyMetricType].records.map(
              bodyMetricId => state.bodyMetrics[bodyMetricId],
            );
      },
    );
  }

  @Action(LoadBodyMetricsData)
  loadBodyMetricsData(ctx: StateContext<BodyMetricsStateModel>) {
    ctx.patchState({
      isLoading: true,
      status: 'loading',
    });

    return this.http
      .get<
        WrappedApiResponse<BodyMetricData<string>>
      >(apiUrl('body-metrics/metrics', 'v2.8'))
      .pipe(
        catchError(() => {
          ctx.patchState({ isLoading: false, status: 'error' });
          return EMPTY;
        }),
        map(response => response.data),
        map(data => ({
          ...data,
          weighIns: convertWeighIns(data.weighIns),
          bodyMetrics: convertBodyMetrics(data.bodyMetrics),
        })),
        tap((data: BodyMetricData) => {
          const weighIns = createIndexedMap(
            data.weighIns.map(metric => enrichWeighIn(metric)),
            'id',
          );
          const weighInsSortedIndex = createSortIndex(
            Object.values(weighIns),
            descending,
            'id',
          );
          const bodyMetrics = createIndexedMap(
            data.bodyMetrics.map(metric => enrichBodyMetric(metric)),
            'id',
          );

          if (!data.configuration || !data.configuration.configurations) {
            data.configuration = {
              configurations: MetricTypeNames.map(metric => ({
                use: true,
                type: metric.value,
              })),
            };
          }
          const bodyMetricIndex = MetricTypeNames.map(mtn => {
            const ourValues = Object.values(bodyMetrics).filter(
              bm => bm.body_metric_type === mtn.value,
            );
            const records = createSortIndex(ourValues, descending, 'id');
            return {
              key: mtn.value,
              value: {
                latestId: records.slice(0, 1)[0] || null,
                firstId: records.slice(-1)[0] || null,
                records,
              },
            };
          }).reduce((o, v) => ({ ...o, [v.key]: v.value }), {});

          ctx.setState({
            isLoading: false,
            status: 'loaded',
            configurations: [...data.configuration.configurations],
            bodyMetricIndex,
            weighInIndex: {
              latestId: weighInsSortedIndex.slice(0, 1)[0] || null,
              firstId: weighInsSortedIndex.slice(-1)[0] || null,
              records: weighInsSortedIndex,
            },
            bodyMetrics,
            weighIns,
          });
        }),
      );
  }

  @Action(AddWeighIn)
  addWeighIn(ctx: StateContext<BodyMetricsStateModel>, { mr }: AddWeighIn) {
    const add: UnitType = {
      value: mr.value,
      unit: mr.unit,
    };
    ctx.patchState({ isLoading: true });
    return this.http
      .post<
        WrappedApiResponse<Weight<string>>
      >(apiUrl('body-metrics/weigh-ins', 'v2.8'), add)
      .pipe(
        map(data => convertWeighIn(data.data)),
        handleApiError(),
        tap(data => {
          let { weighIns, weighInIndex } = ctx.getState();

          weighIns = {
            ...weighIns,
            [data.id]: enrichWeighIn(data),
          };
          weighInIndex = {
            firstId: weighInIndex.firstId,
            latestId: data.id,
            records: [data.id, ...weighInIndex.records],
          };
          ctx.patchState({
            weighInIndex,
            weighIns,
          });
        }),
        switchMap(() => this.onBoardingService.fetchOnBoard()),
        finalize(() => ctx.patchState({ isLoading: false })),
      );
  }

  @Action(DeleteWeighIn)
  deleteWeighIn(
    ctx: StateContext<BodyMetricsStateModel>,
    { id }: DeleteWeighIn,
  ) {
    ctx.patchState({ isLoading: true });
    return this.http
      .delete<null>(apiUrl(`body-metrics/weigh-ins/${id}`, 'v2.8'))
      .pipe(
        handleApiError(),
        tap(() => {
          const { weighInIndex, weighIns } = ctx.getState();
          const records = weighInIndex.records.filter(key => key !== id);

          const newWeighInIndex = {
            latestId:
              weighInIndex.latestId === id
                ? records.slice(0, 1)[0] || null
                : weighInIndex.latestId,
            firstId:
              weighInIndex.latestId === id
                ? records.slice(-1)[0] || null
                : weighInIndex.firstId,
            records,
          };

          const updatedWeighIns = { ...weighIns };
          delete updatedWeighIns['' + id];

          ctx.patchState({
            weighInIndex: newWeighInIndex,
            weighIns: updatedWeighIns,
          });
        }),
        switchMap(() => this.onBoardingService.fetchOnBoard()),
        finalize(() => ctx.patchState({ isLoading: false })),
      );
  }

  @Action(UpdateWeighIn)
  updateWeighIn(
    ctx: StateContext<BodyMetricsStateModel>,
    { weighIn, mr }: UpdateWeighIn,
  ) {
    const update: UnitType = {
      value: mr.value,
      unit: weighIn.unit_type,
    };
    ctx.patchState({ isLoading: true });
    return this.http
      .put<
        WrappedApiResponse<Weight<string>>
      >(apiUrl(`body-metrics/weigh-ins/${weighIn.id}`, 'v2.8'), update)
      .pipe(
        map(data => convertWeighIn(data.data)),
        handleApiError(),
        tap(data => {
          const { weighIns } = ctx.getState();
          ctx.patchState({
            weighIns: {
              ...weighIns,
              [data.id]: enrichWeighIn(data),
            },
          });
        }),
        switchMap(() => this.onBoardingService.fetchOnBoard()),
        finalize(() => ctx.patchState({ isLoading: false })),
      );
  }

  @Action(AddBodyMetric)
  addBodyMetric(
    ctx: StateContext<BodyMetricsStateModel>,
    { bodyMetricEntry }: AddBodyMetric,
  ) {
    ctx.patchState({ isLoading: true });
    return this.http
      .post<
        WrappedApiResponse<BodyMetric<string>>
      >(apiUrl('body-metrics/metrics', 'v2.8'), bodyMetricEntry)
      .pipe(
        map(data => convertBodyMetric(data.data)),
        handleApiError(),
        tap(data => {
          ctx.setState(
            produce(draft => {
              draft.bodyMetrics[data.id] = enrichBodyMetric(data);
              draft.bodyMetricIndex[bodyMetricEntry.body_metric_type].latestId =
                data.id;
              draft.bodyMetricIndex[
                bodyMetricEntry.body_metric_type
              ].records.unshift(data.id);
            }),
          );
        }),
        finalize(() => ctx.patchState({ isLoading: false })),
      );
  }

  @Action(DeleteBodyMetric)
  deleteBodyMetric(
    ctx: StateContext<BodyMetricsStateModel>,
    { bodyMetric }: DeleteBodyMetric,
  ) {
    ctx.patchState({ isLoading: true });
    return this.http
      .delete<null>(apiUrl(`body-metrics/metrics/${bodyMetric.id}`, 'v2.8'))
      .pipe(
        handleApiError(),
        tap(() => {
          ctx.setState(
            produce(draft => {
              const records = draft.bodyMetricIndex[
                bodyMetric.body_metric_type
              ].records.filter(key => key !== bodyMetric.id);
              draft.bodyMetricIndex[bodyMetric.body_metric_type].records =
                records;
              if (
                draft.bodyMetricIndex[bodyMetric.body_metric_type].latestId ===
                bodyMetric.id
              ) {
                draft.bodyMetricIndex[bodyMetric.body_metric_type].latestId =
                  records.slice(0, 1)[0] || null;
              }
              if (
                draft.bodyMetricIndex[bodyMetric.body_metric_type].firstId ===
                bodyMetric.id
              ) {
                draft.bodyMetricIndex[bodyMetric.body_metric_type].firstId =
                  records.slice(-1)[0] || null;
              }
              delete draft.bodyMetrics['' + bodyMetric.id];
            }),
          );
        }),
        finalize(() => ctx.patchState({ isLoading: false })),
      );
  }

  @Action(UpdateBodyMetric)
  updateBodyMetric(
    ctx: StateContext<BodyMetricsStateModel>,
    { bodyMetric }: UpdateBodyMetric,
  ) {
    ctx.patchState({ isLoading: true });
    return this.http
      .put<
        WrappedApiResponse<BodyMetric<string>>
      >(apiUrl(`body-metrics/metrics/${bodyMetric.id}`, 'v2.8'), bodyMetric)
      .pipe(
        map(data => convertBodyMetric(data.data)),
        handleApiError(),
        tap(data => {
          const { bodyMetrics } = ctx.getState();

          ctx.patchState({
            bodyMetrics: {
              ...bodyMetrics,
              [data.id]: enrichBodyMetric(data),
            },
          });
        }),
        finalize(() => ctx.patchState({ isLoading: false })),
      );
  }

  @Action(Reset)
  reset(ctx: StateContext<BodyMetricsStateModel>) {
    ctx.patchState(BODY_METRICS_STATE_EMPTY);
    return of(BODY_METRICS_STATE_EMPTY);
  }

  @Action(SaveConfiguration)
  saveConfiguration(
    ctx: StateContext<BodyMetricsStateModel>,
    { configurations }: SaveConfiguration,
  ) {
    this.nextConfig$.next(configurations);
    ctx.patchState({ configurations });
  }
}
