import {
  BodyMetric,
  BodyMetricEntry,
  MetricTypes,
} from '../../types/body-metric';
import {
  mapBodyMetricUnit,
  mapWeightUnit,
} from '../../../../helpers/map-unit-helper';
import { UnitTypes, WeighIn, Weight } from '../../../../interfaces';

export function enrichBodyMetric(metric: BodyMetric): BodyMetric {
  return {
    ...mapBodyMetricUnit(metric),
  };
}

export function enrichWeighIn(metric: Weight): WeighIn {
  return {
    ...mapWeightUnit(metric),
  };
}

export function bodyMetricEntry(
  value,
  type: MetricTypes,
  unit_type: UnitTypes,
): BodyMetricEntry {
  return {
    body_metric_type: type,
    metric_value: value,
    unit_type,
  };
}

export const descending = (
  a: WeighIn | Weight | BodyMetric,
  b: WeighIn | Weight | BodyMetric,
) => (a.logged_on > b.logged_on ? -1 : 1);
