import { Pipe, PipeTransform } from '@angular/core';
import { ExertionSettings, ExertionSettingsEnum } from '../../../interfaces';

@Pipe({
  name: 'exertionDisplay',
})
export class ExertionDisplayPipe implements PipeTransform {
  /**
   * This pipe is used to transform ExertionSetting values that are displayed in the
   * description of every exercise. E.g. "4 sets @ 4-10 RPE" or "4 sets @ 3,4,5,6 %"
   * @param setting
   */
  transform(setting: ExertionSettings): string {
    let display: string;

    if (setting.value.includes('-')) {
      // If the value contains '-', then it means there's a range within the exertion settings,
      // we want to display these as they come.
      const exertionValues = [...setting.value.split(',')];

      display = `${setting.value}`;

      // If every value is the same, only display the first;
      // e.g. for ExertionSettings value of 4-10,4-10,4-10,4-10 only display 4-10
      if (exertionValues.every(v => v === exertionValues[0])) {
        display = `${exertionValues[0]}`;
      }
    } else {
      // If there's no range then let's sort these from the smallest to greatest value
      const exertionValues = [
        ...setting.value
          .split(',')
          .map(val => +val)
          .sort((n1, n2) => n1 - n2),
      ];

      // Display the smallest to greatest; e.g. "4-10"
      display = `${exertionValues[0]}-${exertionValues[exertionValues.length - 1]}`;

      // If every value is the same, only display the first;
      // e.g. for ExertionSettings value of 4,4,4,4 only display 4
      if (exertionValues.every(v => v === exertionValues[0])) {
        display = `${exertionValues[0]}`;
      }
    }

    return `${display} ${ExertionSettingsEnum[setting?.type] || ''}`;
  }
}
