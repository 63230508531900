import {
  ActivityDataPoint,
  Exercise,
  ExertionSettings,
  IndexedArray,
  Swap,
  Training,
  TrainingConfig,
  Workout,
  WorkoutSession,
  WorkoutSessionV3WithMetadata,
} from '../../../interfaces';

export interface WorkoutsStateModel {
  isExerciseExpanded: { [key: string]: boolean };
  isLoadingWorkout: boolean;
  isLoadingWorkouts: boolean;
  workoutSessionByDate: IndexedArray<number>;
  workoutSessions: IndexedArray<WorkoutSession>;
  sessions: WorkoutSessionV3WithMetadata[];
  swap_days: Swap[];
  customWorkoutId: number;
  group_scores: IndexedArray<ActivityDataPoint>;
  workoutsById: IndexedArray<Workout>;
  trainingsById: IndexedArray<Training>;
  trainings: number[];
  trainingConfig: TrainingConfig[];
}

export enum SessionExistence {
  FOUND,
  NOT_FOUND,
  ERRORED,
  UNKNOWN,
}

export interface DayInstance {
  date?: string;
  day?: string;
  calendar_date: Date;
  dayNumber?: string;
  future?: boolean;
  completed?: boolean;
}

export interface DayMap {
  [key: number]: number;
}

export interface RestDayRecord<T = Date> {
  day: RestDay;
  start_date?: T;
}

export interface SwapDayRecord<T = Date> {
  firstDay: number;
  secondDay: number;
  recorded: T;
}

export interface OffsetRecord<T = Date> {
  offset: number;
  recorded?: T;
}

export enum RestDay {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  NONE = null,
}

export type WorkoutDayInfo = Pick<Workout, 'is_rest_day' | 'day_no'>;

export interface RemoveResult {
  swapId: number;
  role: 'remove';
}

export interface SwapResult {
  scope: 'all' | 'session';
  originalMovementId: number;
  newMovementId: number;
}

export type MovementResult = RemoveResult | SwapResult;
export const isSwap = (r: MovementResult, role: string): r is SwapResult =>
  role === 'swap';
export const isRemove = (r: MovementResult, role: string): r is RemoveResult =>
  role === 'remove';

export interface TrainingSessionCurrentPr {
  id: string;
  trainingSessionId: number;
  movementId: number;
  movementGroupId: number;
  mobementGroupMovementId?: number;
  set: number;
  reps: number;
  weight: string;
  perceivedExertion?: ExertionSettings;
}

export type RepMaxFilters = 'prOnly' | 'movements';

export interface RepMaxResponse {
  id: number;
  transphormerId: number;
  movementId: number;
  currentRepMaxId: number;
  currentPrId: number;
  reps: number;
}

export interface RepMaxRecord extends RepMaxResponse {
  currentPr: TrainingSessionCurrentPr;
  exercise?: Exercise;
  workout?: Workout;
}
