import {
  addDays,
  eachDayOfInterval,
  format,
  isAfter,
  isSameDay,
} from 'date-fns';
import { chunk } from 'lodash';
import {
  DayMap,
  OffsetRecord,
  RestDay,
  RestDayRecord,
  SwapDayRecord,
  WorkoutDayInfo,
} from '../types';
import { isSameDayOrBefore } from '../../../helpers/date';
import {
  getInitialOffset,
  hasNonInitialOffsets,
  nonInitialOffsets,
  offsetsHasInitialOffset,
} from './activity/functions';
import {
  ActivityDataPoint,
  Exercise,
  TrainingConfig,
  Workout,
} from '../../../interfaces';

// Add the week and regular day numbers to a workout, given it's day_no.
export const addWeekAndDay = (
  workout: Pick<Workout, 'day_no'>,
): { day_no: number; week: number; day: number } => ({
  day_no: workout.day_no,
  week: Math.floor((+workout.day_no - 1) / 7 + 1),
  day: ((workout.day_no - 1) % 7) + 1,
});

/**
 * Creates a "default map" of a given length, which is literally just an object, starting with an index of 1
 * that maps each number to itself. IOW: {1: 1, 2: 2, ..., n: n}.
 *
 * @param length number
 */
export const defaultMap = (length: number): DayMap => {
  const a = { ...[...new Array(length + 1).keys()] };
  delete a[0];
  return a;
};

/**
 * Converts a "carbon" formatted date number 1 - 7, to what Carbon uses: 0 - 6.
 * @param iso string
 */
export function isoToCarbon(iso: string): number {
  return +iso - 1;
}

/**
 * Returns the "carbon" day of week for a given date.
 *
 * @param d
 */
function dayOfWeek(d: Date): number {
  return isoToCarbon(format(d, 'e'));
}

/**
 * For a given array of workout days, "pull out" the first rest day found and return it.
 * @param ws
 */
const pullOutRestDay = (ws: WorkoutDayInfo[]): WorkoutDayInfo => {
  const numberOfRestDays = ws.reduce(
    (a, wd) => a + (wd.is_rest_day ? 1 : 0),
    0,
  );
  if (numberOfRestDays > 2) {
    ws = ws.reverse();
  }
  const di = ws.findIndex(w => w.is_rest_day);
  if (di === -1) {
    return null;
  }
  return ws.splice(di, 1)[0];
};

/**
 * For a given list of workout days, pull out the next day and return it.
 * @param ws
 */
const pullOutNextWorkout = (ws: WorkoutDayInfo[]) => {
  return ws.shift();
};

// Since it is possible for days to have multiple workouts, we figure this out based on the max day_no in the set.
export const numberOfWorkoutDays = workouts =>
  workouts.reduce((a, c) => (c.day_no > a ? c.day_no : a), 0);

// Iterate over a given set of workouts and produce an array indexed by the given week a workout exists in.
export function organizeByWeeks(workouts: Workout[]) {
  return workouts.reduce((a, c) => {
    const week = Math.floor((+c.day_no - 1) / 7);
    a[week] = [...(a[week] || []), { ...c, ...addWeekAndDay(c) }];
    return a;
  }, []);
}

/**
 * Given a set of input data, return a mapping of calendar days to training plan days.
 *
 * The idea works like this: A normal workout, with no days, is just a 1:1 mapping:
 * {1: 1, 2: 2, 3: 3}
 *
 * That is the first, second, and third days all would use the first, second, and third days of the
 * training plan workouts respectively.
 *
 * However, if a user wants a rest day, then we apply algorithm that swaps the days around to
 * give them a rest day on their preferred day. This means that the plan does not have to change, only
 * how the user sees or experiences the plan would need to change.
 *
 * Setting up a mapping allows us to easily build and test new idea and types of mapping, if we want to.
 *
 * @param startDate
 *  The calendar day that the plan starts. We need this to be able to calculate the map and have a reference
 *  since the workouts aren't tied to calendar days any more.
 * @param restDays
 *  The user's rest day preference. This can be a single `RestDay` or a `RestDayRecord[]` which defines a number of
 *  rest day configurations that are cumulatively applied to the rest day configuration over the course of say, many
 *  weeks.
 * @param _workouts
 *  The actual list of workouts that are in the training program. Note that for testing and other purposes, we only
 *  need a couple of the fields.
 * @param offsets
 */
export function generateWorkoutRestDayMap(
  startDate: Date,
  restDays: RestDay | RestDayRecord[],
  _workouts: WorkoutDayInfo[],
  offsets: OffsetRecord[] = [],
): DayMap {
  // Get an idea of the number of days and make a shallow copy of the workouts for our purposes.
  const workouts = [..._workouts];

  // If no rest day is configured and there are no offsets, then we use whatever the program has. Return
  // a default mapping that's literally just a 1:1 mapping of plan days to workout days.
  if (
    offsets.length === 0 &&
    (restDays === null || (Array.isArray(restDays) && restDays.length === 0))
  ) {
    return defaultMap(numberOfWorkoutDays(workouts));
  }

  // Here is the magic. The date-getter takes the state of the workouts, the start dates, offsets, etc. and
  // produces a function which takes a date as the argument and produces the day_number and a flag for
  // whether or not the day is a rest day.
  const getNextForDate = dayGetter(startDate, restDays, workouts, offsets);

  // For each day in the total number of days, build the mapping. The first function produces an array of
  // dates, based on the configuration of the offsets and the start date. This boils down to a set of ranges
  // that end up being combined and returned, giving us the basis for plan.

  return (
    trainingConfigDaysWithOffsets(startDate, workouts, offsets)
      // Feed each date into the day-getter and return the day info for each calendar date.
      .map(date => getNextForDate(date))
      // Map and reduce it down to the final mapping based on the day_no.
      .map((a, i) => [i + 1 + getInitialOffset(offsets), a.day_no])
      // Reduce it down to the final mapping.
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {})
  );
}

/**
 * With the start_date, the number of workouts, and the offsets, create a calendar of dates that will be used
 * to populate the final lookup table.
 *
 * @param startDate
 * @param workouts
 * @param offsets
 */
export function trainingConfigDaysWithOffsets(
  startDate: Date,
  workouts: number | WorkoutDayInfo[],
  offsets: OffsetRecord[] = [],
) {
  let numberOfDays = Number.isInteger(workouts)
    ? workouts
    : numberOfWorkoutDays(workouts);

  // Initial offsets remove days from the front and make a plan shorter. Remove these days.
  if (offsetsHasInitialOffset(offsets)) {
    numberOfDays = numberOfDays - getInitialOffset(offsets);
  }

  // If there are no additional offsets, then we can just return a range of dates starting with the start date
  // and the number of days of the length of the plan.
  if (!hasNonInitialOffsets(offsets)) {
    return eachDayOfInterval({
      start: startDate,
      end: addDays(startDate, numberOfDays - 1),
    });
  }

  const intervals = [];

  // We process the non-initial offsets first, because these will reduce the total number of the remaining days.
  // Reverse the offsets to have the last first. I think initially this was easier, but in hindsight, this could
  // likely be simplified.
  nonInitialOffsets(offsets)
    .sort((a, b) => (a.recorded < b.recorded ? 1 : -1))
    .forEach(i => {
      intervals.push({
        start: i.recorded,
        end: addDays(i.recorded, numberOfDays - i.offset - 1),
      });
      numberOfDays = i.offset - getInitialOffset(offsets);
    });

  // Finally push the initial set of days before the offsets.
  intervals.push({
    start: startDate,
    end: addDays(startDate, numberOfDays - 1),
  });

  // Reverse the array and then produce the interval map and flatten it to return a single
  // array of dates for each of the intervals.
  return [...intervals.reverse().flatMap(i => eachDayOfInterval(i))];
}

export const weekForCalendar = (date: Date, calendar: Date[]) =>
  Math.floor((calendar.findIndex(i => isSameDay(date, i)) + 0.5) / 7);

function dayGetter(
  startDate: Date,
  restDays: null | RestDay | RestDayRecord[],
  workouts = [],
  offsets: OffsetRecord[] = [],
) {
  // If we have an initial offset, slice off the workouts provided based on the offset.
  if (offsetsHasInitialOffset(offsets)) {
    workouts = workouts.slice(getInitialOffset(offsets));
  }

  // We need to know the calendar, in case there are offsets which cause breaks.
  const knownCalendar = trainingConfigDaysWithOffsets(
    startDate,
    workouts,
    offsets,
  );

  // Pull out the first rest day of each week and the rest of the workout days.
  // We do this because a user can only have a single rest day configuration and we want to be able to handle
  // possible workouts which have two rest days properly. Since this is an array, we use the
  // array indices to "index" the rest days by week. One per week.
  const weeklyRestDayIndex = splitOutFirstRestDayOfEachWeek(workouts);

  return date => {
    // If today is day of the week that the user has requested a rest day, pull one out
    // if one exists. Because the calendar can be different due to offsets or initial offsets,
    // we have to figure out what week we are in based off of the calendar generated by the
    // "knownCalendar" which is generated from the start date, the length of the program, and the
    // offsets (if they exist).
    const weekNo = weekForCalendar(date, knownCalendar);

    // Because the rest day can change literally daily, we have to know what rest day is currently
    // active for a given day within the configuration.
    const restDayValue = restDayForDate(date, restDays);

    // If we have a null rest day value, then whatever day is next we take.
    if (restDayValue === null) {
      // tslint:disable-next-line:no-shadowed-variable
      const nextWorkout = pullOutNextWorkout(workouts);
      // If the day we get is a rest day and we have not used the rest day for the week AND
      // the day we get is _the_ rest day for the week, go ahead and delete it from the index of rest days.
      if (
        nextWorkout.is_rest_day &&
        weeklyRestDayIndex[weekNo] &&
        nextWorkout.day_no === weeklyRestDayIndex[weekNo].day_no
      ) {
        delete weeklyRestDayIndex[weekNo];
      }
      return nextWorkout;
    }

    // If we got here, then a rest day is configured. Let's figure out if this is a rest day.
    if (restDayValue !== dayOfWeek(date)) {
      // tslint:disable-next-line:no-shadowed-variable
      const nextWorkout = pullOutNextWorkout(workouts);

      // If the next workout is a rest day and it's the rest day for the week, we need to
      // rearrange, since up above we've established that today is NOT the rest day of the week.
      if (
        nextWorkout.is_rest_day &&
        weeklyRestDayIndex[weekNo] &&
        nextWorkout.day_no === weeklyRestDayIndex[weekNo].day_no
      ) {
        // Grab the next workout for the week. Remember that above, we've pulled the rest day
        // workout out of the list of workouts.
        const nextNonRestDay = pullOutNextWorkout(workouts);

        // If the nextWorkout day isn't defined, then put the rest day back into the queue, since it's possible
        // the rest day will be needed later in the week. But if it doesn't exist, then we'll just use the
        // rest day returned.
        if (nextNonRestDay !== undefined) {
          workouts.unshift(nextWorkout);
          return nextNonRestDay;
        }
      }

      // This is a regular workout, just return it!
      return nextWorkout;
    }

    // If we got here, then "today" is a rest day. Pull the next rest day and, if needed
    // remove it from the standard list of workouts.
    const nextWorkout = pullOutNextWorkout(workouts);
    const restDay = getRestDayForWeek(weeklyRestDayIndex, weekNo);

    // If the next workout is a rest day but it isn't the rest day, then let's just go ahead and return it?
    if (nextWorkout.is_rest_day) {
      return nextWorkout;
    } else {
      workouts.unshift(nextWorkout);
    }

    if (restDay) {
      // If we got a rest day, pull it out of the regular workouts list.
      workouts = workouts.filter(i => i.day_no !== restDay.day_no);
      return restDay;
    } else {
      // If one does not exist, then just grab the next workout. It is possible that this
      // program does not have rest days OR the user as already used their weekly rest day
      // earlier in the week.
      return pullOutNextWorkout(workouts);
    }
  };
}

// For a given week, return a rest day.
function getRestDayForWeek(
  weeklyRestDays: { [key: number]: WorkoutDayInfo },
  weekNumber: number,
) {
  const nextDay = weeklyRestDays[weekNumber];
  if (nextDay) {
    delete weeklyRestDays[weekNumber];
  }
  return nextDay || null;
}

// For a given date and rest day configuration, determine the rest day, since this can change over time.
export function restDayForDate(
  date: Date,
  restDayOrRecord: RestDay | RestDayRecord[],
) {
  // If the rest day is just a day, then return that.
  if (!Array.isArray(restDayOrRecord)) {
    return restDayOrRecord;
  }

  if (restDayOrRecord.length === 0) {
    return null;
  }

  restDayOrRecord = [...restDayOrRecord];
  restDayOrRecord.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));
  // Use the latest rest day record. If none is found, use the default.
  return (
    restDayOrRecord.find(
      rdr => rdr.start_date && isSameDayOrBefore(rdr.start_date, date),
    ) || restDayOrRecord.find(rdr => !rdr.start_date)
  ).day;
}

export const configHasRestDays = (tc: Pick<TrainingConfig, 'modifications'>) =>
  tc.modifications?.rest_days &&
  Array.isArray(tc.modifications.rest_days) &&
  tc.modifications.rest_days.length > 0;

/**
 * For a given mapping, apply any swaps.
 *
 * @param swaps
 * @param days
 */
export function applyDaySwapsToMapping(swaps: SwapDayRecord[], days: DayMap) {
  // Nothing to do, get out of here.
  if (swaps.length === 0) {
    return days;
  }

  swaps = [...swaps];

  // Apply each swap sequentially.
  swaps
    .sort((a, b) => (a.recorded > b.recorded ? -1 : 1))
    .forEach(swap => {
      const firstDayValue = days[swap.firstDay];
      days[swap.firstDay] = days[swap.secondDay];
      days[swap.secondDay] = firstDayValue;
    });

  return days;
}

/**
 * Split out the first rest day of each week and the rest of the workouts.
 * This doesn't actually "split" them anymore, but it does pull out each of
 * of the first rest days of the week.
 *
 * @param workouts
 */
export function splitOutFirstRestDayOfEachWeek(
  workouts: Workout[],
): WorkoutDayInfo[] {
  const firstRestDayOfEachWeek = [];

  chunk(workouts, 7) // Split the plan up into weeks.
    .map((week, i) => {
      // For each week.
      const weekRestDay = pullOutRestDay([...week]); // Grab the first rest day.
      if (weekRestDay) {
        // If there is a rest day, add it to the index.
        firstRestDayOfEachWeek[i] = weekRestDay;
      }
    });

  return firstRestDayOfEachWeek;
}

export function isExercise(i): i is Exercise {
  return i.id !== null && i.name !== null;
}

export function generateWorkoutSummaryDateFns(
  recentWorkouts,
  firstDay,
  lastDay,
  today,
) {
  const summary = [];

  recentWorkouts = recentWorkouts || [];

  do {
    summary.push({
      completed: recentWorkouts
        .filter(workout => {
          return workout.date === format(firstDay, 'yyyy-MM-dd');
        })
        .reduce((a, c) => (a ? a : c.completed), false),
      future: isAfter(today, firstDay),
      day: format(firstDay, 'eeeeee'),
      dayNumber: format(firstDay, 'd'),
      date: format(firstDay, 'yyyy-MM-dd'),
    });
    firstDay = addDays(firstDay, 1);
  } while (firstDay <= lastDay);

  return summary;
}

const isEmptyOrNull = (value: number | string | null): boolean =>
  Number.isInteger(value) ? false : value === null || value === '';

export function isActivityDataPointEmpty(adp: ActivityDataPoint) {
  switch (adp.type) {
    case 'reps':
      return isEmptyOrNull(adp.reps);
    case 'repsAtWeight':
      return isEmptyOrNull(adp.reps) && isEmptyOrNull(adp.weight);
    case 'roundsPlusReps':
      return isEmptyOrNull(adp.reps) && isEmptyOrNull(adp.rounds);
    case 'cals':
      return isEmptyOrNull(adp.cals);
    case 'weight':
      return isEmptyOrNull(adp.weight);
    case 'time':
      return isEmptyOrNull(adp.seconds);
    case 'completion':
      return adp.completed;
  }
}
