<ion-content #content>
  <ng-container *ngFor="let tier of tiers$ | async">
    <div class="ion-margin">
      <app-tier-item
        [tier]="tier"
        [selectedTierId]="(selectedTier$ | async)?.id"
        (selectTier)="toggleTier($event)"
      ></app-tier-item>
    </div>
  </ng-container>

  <ion-grid class="bottom" *ngIf="selectedTier$ | async as tier">
    <ion-row>
      <ion-col class="ion-padding">
        <ion-text class="disclaimer">
          {{ privacyText }}
        </ion-text>

        <div class="ion-margin-top">
          <a appExternalLink href="https://1stphorm.com">Privacy Policy</a> and
          <a appExternalLink href="https://1stphorm.com">Terms & Conditions</a>
        </div>
        <div class="ion-margin-top">
          <a appExternalLink href="https://1stphorm.com"
            >View Full Plan Comparison</a
          >
        </div>
      </ion-col>
    </ion-row>

    <ion-row class="priceButton">
      <ng-container *ngFor="let price of tier.prices">
        <ion-col>
          <ion-button
            class="ion-margin"
            expand="block"
            [fill]="
              (selectedPrice$ | async)?.sku === price.sku ? 'solid' : 'outline'
            "
            (click)="selectPrice(price)"
          >
            {{ price.title }}
          </ion-button>
          <ion-text style="font-size: 0.8rem">
            {{ price.subtitle }}
          </ion-text>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-grid>

  <ng-container *ngIf="selectedTier$ | async">
    <ion-button (click)="chooseTier()" [expand]="'full'" class="ion-margin">
      Choose This Plan
    </ion-button>
  </ng-container>
</ion-content>
