import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services';
import { PermissionsService } from '../modules/permissions/permissions.service';
import { PermissionsData } from '../modules/permissions/permissions';

@Injectable({
  providedIn: 'root',
})
export class AdvisorChatGuard {
  constructor(
    private router: Router,
    private user: UserService,
    private permissions: PermissionsService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([
      this.user.user$,
      this.permissions.hasAccessTo(PermissionsData.ADVISOR_CHAT),
    ]).pipe(
      map(([user, hasAccess]) => {
        // If the Transphormer does not have an advisor and has advisor.chat permissions
        // redirect to the Advisor page
        if (
          !user.linked_trainer ||
          user.linked_trainer.status !== 'accepted' ||
          (!user.linked_trainer.trainer && hasAccess)
        ) {
          return this.router.createUrlTree(['/trainer-requests']);
        }

        // If the user does not have access,
        // Show the Preview of Chat with the Upgrade to Premium button
        return true;
      }),
    );
  }
}
