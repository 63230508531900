import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SubscribeSlide } from '../../pages/onboarding/components';
import { QuestionSlide } from '../../../../interfaces';
import {
  AlertController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { AuthenticationService } from '../../../../services';
import subscriptionSlideContent from '../../subscription-slide-content';
import { SwiperContainer } from 'swiper/swiper-element';

@Component({
  selector: 'app-subscription-content',
  templateUrl: './subscription-content.component.html',
  styleUrls: ['./subscription-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionContentComponent implements AfterViewInit {
  @ViewChild('slider') slider: ElementRef | undefined;
  private swiperContainer?: SwiperContainer;

  public activeSlide$ = new BehaviorSubject<SubscribeSlide>(null);

  slides: SubscribeSlide[] = subscriptionSlideContent;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private authService: AuthenticationService,
    private navController: NavController,
  ) {
    this.activeSlide$.next(this.slides[0]);
  }

  ngAfterViewInit() {
    this.setSwiperInstance();
  }

  setSwiperInstance() {
    this.swiperContainer = this.slider.nativeElement;
    this.swiperContainer.swiper.on('slideChangeTransitionEnd', () => {
      this.changeIndex();
    });
  }

  changeIndex() {
    this.activeSlide$.next(
      this.slides[this.swiperContainer.swiper.activeIndex],
    );
  }

  trackBySlide(index: number, item: QuestionSlide) {
    return item.title;
  }

  next() {
    return this.modalController
      .create({
        component: SubscribeComponent,
        backdropDismiss: false,
        cssClass: 'subscriptionModal',
        componentProps: { callBranch: false, canClose: true },
      })
      .then(modal => {
        modal.present().then();
        return modal.onDidDismiss();
      })
      .then(result => {
        if (result.role === 'subscribed') {
          this.modalController.dismiss(null, 'dismissed');
        }
      });
  }

  logout() {
    this.alertController
      .create({
        header: 'Are you sure?',
        message:
          'Are you sure you want to log out? You can restart at any time by logging in again.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Yes',
            role: 'success',
            handler: () => {
              this.modalController.dismiss(null, 'logout');
            },
          },
        ],
      })
      .then(alert => {
        alert.present().then();
      });
  }
}
