import 'cordova-plugin-purchase/www/store.d';

export class SubscriptionOwned {
  static readonly type = '[IAP] SubscriptionOwned';

  constructor(public product: CdvPurchase.Product) {}
}

export class SubscriptionExpired {
  static readonly type = '[IAP] SubscriptionExpired';

  constructor(public product: CdvPurchase.Product) {}
}

export class SubscriptionApplyProducts {
  static readonly type = '[IAP] Subscription Products Apply';

  constructor(public products: CdvPurchase.Product[]) {}
}

export class SubscriptionStateSave {
  static readonly type = '[IAP] SaveState';
}

export class SubscriptionsLoaded {
  static readonly type = '[IAP] Loaded Subscription Old State';
}

export class SubscriptionActivated {
  static readonly type = '[IAP] Activated Subscription';
}

export class SubscriptionDeactivated {
  static readonly type = '[IAP] Deactivated Subscription';
}
