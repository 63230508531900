import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-logo',
  template:
    '<div class="logo">' +
    '<svg viewBox="0 0 1024 1024" width="512px" height="512px" xmlns="http://www.w3.org/2000/svg">\n' +
    '  <g transform="matrix(1.7501282,0,0,1.7501282,55.35655,2.5096282e-7)">\n' +
    '    <polygon points="308.17,220.53 280.65,325.78 464.06,226.9 521.84,0 298.43,0 349.95,60.74 "/>\n' +
    '    <polygon points="27.52,479.85 0,585.1 182.49,486.22 280.65,92.07 58.16,92.07 109.68,152.81 "/>\n' +
    '  </g>\n' +
    '</svg></div>\n',
  styles: [
    `
      :host {
        --width: 80px;
        --margin: auto;
        --color: var(
          --ion-color-base,
          var(--ion-color-organization-contrast, var(--ion-color-dark))
        );
        display: flex;
      }

      :host-context(ion-toolbar ion-buttons) {
        --padding-start: 5px;
        --padding-end: 5px;
        margin: 0;
        --width: 1.5em;
      }

      .logo {
        margin: var(--margin);
        box-sizing: content-box;
        width: var(--width);
        height: var(--width);
        display: inline-block;
        padding-inline-start: var(--padding-start, 0);
        padding-inline-end: var(--padding-end, 0);
      }

      svg {
        width: 100%;
        height: 100%;
        fill: var(--color);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {}
