import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { BrowserService } from '../../services/browser/browser.service';

@Directive({
  selector: '[appExternalLink]',
})
export class ExternalLinkDirective implements OnInit {
  @Input() href: string;

  constructor(
    private browserService: BrowserService,
    public element: ElementRef,
  ) {
    const link = <HTMLLinkElement>this.element.nativeElement;
    link.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      this.browserService.create(this.href);
    });
  }

  ngOnInit() {
    this.element.nativeElement.innerHTML = `${this.element.nativeElement.innerHTML} <ion-icon name="exit"></ion-icon>`;
  }
}
