export class LoadVideos {
  static readonly type = '[Videos] Load videos';
  constructor(
    public limit: number = 25,
    public page: number = 1,
    public append: boolean = true,
  ) {}
}

export class Reset {
  static readonly type = '[Videos] Reset state';
}
