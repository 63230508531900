import { Units, UnitTypes } from '../../../interfaces';

export enum MetricTypes {
  Neck = 1,
  Bicep,
  Chest,
  Waist,
  Hips,
  Thigh,
  Calves,
}

export interface MetricTypeName {
  name: string;
  value: MetricTypes;
  icon: string;
}

export const MetricTypeNames: MetricTypeName[] = [
  { name: 'Neck', value: MetricTypes.Neck, icon: 'assets/icon/neck.svg' },
  { name: 'Bicep', value: MetricTypes.Bicep, icon: 'assets/icon/bicep.svg' },
  { name: 'Chest', value: MetricTypes.Chest, icon: 'assets/icon/chest.svg' },
  { name: 'Waist', value: MetricTypes.Waist, icon: 'assets/icon/waist.svg' },
  { name: 'Hips', value: MetricTypes.Hips, icon: 'assets/icon/hips.svg' },
  { name: 'Thigh', value: MetricTypes.Thigh, icon: 'assets/icon/thigh.svg' },
  {
    name: 'Calves',
    value: MetricTypes.Calves,
    icon: 'assets/icon/calves.svg',
  },
];

export interface BodyMetricGrouping {
  label: string;
  data: BodyMetric[];
}

export interface BodyMetricEntry {
  body_metric_type: MetricTypes;
  metric_value: number;
  unit_type: UnitTypes;
}

export interface BodyMetric<T = Date> extends Units, BodyMetricEntry {
  id: number;
  dateString?: string;
  logged_on: T;
  created_at: T;
  updated_at: T;
}
