import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MealTemplatesService } from '../../../services';
import { ErrorsService, ToastService } from '../../../dependencies';
import { FoodItem, MealTemplate, TrackedMeal } from '../../../interfaces';

@Component({
  selector: 'app-meal-template-name',
  templateUrl: './meal-template-name.component.html',
  styleUrls: ['./meal-template-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MealTemplateNameComponent implements OnInit {
  public spinner = false;

  @Input()
  public meal: MealTemplate<FoodItem>;

  @Input()
  public trackedMeal: TrackedMeal;

  @Input() type = 'Meal';

  public form = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(250),
    ]),
  });

  constructor(
    public modalController: ModalController,
    private toastService: ToastService,
    private errorService: ErrorsService,
    private mealTemplateService: MealTemplatesService,
  ) {}

  ngOnInit() {
    this.form.patchValue({
      name: this.meal ? this.meal.name : '',
    });
  }

  public async save() {
    if (this.meal) {
      this.spinner = true;
      try {
        const meal = await this.mealTemplateService
          .update(this.meal.id, this.form.get('name').value)
          .toPromise();
        await this.toastService.flash('Meal name updated successfully.');
        await this.modalController.dismiss(meal);
      } catch (e) {
        await this.toastService.flash(this.errorService.firstError(e));
      } finally {
        this.spinner = false;
      }
    } else {
      await this.modalController.dismiss(this.form.get('name').value);
    }
  }

  public close() {
    this.modalController.dismiss(false).then();
  }

  @HostListener('document:backbutton', ['$event'])
  public backButtonHandler($event) {
    $event.preventDefault();
    this.close();
  }
}
