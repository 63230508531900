import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { WrappedApiPaginatedResponse } from '../../../interfaces';
import { handleApiError } from '../../../helpers/operators';
import { StepsLeaderboard, StepsLeaderboardRecord } from '../types';
import { map } from 'rxjs/operators';
import { apiUrl } from '../../../helpers';

@Injectable()
export class StepsLeaderboardService {
  constructor(protected http: HttpClient) {}

  public getLeaderboards(page = 1) {
    const params: { page?: number } = {};

    if (page) {
      params.page = page;
    }

    return this.http
      .get<
        WrappedApiPaginatedResponse<StepsLeaderboard[]>
      >(apiUrl('steps/leaderboards'), { params })
      .pipe(
        map(
          leaderboards =>
            ({
              data: leaderboards.data,
              meta: leaderboards.meta,
            }) as WrappedApiPaginatedResponse<StepsLeaderboard[]>,
        ),
        handleApiError(),
      );
  }

  public getLeaderboard(id: number, page = 1) {
    const params = { page };

    return this.http
      .get<
        WrappedApiPaginatedResponse<StepsLeaderboardRecord[]>
      >(apiUrl(`steps/leaderboard/${id}/entries`), { params })
      .pipe(handleApiError());
  }
}
