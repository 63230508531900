import { Weight } from '../../../interfaces';
import {
  CategoryTypes,
  MealItem,
  MealType,
  NutritionItem,
  NutritionTypes,
  SimpleMeal,
  SimpleNutritionData,
  SupplementsConfig,
} from './simple';
import {
  AlternativeServings,
  BrandedFoodSearchResult,
  CommonFoodSearchResult,
} from '../services/nutritionix/types';
import { CustomFoodTemplate } from '../services/custom-food-template/custom-food-template.service';
import { FoodItem, MacroCountingInfo, Meal } from './legacy';
import { MacroWindowConfiguration } from '../components/nutrition-detail/functions';

export { CustomFoodTemplate } from '../services/custom-food-template/custom-food-template.service';

export { Meal, MacroCountingInfo, FoodItem };

export {
  CategoryTypes,
  NutritionTypes,
  NutritionItem,
  MealItem,
  MealType,
  SimpleMeal,
  SimpleNutritionData,
};

export { AlternativeServings as AltServing };

/**
 * The 3 main plans that are available in the application.
 */
export enum NutritionPlan {
  MacroMealPlan = 'Macro meal plan',
  CalorieMacroCounting = 'Calorie / Macro counting',
  PortionControl = 'Portion control',
}

/**
 *
 */
export interface ServingInfo {
  id: number;
  tracked_item_id: number;
  fiber?: number;
  fats: number;
  carbs: number;
  protein: number;
  calories: number;
  serving_amount: number;
  serving_unit: string;
  alt_servings: AlternativeServings[];
}

export interface TrackedMeal {
  meal_count: number;
  food_items: TrackedItem[];
  active: boolean;
  macros?: CaloriesMacros;
  numberQuickAddedItems?: number;
  onlyQuickAddedItems?: boolean;
}

export interface UnitAmount {
  amount: number;
  unit: string;
}

/**
 * For items which
 */
export interface ServingSize extends UnitAmount {
  carbs: number;
  calories: number;
  protein: number;
  fats: number;
  fiber: number;
}

export type AmountConsumed = UnitAmount;

/**
 *
 */
export interface CustomMacro extends MacroInfo {
  id: number;
  reset_to_original: boolean;
  transphormer_id?: number;
  edit_date: Date;
  base_weight?: Weight;
}

export function isCustomMacro(val): val is CustomMacro {
  return typeof val.reset_to_original === 'boolean';
}

export interface NutritionData {
  nutrition_day_id?: number;
  food: TrackedItem[];
  supplements?: CmcSupplementTracker[];
}

// Coming from the API's unified endpoint
export interface NutritionResponse<T = SimpleNutritionData | NutritionData> {
  id: number;
  track_date: string;
  type: NutritionPlan;
  transphormer_id: number;
  data: T;
  water: number; // Added
  configuration: NutritionConfiguration; // <-- macros for the day are here (moved from .data)
  created_at?: string;
  updated_at?: string;
}

export type CmcNutrition = NutritionResponse<NutritionData>;

export interface SimpleNutritionResponse extends NutritionResponse {
  data: SimpleNutritionData;
}

export interface NutritionDay extends NutritionResponse {
  isComplex?: boolean;
}

export interface MealConfig {
  meal: number;
  expanded: boolean;
}

export interface NutritionConfiguration {
  water_goal?: number;
  water_goal_unit?: string;
  macros?: MacroInfo;
  supplements?: SupplementsConfig;
  cmcSupplements?: CmcSupplementConfig[];
  meals: MealConfig[];
  macroWindows?: MacroWindowConfiguration;
}

export function isNutrition(
  data: NutritionData | SimpleNutritionData,
): data is NutritionData {
  return (data as NutritionData).food !== undefined;
}

/**
 * Calculated macro information, which is what the application should be looking at whenever Macros are generated.
 */
export interface MacroInfo {
  calories: number;
  percentProtein?: number;
  percentCarbs?: number;
  percentFats?: number;
  fiber?: number;
  protein: number;
  carbs: number;
  fats: number;
}

/**
 * When looking at
 */
export interface CaloriesMacros<T = number> {
  carbs: T;
  calories: T;
  protein: T;
  fats: T;
  fiber: T;
}

export interface MealTemplate<T = TrackedItem> {
  id: number;
  name: string;
  items: T[];
  transphormer_id: number;
  total_count?: number;
  created_at?: string;
  updated_at?: string;
}

export type FoodSearchResult =
  | { type: 'custom'; data: CustomFoodTemplate; id: string }
  | { type: 'branded'; data: BrandedFoodSearchResult; id: string }
  | { type: 'common'; data: CommonFoodSearchResult; id: string }
  | { type: 'meal'; data: MealTemplate; id: string }
  | { type: 'recipe'; data: Recipe; id: string };

export type FoodSearchResultItem =
  | { type: 'custom'; data: CustomFoodTemplate; id: string | number }
  | { type: 'branded'; data: BrandedFoodSearchResult; id: string | number }
  | { type: 'common'; data: CommonFoodSearchResult; id: string | number }
  | { type: 'meal'; data: MealTemplate; id: string | number };

export interface Recipe extends CaloriesMacros {
  id: number;
  name: string;
  items: TrackedItem[];
  serving_size: UnitAmount;
  picture?: string;
}

export interface NutritionSearchResults {
  branded: BrandedFoodSearchResult[];
  common: CommonFoodSearchResult[];
  customFood: CustomFoodTemplate[];
  meals: MealTemplate<TrackableItem>[];
  recipes: Recipe[];
  trackedFoodIndex: WeightedFoodItemIndexElement[];
}

export type SearchFood =
  | MealTemplate<FoodItem>
  | CustomFoodTemplate
  | CommonFoodSearchResult
  | BrandedFoodSearchResult;

export interface ManagedFoodItemAttributes {
  source: string;
  source_type: string | null;
  source_id: string | null;
}

export interface WeightedFoodItemIndexElement
  extends ManagedFoodItemAttributes {
  weight: number;
}

export interface FoodSourceItem {
  type: string | null;
  id: string | null;
}

export interface TrackableItem extends ManagedFoodItemAttributes {
  name: string;
  brand_name?: string;
  thumbnail: string | null;
  default_consumption_value?: AmountConsumed;
  serving_size: ServingSize;
  servings: AlternativeServings[];
  picture_url?: string;
}

export interface TrackableSourceItem extends FoodSourceItem {
  name: string;
  thumbnail: string | null;
  default_consumption_value?: AmountConsumed;
  serving_size: ServingSize;
  servings: AlternativeServings[];
}

// TrackableItem + AmountConsumed => TrackedItem

export interface TrackedItem<T = string> extends TrackableItem, CaloriesMacros {
  id?: number;
  localId?: string;
  meal_template_id?: number;
  nutrition_day_id?: number;
  created_at?: T;
  updated_at?: T;
  meal?: number;
  consumed: AmountConsumed;
  originalServings?: AlternativeServings[];
}

export interface TrackedMealTemplate
  extends MealTemplate,
    ManagedFoodItemAttributes,
    CaloriesMacros {
  items: TrackedItem[];
  name: string;
  serving_size?: ServingSize;
  consumed: AmountConsumed;
}

export function isTrackedItem(arg): arg is TrackedItem {
  return (
    arg.calories !== undefined &&
    arg.name !== undefined &&
    arg.consumed !== undefined &&
    arg.protein !== undefined
  );
}

export function isTrackedMeal(arg): arg is TrackedMealTemplate {
  return (
    arg.name !== undefined &&
    arg.source_type === 'meal' &&
    Array.isArray(arg.items) &&
    arg.calories !== undefined
  );
}

export interface MealProgress {
  name: string;
  completedItems: number;
  completion: 'finished' | 'partial' | 'none';
}

export interface MacroReportingResponse<T = Date> extends MacroCountingInfo<T> {
  transphormer_id: number;
  created_at: T;
  updated_at: T;
  type: 'mmp' | 'pc' | 'cmc';
  configuration: NutritionConfiguration;
  data?: NutritionData;
}

export type BaseMacroType = 'protein' | 'carbs' | 'fats' | 'fiber';

export type MacroType = BaseMacroType | 'calories';

export function isQuickAddItem(item: TrackedItem) {
  return item.name === 'Quick Add';
}

export interface CmcSupplement {
  id: number;
  name: string;
  is1p: boolean;
  recommended: boolean;
  weight: number;
  group: string;
}

export interface CmcSupplementConfig extends CmcSupplement {
  times: number;
}

export interface CmcSupplementSelection extends CmcSupplementConfig {
  selected: boolean;
}

export interface CmcSupplementTracker extends CmcSupplementConfig {
  taken: number;
}
