import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import {
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { createEntityCollections } from '@angular-ru/cdk/entity';
import { UserProfileResponse } from '../../interfaces/user-profiles';
import { UserProfilesService } from '../../services/user-profiles/user-profiles.service';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
@Persistence({
  path: 'userProfiles',
  existingEngine: localStorage,
})
@StateRepository()
@State({
  name: 'userProfiles',
  defaults: createEntityCollections(),
})
@Injectable()
export class UserProfilesEntitiesState extends NgxsDataEntityCollectionsRepository<UserProfileResponse> {
  #requestedProfiles$ = new BehaviorSubject<number[]>([]);

  #userQueryResults = this.#requestedProfiles$.pipe(
    filter(i => i.length > 0),
    debounceTime(150),
    // Reset it.
    tap(() => this.#requestedProfiles$.next([])),
    switchMap(userIds => this.getUserProfileList(userIds)),
  );

  constructor(private readonly userProfileService: UserProfilesService) {
    super();
    this.#userQueryResults.subscribe();
  }

  loadUserProfile(id: number | number[]) {
    if (!Array.isArray(id)) {
      id = [id];
    }
    // We have a user ID. We want to combine a LOT of user information requests INTO a single request.
    this.#requestedProfiles$.next([
      ...this.#requestedProfiles$.getValue(),
      ...id,
    ]);
  }

  @DataAction()
  public getUserProfileList(
    @Payload('profiles') profiles: number[],
  ): Observable<void> {
    const uniqueProfiles = this.uniqueProfilesToLoad(profiles);
    const chunkSize = 100;
    const requests: Observable<void>[] = [];
    for (let i = 0; i < uniqueProfiles.length; i += chunkSize) {
      const chunk = uniqueProfiles.slice(i, i + chunkSize);
      requests.push(
        this.userProfileService.getUserProfiles(chunk).pipe(
          map((userProfiles: UserProfileResponse[]) =>
            this.upsertMany(userProfiles),
          ),
          map(data => data),
        ),
      );
    }

    return forkJoin(requests).pipe(map(() => void 0));
  }

  uniqueProfilesToLoad(ids: number[]): number[] {
    const profilesToLoadNotInState = ids.filter(
      item => !this.ids.includes(item),
    );
    return profilesToLoadNotInState || [];
  }
}
