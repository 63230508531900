import { AssessmentConfig } from '../types';

export class LoadAdvisorConfig {
  static readonly type = '[Assessments Config] Load Advisor Config';

  constructor(public advisorId: number) {}
}

export class LoadMyAdvisorConfig {
  static readonly type = '[Assessment Config] Load My Advisor Config';

  constructor(public myId: number) {}
}

export class UpdateAdvisorConfigQuestions {
  static readonly type = '[Assessments Config] Update Advisor Config';

  constructor(
    public configId: string | number,
    public questions,
  ) {}
}

export class UpdateDaysAllowed {
  static readonly type = '[Assessments Config] Update Days Allowed';

  constructor(
    public configId: number,
    public daysAllowed: Partial<AssessmentConfig>,
  ) {}
}

export class UpdateMyDaysAllowed {
  static readonly type = '[Assessment Config] Update My Days Allowed';

  constructor(
    public configId: string | number,
    public daysAllowed: Partial<AssessmentConfig>,
  ) {}
}
