import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentViewComponent } from './assessment-view.component';
import { IonicModule } from '@ionic/angular';
import { NgxLaunchDarklyModule } from '../../../../modules/launchdarkly/ngx-launchdarkly.module';
import { ConvertUnitModule } from '../../../../pipes/convert-unit/convert-unit.module';
import { AnswersListModule } from '../assessment/answers-list/answers-list.module';
import { NutritionDetailModule } from '../../../nutrition/components/nutrition-detail/nutrition-detail.module';
import { WorkoutViewLegacyModule } from '../../../training/components/advisor-workout-view/advisor-workout-view.module';
import { ProgressComparisonComponent } from '../../../../components/progress-comparison/progress-comparison.component';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [AssessmentViewComponent, ProgressComparisonComponent],
  imports: [
    CommonModule,
    IonicModule,
    ConvertUnitModule,
    NgxLaunchDarklyModule,
    AnswersListModule,
    NutritionDetailModule,
    WorkoutViewLegacyModule,
    NgChartsModule,
  ],
  exports: [AssessmentViewComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AssessmentViewModule {}
