import { TierItemComponent } from './tier-item/tier-item.component';
import { SubscriptionsTieredComponent } from './subscriptions-tiered/subscriptions-tiered.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [TierItemComponent, SubscriptionsTieredComponent],
  exports: [TierItemComponent, SubscriptionsTieredComponent],
})
export class TiersModule {}
