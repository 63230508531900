<ion-progress-bar
  *ngIf="transfersVisible && (transfersInProgress$ | async)"
  [value]="(avgTransfersProgress$ | async) / 100"
></ion-progress-bar>

<ng-container *ngxLaunchDarkly="'chat-3--pending-uploads-ui'">
  <!-- Only display this on trainer accounts -->
  <ng-container *ngIf="(user$ | async)?.trainer">
    <app-pending-messages></app-pending-messages>
  </ng-container>
</ng-container>

<nav class="nav-container">
  <ng-container *ngxLaunchDarkly="'feed--display-feed-in-menu'">
    <div
      class="nav-item ion-tappable"
      [routerDirection]="'root'"
      routerLinkActive="active"
      routerLink="today"
    >
      <ion-badge
        data-test-id="community-count"
        mode="ios"
        *ngIf="(unreadTransphormerAnnouncements$ | async) > 0"
      >
        {{ unreadTransphormerAnnouncements$ | async }}
      </ion-badge>
      <div class="icon">
        <ion-icon name="people-outline"></ion-icon>
      </div>
      <div class="nav-name">Community</div>
    </div>
  </ng-container>
  <ng-container *ngxLaunchDarkly="'feed--display-feed-in-menu'; value: false">
    <div
      class="nav-item ion-tappable"
      [routerDirection]="'root'"
      routerLinkActive="active"
      routerLink="dashboard"
    >
      <div class="icon">
        <ion-icon name="grid-outline"></ion-icon>
      </div>
      <div class="nav-name">Dashboard</div>
    </div>
  </ng-container>
  <div
    class="nav-item ion-tappable"
    [matchMulti]="['^/activity', '^/workout']"
    appActiveRoute
    [routerDirection]="'root'"
    routerLinkActive="active"
    routerLink="activity"
  >
    <div class="icon">
      <ion-icon name="barbell-outline"></ion-icon>
    </div>
    <div class="nav-name">Activity</div>
  </div>
  <ng-container *ngxLaunchDarkly="'feed--display-feed-in-menu'">
    <div
      class="nav-item ion-tappable"
      [routerDirection]="'root'"
      routerLinkActive="active"
      routerLink="dashboard"
    >
      <div class="icon">
        <ion-icon src="assets/logo/bottomIcon.svg"></ion-icon>
      </div>
      <div class="nav-name">Today</div>
    </div>
  </ng-container>
  <div
    class="nav-item ion-tappable"
    [routerDirection]="'root'"
    routerLinkActive="active"
    routerLink="nutrition"
  >
    <div class="icon">
      <ion-icon name="restaurant-outline"></ion-icon>
    </div>
    <div class="nav-name">Nutrition</div>
  </div>
  <div
    class="nav-item ion-tappable"
    [routerDirection]="'root'"
    routerLinkActive="active"
    routerLink="messages"
  >
    <div class="icon">
      <ion-icon name="chatbubbles-outline"></ion-icon>
    </div>
    <div class="nav-name">Chat</div>
    <ion-badge mode="ios" *ngIf="(messageCount$ | async) > 0">
      {{ messageCount$ | async }}
    </ion-badge>
  </div>
  <ng-container
    *ngxLaunchDarkly="
      'menu--select-area-where-menu-displays';
      value: 'bottom-nav'
    "
  >
    <div
      class="nav-item ion-tappable"
      appActiveRoute
      [matchMulti]="moreActiveUrls"
      (click)="openMoreMenu()"
    >
      <div class="icon">
        <ion-icon name="ellipsis-horizontal"></ion-icon>
      </div>
      <div class="nav-name">Menu</div>
      <ion-badge
        mode="ios"
        data-test-id="expanded-menu-count"
        *ngIf="(menuCount$ | async) > 0"
        >{{ menuCount$ | async }}</ion-badge
      >
    </div>
  </ng-container>
</nav>
