import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IonContent } from '@ionic/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { privacyText } from '../helpers';
import {
  PaymentTier,
  PaymentTierPrice,
  PaymentTierWithPricing,
} from '../interfaces';

@Component({
  selector: 'app-subscriptions-tiered',
  templateUrl: './subscriptions-tiered.component.html',
  styleUrls: ['./subscriptions-tiered.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsTieredComponent {
  @Input() set tiers(tiers: PaymentTier[]) {
    if (!tiers) {
      return;
    }
    this.tiers$ = of(tiers);
  }

  @Output() selectProduct: EventEmitter<PaymentTierPrice> =
    new EventEmitter<PaymentTierPrice>();
  @Output() upgrade: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('content', { static: true }) content: IonContent;

  tiers$: Observable<PaymentTier[]>;
  selectedTier$ = new BehaviorSubject<PaymentTier>(null);
  selectedPrice$ = new BehaviorSubject<PaymentTierPrice>(null);

  readonly privacyText = privacyText;

  toggleTier(tier: PaymentTierWithPricing) {
    // It's possible that a tier doesn't have a price associated with it.
    // e.g. Admin Overrides. Also, let's not allow users to unselect a tier.
    if (!tier.prices?.length) {
      return;
    }

    // If the tier checkbox they tapped on is the same as their selected tier,
    // just return
    if (tier.id === this.selectedTier$.value?.id) {
      this.selectedTier$.next(null);
      this.selectPrice(null);
      return;
    }

    this.selectedTier$.next(tier);
    this.selectPrice(tier.prices[0]);

    // this timeout is here to allow the UI to reflect the changes
    // after changing the selectedTier$. The page's height is taller
    // and if we don't time out, it will scroll to the previous height.
    setTimeout(() => {
      this.scrollToBottom();
    }, 250);
  }

  selectPrice(price: PaymentTierPrice) {
    this.selectedPrice$.next(price);
    this.selectProduct.emit(price);
  }

  chooseTier() {
    this.upgrade.emit();
  }

  scrollToBottom() {
    this.content.scrollToBottom();
  }
}
