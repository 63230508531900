import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AssessmentViewComponent } from '../modules/assessments-v2/components/assessment-view/assessment-view.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AnswersListModule } from '../modules/assessments-v2/components/assessment/answers-list/answers-list.module';
import { NutritionDetailModule } from '../modules/nutrition/components/nutrition-detail/nutrition-detail.module';
import { ConvertUnitModule } from '../pipes/convert-unit/convert-unit.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FiveStarRatingComponent } from './five-star-rating/five-star-rating.component';
import { CircleGaugeComponent } from './circle-gauge/circle-gauge.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { CreateFilterComponent } from './create-filter/create-filter.component';
import { PhotoImageComponent } from './photo-image/photo-image.component';
import { ZoomImgModule } from './zoom-img/zoom-img.module';
import { DynamicListingComponent } from './dynamic-listing/dynamic-listing.component';
import { PhotosListItemComponent } from './photos-list-item/photos-list-item.component';
import { PhotoItemOptionsComponent } from './photos-list-item/photo-item-options/photo-item-options.component';
import { SmartAudioService } from '../services/smart-audio/smart-audio.service';
import { UserProfilePictureComponent } from './user-profile-picture/user-profile-picture.component';
import { WorkoutViewLegacyModule } from '../modules/training/components/advisor-workout-view/advisor-workout-view.module';
import { IntersectionObserverModule } from '../directives/intersect-observer/intersection-observer.module';
import { TransphormerAdvisorLabelListingComponent } from './tags-listing/transphormer-advisor-label-listing.component';
import { LabelComponent } from './label/label.component';
import { AssessmentViewModule } from '../modules/assessments-v2/components/assessment-view/assessment-view.module';
import { LogoModule } from './logo/logo.module';
import { FiveStarRatingChat3Component } from './five-star-rating-3/five-star-rating.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    AnswersListModule,
    NutritionDetailModule,
    ConvertUnitModule,
    ZoomImgModule,
    IntersectionObserverModule,
    WorkoutViewLegacyModule,
    AssessmentViewModule,
    LogoModule,
  ],
  declarations: [
    ChangePasswordComponent,
    FiveStarRatingComponent,
    FiveStarRatingChat3Component,
    CircleGaugeComponent,
    CreateFilterComponent,
    ProgressBarComponent,
    PhotoImageComponent,
    PhotosListItemComponent,
    PhotoItemOptionsComponent,
    DynamicListingComponent,
    UserProfilePictureComponent,
    LabelComponent,
    TransphormerAdvisorLabelListingComponent,
  ],
  exports: [
    AssessmentViewComponent,
    CreateFilterComponent,
    ChangePasswordComponent,
    FiveStarRatingComponent,
    FiveStarRatingChat3Component,
    CircleGaugeComponent,
    ProgressBarComponent,
    PhotoImageComponent,
    DynamicListingComponent,
    PhotosListItemComponent,
    UserProfilePictureComponent,
    LabelComponent,
    TransphormerAdvisorLabelListingComponent,
  ],
  providers: [SmartAudioService],
})
export class AppComponentsModule {}
