import { v1 } from 'uuid';
import { HttpErrorResponse } from '@angular/common/http';

export enum TransferType {
  Announcement = 'Announcement',
  Message = 'Message',
  WorkoutPhoto = 'WorkoutPhoto',
  ProgressVideo = 'ProgressVideo',
  ActivityFeed = 'ActivityFeed',
  ActivityFeedComment = 'ActivityFeedComment',
  MealPhoto = 'MealPhoto',
}

export enum TransferStreamType {
  Download = 'Download',
  Upload = 'Upload',
}

export enum TransferState {
  Pending,
  Sending,
  Receiving,
  Completed,
  Error,
}

// This interface now includes some items that were removed as part of merging MTST2-2506 into master.
// Leaving these in for now and noting the changes, as we may come back later and re-use them.
export interface Transfer<T = unknown, U = T> {
  id?: string;
  name?: string;
  fileUrl?: string;
  state: TransferState;
  progress?: number;
  progressTimestamp?: number;
  streamType?: TransferStreamType;
  type: TransferType;
  context?: Partial<T>;
  result?: U;
  error?: HttpErrorResponse;
  retryCount?: number;
  formData?: FormData;
  remoteUri?: string;
  mimeType?: string;
  file?: File;
}

export function messageTransfer(context = {}): Transfer {
  return {
    context,
    id: v1(),
    name: v1(),
    progress: 0,
    state: TransferState.Pending,
    type: TransferType.Message,
  };
}
