import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { AssessmentConfig, TimedAssessmentConfig } from '../types';
import { Injectable } from '@angular/core';
import { AssessmentsService } from '../assessments.service';
import { HttpClient } from '@angular/common/http';
import {
  LoadAdvisorConfig,
  LoadMyAdvisorConfig,
  UpdateAdvisorConfigQuestions,
  UpdateDaysAllowed,
  UpdateMyDaysAllowed,
} from './assessment-config.actions';
import { map } from 'rxjs/operators';
import produce from 'immer';
import { differenceInMinutes, getDay } from 'date-fns';

export const ASSESSMENT_CONFIG_STATE_TOKEN =
  new StateToken<AssessmentConfigStateModel>('assessment_config');

export interface AssessmentConfigStateModel {
  advisorAssessmentConfigs: TimedAssessmentConfig<Date>; // This will store their Advisors' Assessment Config
  myAssessmentConfig: AssessmentConfig; // If they are an Advisor, this will populate
  isLoading: boolean;
}

export const ASSESSMENT_CONFIG_STATE_DEFAULT = <AssessmentConfigStateModel>{
  advisorAssessmentConfigs: null,
  isLoading: false,
  myAssessmentConfig: null,
};

@State<AssessmentConfigStateModel>({
  name: ASSESSMENT_CONFIG_STATE_TOKEN,
  defaults: ASSESSMENT_CONFIG_STATE_DEFAULT,
})
@Injectable({
  providedIn: 'root',
})
export class AssessmentConfigState {
  constructor(
    protected http: HttpClient,
    private assessmentsService: AssessmentsService,
  ) {}

  @Selector()
  static isLoading(state: AssessmentConfigStateModel) {
    return state.isLoading;
  }

  @Selector([AssessmentConfigState])
  static currentConfig(
    state: AssessmentConfigStateModel,
  ): TimedAssessmentConfig<Date> {
    if (!state.advisorAssessmentConfigs) {
      return null;
    }

    return fixupConfigQuestions(state.advisorAssessmentConfigs);
  }

  @Selector([AssessmentConfigState])
  static myCurrentConfig(state: AssessmentConfigStateModel) {
    return fixupConfigQuestions(state.myAssessmentConfig);
  }

  static daysTillAssessmentAllowedWithDate(date = new Date()) {
    return createSelector(
      [AssessmentConfigState.currentConfig],
      (state): number => {
        const currentDay = getDay(date);
        const daysAllowed = state.days_allowed;
        const closest = daysAllowed.filter(da => +da >= currentDay);
        if (!closest.length) {
          return +daysAllowed.filter(da => +da >= 0)[0] + (7 - currentDay);
        }

        return closest[0] - currentDay;
      },
    );
  }

  @Selector([AssessmentConfigState.currentConfig])
  static daysTillAssessmentAllowed(
    config: AssessmentConfigStateModel['advisorAssessmentConfigs'],
  ): number {
    const currentDay = getDay(new Date());
    const daysAllowed = config.days_allowed;
    const closest = daysAllowed.filter(da => +da >= currentDay);
    if (!closest.length) {
      return +daysAllowed.filter(da => +da >= 0)[0] + (7 - currentDay);
    }

    return closest[0] - currentDay;
  }

  @Action(UpdateAdvisorConfigQuestions)
  public updateAdvisorConfig(
    ctx: StateContext<AssessmentConfigStateModel>,
    { configId, questions }: UpdateAdvisorConfigQuestions,
  ) {
    this.assessmentsService
      .updateAssessmentConfig(configId, questions)
      .subscribe(() => {
        ctx.setState(
          produce((draft: AssessmentConfigStateModel) => {
            draft.myAssessmentConfig = {
              ...draft.myAssessmentConfig,
              ...questions,
            };
          }),
        );
      });
  }

  @Action(UpdateDaysAllowed)
  public updateDaysAllowed(
    ctx: StateContext<AssessmentConfigStateModel>,
    { configId, daysAllowed }: UpdateDaysAllowed,
  ) {
    this.assessmentsService
      .updateAssessmentConfig(configId, daysAllowed)
      .subscribe(() => {
        ctx.setState(
          produce((draft: AssessmentConfigStateModel) => {
            draft.advisorAssessmentConfigs = {
              ...draft.advisorAssessmentConfigs,
              ...daysAllowed,
            };
          }),
        );
      });
  }

  @Action(UpdateMyDaysAllowed)
  public updateMyDaysAllowed(
    ctx: StateContext<AssessmentConfigStateModel>,
    { configId, daysAllowed }: UpdateMyDaysAllowed,
  ) {
    this.assessmentsService
      .updateAssessmentConfig(configId, daysAllowed)
      .subscribe(() => {
        ctx.setState(
          produce((draft: AssessmentConfigStateModel) => {
            draft.myAssessmentConfig = {
              ...draft.myAssessmentConfig,
              ...daysAllowed,
            };
          }),
        );
      });
  }

  @Action(LoadMyAdvisorConfig)
  public loadMyAdvisorConfig(
    ctx: StateContext<AssessmentConfigStateModel>,
    { myId }: LoadMyAdvisorConfig,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    return this.assessmentsService
      .retrieveAssessmentConfig(myId)
      .pipe(
        map(ac => {
          ctx.setState(
            produce((draft: AssessmentConfigStateModel) => {
              draft.isLoading = false;
              draft.myAssessmentConfig = ac;
            }),
          );
          return ac;
        }),
      )
      .subscribe();
  }

  @Action(LoadAdvisorConfig)
  public loadAdvisorConfig(
    ctx: StateContext<AssessmentConfigStateModel>,
    { advisorId }: LoadAdvisorConfig,
  ) {
    const advisorAssessmentConfigs = ctx.getState().advisorAssessmentConfigs;

    // If it has been less than 15 minutes since the last call, just return that data instead of calling the API
    if (
      advisorAssessmentConfigs &&
      differenceInMinutes(new Date(), advisorAssessmentConfigs.retrieved_at) <
        15
    ) {
      return;
    }

    ctx.patchState({
      isLoading: true,
    });

    return this.assessmentsService
      .retrieveAssessmentConfig(advisorId)
      .pipe(
        map(ac => {
          ctx.setState(
            produce((draft: AssessmentConfigStateModel) => {
              draft.isLoading = false;
              draft.advisorAssessmentConfigs = {
                ...ac,
                retrieved_at: new Date(),
              } as TimedAssessmentConfig<Date>;
            }),
          );
          return ac;
        }),
      )
      .subscribe();
  }
}

// Take up to 3 questions, and pad with nulls.
const fixupQuestions = q => [...(q ?? []), ...[null, null, null]].slice(0, 3);

function fixupConfigQuestions<T extends AssessmentConfig>(c: T): T {
  return {
    ...c,
    initial_questions: fixupQuestions(c.initial_questions),
    current_questions: fixupQuestions(c.current_questions),
  };
}
