<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Create Template</ion-title>
    <ion-buttons slot="end">
      <app-logo></app-logo>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<section class="body ion-padding">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div>
      <ion-label>Name</ion-label>
      <ion-input formControlName="name"></ion-input>
    </div>

    <ion-button type="button" color="primary" expand="full" (click)="submit()"
      >Save</ion-button
    >
  </form>
</section>
