import { Injectable } from '@angular/core';
import { UserService } from './user/user.service';
import { StorageService } from './storage.service';

export const APPLICATION_DEBUGGING = 'application-debugging';
export const APPLICATION_DEBUGGING_EXPIRATION =
  'application-debugging-expiration';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  private data = {};
  private loaded = false;

  debugging = false;

  constructor(
    private user: UserService,
    private storageService: StorageService,
  ) {
    this.user.user$.subscribe(transphormer => {
      if (transphormer === null) {
        this.data = [];
        this.loaded = false;
        this.debug('UserPrefs: logged out()');
      } else {
        this.loadPrefs(transphormer);
        this.debug('UserPrefs: logged in()');
      }
    });
  }

  debug(...args) {
    if (this.debugging) {
      console.log(...args);
    }
  }

  getAsync<T>(key: string, defaultValue = null): Promise<T> {
    const MAX_ITERATIONS = 10;
    let currentIterations = 0;

    this.debug(`UserPrefs: getAsync(${key}, ${defaultValue}) -- STARTED`);
    return new Promise(resolve => {
      const i = setInterval(() => {
        if (this.loaded) {
          clearInterval(i);
          const val = this.get(key, defaultValue);
          this.debug(`UserPrefs: getAsync(${key}, ${defaultValue}) = ${val}`);
          resolve(val);
        }
        currentIterations++;
        if (currentIterations >= MAX_ITERATIONS) {
          clearInterval(i);
          resolve(defaultValue);
        }
      }, 200);
    });
  }

  get(key: string, defaultValue = null) {
    const val = this.data[key] || defaultValue;
    this.debug(`UserPrefs: get(${key}, ${defaultValue}) = ${val}`);
    return val;
  }

  set(key: string, value) {
    this.debug(`UserPrefs: set(${key}, ${JSON.stringify(value)})`);
    this.data[key] = value;
    this.storageService.set(`prefs.${this.user.user.id}`, this.data);
  }

  async loadPrefs(user) {
    this.debug(`UserPrefs: start loadPrefs(prefs.${user.id})`);
    this.data = (await this.storageService.get(`prefs.${user.id}`)) || {};
    this.debug('UserPrefs: data: ', this.data);
    this.loaded = true;
    this.debug(`UserPrefs: completed loadPrefs(prefs.${user.id})`, this.data);
  }
}
