import { DatePeriod, HealthDataType } from '../types';

export class SetActiveDataType {
  static readonly type = '[Pedometer] Set active data type';
  constructor(public dataType: HealthDataType) {}
}

export class SetActiveDatePeriod {
  static readonly type = '[Pedometer] Set active date period';
  constructor(
    public datePeriod: DatePeriod,
    public startDate?: Date,
    public endDate?: Date,
  ) {}
}
