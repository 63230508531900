import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { AuthenticationService, UserService } from '../../services';
import { filter, map, switchMap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { distinctUntilKeysChanged } from '../../helpers/operators';
import { SubscriptionContentComponent } from '../../modules/onboarding-2022/onboarding-modals/subscription-content/subscription-content.component';

@Injectable({
  providedIn: 'root',
})
export class SubscribersOnlyGuard {
  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private route: Router,
    private modalController: ModalController,
  ) {}

  openSubscribe(): Promise<UrlTree | boolean> {
    return this.modalController
      .create({
        component: SubscriptionContentComponent,
        backdropDismiss: false,
        cssClass: 'subscriptionModal',
      })
      .then(modal => {
        modal.present().then();
        return modal.onDidDismiss();
      })
      .then(result => {
        switch (result.role) {
          // Return true to allow the navigation to continue if the user successfully goes premium.
          // case 'subscribed': return true;
          case 'logout':
            return this.authService
              .logout()
              .toPromise()
              .then(() => this.route.parseUrl('/login'));
        }
      });
  }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userService.user$.pipe(
      filter(user => user !== null),
      distinctUntilKeysChanged(['id', 'is_paid_user']),
      filter(user => user.profile_complete),
      map(user => user.is_paid_user),
      switchMap(isPaid => {
        if (isPaid) {
          return of(true);
        }

        // Here, we leverage Angular Guard's functionality where if a guard does
        // not return anything, the observable remains open and can handle re-emits.
        // Since this only returns something if the user chooses to log out, we
        // allow the user service to go premium which will then re-emit from user.user$ above
        // and then the above code will fire off, and we will navigate to the intended
        // destination. Otherwise, the redirect to /login will occur and navigation will
        // be cancelled.
        return from(this.openSubscribe());
      }),
    );
  }
}
