import { SafeResourceUrl } from '@angular/platform-browser';
import { Transfer } from '../../../interfaces';
import { v1 } from 'uuid';
import { NutritionPhotoUploadContext } from './meal-photo-uploader.service';
import { PickedFile } from '../../../../../capacitor/media-picker';
import {
  FileUploadInfo,
  getFileNameFromUri,
  getMimeTypeFromFileUri,
} from '../../../helpers';

export { BarcodeScannerService } from './barcode-scanner/barcode-scanner.service';
export { CustomFoodTemplateService } from './custom-food-template/custom-food-template.service';
export { CustomMacroService } from './custom-macro/custom-macro.service';
export { FoodItemsService } from './food-items/food-items.service';
export { MealTemplatesService } from './meal-templates/meal-templates.service';
export { MacroCalculatorService } from './macro-calculator/macro-calculator.service';
export { NutritionixService } from './nutritionix/nutritionix.service';
export { RecipeService } from './recipe/recipe.service';

export interface NutritionPhoto {
  uuid: string;
  url: string;
  thumbnail_url: string;
  mime?: string;
  type: string;
  previewUri?: SafeResourceUrl;
  thumb_url?: string;
  transferIds?: { uuid: string; type: string }[];
  uploads?: { uuid: string; type: 'thumbnail' | 'file' }[];
  remoteUris?: { uri: string; type: 'thumbnail' | 'file' }[];
  native_thumb_url?: string;
  fileUrl?: string;
  file: File;
  fallback?: string;
  image_url?: string;
  file_size?: number;
  mime_type?: string;
  asset_url?: string;
  title?: string;
  state: 'error' | 'success' | 'uploading';
}

export interface NutritionPhotoUpload {
  uuid?: string;
  url?: string;
  thumbnail_url?: string;
  native_thumb_url?: string;
  native_file_uri?: string;
  state?: string;
  type: string;
  file?: File;
  transferIds?: { type: string; uuid: string }[]; // Transfer id for the file and thumbnail
  uploadUuids?: { type: string; uuid: string }[]; // Transfer UUID for the file and thumbnail
  remoteUris?: { type: string; uri: string }[]; // remote uris for the file and thumbnail
  fileUrl?: string;
  mime?: string;
  previewUri?: string | SafeResourceUrl;
}

export function createNutritionPhotoAttachment(
  transfer: Transfer<NutritionPhotoUploadContext>,
): NutritionPhoto {
  return {
    uuid: v1(),
    type: transfer.context.type,
    file: {
      name: transfer.name,
      type: transfer.mimeType,
    } as File,
    thumbnail_url: null,
    native_thumb_url: null,
    state: 'uploading',
    url: transfer.context.url,
    transferIds: null,
    uploads: null,
    remoteUris: null,
    previewUri: null,
  };
}

/**
 * Get the file UID from an Attachment Feed
 * @param attachment
 */
export function getFileUploadIdFromUpload(attachment: NutritionPhoto) {
  return attachment.uploads.filter(upload => upload.type === 'file')[0].uuid;
}

/**
 * Get the
 * @param attachment
 */
export function getThumbnailUploadIdFromUpload(attachment: NutritionPhoto) {
  return attachment.uploads.filter(upload => upload.type === 'thumbnail')[0]
    .uuid;
}

export function getFileTransferIdFromUpload(attachment: NutritionPhoto) {
  return attachment.transferIds.filter(upload => upload.type === 'file')[0]
    .uuid;
}

export function getThumbnailTransferIdFromUpload(attachment: NutritionPhoto) {
  return attachment.transferIds.filter(upload => upload.type === 'thumbnail')[0]
    .uuid;
}

export function generateTransferFileUpload(
  file: Pick<PickedFile, 'path' | 'mimeType'> | File | string,
): FileUploadInfo {
  if (file instanceof File) {
    return {
      name: file.name,
      mimeType: file.type,
    };
  }

  if (typeof file === 'string') {
    // File URI
    return {
      name: getFileNameFromUri(file),
      mimeType: getMimeTypeFromFileUri(file as string),
      path: file,
    };
  } else {
    return {
      name: getFileNameFromUri(file.path),
      mimeType: file.mimeType,
      path: file.path,
    };
  }
}
