import { NgxsDataEntityCollectionsRepository } from '@angular-ru/ngxs/repositories';
import {
  Computed,
  DataAction,
  Payload,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { createEntityCollections } from '@angular-ru/cdk/entity';
import {
  ChatListFilterByOptions,
  ChatListItem,
  ChatListItemResponse,
  ChatListSortByOptions,
  LatestMessageItemResponse,
  NormalizedChatListResponseData,
} from '../../interfaces/chat';
import { UserProfilesEntitiesState } from '../user-profiles/user-profiles.state';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  tap,
  EMPTY,
  finalize,
} from 'rxjs';
import { LatestMessageEntitiesState } from '../latest-message/latest-message.state';
import { ChatService } from '../../services/chat/chat.service';
import {
  JsonApiObject,
  JsonApiResponse,
} from '../../../assessments-v2/services/assessments/types';
import normalize from 'json-api-normalizer';
import { MessageListResponse } from '../../interfaces/message';
import { UserService } from '../../../../services';
import { MessageAttachment3 } from '../../interfaces/message';
import { MessageAttachmentsState } from '../message-attachments/message-attachments.state';
import { isEmpty } from 'lodash';
import { SortOrderType } from '@angular-ru/cdk/typings';
import { MessageReadStatus } from '../../../../pages/message-center/message-read.status';
import { Team } from '../../../../interfaces';

const PER_PAGE = 150;

function defaultFilters() {
  const f = new Map<string, string>();
  f.set(ChatListFilterByOptions.ADVISOR_UNREAD, undefined);
  f.set(ChatListFilterByOptions.EXCLUDE_MINE, 'true');
  return f;
}

export interface ChatListFilterOptionsStateModel {
  filters: Map<string, string>;
  sortBy: ChatListSortByOptions;
  isNewestFirst: boolean;
  loadNextPage: boolean;
}

@StateRepository()
@State({
  name: 'chat_list',
  defaults: {
    ...createEntityCollections(),
    loading: false,
    pagedData: {
      currentPage: 1,
      lastPage: 1,
      perPage: PER_PAGE,
      total: 0,
    },
    // This won't show up in Redux. Boo.
    filters: defaultFilters(),
    sortBy: ChatListSortByOptions.LAST_MSG,
    isNewestFirst: true,
    loadNextPage: false,
  },
})
@Injectable()
export class ChatListEntitiesState extends NgxsDataEntityCollectionsRepository<ChatListItemResponse> {
  constructor(
    private readonly chatservice: ChatService,
    private userProfileState: UserProfilesEntitiesState,
    private lastMessageState: LatestMessageEntitiesState,
    private userService: UserService,
    private attachmentState: MessageAttachmentsState,
  ) {
    super();
  }

  @DataAction()
  public loadChatList(): Observable<void> {
    const { sortBy, isNewestFirst, filters } = this.snapshot;
    const { pagedData } = this.snapshot;
    this.setLoading(true);
    return this.chatservice
      .getChatList(sortBy, isNewestFirst ? '-' : '', filters, pagedData)
      .pipe(
        map(
          (
            data: JsonApiResponse<
              JsonApiObject<ChatListItemResponse[]>,
              { page: string },
              JsonApiObject<LatestMessageItemResponse[]>
            >,
          ) => {
            const currentPage = data.meta.page['currentPage'];
            const lastPage = data.meta.page['lastPage'];
            const total = data.meta.page['total'];
            const perPage = data.meta.page['perPage'];
            this.patchState({
              pagedData: {
                currentPage,
                lastPage,
                perPage,
                total,
              },
            });
            return data;
          },
        ),
        map(data => this.normalizeChatListData(data)),
        map((data: NormalizedChatListResponseData) => {
          const latestMessages: LatestMessageItemResponse[] =
            this.flattenLatestMessagesForStore(data.normalLatestMessages);
          this.lastMessageState.upsertMany(latestMessages);

          const chats: ChatListItemResponse[] = this.flattenChatListForStore(
            data.normalChatList,
          );

          if (chats?.length > 0) {
            this.upsertMany(chats);
          }

          return chats;
        }),
        map((chats: ChatListItemResponse[]) => {
          if (chats?.length > 0) {
            const transphormerIds = chats.map(chat => chat.transphormerId);
            return this.userProfileState.getUserProfileList(transphormerIds);
          }
          return EMPTY;
        }),

        map(() => void 0),
        finalize(() => {
          this.setLoading(false);
          this.sortDataInStore();
        }),
      );
  }

  @DataAction()
  loadSingleChat(@Payload('transformerId') transformerId: number) {
    return this.chatservice.getSingleTransphormerChat(transformerId).pipe(
      map(resp => {
        if (isEmpty(resp.data)) {
          return null;
        }

        const chatItem: ChatListItemResponse = {
          id: resp.data[0].id,
          ...resp.data[0]['attributes'],
        };

        this.upsertOne(chatItem);

        return chatItem;
      }),
    );
  }

  @DataAction()
  updateChatListWithLatestMessage(
    @Payload('id') chatUuid: ChatListItem['id'],
    @Payload('message') message: MessageListResponse,
  ): void {
    const updatedChatListItem: ChatListItem = {
      ...this.entities[chatUuid],
      latestMessageId: message.id,
      lastMessageSent: message.createdAt,
      lastAdvisorMessage:
        this.userService?.user?.trainer?.id ===
        this.entities[chatUuid].trainerId
          ? message.createdAt
          : this.entities[chatUuid].lastAdvisorMessage,
    };
    this.updateOne({
      id: chatUuid,
      changes: updatedChatListItem,
    });
    const latestMessage: LatestMessageItemResponse = {
      id: message.id,
      message: message.message,
      createdAt: message.createdAt,
      type: message.messageType,
      messageAttachments: message.messageAttachments,
    };

    this.lastMessageState.setOne(latestMessage);
  }

  @DataAction()
  sortDataInStore() {
    this.sort({
      sortBy: this.snapshot.sortBy.toString() as keyof ChatListItemResponse,
      sortByOrder: this.snapshot.isNewestFirst
        ? SortOrderType.DESC
        : SortOrderType.ASC,
    });
  }

  @DataAction()
  markRead(@Payload('uuid') uuid: ChatListItem['id']) {
    return this.chatservice.markRead(uuid).pipe(
      tap(response => {
        this.updateOne({
          id: response.data.id,
          changes: response.data.attributes,
        });
        this.sortDataInStore();
      }),
    );
  }

  @DataAction()
  markUnread(@Payload('uuid') uuid: ChatListItem['id']) {
    return this.chatservice.markUnread(uuid).pipe(
      tap(response => {
        this.updateOne({
          id: response.data.id,
          changes: response.data.attributes,
        });
        this.sortDataInStore();
      }),
    );
  }

  @Computed()
  public get loading$(): Observable<boolean> {
    return this.state$.pipe(map(state => state.loading));
  }

  @Computed()
  public get chatListItems$(): Observable<ChatListItem[]> {
    const chatItems$: Observable<ChatListItem[]> = combineLatest([
      this.entitiesArray$,
      this.userProfileState.entities$.pipe(distinctUntilChanged()),
      this.lastMessageState.entities$,
    ]).pipe(
      map(([chatListItems, profiles, lastMessage]) => {
        const filteredData = this.getFilteredData(chatListItems);
        return filteredData.map((item: ChatListItemResponse) => {
          if (!item) return item;
          const latestMessage = this.getLatestMessage(item);
          const chatItem: ChatListItem = {
            ...item,
            displayName: profiles[item.transphormerId]?.displayName,
            latestMessage,
            lastestMessageIsAttachment:
              !!lastMessage[item.latestMessageId]?.messageAttachments?.length,
            latestMessageDate: this.getChatListItemDisplayDate(item),
            iSentLastMessage:
              new Date(item.lastMessageSent).getTime() <=
              new Date(item.lastAdvisorMessage).getTime(),
          };

          return chatItem;
        });
      }),
    );

    this.loadNextPage(false);
    return chatItems$;
  }

  @Computed()
  public get canLoadMore$(): Observable<boolean> {
    return this.state$.pipe(
      map(
        state => state.pagedData['currentPage'] < state.pagedData['lastPage'],
      ),
    );
  }

  @Computed()
  public get canLoadMore(): boolean {
    return (
      this.snapshot.pagedData['currentPage'] <
      this.snapshot.pagedData['lastPage']
    );
  }

  @Computed()
  public get isLoading(): boolean {
    return this.ctx.getState().loading;
  }

  @DataAction()
  public resetPagingData(): void {
    this.patchState({
      pagedData: {
        currentPage: 1,
        lastPage: 1,
        perPage: PER_PAGE,
        total: 0,
      },
    });
  }

  @DataAction()
  public getNextPage(): void {
    if (
      this.snapshot.pagedData['currentPage'] <
      this.snapshot.pagedData['lastPage']
    ) {
      const state = this.ctx.getState();
      const currentPage = this.ctx.getState().pagedData['currentPage'] + 1;

      this.ctx.setState({
        ...state,
        pagedData: { ...state.pagedData, currentPage },
      });
    }
  }

  @DataAction()
  public setLoading(@Payload('loading') loading: boolean): void {
    this.ctx.patchState({ loading });
  }

  private flattenLatestMessagesForStore(
    normalLatestMessages: NormalizedChatListResponseData['normalLatestMessages'],
  ): LatestMessageItemResponse[] {
    return normalLatestMessages.map((message: LatestMessageItemResponse) => {
      const props = message['attributes'];
      const attachments = message['relationships']['attachments']['data'];

      const latestMessage: LatestMessageItemResponse = {
        id: message.id,
        messageAttachments: attachments.length
          ? attachments.map(
              attachment =>
                this.attachmentState.entities[attachment.id] || undefined,
            )
          : [],
        ...props,
      };

      return latestMessage;
    });
  }

  private flattenChatListForStore(
    normalChatList: NormalizedChatListResponseData['normalChatList'],
  ): ChatListItemResponse[] {
    return Object.entries(normalChatList).map(chatItem => {
      const latestMessageId =
        chatItem[1]['relationships']['latestMessage']['data']?.id;
      const updatedChat = {
        ...chatItem[1]['attributes'],
        id: chatItem[0],
        latestMessageId,
      };
      return updatedChat;
    });
  }

  private normalizeChatListData(
    data: JsonApiResponse<
      JsonApiObject<ChatListItemResponse[]>,
      { page: string },
      JsonApiObject<LatestMessageItemResponse[]>
    >,
  ): NormalizedChatListResponseData {
    const normalizedData = normalize(data);
    const latestMessages = normalizedData['messages'] || [];
    const normalChatList = normalizedData['chats'] || [];
    const normalAttachments = normalizedData['messageAttachments'] || [];
    this.addAttachmentsToState(normalAttachments);
    const normalLatestMessages: LatestMessageItemResponse[] =
      Object.values(latestMessages);

    return {
      normalChatList,
      normalLatestMessages,
    } as NormalizedChatListResponseData;
  }

  private addAttachmentsToState(messageAttachments): void {
    const attachments: MessageAttachment3[] = Object.values(
      messageAttachments,
    ).map((msgAttachment: MessageAttachment3) => {
      const props = msgAttachment['attributes'];
      const item: MessageAttachment3 = {
        id: msgAttachment.id,
        ...props,
      };

      return item;
    });

    this.attachmentState.upsertMany(attachments);
  }

  private getLatestMessage(item: ChatListItem): LatestMessageItemResponse {
    return this.lastMessageState.entities[item.latestMessageId];
  }

  private getChatListItemDisplayDate(
    currentItem: ChatListItemResponse,
  ): string {
    switch (this.snapshot.sortBy) {
      case ChatListSortByOptions.LAST_MSG:
        return this.lastMessageState.entities[currentItem.latestMessageId]
          ?.createdAt;
      case ChatListSortByOptions.UPDATED_AT:
        return currentItem.updatedAt;
      case ChatListSortByOptions.OLDEST_TRANSPHORMER_UNREAD_MSG:
        return currentItem.oldestUnrespondedTransphormerMessage;
    }
  }

  private getFilteredData(
    items: ChatListItemResponse[],
  ): ChatListItemResponse[] {
    const advisorUnreadFilter = this.snapshot.filters.get(
      ChatListFilterByOptions.ADVISOR_UNREAD,
    );
    const battleBuddyVisibility = this.snapshot.filters.get(
      ChatListFilterByOptions.ADVISOR_ID,
    );

    if (advisorUnreadFilter) {
      items = items.filter(
        item => item.advisorUnread === JSON.parse(advisorUnreadFilter),
      );
    }

    if (battleBuddyVisibility) {
      items = items.filter(
        item => item.trainerId === JSON.parse(battleBuddyVisibility),
      );
    }

    return items;
  }

  @DataAction()
  public setSortOrder(@Payload('isNewestFirst') isNewestFirst: boolean): void {
    this.patchState({ isNewestFirst });
    this.refreshChatListItems();
  }

  @DataAction()
  public loadNextPage(@Payload('loadNextPage') loadNextPage: boolean): void {
    this.patchState({ loadNextPage });
    this.loadChatList();
  }

  @DataAction()
  public setSortByOption(
    @Payload('sortByOption') sortBy: ChatListSortByOptions,
  ): void {
    this.patchState({ sortBy });
    this.refreshChatListItems();
  }

  private refreshChatListItems() {
    this.resetPagingData();
    this.removeAll();
    this.loadChatList();
  }

  public filterByNotResponded(notResponded = 'true'): void {
    const sortBy: ChatListSortByOptions =
      ChatListSortByOptions.OLDEST_TRANSPHORMER_UNREAD_MSG;
    const filters: Map<string, string> = this.ctx.getState().filters;
    filters.set(ChatListFilterByOptions.NOT_RESPONDED.toString(), notResponded);
    filters.delete(ChatListFilterByOptions.ADVISOR_UNREAD.toString());
    this.patchState({ filters, sortBy });
    this.refreshChatListItems();
  }

  @DataAction()
  public filterByAdvisorReadStatus(
    @Payload('messageReadStatus') status: MessageReadStatus,
  ): void {
    if (status === MessageReadStatus.UNRESPONDED) {
      this.filterByNotResponded();
      return;
    }

    const state = this.ctx.getState();
    let sortBy: ChatListSortByOptions = state.sortBy;
    const filters: Map<string, string> = state.filters;
    let appliedAdvisorUnreadFilter = '';

    switch (status) {
      case MessageReadStatus.UNREAD:
        appliedAdvisorUnreadFilter = 'true';
        sortBy = ChatListSortByOptions.OLDEST_TRANSPHORMER_UNREAD_MSG;
        break;
      case MessageReadStatus.READ:
        appliedAdvisorUnreadFilter = 'false';
        sortBy = ChatListSortByOptions.LAST_MSG;
        break;
      default:
        appliedAdvisorUnreadFilter = undefined;
        sortBy = ChatListSortByOptions.UPDATED_AT;
        break;
    }

    filters.delete(ChatListFilterByOptions.NOT_RESPONDED.toString());
    filters.set(
      ChatListFilterByOptions.ADVISOR_UNREAD.toString(),
      appliedAdvisorUnreadFilter,
    );

    this.patchState({ filters, sortBy });
    this.refreshChatListItems();
  }

  @DataAction()
  filterByBattleBuddy(@Payload('battleBuddyId') battleBuddyId: string | null) {
    const state = this.ctx.getState();

    const filters: Map<string, string> = state.filters;
    filters.set(ChatListFilterByOptions.ADVISOR_ID.toString(), battleBuddyId);

    this.patchState({ filters });

    this.refreshChatListItems();
  }

  @DataAction()
  filterByTeamId(@Payload('teamId') teamId: Team['id'] | null) {
    const state = this.ctx.getState();

    const filters: Map<string, string> = state.filters;
    filters.set(ChatListFilterByOptions.TEAM_ID.toString(), teamId?.toString());

    this.patchState({ filters });

    this.refreshChatListItems();
  }
}
