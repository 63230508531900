import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { SupplementsConfigComponent } from './supplements-config/supplements-config.component';
import { SupplementsSearchComponent } from './supplements-search/supplements-search.component';
import { SupplementsTrackerComponent } from './supplements-tracker/supplements-tracker.component';
import { UniversalComponentsModule } from '../../../universal/universal-components.module';
import { SupplementsTrackerItemComponent } from './supplements-tracker/supplements-tracker-item/supplements-tracker-item.component';
import { SupplementsConfigItemComponent } from './supplements-config/supplements-config-item/supplements-config-item.component';
import { SupplementsComponent } from './supplements/supplements.component';

@NgModule({
  imports: [IonicModule, CommonModule, UniversalComponentsModule],
  declarations: [
    SupplementsComponent,
    SupplementsConfigComponent,
    SupplementsConfigItemComponent,
    SupplementsSearchComponent,
    SupplementsTrackerComponent,
    SupplementsTrackerItemComponent,
  ],
  exports: [SupplementsComponent],
})
export class SupplementsComponentsModule {}
