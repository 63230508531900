import { NgModule } from '@angular/core';
import { ReactionComponent } from './components/reaction/reaction.component';
import { ReactionContainerComponent } from './components/reaction-container/reaction-container.component';
import { FormattedCountPipe } from '../../pipes/formatted-count/formatted-count.pipe';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { ReactionsState } from './state/reactions.state';

@NgModule({
  declarations: [
    ReactionComponent,
    ReactionContainerComponent,
    FormattedCountPipe,
  ],
  imports: [CommonModule, IonicModule, NgxsModule.forFeature([ReactionsState])],
  exports: [ReactionContainerComponent, FormattedCountPipe],
})
export class ReactionsModule {}
