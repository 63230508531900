import { schema } from 'normalizr';

const training = new schema.Entity('training');

// Define a users schema
const trainingConfig = new schema.Entity('trainingConfigs', {
  training,
});

// Define your comments schema
const activityConfig = new schema.Entity(
  'activityConfigs',
  {},
  {
    idAttribute: 'uuid',
  },
);

// Define your comments schema
const workoutSessionsConfig = new schema.Entity(
  'workoutSessions',
  {},
  {
    idAttribute: 'uuid',
  },
);

// Define your comments schema
const walkTimerConfig = new schema.Entity(
  'walkTimer',
  {},
  {
    idAttribute: 'uuid',
  },
);

// Define your article
const day = new schema.Entity(
  'days',
  {
    activityConfig: activityConfig,
    trainingConfig: trainingConfig,
    walkTimer: walkTimerConfig,
    workoutSessions: [workoutSessionsConfig],
  },
  {
    idAttribute: 'day',
  },
);

export { day, activityConfig, trainingConfig, training };

// const normalizedData = normalize(, article);
