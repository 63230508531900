import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  public isSplashVisible: boolean = null;
  public hide$: Subject<null> = new Subject();

  constructor(private platform: Platform) {
    this.platform.ready().then(readySource => {
      this.isSplashVisible = readySource === 'capacitor';
      if (!this.isSplashVisible) this.hide$.next(null);
    });
  }

  public async hide() {
    if (this.isSplashVisible) {
      await SplashScreen.hide();
      this.isSplashVisible = false;
      this.hide$.next(null);
    } else if (this.isSplashVisible === null) {
      const readySource = await this.platform.ready();
      console.log('readySource', readySource);
      if (readySource === 'capacitor') {
        await SplashScreen.hide();
        this.isSplashVisible = false;
        this.hide$.next(null);
      }
    }
  }
}
