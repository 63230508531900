import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import 'cordova-plugin-purchase';
import { Observable, ReplaySubject } from 'rxjs';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';
import { OnboardingService } from '../onboarding/onboarding.service';
import { UserService } from '../user/user.service';
import {
  ActiveAppleProducts,
  ActiveGoogleProducts,
  AppleProducts,
  GoogleProducts,
  Product,
  testingProducts,
} from './products';
import { environment } from '../../../environments/environment';
import { Transphormer } from '../../interfaces';
import {
  APPLICATION_DEBUGGING,
  UserPreferencesService,
} from '../user-preferences.service';
import { handleApiError } from '../../helpers/operators';
import { Device } from '@capacitor/device';
import { PurchasePlugin } from '../../purchase';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InAppPurchaseService {
  static readonly ERROR_CODES_BASE: number = 6777000;
  static readonly ERROR: { [key: number]: string } = {
    1: 'Error setting up IAP system.',
    2: 'Error during load.',
    3: 'Error during purchase.',
    4: 'Error while loading receipts.',
    5: 'Invalid client.',
    6: 'Payment cancelled by user.',
    7: "Couldn't process payment.",
    8: 'Payment not allowed.',
    10: 'Unknown error.',
    11: 'Unable to refresh receipts.',
    12: 'Invalid product ID.',
    13: 'Could not finish transaction.',
    14: 'Error while communicating with the server.',
    15: 'Subscriptions are not currently available.',
    16: 'Missing token in purchase information.',
    17: 'Verification failed.',
    18: 'Bad verification response.',
    19: 'Refresh failed.',
    20: 'Payment has expired.',
    21: 'Could not download purchase.',
    22: 'Subscription update is not currently available.',
    23: 'Product is not currently available.',
    24: 'User denied access to service information.',
    25: 'Network connection failed.',
    26: 'User revoked permission to use this service.',
    27: "Apple's privacy policy not yet acknowledged.",
    28: 'Unauthorized request.',
    29: 'Invalid offer identifier.',
    30: 'App Store Connect price is no longer valid.',
    31: 'Invalid signature.',
    32: 'Error claiming offer.',
  };

  protected ready = new ReplaySubject<CdvPurchase.Product[]>(1);

  // Create an observable of products which have the pricing
  // information added to them so that we can easily display it
  // to a user. This will only show products which are both:
  // 1) available to a user for purchase and 2) actually returned
  // by the store since in certain situations, those two list of
  // products may not match 100%.
  readonly productsAvailableForPurchase$: Observable<Product[]>;

  constructor(
    public http: HttpClient,
    public platform: Platform,
    @Inject(PurchasePlugin) public iap: CdvPurchase.Store,
    private userService: UserService,
    private prefs: UserPreferencesService,
    private onboardingService: OnboardingService,
    @Inject(RollbarService) public rollbar: Rollbar,
  ) {
    this.rollbar.debug('IAP constructor()');

    this.productsAvailableForPurchase$ = this.ready.pipe(
      map(products => {
        if (!Array.isArray(products)) {
          this.rollbar.error(
            'products returned by storeReady was not an array, returning.',
            { products },
          );
          return [];
        }
        return products;
      }),
      map(products =>
        products
          .filter(p => Object.values(this.platformProducts()).includes(p.id))
          .map(p => new Product(p)),
      ),
      shareReplay(1),
    );
  }

  public async init() {
    if (environment.production && !this.platform.is('capacitor')) {
      this.rollbar.debug('Non-mobile production environment, returning.');
      return;
    }

    const debuggingPref = this.prefs.get(APPLICATION_DEBUGGING, false);
    CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;

    if (!environment.production && !this.platform.is('capacitor')) {
      return this.ready.next(testingProducts());
    }

    await this.platform.ready();
    if (!environment.production && debuggingPref) {
      this.rollbar.debug('Setting up rollbar debugging.');
      this.setDebugLevel('DEBUG');
    }

    await this.setupStore();
  }

  private notifyAndUpdate() {
    // MTST-11189 - If there is no user, this call won't work. Because of various preloading and whatnot.
    if (!this.userService.user) {
      return;
    }

    this.http
      .get(`${environment.apiUrl}subscription/notify`)
      .pipe(handleApiError())
      .subscribe(() => {
        this.onboardingService.fetchOnBoard().subscribe();
      });
  }

  public setDebugLevel(
    debugLevel: 'QUIET' | 'ERROR' | 'WARNING' | 'INFO' | 'DEBUG',
  ) {
    this.iap.verbosity = this.iap[debugLevel];
  }

  public async setupStore() {
    this.rollbar.info('IAPS.setupStore()');

    this.iap.register(await this.iapProducts());

    // Set up our validator endpoint.
    // @todo - move this into config!
    this.iap.validator =
      'https://validator.iaptic.com/v3/validate?appName=com.firstphorm.app&apiKey=24a5b155-6d3a-4607-a6cb-6099f9de7941';

    this.iap.applicationUsername = () => {
      if (this.transphormer()) {
        return `${this.transphormer().id}`;
      }
      return null;
    };

    this.iap.ready(() => {
      // Seems as though in some cases we get an invalid product. Until we know what's going on,
      // Let's log the invalid product and then filter out the undefined ones after-the-fact.
      const products = Object.values(this.platformProducts())
        .map(p => {
          const product = this.iap.get(
            p,
            this.platform.is('ios')
              ? CdvPurchase.Platform.APPLE_APPSTORE
              : CdvPurchase.Platform.GOOGLE_PLAY,
          );
          if (!product) {
            this.rollbar.warn(`Invalid product returned`, {
              productSku: p,
              product,
            });
            return undefined;
          }

          return product;
        })
        .filter(i => !!i);
      // AFAIK, we don't need to do this at all.
      // this.store.dispatch(new SubscriptionApplyProducts(products));

      this.ready.next(products);
      this.iap.error((err: CdvPurchase.IError) => {
        if (
          this.iap.owned(err.productId) &&
          products.find(product => product?.id !== err.productId)
        ) {
          this.rollbar.info('IAP invalid product, no longer active');
        } else {
          this.rollbar.error('IAP Error', err.message, err);
        }
      });
    });

    this.handlePaymentCompletion();

    await this.iap.initialize().catch((err: CdvPurchase.IError) => {
      this.rollbar.error('IAP Init Error', err.message, err);
    });
  }

  public storeReady(): Observable<CdvPurchase.Product[]> {
    return this.ready;
  }

  public async purchase(
    productId: string,
    platform?: CdvPurchase.Platform,
    offerId?: string,
  ): Promise<CdvPurchase.IError> {
    if (!this.platform.is('capacitor')) {
      this.rollbar.info('Tried purchasing on unsupported platform.');
      return;
    }

    const offer = this.iap.get(productId, platform)?.getOffer(offerId);
    return await this.iap.order(offer);
  }

  private handlePaymentCompletion() {
    this.iap.when().approved((tr: CdvPurchase.Transaction) => {
      this.rollbar.info('IAP.approved()', tr);
      tr.verify();
    });

    this.iap.when().verified((receipt: CdvPurchase.VerifiedReceipt) => {
      this.rollbar.info('IAP.verified()', receipt);
      this.notifyAndUpdate();
      const ownedProducts = this.determineProductsOwned(this.iap.products);
      if (ownedProducts.length > 0) {
        // ... we are premium we could actually update the system here to let the user continue.
        // but we don't because we are going to, like, totally pull entitlements next, or something. idk lol
      }
      receipt.finish();
    });

    this.iap.when().finished((tr: CdvPurchase.Transaction) => {
      this.rollbar.info('IAP.finished()', tr);
      tr.finish();
    });
  }

  public platformProducts(): { [key: string]: string } {
    if (!this.platform.is('capacitor')) {
      return ActiveAppleProducts;
    } else if (this.platform.is('android')) {
      return ActiveGoogleProducts;
    } else if (this.platform.is('ios')) {
      return ActiveAppleProducts;
    }
  }

  private determineProductsOwned(products: CdvPurchase.Product[]) {
    const paidProducts = products.filter(
      p => p.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION,
    );
    return paidProducts.filter(product => this.iap.owned(product));
  }

  public allPlatformProducts(): Promise<string[]> {
    return Device.getInfo()
      .then(info => {
        if (info.platform === 'android') {
          return GoogleProducts;
        } else if (info.platform === 'ios') {
          return AppleProducts;
        } else {
          // This... shouldn't happen but in some instances this does appear to be happening?
          this.rollbar.error('Invalid platform.', {
            platforms: this.platform.platforms(),
            info,
          });
          return [];
        }
      })
      .catch(_ => []);
  }

  private async iapProducts(): Promise<CdvPurchase.IRegisterProduct[]> {
    const productIds = Object.values(await this.allPlatformProducts());
    return productIds.map(productId => {
      return <CdvPurchase.IRegisterProduct>{
        id: productId,
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        platform: this.platform.is('ios')
          ? CdvPurchase.Platform.APPLE_APPSTORE
          : CdvPurchase.Platform.GOOGLE_PLAY,
      };
    });
  }

  public transphormer(): Transphormer {
    return this.userService.user;
  }

  public getErrorDescription(error: CdvPurchase.IError): string {
    return error.code
      ? InAppPurchaseService.ERROR[
          error.code - InAppPurchaseService.ERROR_CODES_BASE
        ] ||
          error.message ||
          'Unknown error'
      : error.message || 'Unknown error';
  }
}
