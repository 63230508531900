import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, filter } from 'rxjs/operators';
import { TransfersState } from '../../../../store/states/transfers.state';
import { Select } from '@ngxs/store';
import { UnreadState } from '../../../../store/unread/unread.store';
import { MenuService } from '../../services/menu/menu.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RollbarService } from '../../../../rollbar';
import * as Rollbar from 'rollbar';
import { UserService } from '../../../../services';
import { Transphormer } from '../../../../interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@UntilDestroy()
@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomNavComponent implements OnInit {
  @Input() transfersVisible = true;

  public moreActiveUrls: string[] = [
    'advisor',
    'announcement',
    'assessment',
    'body-metrics',
    'camera',
    'help',
    'info-contact',
    'info-personal',
    'leaderboard',
    'live',
    'notifications',
    'nutrition/custom-food',
    'password-change',
    'photo',
    'products',
    'profile',
    'reminders',
    'settings',
    'subscription',
    'trainer-request',
    'week-in-review',
    'weight',
  ];
  private navEnd$: Observable<NavigationEnd>;
  @Select(TransfersState.avgProgress) avgTransfersProgress$: Observable<number>;
  @Select(TransfersState.inProgress) transfersInProgress$: Observable<boolean>;
  @Select(UnreadState.unreadTransphormerMessages)
  messageCount$: Observable<number>;
  @Select(UnreadState.expandedMenuCount) menuCount$: Observable<number>;
  @Select(UnreadState.unreadTransphormerAnnouncements)
  unreadTransphormerAnnouncements$: Observable<number>;

  user$: Observable<Transphormer> = this.userService.user$.pipe(
    takeUntilDestroyed(),
    filter(user => !!user),
    distinctUntilKeyChanged('id'),
  );

  constructor(
    private changeDetection: ChangeDetectorRef,
    private menuService: MenuService,
    private router: Router,
    private userService: UserService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {
    this.navEnd$ = router.events.pipe(
      filter(evt => evt instanceof NavigationEnd),
    ) as Observable<NavigationEnd>;
  }

  ngOnInit() {
    this.navEnd$.subscribe(() => {
      this.changeDetection.markForCheck();
    });
  }

  /**
   * Open expanded menu
   */
  public openMoreMenu() {
    this.menuService.open().then();
  }
}
