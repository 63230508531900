const US_CANADA_MIL_STATE =
  '((A|F|D)(PO\\b)\\s[A-Z]*\\b)|^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))|^(AB|BC|NB|NL|MB|ON|QC|NS|PE|SK)';
const US_CANADA_POSTAL_CODE =
  '^((\\d{5}-\\d{4})|(\\d{5})|([AaBbCcEeGgHhJjKkLlMmNnPpRrSsTtVvXxYy]\\d[A-Za-z]\\s?\\d[A-Za-z]\\d))$';
const US_CANADA_PHONE_NUMBER =
  '^(?:\\+\\d{1,3}|0\\d{1,3}|00\\d{1,2})?(?:\\s?\\(\\d+\\))?(?:[-\\/\\s.]|\\d)+$';
const INSTAGRAM_NAME = '^([A-Za-z0-9_.]{4,})$';
const TWITTER_NAME = '^([A-Za-z0-9_.]{4,})$';
const EMAIL =
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

/**
 * Used for numeric input but attempting to filter out weird-numbers like those starting with 0.
 *
 * MATCHES: 1, 1.2, .2, .23, .00002, etc.
 * REJECTS: 01, 01.2, 2.a
 */
const FORMATTED_NUMBER =
  '^([1-9][0-9]*\\.?|0|\\.[0-9]{1,3}|([1-9][0-9]*|0)\\.[0-9]{1,3})?$';

const FORMATTED_NUMBER_X_DOT_Y = '^(0|[1-9]+[0-9]*(.[1-9])?)$';

/**
 * Used for numeric input but attempting to filter out weird-numbers like those starting with 0.
 *
 * MATCHES: 1, 10, 0, etc.
 * REJECTS: 01, 01.2, 2.a, 1.2, .2, .23, .00002
 */
const WHOLE_NUMBER = '^([1-9][0-9]*|0)$';

export {
  US_CANADA_MIL_STATE,
  US_CANADA_POSTAL_CODE,
  US_CANADA_PHONE_NUMBER,
  INSTAGRAM_NAME,
  TWITTER_NAME,
  EMAIL,
  FORMATTED_NUMBER,
  FORMATTED_NUMBER_X_DOT_Y,
  WHOLE_NUMBER,
};
