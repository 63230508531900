import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';
import {
  PaginationMetadata,
  Video,
  WrappedApiPaginatedResponse,
} from '../../interfaces';
import { apiUrl } from '../../helpers';
import { handleApiError } from '../../helpers/operators';
import { ToastService } from '../toast-service/toast-service.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { parseISO } from 'date-fns';

export interface VideoSearchResponse {
  meta: PaginationMetadata;
  data: Video[];
}

@Injectable({
  providedIn: 'root',
})
export class VideosService {
  constructor(
    protected http: HttpClient,
    private toastSvc: ToastService,
    @Inject(RollbarService) public rollbar: Rollbar,
  ) {}

  public getVideo(videoId: number): Observable<Video> {
    return this.http
      .get<Video<string>>(apiUrl(`vod/${videoId}`))
      .pipe(map(d => videoTimestamps(d)));
  }

  /**
   * Return either the active stream or the latest VOD video from the API.
   * Either of these counts as the "latest" video.
   */
  public getLatestVideo(): Observable<Video> {
    return this.getVideos(1).pipe(
      map(response => {
        if (response && response.active_stream) {
          return response.active_stream;
        }

        return (response && response.data && response.data[0]) || null;
      }),
    );
  }

  public getVideos(limit = 0, page = 1): Observable<VideoResponse> {
    const params = {
      limit,
      page,
    };

    return this.http
      .get<VideoResponse<string>>(apiUrl('videos'), { params })
      .pipe(
        map(d => {
          return {
            ...d,
            active_stream: d.active_stream
              ? videoTimestamps(d.active_stream)
              : undefined,
            data: d.data.map(videoTimestamps),
          };
        }),
        handleApiError(this.rollbar, this.toastSvc, true),
      );
  }

  public getPlaybackUrl(streamId: number): Observable<string> {
    return this.http.post<string>(apiUrl(`vod/${streamId}/playback-url`), {});
  }

  public startPlaying(streamId: number): Observable<string> {
    return this.http.post<string>(apiUrl(`vod/${streamId}/watching`), {});
  }

  public search(
    query = 'fat',
    page = 1,
    limit = 15,
  ): Observable<VideoSearchResponse> {
    const params = {
      query,
      limit,
      page,
    };
    return this.http
      .get<VideoSearchResponse>(apiUrl(`video/search`), { params })
      .pipe(handleApiError());
  }

  public stopPlaying(streamId: number): Observable<string> {
    return this.http.post<string>(apiUrl(`vod/${streamId}/not-watching`), {});
  }
}

export const videoTimestamps = (v: Video<string>): Video => ({
  ...v,
  created_at: parseISO(v.created_at),
  publish_date: parseISO(v.publish_date),
});

interface VideoResponse<T = Date>
  extends WrappedApiPaginatedResponse<Video<T>[]> {
  active_stream: Video<T>;
}
