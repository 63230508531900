import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPedometerComponent } from './components/dashboard-pedometer/dashboard-pedometer.component';
import { IonicModule } from '@ionic/angular';

import { Health } from '@awesome-cordova-plugins/health/ngx';
import { HealthService } from './services/health.service';
import { AppComponentsModule } from '../../components/common-components.module';
import { UniversalComponentsModule } from '../universal/universal-components.module';

@NgModule({
  declarations: [DashboardPedometerComponent],
  imports: [
    CommonModule,
    IonicModule,
    AppComponentsModule,
    UniversalComponentsModule,
  ],
  exports: [DashboardPedometerComponent],
  providers: [Health, HealthService],
})
export class HealthModule {}
