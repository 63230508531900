import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import {
  AddReaction,
  RemoveReaction,
  RetrieveAllReactions,
  RetrieveMyReactions,
  SetMyReaction,
} from './reactions.actions';
import {
  ReactionsService,
  retrieveEmojiForValue,
} from '../services/reactions.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import produce from 'immer';
import {
  Reaction,
  ReactionAggregation,
  ReactionAttributes,
  ReactionServerAggregations,
  ReactionType,
  UserReaction,
  UserReactionPersisted,
} from '../types';
import { of } from 'rxjs';

export const REACTIONS_STATE_TOKEN = new StateToken<ReactionsStateModel>(
  'reactions',
);

export const REACTIONS_STATE_EMPTY: ReactionsStateModel = {
  allReactions: { announcement: {}, asset: {} },
  myReactions: { announcement: {}, asset: {} },
};

export interface ReactionsStateModel {
  allReactions: Record<
    ReactionType,
    { [key: number]: ReactionServerAggregations }
  >;
  myReactions: Record<
    ReactionType,
    { [key: number]: UserReaction | UserReactionPersisted }
  >;
}

@State<ReactionsStateModel>({
  name: REACTIONS_STATE_TOKEN,
  defaults: REACTIONS_STATE_EMPTY,
})
@Injectable({
  providedIn: 'root',
})
export class ReactionsState {
  constructor(private reactionsService: ReactionsService) {}

  @Selector([ReactionsState])
  static allReactions(state: ReactionsStateModel) {
    return state.allReactions;
  }

  @Selector([ReactionsState])
  static myReactions(state: ReactionsStateModel) {
    return state.myReactions;
  }

  static selectReactionsForId(type: string, id: number) {
    return createSelector(
      [ReactionsState.allReactions],
      (r): ReactionServerAggregations | undefined => r[type][id],
    );
  }

  static selectMyReactions(type: string, id: number) {
    return createSelector(
      [ReactionsState.myReactions],
      (r): ReactionAttributes | undefined => r[type][id],
    );
  }

  static selectReactionsForAsset(type: string, id: number) {
    return createSelector(
      [
        ReactionsState.selectReactionsForId(type, id),
        ReactionsState.selectMyReactions(type, id),
      ],
      (
        all: ReactionServerAggregations,
        my: UserReactionPersisted,
      ): ReactionAggregation[] | null => {
        if (all === undefined || my === undefined) {
          return undefined;
        }

        // If there is no reaction from me, return everyone else's. Or, if
        // we have both, then return the all, because we can assume that it has
        // all the relevant data.
        if (!my) {
          return addEmojis(all.value);
        }

        // There are 4 possible scenarios:
        // 1. No data at all. (handled above.)
        // 2. Only aggregate, no "my" data. (also handled above.)
        // 3. Only my data, no aggregate.
        // 4. Both data.
        // Handle #3 here.
        if (!all || all.value.length === 0) {
          return addEmojis([
            {
              value: my.value,
              total: 1,
              highlighted: true,
            },
          ]);
        }

        // At this point, we have both my and all, figure out what to do.
        // If we have all data and my data, but my data is too old, then just highlight my reaction and return.
        if (my.createdAt < all.createdAt) {
          return addEmojis(highlightMyReaction(all.value, my));
        }

        return addEmojis(
          incrementMyReaction(
            highlightMyReaction(ensureMyEmojiExists(all.value, my), my),
            my,
          ),
        );
      },
    );
  }

  @Action(RetrieveAllReactions)
  retrieveReaction(
    ctx: StateContext<ReactionsStateModel>,
    { id, type }: RetrieveAllReactions,
  ) {
    return this.reactionsService.getReactionAggregations(id, type).pipe(
      catchError(() => {
        return [];
      }),
      map(r => {
        ctx.setState(
          produce((draft: ReactionsStateModel) => {
            draft.allReactions[type][id] = r;
          }),
        );
      }),
    );
  }

  @Action(RetrieveMyReactions)
  retrieveMyReactions(
    ctx: StateContext<ReactionsStateModel>,
    { userId, id, type },
  ) {
    return this.reactionsService
      .getReactionsForTransphormer(userId, id, type)
      .pipe(
        tap(r =>
          ctx.setState(
            produce((draft: ReactionsStateModel) => {
              draft.myReactions[type][id] = r;
            }),
          ),
        ),
      );
  }

  @Action(AddReaction)
  addReaction(
    ctx: StateContext<ReactionsStateModel>,
    { reaction }: AddReaction,
  ) {
    ctx.setState(
      produce((draft: ReactionsStateModel) => {
        draft.myReactions[reaction.reactionable_type][
          reaction.reactionable_id
        ] = reaction;
      }),
    );

    // Add action for adding reaction
    return this.reactionsService.addReaction(reaction).pipe(
      tap(a => {
        ctx.setState(
          produce((draft: ReactionsStateModel) => {
            draft.myReactions[reaction.reactionable_type][
              reaction.reactionable_id
            ] = a;
          }),
        );
      }),
    );
  }

  @Action(RemoveReaction)
  removeReaction(
    ctx: StateContext<ReactionsStateModel>,
    { type, assetId, myId }: RemoveReaction,
  ) {
    ctx.setState(
      produce((draft: ReactionsStateModel) => {
        draft.myReactions[type][assetId] = null;
      }),
    );

    return this.reactionsService.removeReaction(myId);
  }

  @Action(SetMyReaction)
  setReaction(
    ctx: StateContext<ReactionsStateModel>,
    { type, assetId, reaction }: SetMyReaction,
  ) {
    // Here are the possible flows:
    // 1. Adding a brand-new reaction.
    // 2. Updating my existing reaction.
    // 3. Removing my existing reaction.
    const myExistingReaction = ctx.getState().myReactions[type]?.[
      assetId
    ] as UserReactionPersisted;

    const removalStep = () =>
      myExistingReaction
        ? ctx.dispatch(new RemoveReaction(type, assetId, myExistingReaction.id))
        : of(true);

    // If I don't already have a reaction, add it.
    const insertionStep = () =>
      reaction
        ? ctx.dispatch(
            new AddReaction({
              ...reaction,
              reactionable_type: type,
              reactionable_id: assetId,
            }),
          )
        : of(true);

    return of(true).pipe(switchMap(removalStep), switchMap(insertionStep));
  }
}

const incrementMyReaction = <T extends Reaction = Reaction>(
  all: T[],
  my: UserReaction,
): T[] =>
  all.map(i => ({
    ...i,
    total: i.value === my.value ? i.total + 1 : i.total,
  }));

function ensureMyEmojiExists<T extends Reaction>(
  all: T[],
  my: UserReaction,
): T[] {
  const hasEmoji = all.find(e => e.value === my.value);

  if (!hasEmoji) {
    all.push({
      value: my.value,
      total: 0,
    } as T);
  }

  return all;
}

const highlightMyReaction = <T extends Reaction = Reaction>(
  all: T[],
  my: UserReaction,
): (T & {
  highlighted: boolean;
})[] =>
  all.map(i => ({
    ...i,
    highlighted: i.value === my.value,
  }));
const addEmojis = <T extends { value: string }>(
  all: T[],
): (T & {
  character: string;
})[] => all.map(re => retrieveEmojiForValue(re));
