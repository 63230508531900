import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jsonApiUrl } from '../../../../helpers';
import { Observable, ReplaySubject } from 'rxjs';
import {
  JsonApiObject,
  JsonApiResponse,
} from '../../../assessments-v2/services/assessments/types';
import {
  AvailableWearable,
  GarminWearable,
  ProviderDetails,
  WearableConfig,
  WearableProviderTypes,
} from '../../types';
import { filter, map, share, take, tap } from 'rxjs/operators';
import { Device, DeviceId } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { Transphormer } from '../../../../interfaces';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class WearablesConfigService {
  /**
   * This observable is required when wanting to handle device IDs for things like
   * step sync. This is because of privacy issues where Apple and Google don't give
   * you a "real" device ID. You basically have to fake it. So we wrap the fake IDs
   * they give us and then store them locally so that they don't change unless you
   * delete the app.
   */
  readonly myDevice$ = new ReplaySubject<ProviderDetails | null>(1);
  readonly activeConfig$ = new ReplaySubject<WearableConfig | null>(1);
  readonly hasWearableConnected$ = this.activeConfig$.pipe(
    map(i => i !== null),
  );

  private myDeviceId: string | null = null;

  constructor(private http: HttpClient) {}

  public init(user: Pick<Transphormer, 'steps_optin' | 'steps_optout'>) {
    this.retrieveActiveConfig().subscribe(value => {
      if (user.steps_optin && !user.steps_optout && !value.data[0]) {
        // The user has opt'd in but we don't have a config. Create one.
        this.createActiveConfig();
      }
    });

    this.retrieveProviderDetails().then(details =>
      this.myDevice$.next(details),
    );
  }

  async retrieveProviderDetails() {
    const identifier = await this.getStepsDeviceId();
    const getInfo = await Device.getInfo();

    return {
      identifier,
      name: getInfo.name ?? getInfo.model,
    };
  }

  retrieveActiveConfig(): Observable<
    JsonApiResponse<
      JsonApiObject<WearableConfig>[],
      { availableWearables: AvailableWearable[] }
    >
  > {
    const result$ = this.http
      .get<
        JsonApiResponse<
          JsonApiObject<WearableConfig>[],
          { availableWearables: AvailableWearable[] }
        >
      >(jsonApiUrl('transphormer-wearable-configs'))
      .pipe(
        tap(resp => {
          if (resp.data[0] && !resp.data[0]?.attributes.providerDetails) {
            switch (resp.data[0].attributes.providerId) {
              case WearableProviderTypes.Garmin:
                resp.data[0].attributes.providerDetails = {
                  name: GarminWearable.name,
                  identifier: null,
                };
                break;
            }
          }

          if (resp.data[0]) {
            this.activeConfig$.next({
              ...resp.data[0].attributes,
              id: resp.data[0].id,
            });
          } else {
            this.activeConfig$.next(null);
          }
        }),
      );

    result$.subscribe();

    return result$;
  }

  createWearableConfig(
    config: WearableConfig,
  ): Observable<JsonApiResponse<JsonApiObject<WearableConfig>>> {
    const result$ = this.http
      .post<JsonApiResponse<JsonApiObject<WearableConfig>>>(
        jsonApiUrl('transphormer-wearable-configs'),
        {
          data: {
            type: 'transphormer-wearable-configs',
            attributes: {
              providerId: config.providerId,
              providerDetails: config.providerDetails,
            },
          },
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/vnd.api+json',
          }),
        },
      )
      .pipe(
        tap(result => {
          this.activeConfig$.next({
            ...result.data.attributes,
            id: result.data.id,
          });
        }),
        share(),
      );

    result$.subscribe();

    return result$;
  }

  updateWearableConfig(providerId: string): Observable<unknown> {
    return this.http.patch(
      jsonApiUrl(`transphormer-wearable-configs/${providerId}/changeProvider`),
      { type: 'transphormer-wearable-configs' },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/vnd.api+json',
        }),
      },
    );
  }

  deleteWearableConfig(id: string) {
    const result$ = this.http
      .delete(jsonApiUrl(`transphormer-wearable-configs/${id}`))
      .pipe(
        tap(() => this.activeConfig$.next(null)),
        share(),
      );

    result$.subscribe();

    return result$;
  }

  /**
   * Generate a device ID that doesn't change.
   *
   * Because of various privacy issues, we can't use the device specific APIs to
   * load this information. Instead, we'll use the current device to populate an
   * ID that we will store locally. This means that if the user resets their
   * device, it will cause them to have to "re-approve" this device.
   */
  private async getStepsDeviceId(): Promise<DeviceId['identifier']> {
    const value = await Preferences.get({ key: 'stepsIdentifierId' });
    if (value?.value) {
      this.myDeviceId = value.value;
    } else {
      this.myDeviceId = (await Device.getId()).identifier;
      Preferences.set({ key: 'stepsIdentifierId', value: this.myDeviceId });
    }
    return this.myDeviceId;
  }

  clear() {
    this.activeConfig$.next(null);
  }

  private createActiveConfig() {
    // If the user is already opted into steps, create a config and update
    if (Capacitor.isNativePlatform()) {
      this.myDevice$
        .pipe(
          filter(d => !!d),
          take(1),
        )
        .subscribe(myDevice => {
          console.debug(
            'We have no provider details, but we have steps enabled. Creating a default config.',
          );

          this.createWearableConfig({
            providerId: WearableProviderTypes.App,
            providerDetails: {
              identifier: myDevice.identifier,
              name: myDevice.name,
            },
          })
            .pipe(
              take(1),
              map(_ => ({ role: 'create' })),
            )
            .subscribe();
        });
    }
  }
}
