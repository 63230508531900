import {
  PaymentTier,
  PaymentTierPrice,
  PaymentTierResponse,
  Tiers,
  TierSkuResponse,
} from '../../interfaces';
import { getTierLevel, getTierPointers } from '../../helpers';

function getPaymentTierAdditionalInfo(tierId: PaymentTier['id']): string {
  if (tierId == Tiers.Premium) {
    return 'Includes all basic plan features and...';
  }

  return null;
}

export const adapt = (tier: PaymentTierResponse): PaymentTier => ({
  name: tier.attributes.name,
  id: +tier.id,
  selected: false,
  additionalInfo: getPaymentTierAdditionalInfo(+tier.id),
  tierLevel: getTierLevel(+tier.id),
  pointers: getTierPointers(+tier.id),
});

export const adaptTiers = (response: PaymentTierResponse[]): PaymentTier[] =>
  response.map(response => adapt(response));

export const adaptSku = (
  tierSku: TierSkuResponse,
  tierList: PaymentTier[],
): PaymentTierPrice => ({
  tierId: +tierSku.attributes.tierId,
  sku: tierSku.attributes.sku,
  title: tierList.find(t => t.id === tierSku.attributes.tierId).name,
});

export const adaptSkuList = (
  response: TierSkuResponse[],
  tierList: PaymentTier[],
): PaymentTierPrice[] => response.map(response => adaptSku(response, tierList));
