import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  debounce,
  filter,
  groupBy,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  EMPTY,
  from,
  interval,
  merge,
  Observable,
  of,
  partition,
  Subject,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { isToday } from 'date-fns';

import {
  CopyDayMealToDay,
  CopyMealsToDay,
  FoodStackTrackedItem,
  InitializeMacros,
  LoadNutritionForDay,
  MealVisibilityToggled,
  RemoveTrackedItem,
  ResetNutritionState,
  SetActiveDay,
  SetActiveNutritionType,
  SetTodayDate,
  StoreNutritionFoodData,
  ToggleExpandMeal,
  ToggleExpandSupplements,
  ToggleSupplementUsed,
  TrackNewItem,
  TrackQuickAdd,
  UpdateCmcSupplements,
  UpdateMacros,
  UpdateQuickAdd,
  UpdateTrackedFoodItem,
  UpdateWaterAmount,
} from './nutrition.actions';

import {
  CaloriesMacros,
  CmcSupplementTracker,
  NutritionConfiguration,
  NutritionData,
  NutritionDay,
  NutritionPlan,
  NutritionResponse,
  SimpleMeal,
  SimpleNutritionData,
  SimpleNutritionResponse,
  TrackedItem,
  TrackedMeal,
} from '../../interfaces';

import { MacroCalculatorService } from '../../services';
import { ErrorsService, UserService } from '../../dependencies';
import { SupplementsState } from '../supplements/supplements.state';
import { apiUrl, mySQLFormattedDate } from '../../../../helpers';
import {
  calculateMacrosForTrackedItems,
  foodEquals,
  generateTrackedMealsForNutritionData,
  isSimpleNutritionType,
  quickAddToTrackedItem,
  trackDateIsTodayOrFuture,
} from './functions';
import { produce } from 'immer';
import { v1 } from 'uuid';
import {
  distinctUntilKeysChanged,
  handleApiError,
} from '../../../../helpers/operators';
import { activeNutritionForUser } from '../../../../services/user/functions';
import { NUTRITION_DEFAULT_DAY_CONFIG, NUTRITION_DEFAULT_STATE } from './data';
import {
  generateDayFor,
  generateDays,
} from '../../services/simple-nutrition.functions';
import { genericRetryStrategy } from '../../../../services/interceptors/automatic-retry-interceptor/automatic-retry-interceptor';
import { LoadSupplementConfiguration } from '../supplements/supplements.actions';
import {
  dateBeforeToday,
  hasLessSimpleNutrition,
  hasMoreSimpleNutrition,
  hasSimpleNutritionData,
} from '../../../../helpers/date';
import { mergeSupplementsConfig } from '../../functions';
import { Supplements } from '../../interfaces/simple';
import { throwIf } from '../../../../helpers/throw-if';

export const NUTRITION_STATE_TOKEN = new StateToken<NutritionStateModel>(
  'nutrition',
);

// It has an activeDate and multiple NutritionResponses in data for each date it got from the API
export interface NutritionStateModel {
  // Supposed to hold the current active Nutrition plan. Changes
  // to this will affect today and beyond.
  activeNutrition: NutritionPlan;
  // @deprecated: to remove
  activeDate: string;

  data: { [date: string]: NutritionDay };

  // The date which is "today".
  todayDate: Date;
  // Which record is technically today.
  todayRecord: number;

  // A map storing all the loaded records for easy access.
  daysById: { [key: number]: NutritionResponse };

  // Storing which days are loaded by type.
  dateToIdMap: { [date: string]: { simple: number; complex: number } };
}

interface StatePayload {
  dateToSave: string;
  activeData: NutritionDay;
  payload: Partial<NutritionDay>;
}

function createStatePayload(
  ctx: StateContext<NutritionStateModel>,
  date,
): StatePayload {
  const { activeDate, data } = ctx.getState();
  const dateToSave = date || activeDate;
  const activeData = data[dateToSave];

  const { type, water, configuration } = activeData;

  let payload: Partial<NutritionDay> = { type, water, configuration };

  if (isSimpleNutritionType(activeData.type)) {
    const { nutrition, supplements } = <SimpleNutritionData>activeData.data;
    payload = {
      ...payload,
      data: nutrition,
      supplements,
    } as unknown as SimpleNutritionResponse;
  } else {
    const { supplements } = <NutritionData>activeData.data;
    payload = {
      data: {
        ...(activeData.data as NutritionData),
        supplements: supplements.map(s => ({ id: s.id, taken: s.taken })),
      },
      ...payload,
    } as NutritionDay;
  }
  return { dateToSave, activeData, payload };
}

@State<NutritionStateModel>({
  name: NUTRITION_STATE_TOKEN,
  defaults: NUTRITION_DEFAULT_STATE,
})
@Injectable({
  providedIn: 'root',
})
export class NutritionState implements NgxsAfterBootstrap {
  public nextState$: Subject<StatePayload>;

  @Selector([NutritionState])
  static activeConfiguration(
    state: NutritionStateModel,
  ): NutritionConfiguration {
    const { activeDate } = state;
    return NutritionState.configurationForDate(activeDate, state);
  }

  static configurationForDate(date: string, state: NutritionStateModel) {
    try {
      // If we cannot return the active configuration for any reason, return a reasonable
      // default value.
      return state.data[date].configuration || NUTRITION_DEFAULT_DAY_CONFIG;
    } catch (e) {
      return NUTRITION_DEFAULT_DAY_CONFIG;
    }
  }

  @Selector()
  static activeNutrition(state: NutritionStateModel): NutritionResponse | null {
    const { activeDate, activeNutrition } = state;
    if (state.data[activeDate] && dateBeforeToday(activeDate)) {
      return state.data[activeDate];
    }

    if (
      state.data[activeDate] &&
      !dateBeforeToday(activeDate) &&
      state.data[activeDate].type === activeNutrition
    ) {
      return state.data[activeDate];
    }

    // This feels wrong. If we don't have any data loaded, then we shouldn't
    // return a blank response.
    return <NutritionResponse>{
      id: null,
      track_date: activeDate,
      type: state.activeNutrition,
      transphormer_id: null,
      data: null,
      water: 0,
      configuration: null,
    };
  }

  @Selector()
  static activeMeals(state: NutritionStateModel): TrackedMeal[] {
    const { activeDate, data } = state;
    const dayData = data[activeDate];

    if (dayData.type !== NutritionPlan.CalorieMacroCounting) {
      return [
        {
          meal_count: 0,
          numberQuickAddedItems: 0,
          onlyQuickAddedItems: false,
          food_items: [],
          active: false,
        },
      ];
    }

    return generateTrackedMealsForNutritionData(
      dayData.data,
      dayData.configuration,
    );
  }

  @Selector()
  static consumedMacros(state: NutritionStateModel): CaloriesMacros {
    const { activeDate } = state;
    return NutritionState.consumedMacrosForDate(activeDate, state);
  }

  static consumedMacrosForDate(
    activeDate: string,
    state: NutritionStateModel,
  ): CaloriesMacros {
    if (state.data[activeDate].type !== NutritionPlan.CalorieMacroCounting) {
      return { calories: 0, protein: 0, carbs: 0, fats: 0, fiber: 0 };
    }

    if (
      state.data[activeDate].data === null ||
      (state.data[activeDate].data as NutritionData).food.length === 0
    ) {
      return { calories: 0, protein: 0, carbs: 0, fats: 0, fiber: 0 };
    }
    return calculateMacrosForTrackedItems(
      (state.data[activeDate].data as NutritionData).food,
    );
  }

  /**
   * Dynamic selector to grab a particular date.
   * @param date
   */
  static nutritionForDay(date: string) {
    return createSelector([NutritionState], (state: NutritionStateModel) => {
      return !!state.data[date] ? state.data[date] : null;
    });
  }

  @Selector()
  static activeNutritionData(state: NutritionStateModel) {
    const { activeDate } = state;
    if (isSimpleNutritionType(state.data[activeDate].type)) {
      return (
        !!state.data[activeDate].data &&
        !!state.data[activeDate].data['nutrition']
          ? state.data[activeDate].data['nutrition']
          : []
      ) as SimpleMeal[];
    } else {
      return state.data[activeDate] as NutritionResponse;
    }
  }

  @Selector()
  static simpleMacros(state: NutritionStateModel) {
    const { activeDate, data } = state;
    return data[activeDate].configuration.macros;
  }

  @Selector()
  static activeDate(state: NutritionStateModel) {
    return state.activeDate;
  }

  @Selector()
  static activeSupplementData(state: NutritionStateModel) {
    const { activeDate } = state;
    if (isSimpleNutritionType(state.data[activeDate].type)) {
      return !!state.data[activeDate].data &&
        !!state.data[activeDate].data['supplements']
        ? state.data[activeDate].data['supplements']
        : {};
    }
    return null;
  }

  public saveData(ctx: StateContext<NutritionStateModel>, date = null) {
    const payload = createStatePayload(ctx, date);
    this.nextState$.next(payload);
  }

  constructor(
    private errorService: ErrorsService,
    private http: HttpClient,
    private store: Store,
    private userService: UserService,
    private macroCalculator: MacroCalculatorService,
  ) {
    this.nextState$ = new Subject<StatePayload>();

    this.nextState$
      .pipe(
        groupBy(v => v.activeData.id),
        mergeMap(group => from(group).pipe(debounce(() => interval(2500)))),
        switchMap(payload => this.uploadPayload(payload)),
      )
      .subscribe();
  }

  uploadPayload({ dateToSave, activeData, payload }) {
    // Specify our own retries.
    // Right here we could hook into a single day's saving—so that if we wanted
    // to follow the saving process from start to finish, we could "start" here
    // and end below in the second pipe.
    const NO_RETRIES = { headers: { 'X-No-Retry': 'true' } };
    return (
      activeData.id
        ? this.http.put(
            apiUrl('nutrition/day/' + activeData.id),
            payload,
            NO_RETRIES,
          )
        : this.http.post(
            apiUrl('nutrition/day/' + dateToSave),
            payload,
            NO_RETRIES,
          )
    ).pipe(
      retryWhen(
        genericRetryStrategy({ maxRetryAttempts: 150, scalingDuration: 2500 }),
      ),
      handleApiError(),
    );
  }

  @Action(StoreNutritionFoodData)
  storeNutritionFoodData(
    ctx: StateContext<NutritionStateModel>,
    { meal, mealItem, nutritionItem }: StoreNutritionFoodData,
  ) {
    const state = ctx.getState();

    const data = { ...state.data };

    const currentNutritionData = JSON.parse(
      JSON.stringify(state.data[state.activeDate]),
    );
    const activeNutritionData = currentNutritionData.data.nutrition;

    const mealIndex = activeNutritionData.findIndex(m => m.name === meal.name);
    activeNutritionData[mealIndex] = { ...activeNutritionData[mealIndex] };

    const foodIndex = activeNutritionData[mealIndex].items.findIndex(
      f => f.type === mealItem.type,
    );
    activeNutritionData[mealIndex].items[foodIndex].selectedNutrition =
      nutritionItem;

    data[state.activeDate] = currentNutritionData;

    ctx.patchState({ data });
    this.saveData(ctx);
  }

  @Action(ResetNutritionState)
  resetNutritionState(ctx: StateContext<NutritionStateModel>) {
    ctx.setState(NUTRITION_DEFAULT_STATE);
  }

  @Action(SetActiveNutritionType)
  setActiveNutrition(
    ctx: StateContext<NutritionStateModel>,
    { type }: SetActiveNutritionType,
  ) {
    ctx.patchState({ activeNutrition: type });
  }

  @Action(ToggleExpandSupplements)
  expandSupplements(ctx: StateContext<NutritionStateModel>) {
    const { activeDate, data } = ctx.getState();

    if (!isSimpleNutritionType(data[activeDate].type)) {
      return;
    }

    ctx.setState(
      produce(draft => {
        draft.data[activeDate].data['supplements'].expanded =
          !draft.data[activeDate].data['supplements'].expanded;
      }),
    );

    this.saveData(ctx);
  }

  @Action(ToggleExpandMeal)
  expandMeal(
    ctx: StateContext<NutritionStateModel>,
    { activeDate, mealIndex }: ToggleExpandMeal,
  ) {
    const { data } = ctx.getState();

    if (!isSimpleNutritionType(data[activeDate].type)) {
      return;
    }

    ctx.setState(
      produce(draft => {
        draft.data[activeDate].data['nutrition'][mealIndex].expanded =
          !draft.data[activeDate].data['nutrition'][mealIndex].expanded;
      }),
    );

    this.saveData(ctx);
  }

  @Action(ToggleSupplementUsed)
  toggleSupplementUsed(
    ctx: StateContext<NutritionStateModel>,
    { supplementIndex }: ToggleSupplementUsed,
  ) {
    const { activeDate, data } = ctx.getState();

    if (isSimpleNutritionType(data[activeDate].type)) {
      ctx.setState(
        produce(draft => {
          draft.data[activeDate].data['supplements'].items[
            supplementIndex
          ].selectedNutrition = !(<Supplements>(
            data[activeDate].data['supplements']
          )).items[supplementIndex].selectedNutrition;
        }),
      );
      this.saveData(ctx);
    }
  }

  @Action(LoadNutritionForDay)
  loadNutritionForDay(
    ctx: StateContext<NutritionStateModel>,
    { date }: LoadNutritionForDay,
  ) {
    // Do we have any existing nutrition data? If we do, and it matches the current activeNutritionType, then go ahead
    // and load it up immediately.
    const state = ctx.getState();

    const existingData$ = this.loadNutrition(
      state,
      date,
      this.getRemoteData$(state, date),
    ).pipe(
      withLatestFrom(this.userService.user$),
      filter(([_, userState]) => !!userState),
      map(([nutritionData, _]) => nutritionData),
    );

    // Take the responses from the existing data (if any) and the API data, and update the nutrition state.
    // Furthermore, partition the data so that complex and simple days are handled differently.
    const [complexDays, simpleDays] = partition(
      existingData$,
      nd => nd.type === NutritionPlan.CalorieMacroCounting,
    );

    return merge(
      complexDays.pipe(
        map(nd =>
          this.handleComplexLoad(nd as NutritionResponse<NutritionData>),
        ),
      ),
      simpleDays.pipe(
        map(nd => this.handleSimpleLoad(nd as SimpleNutritionResponse)),
      ),
    ).pipe(
      tap(dayData => {
        ctx.setState(
          produce(draft => {
            draft.data[date] = dayData;
          }),
        );
      }),
    );
  }

  /**
   * Get the remote data.
   * @param state
   * @param date
   */
  public getRemoteData$(state: NutritionStateModel, date: string) {
    return this.http
      .get<NutritionResponse>(apiUrl('nutrition/day/' + date))
      .pipe(
        catchError(() => {
          return of<NutritionResponse>({
            id: null,
            track_date: date,
            type: state.activeNutrition,
            transphormer_id: null,
            data: null,
            water: 0,
            configuration: null,
          });
        }),
        map(response =>
          applyConfigurationIfMissing(
            response,
            this.getNutritionConfiguration(),
          ),
        ),
      );
  }

  /**
   * Grab the existing data from the state data array if and only if the type matches the
   * activeNutrition type set on the state. This means that if the user changes the value,
   * then we should see the data be loaded and set.
   *
   * @param state
   * @param date
   * @param next
   */
  loadNutrition(
    state: NutritionStateModel,
    date: string,
    next: Observable<NutritionResponse> = EMPTY,
  ) {
    return of(
      state.data[date] && state.data[date].type === state.activeNutrition,
    ).pipe(
      switchMap(val => (val ? of(state.data[date]) : next)),
      filter<NutritionResponse>(
        value => value !== null && value !== undefined && value.id !== null,
      ),
      map((dayData: NutritionResponse) =>
        applyConfigurationIfMissing(dayData, this.getNutritionConfiguration()),
      ),
    );
  }

  getNutritionConfiguration() {
    if (!this.userService.user) {
      return null;
    }

    return {
      macros: this.macroCalculator.calculateMacros(
        this.userService.user,
        activeNutritionForUser(this.userService.user),
      ),
      water_goal: this.macroCalculator.waterIntake(
        this.userService.user.latest_weight.imperial.value,
      ),
      // supplements:
      meals: [
        { meal: 1, expanded: true },
        { meal: 2, expanded: true },
        { meal: 3, expanded: true },
        { meal: 4, expanded: true },
        { meal: 5, expanded: true },
        { meal: 6, expanded: true },
      ],
    };
  }

  /**
   * Set up and load the current "active day" data.
   *
   * @param ctx
   * @param activeDate
   */
  @Action(SetActiveDay)
  setActiveDay(
    ctx: StateContext<NutritionStateModel>,
    { activeDate }: SetActiveDay,
  ) {
    ctx.patchState({ activeDate });
    this.store.dispatch(new LoadNutritionForDay(activeDate));
  }

  @Action(UpdateWaterAmount)
  updateWater(
    ctx: StateContext<NutritionStateModel>,
    { water }: UpdateWaterAmount,
  ) {
    const state = ctx.getState();
    const activeDate = state.activeDate;
    const data = { ...state.data };

    data[activeDate] = { ...state.data[activeDate] };
    data[activeDate].water += water;
    ctx.patchState({ data });
    this.saveData(ctx);
  }

  @Action(UpdateMacros)
  updateMacros(
    ctx: StateContext<NutritionStateModel>,
    { date, macros }: UpdateMacros,
  ) {
    const state = ctx.getState();
    const activeDate = date || state.activeDate;
    const data = { ...state.data };
    data[activeDate] = { ...state.data[activeDate] };
    data[activeDate].configuration = {
      ...state.data[activeDate].configuration,
    };
    data[activeDate].configuration.macros = macros;
    ctx.patchState({ data });
    this.saveData(ctx);
  }

  @Action(InitializeMacros)
  initializeMacros(ctx: StateContext<NutritionStateModel>) {
    const { activeDate } = ctx.getState();

    this.store.dispatch(
      new UpdateMacros(
        activeDate,
        this.macroCalculator.calculateMacros(this.userService.user),
      ),
    );
  }

  @Action(MealVisibilityToggled)
  updateMeal(
    ctx: StateContext<NutritionStateModel>,
    { meal }: MealVisibilityToggled,
  ) {
    const { activeDate, data } = ctx.getState();

    const newData = { ...data };
    newData[activeDate] = { ...data[activeDate] };
    newData[activeDate].data = { ...data[activeDate].data } as NutritionData;

    // Grab all the unrelated foods, and then just append the meal back to the list of foods.
    const allFoods = (newData[activeDate].data as NutritionData).food;
    const unrelatedFoods = allFoods.filter(fi => fi.meal !== meal.meal_count);
    (newData[activeDate].data as NutritionData).food = [
      ...unrelatedFoods,
      ...(meal.food_items as TrackedItem[]),
    ];

    // Update the config.
    const unrelatedMealConfigs = newData[activeDate].configuration.meals.filter(
      i => i.meal !== meal.meal_count,
    );
    newData[activeDate].configuration = { ...data[activeDate].configuration };
    newData[activeDate].configuration.meals = [
      ...unrelatedMealConfigs,
      { meal: meal.meal_count, expanded: meal.active },
    ];

    ctx.patchState({
      data: newData,
    });

    this.saveData(ctx);
  }

  @Action([TrackNewItem, FoodStackTrackedItem])
  trackNewItem(
    ctx: StateContext<NutritionStateModel>,
    { item, meal }: TrackNewItem,
  ) {
    const { activeDate, data } = ctx.getState();

    const newData = produce(data, draft => {
      (draft[activeDate].data as NutritionData).food.push({
        ...item,
        meal,
        localId: v1(),
      });
    });

    ctx.patchState({
      data: newData,
    });

    this.saveData(ctx);
  }

  ngxsAfterBootstrap(ctx?: StateContext<NutritionStateModel>): void {
    this.userService.user$
      .pipe(
        distinctUntilKeysChanged([
          'id',
          'likely_to_do',
          'profile_complete',
          'custom_macros',
          'meals_per_day',
        ]),
        // Only run when we have a valid user AND the user has a profile.
        filter(user => user?.profile_complete === true),
      )
      .subscribe(user => {
        this.store.dispatch(
          new SetActiveNutritionType(activeNutritionForUser(user)),
        );
        this.store.dispatch(new SetTodayDate(new Date()));
        this.store.dispatch(new LoadSupplementConfiguration());
      });

    // Every second, let's see if today is actually today.
    interval(1000)
      .pipe(
        map(_ => ctx.getState().todayDate),
        map(today => !isToday(today)),
        filter(needsUpdate => needsUpdate),
        withLatestFrom(this.userService.user$),
        map(([_, user]) => user),
        filter(user => user !== null && user.profile_complete),
      )
      .subscribe(() => {
        ctx.dispatch(new SetTodayDate(new Date()));
      });
  }

  public createSimpleTrackingDay(plan: NutritionPlan): SimpleNutritionData {
    const nutrition = generateDayFor(plan, this.userService.user.meals_per_day);
    const supplements = {
      name: 'Supplements',
      type: 'supplements',
      expanded: true,
      items: [...this.store.selectSnapshot(SupplementsState.supplements)],
    } as SimpleMeal;

    return <SimpleNutritionData>{
      nutrition_day_id: null,
      nutrition,
      supplements,
    };
  }

  @Action([UpdateTrackedFoodItem, UpdateQuickAdd])
  updateTrackedFoodItem(
    ctx: StateContext<NutritionStateModel>,
    { foodItem, meal }: UpdateTrackedFoodItem,
  ) {
    // Update the item.
    const { activeDate, data } = ctx.getState();

    const currentIndex = (
      data[activeDate].data as NutritionData
    ).food.findIndex(foodEquals(foodItem));

    if (currentIndex === -1) {
      throw new Error('Could not find food when updating.');
    }

    ctx.setState(
      produce(draft => {
        (draft.data[activeDate].data as NutritionData).food[currentIndex] = {
          ...foodItem,
          meal,
        };
      }),
    );

    this.saveData(ctx);
  }

  @Action(RemoveTrackedItem)
  removeTrackedItem(
    ctx: StateContext<NutritionStateModel>,
    { foodItem }: UpdateTrackedFoodItem,
  ) {
    // Remove it and then save the state.
    const { activeDate } = ctx.getState();

    ctx.setState(
      produce(draft => {
        draft.data[activeDate].data.food = (
          draft.data[activeDate].data as NutritionData
        ).food.filter(item => {
          return item.localId !== foodItem.localId;
        });
      }),
    );

    this.saveData(ctx);
  }

  @Action(TrackQuickAdd)
  trackQuickAdd(
    ctx: StateContext<NutritionStateModel>,
    { quickItem, meal }: TrackQuickAdd,
  ) {
    const quickTrackedItem = quickAddToTrackedItem(quickItem);
    return this.store.dispatch(new TrackNewItem(quickTrackedItem, meal));
  }

  @Action(CopyMealsToDay)
  copyMealsToDay(
    ctx: StateContext<NutritionStateModel>,
    { meals, date }: CopyMealsToDay,
  ) {
    const food = meals.reduce(
      (a, c) =>
        a.concat(
          c.food_items.map(fi => ({
            ...fi,
            localId: v1(),
          })),
        ),
      [],
    );

    if (!food.length) {
      return EMPTY;
    }

    const dayData$ = this.store.select<NutritionResponse>(
      NutritionState.nutritionForDay(date),
    );
    return this.store.dispatch(new LoadNutritionForDay(date)).pipe(
      withLatestFrom(dayData$),
      throwIf(
        ([_, dayData]) => dayData.type !== NutritionPlan.CalorieMacroCounting,
        () => new Error('Destination day is not Macro Counting'),
      ),
      tap(() => {
        ctx.setState(
          produce(draft => {
            draft.data[date].updated_at = new Date().toISOString();
            draft.data[date].data.food.push(...food);
          }),
        );
        this.saveData(ctx, date);
      }),
    );
  }

  @Action(CopyDayMealToDay)
  copyDayMealToDay(
    ctx: StateContext<NutritionStateModel>,
    { meal, dateFrom, dateTo }: CopyDayMealToDay,
  ) {
    const dayFromData$ = this.store.selectOnce<NutritionResponse>(
      NutritionState.nutritionForDay(dateFrom),
    );

    return this.store.dispatch(new LoadNutritionForDay(dateFrom)).pipe(
      switchMap(() => dayFromData$),
      throwIf(
        source => source.type !== NutritionPlan.CalorieMacroCounting,
        () => new Error('Source day is not Macro Counting'),
      ),
      switchMap(source => {
        const food_items: TrackedItem[] = (
          source.data as NutritionData
        ).food.filter(ffi => ffi.meal === meal.meal_count);
        const trackedItems: TrackedMeal[] = [
          {
            meal_count: meal.meal_count,
            food_items,
            active: true,
          },
        ];
        return this.store.dispatch(new CopyMealsToDay(trackedItems, dateTo));
      }),
    );
  }

  @Action(SetTodayDate)
  setTodayDate(
    ctx: StateContext<NutritionStateModel>,
    { today: todayDate }: SetTodayDate,
  ) {
    ctx.patchState({ todayDate });
    ctx.dispatch(new SetActiveDay(mySQLFormattedDate(todayDate)));
  }

  @Action(UpdateCmcSupplements)
  updateCmcSupplements(
    ctx: StateContext<NutritionStateModel>,
    { supplements, config }: UpdateCmcSupplements,
  ) {
    const state = ctx.getState();
    const activeDate = state.activeDate;
    const data = { ...state.data };

    data[activeDate] = { ...state.data[activeDate] };
    if (config) {
      data[activeDate].configuration = {
        ...state.data[activeDate].configuration,
      };
      data[activeDate].configuration.cmcSupplements = config;
    }
    data[activeDate].data = { ...state.data[activeDate].data };
    data[activeDate].data.supplements = supplements;
    ctx.patchState({ data });
    this.saveData(ctx);
  }

  handleComplexLoad(dayData: NutritionResponse<NutritionData>) {
    if (trackDateIsTodayOrFuture(dayData.track_date)) {
      const { water_goal, macros } = this.getNutritionConfiguration();

      dayData = produce(dayData, draft => {
        if (dayData.data.supplements.length) {
          // Fill supplements data from day configuration
          draft.configuration.cmcSupplements =
            dayData.configuration.cmcSupplements;
          draft.data.supplements = dayData.data.supplements.map(s => ({
            ...dayData.configuration.cmcSupplements.find(sc => sc.id === s.id),
            taken: s.taken,
          }));
        } else {
          const supplementsConfig = this.store.selectSnapshot(
            SupplementsState.cmcSupplements,
          );
          const st = mergeSupplementsConfig([], supplementsConfig);
          draft.configuration.cmcSupplements = supplementsConfig;
          draft.data.supplements = st.map(
            s =>
              ({
                ...s,
                taken: 0,
              }) as CmcSupplementTracker,
          );
        }
        // Configuration
        draft.configuration.water_goal = water_goal;
        draft.configuration.macros = macros;
      });
    } else {
      // TODO BSG: Check this ELSE statement is what we want according to requirements
      dayData = produce(dayData, draft => {
        if (dayData.data.supplements.length) {
          // Fill supplements data from day configuration
          draft.configuration.cmcSupplements =
            dayData.configuration.cmcSupplements;
          draft.data.supplements = dayData.data.supplements.map(s => ({
            ...dayData.configuration.cmcSupplements.find(sc => sc.id === s.id),
            taken: s.taken,
          }));
        } else {
          draft.configuration.cmcSupplements = [];
          draft.data.supplements = [];
        }
      });
    }
    return { ...dayData, isComplex: true };
  }

  handleSimpleLoad(dayData: SimpleNutritionResponse) {
    if (
      !dayData.data ||
      (dayData.data as SimpleNutritionData).nutrition.length === 0
    ) {
      dayData.data = this.createSimpleTrackingDay(dayData.type);
    }

    const mealsPerDay = +this.userService.user.meals_per_day;

    if (trackDateIsTodayOrFuture(dayData.track_date)) {
      const { water_goal, macros } = this.getNutritionConfiguration();
      dayData = produce(dayData, draft => {
        draft.data.supplements.items = [
          ...this.store.selectSnapshot(SupplementsState.supplements).map(i => {
            const matchingItem = draft.data.supplements.items.find(
              j => j.name === i.name,
            );
            return {
              ...i,
              selectedNutrition: matchingItem
                ? matchingItem.selectedNutrition
                : i.selectedNutrition,
            };
          }),
        ];
        if (
          hasSimpleNutritionData(draft) &&
          hasMoreSimpleNutrition(draft, mealsPerDay)
        ) {
          draft.data.nutrition = [
            ...draft.data.nutrition.slice(0, mealsPerDay),
          ];
        } else if (
          hasSimpleNutritionData(draft) &&
          hasLessSimpleNutrition(draft, mealsPerDay)
        ) {
          const missingDays = mealsPerDay - draft.data.nutrition.length;
          draft.data.nutrition = [
            ...draft.data.nutrition,
            ...generateDays(missingDays, draft.data.nutrition.length),
          ];
        }
        draft.configuration.water_goal = water_goal;
        draft.configuration.macros = macros;
      });
    }
    return { ...dayData, isComplex: false };
  }
}

const applyConfigurationIfMissing = (
  dayData: NutritionResponse,
  configuration,
) => {
  if (!configuration) {
    return dayData;
  }

  if (
    !dayData.configuration ||
    (Array.isArray(dayData.configuration) &&
      dayData.configuration.length === 0) ||
    dayData.configuration.macros === undefined
  ) {
    dayData.configuration = configuration;
  }
  return dayData;
};
