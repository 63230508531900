import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  IonContent,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import * as Rollbar from 'rollbar';
import { Product } from '../../../../services/in-app-purchase/products';
import {
  AnalyticsService,
  InAppPurchaseService,
  OnboardingService,
  ToastService,
  UserService,
} from '../../../../services';
import { RollbarService } from '../../../../rollbar';
import { environment } from '../../../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BranchioService } from '../../../../services/branchio/branchio.service';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  interval,
  Observable,
} from 'rxjs';
import { TierService } from '../../../tiers/services/tiers/tier.service';
import {
  PaymentTierPrice,
  PaymentTierWithPricing,
} from '../../../tiers/interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import {
  addPricingToPaymentTier,
  extractProductDetails,
} from '../../../tiers/helpers';
import { MixPanelService } from '../../../../services/mix-panel/mix-panel.service';

@UntilDestroy()
@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeComponent implements OnInit {
  isPurchasing$ = new BehaviorSubject(null);
  @ViewChild('subscribeContent', { static: true }) subscribeContent: IonContent;

  tierList$: Observable<PaymentTierWithPricing[]>;

  // These are used to send information to Branch. Because the await call to
  //purchase the product doesn't always result in a purchase (e.g. the user can
  // cancel) we wait for the user to go premium before we send the information
  // to Branch. This requires a little bit of a hack because if we hook into
  // Branch directly, we may get things like renewals or other items that we do
  // not care about.
  hasAlreadyBeenCalled = false;
  productPurchasing: Product;
  @Input() callBranch = true;

  // The products we are displaying.
  displayProducts$: Observable<Product[]> =
    this.purchaseService.productsAvailableForPurchase$.pipe(
      tap(products => {
        this.selectedSku$.next(products[0]?.id || undefined);
      }),
    );

  // The SKU of the selected Product.
  selectedSku$ = new BehaviorSubject<PaymentTierPrice['sku'] | undefined>(
    undefined,
  );

  // The currently selected product. Based off of the selected SKU and the
  // displayed Products above. This is because in Google we may not have access
  // to all SKUs due to restrictions on the user.
  selectedProduct$: BehaviorSubject<Product> = new BehaviorSubject(null);

  constructor(
    private purchaseService: InAppPurchaseService,
    public modalCtrl: ModalController,
    private platform: Platform,
    private branchService: BranchioService,
    private toastSvc: ToastService,
    private userService: UserService,
    private mixPanel: MixPanelService,
    private onboardingService: OnboardingService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private analyticsService: AnalyticsService,
    private tierService: TierService,
    private loadingCtrl: LoadingController,
  ) {
    combineLatest([this.displayProducts$, this.selectedSku$])
      .pipe(
        map(([displayProducts, sku]) =>
          displayProducts.find(p => p.id === sku),
        ),
        tap(t => this.selectedProduct$.next(t)),
        shareReplay(1),
      )
      .subscribe();

    this.tierList$ = this.tierService.tierListWithSkus$.pipe(
      filter(tList => !!tList),
      withLatestFrom(
        this.displayProducts$.pipe(map(p => extractProductDetails(p))),
      ),
      map(([tierList, products]) =>
        tierList.map(tier => addPricingToPaymentTier(tier, products)),
      ),
    );
  }

  ngOnInit() {
    // Dismiss the modal when the user has gone premium.
    this.userService.user$
      .pipe(
        untilDestroyed(this),
        filter(user => user && user.is_paid_user),
        tap(() => this.sendBranchPurchaseInfo()),
      )
      .subscribe(() => this.modalCtrl.dismiss(true, 'subscribed'));

    // Reload the user every 10 seconds. Just in case. ¯\_(ツ)_/¯
    this.userService.user$
      .pipe(
        untilDestroyed(this),
        switchMap(user =>
          user?.is_paid_user === false ? interval(10000) : EMPTY,
        ),
        switchMap(() => this.onboardingService.fetchOnBoard()),
      )
      .subscribe();
  }

  private async sendBranchPurchaseInfo() {
    if (!this.callBranch || this.hasAlreadyBeenCalled) {
      return;
    }

    // After the purchase has successfully completed, send the product information to Branch
    this.branchService.sendProductPurchase(
      this.productPurchasing.priceMicros * 0.000001,
      this.productPurchasing.billingPeriodUnit,
    );
    await this.analyticsService.FASendProductPurchase(
      this.productPurchasing.priceMicros * 0.000001,
      this.productPurchasing.billingPeriodUnit,
    );
    this.hasAlreadyBeenCalled = true;
  }

  async showLoader() {
    const loader = await this.loadingCtrl.create({
      message: 'Loading Store...',
    });

    await loader.present();
  }

  hideLoader() {
    this.loadingCtrl.dismiss();
  }

  public async handleSubscription(product: Product) {
    if (this.isPurchasing$.getValue() === true) {
      return;
    }
    this.productPurchasing = product;
    this.isPurchasing$.next(true);

    this.showLoader();

    await this.purchaseService
      .purchase(product.id)
      .then(() => {
        this.mixPanel.track('Go Premium: Purchase Successful', { ...product });
        this.reset(); // Give the system 2 seconds and then reset the _isPurchasing state.
      })
      .catch((error: CdvPurchase.IError) => {
        this.reset();

        // The user cancelled. This isn't an error. Just ignore it.
        if (error.code === 6777006) {
          return;
        }

        this.rollbar.debug(
          `Unable to process payment ${JSON.stringify(error)}`,
        );
        this.rollbar.warning('Got error processing payment', error);
        this.toastSvc
          .flash(
            `Unable to process payment ${this.purchaseService.getErrorDescription(error)}`,
          )
          .then();
      });
  }

  private reset() {
    this.hideLoader();
    this.isPurchasing$.next(false);
  }

  public get isMobile(): boolean {
    return this.platform.is('capacitor');
  }

  async upgrade() {
    const selectedProduct = this.selectedProduct$.getValue();

    if (!selectedProduct) {
      this.toastSvc.flash('Please choose your pricing!').then();
      return;
    }

    if (!environment.production && !this.isMobile) {
      this.userService.goPremium();
      return;
    }

    if (!this.isMobile) {
      this.toastSvc.flash('Not supported on this platform.').then();
      return;
    }

    this.handleSubscription(selectedProduct);
  }

  scrollToBottom() {
    this.subscribeContent.scrollToBottom(250).then();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  handleErr(err: HttpErrorResponse) {
    this.toastSvc.flash(`Error: ${err.message}`);
  }
}
