import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-att-pre-prompt',
  templateUrl: './att-pre-prompt.component.html',
  styleUrls: ['./att-pre-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttPrePromptComponent {
  @Input() promptText = '';

  constructor(private modalCtrl: ModalController) {}

  showPrompt(): void {
    this.modalCtrl.dismiss(true);
  }
}
