import {
  CustomFoodTemplate,
  TrackableItem,
  TrackedItem,
} from '../../interfaces';

export function convertCustomTrackedItemToTrackableItem(
  detail: TrackedItem | CustomFoodTemplate,
): TrackableItem {
  if (isCustomFoodTemplate(detail)) {
    return <TrackableItem>{
      source: 'custom',
      source_type: 'custom',
      source_id: null,
      name: detail.name,
      thumbnail: detail.picture_url,
      serving_size: {
        carbs: detail.carbs,
        calories: detail.calories,
        fats: detail.fats,
        protein: detail.protein,
        fiber: detail.fiber,
        unit: detail.serving_unit,
        amount: detail.serving_amount,
      },
      default_consumption_value: {
        amount: 1,
        unit: detail.serving_unit,
      },
      amount_consumed: {
        unit: detail.serving_unit,
        amount: 0,
      },
      servings: [],
    };
  } else {
    return <TrackableItem>{
      source: 'custom',
      source_type: detail.source_type,
      source_id: detail.source_id,
      localId: null,
      id: null,
      name: detail.name,
      thumbnail: null,
      serving_size: detail.serving_size,
      default_consumption_value: {
        amount: detail.consumed.amount || detail.serving_size.amount,
        unit: detail.consumed.unit || detail.serving_size.unit,
      },
      amount_consumed: {
        unit: null,
        amount: null,
      },
      servings: detail.servings,
    };
  }
}

export function isCustomFoodTemplate(food): food is CustomFoodTemplate {
  return (
    food.hasOwnProperty('serving_amount') &&
    food.serving_amount &&
    food.hasOwnProperty('serving_unit') &&
    food.serving_unit &&
    food.hasOwnProperty('name') &&
    food.name
  );
}

export function isTrackedFood(food): food is TrackedItem {
  return (
    food.hasOwnProperty('serving_size') &&
    food.serving_size &&
    food.hasOwnProperty('servings') &&
    food.servings &&
    food.hasOwnProperty('source') &&
    food.source &&
    food.hasOwnProperty('source_type') &&
    food.source_type &&
    food.hasOwnProperty('source_id') &&
    food.source_id
  );
}
