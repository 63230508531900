import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-meal-chooser',
  templateUrl: './meal-chooser.component.html',
  styleUrls: ['./meal-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MealChooserComponent {
  @Input() title;
  @Input() selectedMeal: number;
  @Input() numberOfMeals = 6;
  @Output() mealChanged = new EventEmitter<number>();

  get meals() {
    return [...Array(this.numberOfMeals).keys()].map(i => i + 1);
  }

  trackByMeal(index: number, _) {
    return index;
  }
}
