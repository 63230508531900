import { Inject, Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { Observable, Subject } from 'rxjs';
import { PusherAuthenticated } from '../pusher-factory';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedPusherService {
  constructor(@Inject(PusherAuthenticated) private pusher: Pusher) {}

  openChannel(channelName: string) {
    this.pusher.subscribe(channelName);
  }

  closeChannel(channelName: string) {
    this.pusher.unsubscribe(channelName);
  }

  openEvent<T>(eventName: string): Observable<T> {
    const subject = new Subject<T>();

    this.pusher.bind(eventName, data => {
      subject.next(data as T);
    });

    return subject.asObservable();
  }

  closeEvent(eventName, callback?: CallableFunction) {
    this.pusher.unbind(eventName, callback);
  }
}
