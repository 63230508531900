import { TrackedItem } from '../../interfaces';
import { ScratchpadMode, ServingMode } from './scratchpad.state';

export class Clear {
  static readonly type = '[Nutrition Scratchpad] Clear';
}

export class Import {
  static readonly type = '[Nutrition Scratchpad] Import';

  constructor(public items: TrackedItem[] = []) {}
}

export class SetName {
  static readonly type = '[Nutrition Scratchpad] Set Name';

  constructor(public name: string) {}
}

export class SetImage {
  static readonly type = '[Nutrition Scratchpad] Set Image';

  constructor(public picture: string) {}
}

export class Add {
  static readonly type = '[Nutrition Scratchpad] Add';

  constructor(public trackedItem: TrackedItem) {}
}

export class Update {
  static readonly type = '[Nutrition Scratchpad] Update';

  constructor(public trackedItem: TrackedItem) {}
}

export class Remove {
  static readonly type = '[Nutrition Scratchpad] Remove';

  constructor(public trackedItem: TrackedItem) {}
}

export class ChangeMode {
  static readonly type = '[Nutrition Scratchpad] Change Mode';

  constructor(public mode: ScratchpadMode) {}
}

export class AddServing {
  static readonly type = '[Nutrition Scratchpad] Add Serving';
  public readonly amount = 1;
}

export class SetServing {
  static readonly type = '[Nutrition Scratchpad] Set Serving';

  constructor(public amount: number) {}
}

export class SetServingUnit {
  static readonly type = '[Nutrition Scratchpad] Set Serving Unit';

  constructor(public unit: string) {}
}

export class RemoveServing {
  static readonly type = '[Nutrition Scratchpad] Remove Serving';
  public readonly amount = -1;
}

export class SetServingMode {
  static readonly type = '[Nutrition Scratchpad] Serving Mode';

  constructor(public mode: ServingMode) {}
}
