import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserDashboardResponse } from '../../interfaces';
import { apiUrl } from '../../helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(protected http: HttpClient) {}

  public dashboard(features: string[] = []): Observable<UserDashboardResponse> {
    const featureList =
      features.length > 0 ? '?include=' + features.join(',') : '';
    return this.http.get<UserDashboardResponse>(
      apiUrl(`user-dashboard${featureList}`),
    );
  }
}

export enum DashboardFeatures {
  Motivation = 'motivation',
  WeekInReview = 'week-in-review',
  DailyTasks = 'power-list',
  WeeklyChecklist = 'checklist',
  WinnerVideo = 'winner-video',
  NoWorkout = '!workout',
}
