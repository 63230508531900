import {
  CustomFoodTemplate,
  FoodItem,
  MealTemplate,
  Recipe,
} from '../../interfaces';

export class Clear {
  static readonly type = '[My Foods] Clear';
}

export class LoadFoods {
  static readonly type = '[My Foods] Load Foods';
}

export class UpdateRecipe {
  static readonly type = '[My Foods] Update Recipe';

  constructor(public recipe: Recipe) {}
}

export class AddRecipe {
  static readonly type = '[My Foods] Add Recipe';

  constructor(public recipe: Recipe) {}
}

export class DeleteRecipe {
  static readonly type = '[My Foods] Delete Recipe';

  constructor(public recipe: Recipe) {}
}

export class AddMeal {
  static readonly type = '[My Foods] Add Meal';

  constructor(public meal: MealTemplate<FoodItem>) {}
}

export class UpdateMeal {
  static readonly type = '[My Foods] Update Meal';

  constructor(public meal: MealTemplate<FoodItem>) {}
}

export class DeleteMeal {
  static readonly type = '[My Foods] Delete Meal';

  constructor(public meal: MealTemplate<FoodItem>) {}
}

export class AddFood {
  static readonly type = '[My Foods] Add Food';

  constructor(public food: CustomFoodTemplate) {}
}

export class UpdateFood {
  static readonly type = '[My Foods] Update Food';

  constructor(public food: CustomFoodTemplate) {}
}

export class DeleteFood {
  static readonly type = '[My Foods] Delete Food';

  constructor(public food: CustomFoodTemplate) {}
}

export class UpdateCustomFood {
  static readonly type = '[My Foods] Update Custom Food';

  constructor(public customFood: CustomFoodTemplate) {}
}
