import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Recipe, TrackableItem, TrackedItem } from '../../interfaces';
import { apiUrl } from '../../../../helpers';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WrappedApiResponse } from '../../../../interfaces';
import { handleApiError } from '../../../../helpers/operators';
import {
  calculateMacrosForTrackedItems,
  calculateServingSize,
} from '../../state/nutrition/functions';
import { MealEditorRecipe } from '../../components/meal-editor/meal-editor.component';

@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  constructor(protected http: HttpClient) {}

  public getTrackableItem(result): TrackableItem {
    return <TrackableItem>{
      source: 'recipe',
      source_type: 'recipe',
      source_id: result.id,
      brand_name: 'Recipe',
      name: result.name,
      thumbnail: result.picture,
      serving_size: {
        carbs: result.carbs,
        calories: result.calories,
        fats: result.fats,
        protein: result.protein,
        fiber: result.fiber,
        unit: result.serving_size.unit,
        amount: result.serving_size.amount,
      },
      default_consumption_value: {
        amount: 1,
        unit: result.serving_size.unit,
      },
      amount_consumed: {
        amount: 0,
        unit: result.serving_size.unit,
      },
      servings: [],
    };
  }

  createFromTrackedItems(
    name: string,
    items: TrackedItem[],
    servingUnit: string,
    servings: number,
    picture: string,
  ) {
    const allMacros = calculateMacrosForTrackedItems(items);
    const servingMacros = calculateServingSize(allMacros, servings);

    return this.http
      .post<WrappedApiResponse<Recipe>>(apiUrl('nutrition/recipe'), {
        name,
        items,
        ...servingMacros,
        serving_size: {
          // The serving amount is the amount that the recipe makes.
          amount: servings,
          unit: servingUnit || 'serving',
        },
        picture,
      })
      .pipe(map(response => response.data));
  }

  getAvailableServings() {
    return of([
      {
        label: 'serving(s)',
        measure: 'serving',
        qty: 1,
        serving_weight: 1,
        seq: 0,
      },
      {
        label: 'slice(s)',
        measure: 'slice',
        qty: 1,
        serving_weight: 1,
        seq: 1,
      },
      { label: 'cup(s)', measure: 'cup', qty: 1, serving_weight: 1, seq: 2 },
      {
        label: 'ounce(s)',
        measure: 'ounces',
        qty: 1,
        serving_weight: 1,
        seq: 3,
      },
      { label: 'gram(s)', measure: 'grams', qty: 1, serving_weight: 1, seq: 4 },
      { label: 'ml(s)', measure: 'ml', qty: 1, serving_weight: 1, seq: 5 },
    ]);
  }

  searchForTerm(term: string) {
    const requestParams = {
      params: {
        name: term || '',
      },
    };

    return this.http
      .get<
        WrappedApiResponse<TrackableItem[]>
      >(apiUrl('nutrition/search/recipes'), requestParams)
      .pipe(
        switchMap(results => {
          if (!!results === false) {
            return of([]);
          }
          return of(results.data);
        }),
        catchError(() => of([])),
      );
  }

  getRecipes(): Observable<Recipe[]> {
    return this.http
      .get<WrappedApiResponse<Recipe[]>>(apiUrl('nutrition/recipes'))
      .pipe(
        map(result => result.data),
        handleApiError(),
      );
  }

  update(recipe: Recipe, update: Partial<MealEditorRecipe>) {
    const allMacros = calculateMacrosForTrackedItems(update.items);
    const servingMacros = calculateServingSize(allMacros, update.servings);

    return this.http
      .put<WrappedApiResponse<Recipe>>(
        apiUrl(`nutrition/recipe/${recipe.id}`),
        {
          ...update,
          ...servingMacros,
          serving_size: {
            // The serving amount is the amount that the recipe makes.
            amount: update.servings,
            unit: update.servingUnit || 'serving',
          },
        },
      )
      .pipe(
        map(result => result.data),
        handleApiError(),
      );
  }

  delete(recipe: Recipe) {
    return this.http
      .delete<
        WrappedApiResponse<Recipe>
      >(apiUrl(`nutrition/recipe/${recipe.id}`))
      .pipe(handleApiError());
  }
}
