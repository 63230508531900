import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  SubscriptionApplyProducts,
  SubscriptionExpired,
  SubscriptionOwned,
  SubscriptionStateSave,
} from '../subscription.actions';
import 'cordova-plugin-purchase/www/store.d';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import * as Rollbar from 'rollbar';
import { RollbarService } from '../../rollbar';
import { Inject, Injectable } from '@angular/core';
import { StorageService } from '../../services';

export const SUBSCRIPTION_STATE_TOKEN = new StateToken<SubscriptionStateModel>(
  'iap',
);

export interface SubscriptionStateModel {
  ownedProducts: CdvPurchase.Product[];
  userId: number | null;
  expirationDate: Date | null;
}

@State<SubscriptionStateModel>({
  name: SUBSCRIPTION_STATE_TOKEN,
  defaults: {
    ownedProducts: [],
    expirationDate: null,
    userId: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class SubscriptionState {
  constructor(
    private store: Store,
    private storage: StorageService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  @Action(SubscriptionOwned)
  owned(ctx: StateContext<SubscriptionStateModel>, payload: SubscriptionOwned) {
    // const notCurrentlySubscribed = ctx.getState().ownedProducts.length === 0;
    ctx.setState(
      patch({
        ownedProducts: append([payload.product]),
      }),
    );
    // if (notCurrentlySubscribed) {
    //   ctx.dispatch(new SubscriptionActivated());
    // }
    ctx.dispatch(new SubscriptionStateSave());
  }

  @Action(SubscriptionExpired)
  expired(
    ctx: StateContext<SubscriptionStateModel>,
    payload: SubscriptionExpired,
  ) {
    // const hadSubscription = ctx.getState().ownedProducts.length !== 0;
    ctx.setState(
      patch({
        ownedProducts: removeItem<CdvPurchase.Product>(
          item => item.id === payload.product.id,
        ),
      }),
    );
    // const notCurrentlySubscribed = ctx.getState().ownedProducts.length === 0;
    // if (hadSubscription && notCurrentlySubscribed) {
    //   ctx.dispatch(new SubscriptionDeactivated());
    // }
    ctx.dispatch(new SubscriptionStateSave());
  }

  @Action(SubscriptionStateSave)
  save(ctx: StateContext<SubscriptionStateModel>) {
    this.storage.set('subscriptionState', ctx.getState());
  }

  @Action(SubscriptionApplyProducts)
  async applyProducts(
    ctx: StateContext<SubscriptionStateModel>,
    payload: SubscriptionApplyProducts,
  ) {
    const currentProducts = ctx.getState().ownedProducts;

    // Add any products which are not currently listed in the given products but which are owned.
    const ownedProducts = payload.products.filter(
      p => p.type === 'paid subscription' && p.owned,
    );
    ownedProducts.forEach(product => {
      const existingProduct = currentProducts.find(i => i.id === product.id);
      if (!existingProduct) {
        // We have a new purchase, append it.
        ctx.dispatch(new SubscriptionOwned(product));
      } else {
        // If we have an existing, we can update it here.
        ctx.setState(
          patch({
            ownedProducts: updateItem(i => i.id === product.id, product),
          }),
        );
      }
    });

    // Now, process the products which the payload says are not currently owned but are in the owned items.
    payload.products
      .filter(p => p.type === 'paid subscription' && !p.owned)
      .forEach((product: CdvPurchase.Product) => {
        const existingProduct = currentProducts.find(i => i.id === product.id);
        if (existingProduct) {
          ctx.dispatch(new SubscriptionExpired(product));
        }
      });
  }
}
