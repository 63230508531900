import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { MessageListPayload } from '../../interfaces/message';
import { TransfersState } from '../../../../store/states/transfers.state';
import { Transfer, TransferState } from '../../../../interfaces';
import {
  RemoveTransfer,
  RetryDirectUpload,
  UpdateProgress,
} from '../../../../store/actions/transfers.actions';
import { PendingMessageListEntitiesState } from '../../state/pending-message-list/pending-message-list.state';
import { UserProfilesEntitiesState } from '../../state/user-profiles/user-profiles.state';
import { DirectUploadMessageContext } from '../../services/attachments-3/message-attachment.service';
import { ChatListEntitiesState } from '../../state/chat-list/chat-list.state';

@Component({
  selector: 'app-pending-messages',
  templateUrl: './pending-messages.component.html',
  styleUrls: ['./pending-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingMessagesComponent implements OnInit {
  pendingMessageList$ = this.pendingMessageListEntitiesState.entitiesArray$;

  @Select(TransfersState.uploads) transferList$: Observable<
    Transfer<DirectUploadMessageContext>[]
  >;

  // We'll want an observable to keep track of how many Pending Messages we have in the queue.
  pendingMessageCount$: Observable<number>;
  // Toggle the popOver which holds the pending message info.
  togglePopOver$ = new BehaviorSubject<boolean>(false);
  // The transferProgressList will be responsible for storing the PendingMessage's attachments' upload state.
  // The uuid will be used to reference the item back to the PendingMessages.
  transferProgressList$ = new BehaviorSubject<
    {
      id?: MessageListPayload['id'];
      progress: number;
      uploadState: MessageListPayload['status'];
    }[]
  >([]);

  constructor(
    private store: Store,
    private navController: NavController,
    private pendingMessageListEntitiesState: PendingMessageListEntitiesState,
    private userProfileEntitiesState: UserProfilesEntitiesState,
    private chatListEntitiesState: ChatListEntitiesState,
  ) {
    // The pending messages and the transfer list need to be observed in order to get the local transferProgressList$
    // We don't want to create an infinite loop by updating the progress on the Pending Message itself, so instead we keep
    // track of the current progress list in the transferProgressList$ BehaviorSubject.
    combineLatest([this.pendingMessageList$, this.transferList$])
      .pipe(
        tap(([pmList, tList]) => {
          // We want to iterate through each Pending Message to calculate its attachments' transfers' progress.
          pmList.forEach(pm => {
            // Now that we have the pending message, we want a list of its transfer IDs which can be grabbed from the Pending Message's
            // attachment list.
            const tUuidList = pm.messageAttachments.flatMap(
              ma => ma.transfer.id,
            );

            // Grab the preview transfers
            const pTUuidList = pm.messageAttachments
              .flatMap(ma => ma?.previewTransfer?.id ?? null)
              ?.filter(pT => pT);
            const uuidList = [...tUuidList, ...pTUuidList];

            // Now that we have the transfer ID list for the whole attachment list, we want to grab the progress from the actual transfers themselves by accessing the
            // TransfersState.uploads state selector.
            const pmTransferList = tList
              .filter(t => uuidList.includes(t.id))
              .map(t => t.progress) || [0];

            // Let's create a local var where we can store the calculated progress for all the attachments' transfers.
            let progress: number;

            // We'll set the initial uploading state to uploading.
            let uploadState: 'sending' | 'failed' = 'sending';

            // If the transfer list has transfers in it, we'll calculate the overall progress.
            if (pmTransferList.length > 0) {
              // We'll calculate progress by iterating through pmTransferList and dividing it by the total transfers in
              // the Pending Messages' attachment list.
              progress =
                pmTransferList.reduce((a, b) => a + b) / pmTransferList.length;

              // Now let's calculate the uploadState for the Pending Message. If the predicated transfer
              // is in any of the attachments from the attachment list in the current Pending Message and its `state` is that
              // of TransferState.Error, then we'll go ahead an mark the Pending Message state as `failed` so that it can be
              // retried.

              uploadState =
                tList.filter(
                  t =>
                    uuidList.includes(t.id) && t.state === TransferState.Error,
                ).length > 0
                  ? 'failed'
                  : 'sending';
            } else {
              // If there are no transfers on the current Pending Message attachment list,
              // we'll continue onto the next element in the loop;
              return;
            }

            // Let's get the value of the current transferProgressList$
            let m = this.transferProgressList$.getValue();

            // Let's get the current index of the Pending Message in relation to the transferProgressList$. If there's no
            // elements in the transferProgressList$, then we can assume it's the first Pending Message being added onto the
            // list and assign it the first index in the array.
            const i = m.length > 0 ? m.findIndex(ppmm => ppmm.id === pm.id) : 0;

            // If the overall transfer progress for the attachment is that of 100, then the uploads for the Pending Message
            // have finished, we can remove it from the array.
            if (progress === 100) {
              m = m.slice(i, -1); // Remove the transfer progress for the upload from the array.
            } else {
              i > -1 // If the index exists
                ? (m[i] = { ...pm, progress, uploadState }) // Update the transferProgressList$ element to match the current transfer state
                : m.push({ ...pm, progress, uploadState }); // else let's push the new pending message state into the transferProgressList$ array.
            }

            if (m.length > 0) {
              this.transferProgressList$.next(m); // only push onto transferProgressList$ if there's matching transfers.
            } else {
              this.transferProgressList$.next([]); // push an empty array if there's no matching transfers or all of them have completed.
            }
          });
        }),
      )
      .subscribe();

    // Make sure the popOver is closed if there aren't any pendingMessages.
    this.pendingMessageList$
      .pipe(
        tap(pmList => {
          if (pmList.length === 0) {
            this.togglePopOver$.next(false);
          }
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    // Let's set an observable stream to count how many pending messages there on the list.
    this.pendingMessageCount$ = this.pendingMessageList$.pipe(
      map(i => i?.length),
    );
  }

  togglePopOver() {
    // Toggle the popOver that lists the Pending Messages list.
    this.togglePopOver$.next(!this.togglePopOver$.getValue());
  }

  /**
   * Remove the pending message from the store along with its uploads.
   * @param message
   */
  cancelUpload(message: MessageListPayload) {
    message.messageAttachments.forEach(attachment => {
      // Remove the original file transfer for the attachment
      this.store.dispatch(new RemoveTransfer(attachment.transfer.id));

      // Remove the previewTransfer if any for the attachment
      if (attachment.previewTransfer) {
        this.store.dispatch(new RemoveTransfer(attachment.previewTransfer.id));
      }
    });

    // After all the attachments' transfers have been canceled then we can remove the message.
    this.pendingMessageListEntitiesState.removePendingMessage(message.id);
  }

  /**
   * Go to the chat with the pending upload.
   * @param message
   */
  goToConversation(message: MessageListPayload) {
    const toId =
      this.chatListEntitiesState.snapshot.entities[message.chatUuid]
        .transphormerId;
    this.navController.navigateForward([
      `/advisor/transphormer/${toId}/advisor-messages`,
    ]);
  }

  /**
   * You can retry the upload if it failed.
   * @param message
   */
  retryUpload(message: MessageListPayload) {
    // Let's first filter through the attachment uploads on the attachment service to find the Pending Message's attachment list's
    // attachment uploads.
    this.pendingMessageList$
      .pipe(
        // we'll only need one iteration of the stream
        take(1),
        // we'll only need the attachment uploads that match this pending message's uuid.
        map(m => m.find(m => m.id === message.id)),
        tap(m => {
          // Let's iterate through each of these attachment uploads that belong to this pending message. We'll do this to
          // find and retry the failed transfers for the pending message.
          m.messageAttachments.forEach(mA => {
            // filter only the transfer IDS of the attachments that failed to upload.
            this.transferList$
              .pipe(
                take(1),
                tap(tList => {
                  // Let's get a list of original file transfers that have failed so that we can retry them.
                  const originalToRetry = tList.find(
                    t =>
                      mA.transfer.id === t.id &&
                      t.state === TransferState.Error,
                  );

                  if (originalToRetry) {
                    // reset the transfer's progress and state to its initial values.
                    this.store.dispatch(
                      new UpdateProgress({
                        ...originalToRetry,
                        state: TransferState.Sending,
                        progress: 0,
                      }),
                    );

                    // Set the uploadState of the transfer in transferProgressList$ to 'sending'
                    let updateTProgressList =
                      this.transferProgressList$.getValue();

                    // Find the transfer we need to update.
                    const theUpdatedTransfer = updateTProgressList.find(
                      p => p.id === originalToRetry.context.messageUuid,
                    );
                    theUpdatedTransfer.uploadState = 'sending';
                    theUpdatedTransfer.progress = 0;

                    // Remove the transfer in question so that we don't duplicate it when updating the behavior subject.
                    updateTProgressList = updateTProgressList.filter(
                      t => t.id !== theUpdatedTransfer.id,
                    );

                    // Update the behavior subject.
                    this.transferProgressList$.next([
                      theUpdatedTransfer,
                      ...updateTProgressList,
                    ]);

                    // fire off the upload again.
                    this.store.dispatch(
                      new RetryDirectUpload(
                        originalToRetry,
                        originalToRetry.remoteUri,
                        originalToRetry.context.fileForRetry,
                      ),
                    );
                  }

                  // Let's get a list of preview file transfers that have failed so that we can retry them.
                  const previewToRetry = tList.find(
                    t =>
                      mA.previewTransfer?.id === t.id &&
                      t.state === TransferState.Error,
                  );

                  if (previewToRetry) {
                    // reset the preview transfer's progress and state to their original values.
                    // reset the transfer's progress and state to its initial values.
                    this.store.dispatch(
                      new UpdateProgress({
                        ...previewToRetry,
                        state: TransferState.Sending,
                        progress: 0,
                      }),
                    );

                    // Set the uploadState of the transfer in transferProgressList$ to 'sending'
                    let updateTProgressList =
                      this.transferProgressList$.getValue();

                    // Find the transfer we need to update.
                    const theUpdatedPreviewTransfer = updateTProgressList.find(
                      p => p.id === previewToRetry.context.messageUuid,
                    );
                    theUpdatedPreviewTransfer.uploadState = 'sending';
                    theUpdatedPreviewTransfer.progress = 0;

                    // Remove the transfer in question so that we don't duplicate it when updating the behavior subject.
                    updateTProgressList = updateTProgressList.filter(
                      t => t.id !== theUpdatedPreviewTransfer.id,
                    );

                    // Update the behavior subject.
                    this.transferProgressList$.next([
                      theUpdatedPreviewTransfer,
                      ...updateTProgressList,
                    ]);

                    // fire off the preview upload again.
                    this.store.dispatch(
                      new RetryDirectUpload(
                        previewToRetry,
                        previewToRetry.remoteUri,
                        previewToRetry.context.fileForRetry,
                      ),
                    );
                  }
                }),
              )
              .subscribe();
          });
        }),
      )
      .subscribe();
  }

  userProfileDisplayName(pendingMessage: MessageListPayload): string {
    return (
      this.userProfileEntitiesState.snapshot.entities[
        this.chatListEntitiesState.snapshot.entities[pendingMessage.chatUuid]
          ?.transphormerId
      ]?.displayName ?? 'My Advisor'
    );
  }
}
