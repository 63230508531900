<div class="weeks">
  <ion-button fill="clear" (click)="week = week - 1" [disabled]="week < -1">
    <ion-icon color="dark" name="chevron-back"></ion-icon>
  </ion-button>
  <span>{{ startDate }} - {{ endDate }}</span>
  <ion-button fill="clear" (click)="week = week + 1" [disabled]="week > 2">
    <ion-icon color="dark" name="chevron-forward"></ion-icon>
  </ion-button>
</div>
<div class="row">
  <ng-container *ngFor="let day of days; trackBy: trackByDay">
    <div
      class="weekday"
      [ngClass]="{ sel: isSelected(day.day), today: equalDate(day.day) }"
      tappable
      (click)="selectDate(day.day)"
    >
      {{ weekdays[day.weekday] }}
    </div>
  </ng-container>
</div>
<div class="date">
  {{ _selectedDate | date: 'EEEE' }}, {{ formattedSelectedDate }}
</div>
