import { AccountSetting } from '../../interfaces/account';
import { AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency';

export class LoadAccountSettings {
  static readonly type = '[Account] Load Account';
}

export class UpdateAccountSettings {
  static readonly type = '[Account] Update Account';
  constructor(public accountSettings: Partial<AccountSetting>) {}
}

export class SetTrackingTransparencyStatus {
  static readonly type = '[Account] Update App Tracking Transparency';
  constructor(public status: AppTrackingStatusResponse) {}
}
