import { Injectable } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, scan, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SplitRoutingService {
  _primary$ = new BehaviorSubject([]);
  _right$ = new BehaviorSubject([]);

  primary$ = this._primary$.pipe(
    distinctUntilChanged((a, b) => a.join(':') === b.join(':')),
    scan((acc, v) => [acc[1], v], [null, null]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  right$ = this._right$.pipe(
    distinctUntilChanged((a, b) => a.join(':') === b.join(':')),
    scan((acc, v) => [acc[1], v], [null, null]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(private route: Router) {
    this.route.events.subscribe(async event => {
      if (!(event instanceof RouterEvent)) {
        return;
      }

      const parsed = route.parseUrl(event.url);

      if (parsed.root.children?.primary) {
        // Skip if this is a transphormer route.
        if (
          parsed.root.children?.primary?.segments[1] &&
          parsed.root.children?.primary?.segments[1].path === 'transphormer'
        ) {
          return;
        }
        this._primary$.next(
          parsed.root.children.primary.segments.map(i => i.path),
        );
      }
      if (parsed.root.children?.right) {
        this._right$.next(parsed.root.children.right.segments.map(i => i.path));
      }
    });
  }
}
