import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SplitPaneService {
  public visible$ = new BehaviorSubject(false);

  constructor(private modalController: ModalController) {}

  hide() {
    this.visible$.next(false);
  }

  show() {
    this.visible$.next(true);
  }

  close() {
    this.modalController.dismiss({}, 'dismiss', 'split-pane');
  }

  splitWith(component, props = {}) {
    this.modalController
      .create({
        id: 'split-pane',
        component: component,
        componentProps: props,
        cssClass: 'split-pane',
        showBackdrop: false,
        backdropDismiss: false,
      })
      .then(modal => {
        modal.onDidDismiss().then(() => this.hide());
        this.show();
        modal.present().then();
      });
  }
}
