import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PaymentTier, PaymentTierWithPricing } from '../interfaces';

@Component({
  selector: 'app-tier-item',
  templateUrl: './tier-item.component.html',
  styleUrls: ['./tier-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierItemComponent {
  @Input() tier: PaymentTierWithPricing;
  @Input() selectedTierId: number;
  @Input() showCurrentPlan: boolean;
  @Input() autoRenewText: string;
  @Output() selectTier = new EventEmitter<PaymentTier>();
}
