import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  ClearSearch,
  ClearTrackableItem,
  CreateCustomItem,
  InitializeState,
  ScanBarcode,
  SearchForTerm,
  SelectSearchResult,
  SetupEmptyState,
  TrackRecentItem,
} from './nutrition-search.actions';
import {
  BarcodeScannerService,
  CustomFoodTemplateService,
  FoodItemsService,
  MealTemplatesService,
  NutritionixService,
} from '../../services';
import {
  CustomFoodTemplate,
  isTrackedItem,
  isTrackedMeal,
  MealTemplate,
  NutritionSearchResults,
  Recipe,
  TrackableItem,
  TrackedItem,
  TrackedMealTemplate,
  WeightedFoodItemIndexElement,
} from '../../interfaces';
import { EMPTY, merge, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import {
  BrandedFoodSearchResult,
  CommonFoodSearchResult,
  NutritionixInstantSearchResult,
} from '../../services/nutritionix/types';
import { ToastService } from '../../../../services';
import { append, patch } from '@ngxs/store/operators';
import { calculateValues } from '../nutrition/functions';
import produce from 'immer';
import { RecipeService } from '../../services';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { retryWithDelay } from '../../../../helpers/retryWithDelay';

export const NUTRITION_SEARCH_STATE_TOKEN = new StateToken<AddFoodModel>(
  'nutritionSearch',
);

// It has an activeDate and multiple NutritionResponses in data for each date it got from the API
export interface AddFoodModel {
  searchTerm: string;
  searchResults: NutritionSearchResults;
  resultCount: number;
  editingItem: TrackableItem;
  searchState: 'pending' | 'searching' | 'error' | 'partial' | 'done';
  state:
    | 'empty'
    | 'track'
    | 'create'
    | 'search'
    | 'barcode-scanning'
    | 'barcode-scanned';
  recentItems: TrackedItem[];
  aroundThisTimeItems: TrackedItem[];
  userId: number | null;
  foodStack: TrackableItem[];
}

export type AggregatedFoodResult =
  | {
      type: 'custom';
      data: CustomFoodTemplate;
      id: `custom_${CustomFoodTemplate['id']}`;
    }
  | {
      type: 'branded';
      data: BrandedFoodSearchResult;
      id: `branded_${BrandedFoodSearchResult['nix_item_id']}`;
    }
  | {
      type: 'common';
      data: CommonFoodSearchResult;
      id: `common_${CommonFoodSearchResult['food_name']}`;
    }
  | {
      type: 'recipe';
      data: Recipe;
      id: `recipe_${Recipe['id']}`;
    }
  | {
      type: 'meal';
      data: MealTemplate<TrackableItem>;
      id: `meal_${MealTemplate['id']}`;
    };

@State<AddFoodModel>({
  name: NUTRITION_SEARCH_STATE_TOKEN,
  defaults: {
    searchResults: {
      branded: [],
      common: [],
      recipes: [],
      customFood: [],
      meals: [],
      trackedFoodIndex: [],
    },
    state: 'empty',
    resultCount: 0,
    searchTerm: '',
    editingItem: null,
    foodStack: [],
    recentItems: [],
    aroundThisTimeItems: [],
    searchState: 'pending',
    userId: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class NutritionSearchState {
  constructor(
    private foodItemService: FoodItemsService,
    private customFoodService: CustomFoodTemplateService,
    public nutritionixService: NutritionixService,
    public mealTemplateService: MealTemplatesService,
    public toastService: ToastService,
    public barcodeScanner: BarcodeScannerService,
    public recipeService: RecipeService,
  ) {}

  @Selector()
  static searchResults(state: AddFoodModel): NutritionSearchResults {
    return state.searchResults;
  }

  @Selector()
  static resultCount(state: AddFoodModel): number {
    return state.resultCount;
  }

  @Selector([NutritionSearchState])
  static state(state: AddFoodModel) {
    return state.state;
  }

  @Selector()
  static resultsList(state: AddFoodModel): AggregatedFoodResult[] {
    if (state.resultCount === 0) {
      return [];
    }
    const totalFoodList: AggregatedFoodResult[] = [];

    const searchTerm = state.searchTerm.trim().toLowerCase();

    if (searchTerm.indexOf('1stphorm') !== -1) {
      searchTerm.replace('1stphorm', '1st phorm');
    }

    const matchesBranded = state.searchResults.branded.some(function (
      i: BrandedFoodSearchResult,
    ) {
      return (
        i.brand_name !== '' &&
        searchTerm.indexOf(i.brand_name.toLowerCase()) !== -1
      );
    });

    state.searchResults.customFood.forEach(food => {
      totalFoodList.push({
        type: 'custom',
        data: food,
        id: `custom_${food.id}`,
      });
    });

    state.searchResults.meals.forEach(food => {
      totalFoodList.push({
        type: 'meal',
        data: food,
        id: `meal_${food.id}`,
      });
    });

    state.searchResults.recipes.forEach(food => {
      totalFoodList.push({
        type: 'recipe',
        data: food,
        id: `recipe_${food.id}`,
      });
    });

    if (matchesBranded) {
      state.searchResults.branded.forEach(food => {
        totalFoodList.push({
          type: 'branded',
          data: food,
          id: `branded_${food.nix_item_id}`,
        });
      });
    }

    state.searchResults.common.forEach(food => {
      totalFoodList.push({
        type: 'common',
        data: food,
        id: `common_${food.food_name}`,
      });
    });

    if (!matchesBranded) {
      state.searchResults.branded.forEach(food => {
        totalFoodList.push({
          type: 'branded',
          data: food,
          id: `branded_${food.nix_item_id}`,
        });
      });
    }

    const weigthedList = [];
    state.searchResults.trackedFoodIndex.forEach(indexedElement => {
      const elementIndex = totalFoodList.findIndex(
        foodItem =>
          foodItem.type === indexedElement.source_type &&
          foodItem.id ===
            `${indexedElement.source_type}_${indexedElement.source_id}`,
      );

      if (elementIndex > -1) {
        weigthedList.push(totalFoodList[elementIndex]);
        totalFoodList.splice(elementIndex, 1);
      }
    });
    return [...weigthedList, ...totalFoodList];
  }

  @Action(ClearSearch)
  clear(ctx: StateContext<AddFoodModel>) {
    ctx.patchState({
      searchResults: {
        branded: [],
        common: [],
        customFood: [],
        meals: [],
        recipes: [],
        trackedFoodIndex: [],
      },
      resultCount: 0,
      searchTerm: '',
      foodStack: [],
      searchState: 'pending',
      state: 'empty',
    });
  }

  @Action(SetupEmptyState)
  setupEmpty(ctx: StateContext<AddFoodModel>) {
    ctx.patchState({
      searchTerm: '',
      searchResults: {
        branded: [],
        common: [],
        customFood: [],
        meals: [],
        recipes: [],
        trackedFoodIndex: [],
      },
      foodStack: [],
      resultCount: 0,
      state: 'empty',
      searchState: 'pending',
    });

    // const aroundThisTimeMealTemplateRequest = this.mealTemplateService.aroundThisTime();
    // I feel like this could be further simplified. Is this the best way to do this?
    return merge(
      this.foodItemService
        .recentlyTrackedItems()
        .pipe(map(r => ({ result: r, type: 'recent' }))),
      this.foodItemService.aroundThisTimeTrackedItems().pipe(
        map(r => {
          return Array.isArray(r)
            ? { result: r, type: 'tracked-item' }
            : {
                result: [...r.meals, ...r.trackedItems],
                type: 'tracked-item',
              };
        }),
      ),
    ).subscribe(({ result, type }) => {
      if (type === 'recent') {
        ctx.patchState({ recentItems: result });
      }
      if (type === 'tracked-item') {
        ctx.patchState({ aroundThisTimeItems: result });
      }
    });
  }

  @Action(SearchForTerm, { cancelUncompleted: true })
  search(ctx: StateContext<AddFoodModel>, { term }: SearchForTerm) {
    ctx.patchState({
      searchTerm: term,
      searchResults: {
        branded: [],
        common: [],
        customFood: [],
        meals: [],
        recipes: [],
        trackedFoodIndex: [],
      },
      resultCount: 0,
      state: 'search',
      searchState: 'searching',
    });

    const mapResult = type => map(r => ({ result: r, type }));

    return merge(
      this.nutritionixService
        .search(term)
        .pipe(retryWithDelay(1000, 5), mapResult('nix')),
      this.customFoodService.searchTemplateNew(term).pipe(mapResult('custom')),
      this.mealTemplateService.searchForTerm(term).pipe(mapResult('meal')),
      this.recipeService.searchForTerm(term).pipe(mapResult('recipe')),
      this.foodItemService
        .searchWeightedTrackedItemsForTerm(term)
        .pipe(mapResult('trackedFood')),
    ).pipe(
      finalize(() => {
        ctx.patchState({ searchState: 'done' });
      }),
      tap(searchResults => {
        const state = ctx.getState();
        const results = { ...state.searchResults };

        if (searchResults.type === 'nix') {
          results.common =
            (searchResults.result as NutritionixInstantSearchResult).common ||
            [];
          results.branded =
            (searchResults.result as NutritionixInstantSearchResult).branded ||
            [];
        } else if (searchResults.type === 'custom') {
          results.customFood = searchResults.result as CustomFoodTemplate[];
        } else if (searchResults.type === 'meal') {
          results.meals =
            searchResults.result as unknown as MealTemplate<TrackableItem>[];
        } else if (searchResults.type === 'recipe') {
          results.recipes = searchResults.result as unknown as Recipe[];
        } else if (searchResults.type === 'trackedFood') {
          results.trackedFoodIndex = (
            searchResults.result as unknown as WeightedFoodItemIndexElement[]
          ).sort((a, b) => b.weight - a.weight);
        }
        const count =
          searchResults.type === 'trackedFood'
            ? 0
            : searchResults.type === 'nix'
              ? results.common.length + results.branded.length
              : (searchResults.result as unknown[]).length;

        ctx.patchState({
          searchResults: results,
          resultCount: state.resultCount + count,
          searchState: 'partial',
        });
      }),
    );
  }

  @Action(ScanBarcode)
  scanBarcode(ctx: StateContext<AddFoodModel>) {
    ctx.patchState({
      state: 'barcode-scanning',
    });

    return fromPromise(this.barcodeScanner.scan()).pipe(
      tap(next => {
        if (!next) {
          ctx.patchState({
            state: 'empty',
          });

          return;
        }
        ctx.patchState({
          searchState: 'searching',
          state: 'barcode-scanned',
        });
      }),
      catchError(e => {
        ctx.patchState({
          state: 'empty',
        });
        console.log('Got an error during the barcode scanner operation.', e);
        return of(false);
      }),
      mergeMap((value: string) => {
        if (!value) {
          return EMPTY;
        }
        return this.nutritionixService.getTrackableBarcode(value).pipe(
          tap(editingItem => {
            ctx.patchState({
              editingItem,
              state: 'track',
              searchState: 'done',
            });
          }),
          catchError(e => {
            if (e.status === 404) {
              this.toastService.flash('UPC not found in our database.');
            }
            ctx.patchState({
              searchState: 'error',
              state: 'empty',
            });
            return EMPTY;
          }),
        );
      }),
    );
  }

  @Action(SelectSearchResult)
  selectSearchResult(
    ctx: StateContext<AddFoodModel>,
    { item }: SelectSearchResult,
  ) {
    // Step one, we now have to load the "detail" information.

    // We have a SearchFood, which could be one of several different things. Now, we want to add it, so we have to
    // figure out how to actually do that. In this case, we need to convert the original item into a new item type,
    // one which matches the data type that we use for the forms and which we can use to calculate new values.
    switch (item.type) {
      case 'custom':
      case 'common':
      case 'branded':
      case 'recipe':
        return this.openNixFood(ctx, item.data, item.type).pipe(
          catchError(_ => of(null)),
          tap((result: TrackableItem) => {
            if (result === null) {
              this.toastService
                .flash('Error grabbing food details. Please try again later.')
                .then();
              return;
            }
            ctx.patchState({
              editingItem: result,
              state: 'track',
            });
          }),
        );
      case 'meal':
        return this.handleMealResult(
          ctx,
          item.data as MealTemplate<TrackedItem>,
        );
    }
  }

  handleMealResult(
    ctx: StateContext<AddFoodModel>,
    meal: MealTemplate<TrackedItem> | TrackedMealTemplate,
  ) {
    const lookups = meal.items.map(item => {
      return this.openNixFood(ctx, item, item.source_type).pipe(
        catchError(err => {
          return err.status === 404 ? of(item) : throwError(err);
        }),
        map<TrackableItem, TrackedItem>(next => {
          if (next.source !== 'nutritionix') {
            return item;
          }

          const newItem = {
            ...item,
            thumbnail: next.thumbnail ?? next.picture_url,
            servings: next.servings,
            originalServings: item.servings,
            serving_size: next.serving_size,
          };

          return { ...newItem, ...calculateValues(newItem, item.consumed) };
        }),
      );
    });

    return merge(...lookups).pipe(
      tap((ti: TrackableItem) => {
        ctx.setState(
          patch({
            foodStack: append([ti]),
          }),
        );
      }),
    );
  }

  /**
   * @todo Define what data/source_type actually are.
   * @param ctx
   * @param data
   * @param source_type
   */
  public openNixFood(
    ctx: StateContext<AddFoodModel>,
    data,
    source_type,
  ): Observable<TrackableItem> {
    const { userId } = ctx.getState();

    if (source_type === 'custom') {
      return of(this.customFoodService.getTrackableItem({ result: data }));
    } else if (source_type === 'recipe') {
      return of(
        this.recipeService.getTrackableItem({
          source_type,
          source_id: data.id,
          ...data,
        }),
      );
    } else {
      if (source_type === 'common') {
        return this.nutritionixService
          .getTrackableItem(
            { source_type, source_id: data.source_id || data.food_name },
            userId,
          )
          .pipe(
            catchError(err => {
              if (err.status === 404) {
                if (isTrackedItem(data)) {
                  return of(data);
                }
                return throwError(err);
              }

              return throwError(err).pipe(retryWithDelay(1000, 5));
            }),
          );
      } else {
        return this.nutritionixService
          .getTrackableItem(
            {
              source_type,
              source_id: data.source_id || data.nix_item_id,
            },
            userId,
          )
          .pipe(
            catchError(err => {
              if (err.status === 404) {
                if (isTrackedItem(data)) {
                  return of(data);
                }
                return throwError(err);
              }

              return throwError(err).pipe(retryWithDelay(1000, 5));
            }),
          );
      }
    }
  }

  @Action(InitializeState)
  initialize(ctx: StateContext<AddFoodModel>, { userId }: InitializeState) {
    ctx.patchState({
      userId,
    });
  }

  @Action(ClearTrackableItem)
  clearTrackableItem(ctx: StateContext<AddFoodModel>) {
    const { searchTerm } = ctx.getState();
    const nextState = !(searchTerm === null || searchTerm === '')
      ? 'search'
      : 'empty';

    ctx.patchState({
      editingItem: null,
      state: nextState,
    });
  }

  @Action(TrackRecentItem)
  trackRecentItem(ctx: StateContext<AddFoodModel>, { item }: TrackRecentItem) {
    if (isTrackedMeal(item)) {
      return this.handleMealResult(ctx, item);
    } else {
      return this.openNixFood(ctx, item, item.source_type).pipe(
        catchError(err => (err.status === 404 ? of(item) : throwError(err))),
        tap(food => {
          ctx.setState(
            produce(draft => {
              draft.state = 'track';
              draft.editingItem = {
                ...item,
                ...food,
                default_consumption_value: item.consumed,
              };
            }),
          );
        }),
      );
    }
  }

  @Action(CreateCustomItem)
  createCustomItem(ctx: StateContext<AddFoodModel>) {
    ctx.patchState({
      editingItem: <TrackableItem>{
        name: '',
        source: 'custom',
        source_type: null,
        source_id: null,
        serving_size: {
          amount: null,
          unit: null,
          carbs: null,
          calories: null,
          protein: null,
          fats: null,
          fiber: null,
        },
        default_consumption_value: {
          unit: 'serving',
          amount: 1,
        },
        servings: [],
      },
      state: 'create',
    });
  }
}
