import { WorkoutSessionV3 } from '../../../../interfaces';
import { WorkoutPhotoSizes } from './workouts.state';

export class ImportWorkoutSessions {
  static readonly type = '[Workouts] Import Workout Sessions';

  constructor(public sessions: WorkoutSessionV3[]) {}
}

export class UpdateWorkoutPhoto {
  static readonly type = '[Workouts] Update Workout Photo';

  constructor(
    public uuid: string,
    public photoInfo: WorkoutPhotoSizes,
  ) {}
}

export class RemoveWorkoutPhoto {
  static readonly type = '[Workouts] Remove Workout Photo';

  constructor(public uuid: string) {}
}

export class SwapWorkouts {
  static readonly type = '[Workouts] Swap Workout Day (action)';

  constructor(
    public newDate: string | Date,
    public originalDate: string | Date = new Date(),
  ) {}
}

export class AddMovementSwap {
  static readonly type = '[Workouts] Add Movement Swap';

  constructor(
    public originalMovementId: number,
    public newMovementId: number,
    public sessionId: string | number | null,
  ) {}
}

export class RemoveMovementSwap {
  static readonly type = '[Workouts] Remove Movement Swap';

  constructor(public swapId: number) {}
}

export class UpdateMovementSwap {
  static readonly type = '[Workouts] Update Movement Swap';

  constructor(
    public originalMovementId: number,
    public newMovementId: number,
    public sessionId: string | number | null,
  ) {}
}

export class LoadGlobalMovementSwaps {
  static readonly type = '[Workouts] Load Global Movement Swaps';
}

export class ResetGlobalMovementSwaps {
  static readonly type = '[Workouts] Reset Global Movement Swaps';
}
