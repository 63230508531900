<div>
  <ion-label>{{ title || 'Meal' }}</ion-label>
  <div class="inline meal">
    <ion-button
      *ngFor="let mealNumber of meals; trackBy: trackByMeal"
      [color]="selectedMeal === mealNumber ? 'primary' : 'light'"
      (click)="mealChanged.emit(mealNumber)"
      >{{ mealNumber }}</ion-button
    >
  </div>
</div>
