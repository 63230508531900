<ng-container
  *ngIf="{
    products: products$ | async,
    buttons: productButtons$ | async,
  } as ctx"
>
  <div class="logo">
    <app-logo></app-logo>
  </div>

  <div class="ion-padding content">
    <h1 class="fw-extrabold">
      Simplify the<br />process, earn<br />
      your results.
    </h1>

    <div class="para ion-padding-top">
      <span class="fw-semibold" *appPlatform="'ios'"
        >Payment will be charged to your iTunes Account.</span
      >
      <span class="fw-semibold" *appPlatform="'android'"
        >Payment will be charged to your Google Play Account.</span
      >
      <span class="fw-semibold"
        >The subscription automatically renews unless auto-renew is turned off
        at least 24-hours before the end of the current period.</span
      >
      <span class="fw-semibold"
        >Your account will be charged for renewal within 24-hours prior to the
        end of the current period.</span
      >
      <span class="fw-semibold"
        >Subscriptions may be managed via Account Settings after purchase.</span
      >
      <span class="fw-semibold"
        ><a appExternalLink href="https://1stphorm.app/privacy-policy/"
          >Privacy Policy</a
        >
        and
        <a appExternalLink href="https://1stphorm.app/terms-conditions/"
          >Terms &amp; Conditions</a
        >.
      </span>
    </div>
  </div>
  <div class="products ion-padding-horizontal">
    <app-product-button
      *ngFor="let product of ctx.products; let i = index"
      [selected]="selectedProduct?.id === product.id"
      [buttonText]="ctx.buttons[i]"
      [disabled]="product === 'invalid' || !product.price"
      (selectEmitter)="this.selectProduct.emit(product)"
    ></app-product-button>
  </div>

  <section class="subscribe-continue ion-padding-horizontal">
    <span
      class="subscribe-container"
      (click)="
        ctx.buttons && !isPurchasing && selectedProduct
          ? this.upgrade.emit()
          : ''
      "
      [class.disabled]="!ctx.buttons || isPurchasing || !selectedProduct"
      ><span class="fw-bold">Subscribe + Continue</span>
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </span>
  </section>
</ng-container>
