import { WeighIn } from '../../../../interfaces';
import { Configuration, MetricResult } from '../../types';
import { BodyMetric, BodyMetricEntry } from '../../types/body-metric';

export class LoadBodyMetricsData {
  static readonly type = '[BodyMetrics] Load Body Metrics Data';
}

export class AddWeighIn {
  static readonly type = '[BodyMetrics] Add Weigh In';

  constructor(public mr: MetricResult) {}
}

export class DeleteWeighIn {
  static readonly type = '[BodyMetrics] Delete Weigh In';

  constructor(public id: number) {}
}

export class UpdateWeighIn {
  static readonly type = '[BodyMetrics] Update Weigh In';

  constructor(
    public weighIn: WeighIn,
    public mr: MetricResult,
  ) {}
}

export class AddBodyMetric {
  static readonly type = '[BodyMetrics] Save Body Metric';

  constructor(public bodyMetricEntry: BodyMetricEntry) {}
}

export class DeleteBodyMetric {
  static readonly type = '[BodyMetrics] Delete Body Metric';

  constructor(public bodyMetric: BodyMetric) {}
}

export class UpdateBodyMetric {
  static readonly type = '[BodyMetrics] Update Body Metric';

  constructor(public bodyMetric: BodyMetric) {}
}

export class SaveConfiguration {
  static readonly type = '[BodyMetrics] Save Configuration';

  constructor(public configurations: Configuration[]) {}
}

export class Reset {
  static readonly type = '[BodyMetrics] Reset';
}
