import {
  CaloriesMacros,
  CmcSupplementConfig,
  CmcSupplementTracker,
  CustomMacro,
  MacroInfo,
  MealItem,
  NutritionItem,
  NutritionPlan,
  SimpleMeal,
  TrackedItem,
  TrackedMeal,
} from '../../interfaces';
import { mySQLFormattedDate } from '../../../../helpers';

export class SetActiveDay {
  static readonly type = '[Nutrition] Set Active Day';
  public activeDate: string;

  constructor(private day: string | Date) {
    if (day instanceof Date) {
      this.activeDate = mySQLFormattedDate(day);
    } else {
      this.activeDate = day;
    }
  }
}

export class StoreNutritionFoodData {
  static readonly type = '[Nutrition] StoreNutritionFoodData';

  constructor(
    public day: string,
    public meal: SimpleMeal,
    public mealItem: MealItem,
    public nutritionItem: NutritionItem,
  ) {}
}

export class ToggleSupplementUsed {
  static readonly type = '[Nutrition] ToggleSupplementUsed';

  constructor(public supplementIndex: number) {}
}

export class SupplementConfigurationUpdated {
  static readonly type = '[Nutrition] SupplementConfigurationUpdated';

  constructor(public supplements: MealItem[]) {}
}

export class ToggleExpandSupplements {
  static readonly type = '[Nutrition] ExpandSupplements';
}

export class ToggleExpandMeal {
  static readonly type = '[Nutrition] ToggleExpandMeal';

  constructor(
    public activeDate: string,
    public mealIndex: number,
  ) {}
}

export class ResetNutritionState {
  static readonly type = '[Nutrition] Reset Nutrition State';
}

export class SetActiveNutritionType {
  static readonly type = '[Nutrition] Set Active Nutrition Type';

  constructor(public type: NutritionPlan) {}
}

export class UpdateWaterAmount {
  static readonly type = '[Nutrition] Update water amount';

  constructor(public water: number) {}
}

export class UpdateMacros {
  static readonly type = '[Nutrition] UpdateMacros';

  constructor(
    public date: string,
    public macros: MacroInfo | CustomMacro,
  ) {}
}

export class InitializeMacros {
  static readonly type = '[Nutrition] Initialize Macros';
}

export class MealVisibilityToggled {
  static readonly type = '[Nutrition] Meal Visibility Toggled';

  constructor(public meal: TrackedMeal) {}
}

export class UpdateTrackedFoodItem {
  static readonly type = '[Nutrition] Update Tracked Food Item';

  constructor(
    public foodItem: TrackedItem,
    public meal: number = null,
  ) {}
}

export class CopyMealsToDay {
  static readonly type = '[Nutrition] Copy Meals To Day';
  public date: string;

  constructor(
    public meals: TrackedMeal[],
    private day: Date | string,
  ) {
    if (day instanceof Date) {
      this.date = mySQLFormattedDate(day);
    } else {
      this.date = day;
    }
  }
}

export class CopyDayMealToDay {
  static readonly type = '[Nutrition] Copy Day Meal To Day';
  public dateFrom: string;
  public dateTo: string;

  constructor(
    public meal: TrackedMeal,
    private dayFrom: Date | string,
    private dayTo: Date | string,
  ) {
    if (dayFrom instanceof Date) {
      this.dateFrom = mySQLFormattedDate(dayFrom);
    } else {
      this.dateFrom = dayFrom;
    }
    if (dayTo instanceof Date) {
      this.dateTo = mySQLFormattedDate(dayTo);
    } else {
      this.dateTo = dayTo;
    }
  }
}

export class FoodStackTrackedItem {
  static readonly type = '[Nutrition] Food Stack Tracked Item';

  constructor(
    public item: TrackedItem,
    public meal: number,
  ) {}
}

export class TrackNewItem {
  static readonly type = '[Nutrition] Track New Item';

  constructor(
    public item: TrackedItem,
    public meal: number,
  ) {}
}

export class RemoveTrackedItem {
  static readonly type = '[Nutrition] Remove Tracked Item';

  constructor(
    public foodItem: TrackedItem,
    public meal: number = null,
  ) {}
}

export class TrackQuickAdd {
  static readonly type = '[Nutrition] Track Quick Add';

  constructor(
    public quickItem: CaloriesMacros,
    public meal: number,
  ) {}
}

export class UpdateQuickAdd {
  static readonly type = '[Nutrition] Update Quick Add';

  constructor(
    public foodItem: TrackedItem,
    public meal: number,
  ) {}
}

export class LoadNutritionForDay {
  static readonly type = '[Nutrition] Load Nutrition For Day';
  public date: string;

  constructor(private day: string | Date) {
    if (day instanceof Date) {
      this.date = mySQLFormattedDate(day);
    } else {
      this.date = day;
    }
  }
}

export class SetTodayDate {
  static readonly type = '[Nutrition] Set Today Date';

  constructor(public today: Date) {}
}

export class UpdateCmcSupplements {
  static readonly type = '[Nutrition] UpdateCmcSupplements';

  constructor(
    public supplements: CmcSupplementTracker[],
    public config: CmcSupplementConfig[] = null,
  ) {}
}
