import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import {
  ActivityConfigIndexRecord,
  IndexedArray,
  TrainingConfig,
  TrainingDateIndexRecord,
  WorkoutSessionV3,
} from '../../../../interfaces';
import {
  ActivateTrainingConfig,
  ChangeRestDay,
  CreateActivityConfig,
  DeleteTrainingConfig,
  LoadDay,
  LoadTrainingConfigs,
  PickConfigBackUp,
  ScheduleTrainingPlanAfterConfig,
  SetDefaultRestDay,
  SetInitialOffset,
  SetWalkTimerGoal,
  StartTrainingProgram,
  StopTrainingConfig,
  SwapDays,
  UpdateDoingWalkTimer,
  UpdateDoingWorkouts,
  UpdateStepsGoal,
  UpdateTrainingConfig,
} from './activity.actions';
import { TrainingConfigService } from '../../services/training-config/training-config.service';
import { switchMap, tap } from 'rxjs/operators';
import { normalize } from 'normalizr';

import { day as daySchema } from './normalizr';
import { Injectable } from '@angular/core';
import produce from 'immer';
import {
  differenceInDays,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  subDays,
  subSeconds,
} from 'date-fns';
import { ImportWorkoutSessions } from '../workout/workouts.actions';
import { RestDay, WorkoutsStateModel } from '../../types';
import {
  createTrainingDateIndex,
  enrichConfigWithDates,
  getDayOffset,
  getTrainingConfigForDateWithIndex,
} from './functions';
import {
  _yyyyMMdd,
  isSameDayOrAfter,
  isSameDayOrBefore,
  yyyyMMdd,
} from '../../../../helpers/date';
import { defer, EMPTY, forkJoin } from 'rxjs';
import { ImportTimers } from '../../services/activity/walk-timer.actions';
import { v1 } from 'uuid';

export interface Day<T = Date> {
  activityConfigId: number;
  activityConfig: ActivityConfig<T>;
  trainingConfigId: number;
  trainingConfig: TrainingConfig<T>;
  steps: number;
  walk: number;
  workoutSessions: Partial<WorkoutSessionV3>[];
}

export interface ActivityConfig<T = Date> {
  id: number;
  uuid: string;
  transphormer_id: number;
  doing_steps: boolean;
  doing_walk_timer: boolean;
  doing_workouts: boolean;
  workouts_out_in_gym: boolean | null;
  level: string | number | null;
  desired_program: string | number | null;
  pedometer_goal_steps: number;
  walk_timer_goal: null | number;
  valid_from: T;
  created_at: T | null;
  updated_at: T | null;
}

export type TrainingConfigWithDayNo = TrainingConfig & { day_no: number };

export interface ActivityStateModel {
  trainingConfigs: IndexedArray<TrainingConfig>;
  activityConfigs: IndexedArray<ActivityConfig>;
  activityDateIndex: ActivityConfigIndexRecord[];
  trainingDateIndex: TrainingDateIndexRecord[];
  days: { [key: string]: Day };
  defaultRestDay: RestDay;
}

@State<ActivityStateModel>({
  name: 'activity',
  defaults: {
    activityConfigs: {},
    trainingConfigs: {},
    trainingDateIndex: [],
    activityDateIndex: [],
    days: {},
    defaultRestDay: RestDay.NONE,
  },
})
@Injectable({
  providedIn: 'root',
})
export class ActivityState {
  constructor(private trainingConfigService: TrainingConfigService) {}

  @Selector([ActivityState])
  public static trainingConfigs(state: ActivityStateModel) {
    return state.trainingConfigs;
  }

  @Selector([ActivityState])
  public static defaultRestDay(state: ActivityStateModel) {
    return state.defaultRestDay;
  }

  public static configById(id: number) {
    return createSelector(
      [ActivityState.trainingConfigs, ActivityState.trainingDateIndex],
      (configs, index) => {
        if (!configs[id]) {
          return null;
        }

        const indexEntry: TrainingDateIndexRecord = index.find(
          i => i.id === id,
        );

        if (!indexEntry) {
          return configs[id];
        }

        return enrichConfigWithDates({
          ...configs[id],
          end_date: configs[id].end_date
            ? configs[id].end_date
            : isBefore(indexEntry.end, new Date())
              ? indexEntry.end
              : undefined,
          projected_start_date: configs[id].start_date
            ? null
            : indexEntry.start,
          projected_end_date: configs[id].end_date ? null : indexEntry.end,
        });
      },
    );
  }

  @Selector([ActivityState.activityConfigForDate(yyyyMMdd(new Date()))])
  public static walkTimerGoal(ac: ActivityConfig) {
    return ac ? ac.walk_timer_goal : 1800;
  }

  @Selector()
  public static getState(state: ActivityStateModel) {
    return state;
  }

  @Selector()
  public static trainingDateIndex(state: ActivityStateModel) {
    return state.trainingDateIndex;
  }

  @Selector()
  public static activityConfigIndex(state: ActivityStateModel) {
    return state.activityDateIndex;
  }

  @Selector()
  public static activityConfigs(state: ActivityStateModel) {
    return state.activityConfigs;
  }

  public static getDate(date: string) {
    return createSelector([ActivityState.getState], function (state) {
      return {
        ...state.days?.[date],
        walk: state.days?.[date]?.walk || 0,
      };
    });
  }

  public static activityConfigForDate(day: string) {
    const date = _yyyyMMdd(day);

    return createSelector(
      [ActivityState.activityConfigIndex, ActivityState.activityConfigs],
      function (index, configs) {
        const acIndex = index.find(
          i => i.start <= date && (i.end === null || i.end >= date),
        );
        return configs[acIndex.uuid];
      },
    );
  }

  @Selector([ActivityState.activityConfigForDate(yyyyMMdd(new Date()))])
  public static activeActivityConfig(ac: ActivityConfig) {
    return ac;
  }

  @Selector([ActivityState.trainingConfigForDate(new Date())])
  public static activeTrainingConfig(tc: TrainingConfig) {
    return tc;
  }

  @Selector()
  public static futureTrainingConfigs() {
    return ActivityState.futureTrainingConfigsForDate(new Date());
  }

  public static trainingConfigForDate(date: Date) {
    return createSelector(
      [ActivityState.trainingDateIndex, ActivityState.trainingConfigs],
      function (dateIndex, configs): TrainingConfigWithDayNo {
        const indexRecord = getTrainingConfigForDateWithIndex(date, dateIndex);

        if (!indexRecord) {
          return null;
        }

        const tc = configs[indexRecord.id];

        return {
          ...tc,
          // These are 1-indexed.
          projected_start_date: indexRecord['start'],
          day_no:
            differenceInDays(date, indexRecord['start']) +
            1 +
            getDayOffset(tc.modifications.offsets, date),
        };
      },
    );
  }

  public static trainingConfigsForDate(date: Date) {
    return createSelector(
      [ActivityState.trainingDateIndex, ActivityState.trainingConfigs],
      function (dateIndex, configs): TrainingConfigWithDayNo[] {
        // return all training configs with date which match the current date
        return dateIndex
          .filter(
            di =>
              isSameDayOrAfter(date, di.start) &&
              (di.end ? isSameDayOrBefore(date, di.end) : true),
          )
          .map(indexRecord => ({
            ...configs[indexRecord.id],
            // These are 1-indexed.
            projected_start_date: indexRecord['start'],
            day_no:
              differenceInDays(date, indexRecord['start']) +
              1 +
              getDayOffset(configs[indexRecord.id].modifications.offsets, date),
          }));
      },
    );
  }

  public static futureTrainingConfigsForDate(date: Date) {
    return createSelector(
      [ActivityState.trainingDateIndex, ActivityState.trainingConfigs],
      function (index, configs) {
        if (index.length === 0) {
          return null;
        }
        const futurePlans = index.filter(di => isAfter(di.start, date));
        return [
          {
            ...configs[futurePlans[0].id],
            projected_start_date: futurePlans[0].start,
          },
        ];
      },
    );
  }

  public static trainingConfigsBefore(date: Date) {
    return createSelector(
      [ActivityState.trainingDateIndex, ActivityState.trainingConfigs],
      function (index, configs) {
        return index
          .filter(di => isBefore(di.end, date))
          .map(i => enrichConfigWithDates(configs[i.id]));
      },
    );
  }

  @Action(LoadTrainingConfigs)
  loadConfig(ctx: StateContext<WorkoutsStateModel>) {
    return this.trainingConfigService.fetchConfigs().pipe(
      tap(trainingConfigs => {
        ctx.setState(
          produce(draft => {
            trainingConfigs.forEach(tc => {
              draft.trainingConfigs[tc.id] = tc;
            });
            draft.trainingDateIndex = createTrainingDateIndex(trainingConfigs);
          }),
        );
      }),
    );
  }

  @Action(LoadDay)
  loadDay(ctx: StateContext<ActivityStateModel>, action: LoadDay) {
    return this.trainingConfigService.fetchActivityDay(action.date).pipe(
      tap(day => {
        const { entities } = normalize(day, daySchema);
        ctx.setState(
          produce(draft => {
            draft.activityConfigs = {
              ...draft.activityConfigs,
              ...entities.activityConfigs,
            };
            draft.activityDateIndex = createActivityConfigIndex(
              Object.values(draft.activityConfigs),
            );
            // We don't want this set here anymore because we are loading this elsewhere.
            // draft.trainingConfigs = {...draft.trainingConfigs, ...entities.trainingConfigs};
            draft.days = { ...draft.days, ...entities.days };
          }),
        );

        // Import these into the store.
        if (entities.workoutSessions) {
          ctx.dispatch(
            new ImportWorkoutSessions(Object.values(entities.workoutSessions)),
          );
        }
        // Import these into the store.
        if (entities.walkTimer) {
          ctx.dispatch(new ImportTimers(Object.values(entities.walkTimer)));
        }
      }),
    );
  }

  @Action(StartTrainingProgram)
  startTrainingProgram(
    ctx: StateContext<ActivityStateModel>,
    { planId, startDate }: StartTrainingProgram,
  ) {
    return this.trainingConfigService
      .store({ training_id: planId, start_date: startDate })
      .pipe(
        tap(result => {
          ctx.setState(
            produce((draft: ActivityStateModel) => {
              draft.trainingConfigs[result.id] = result;
              draft.trainingDateIndex = createTrainingDateIndex(
                Object.values(draft.trainingConfigs),
              );
            }),
          );
        }),
      );
  }

  @Action(StopTrainingConfig)
  stopTrainingConfig(
    ctx: StateContext<ActivityStateModel>,
    { config }: StopTrainingConfig,
  ) {
    // If the plan is in the future, delete it, and update the referencing plan.
    if (!config.start_date) {
      return this.deleteFuturePlan(ctx, config);
    } else {
      // If the plan is now, well, just delete it for now.
      return this.stopCurrentPlan(ctx, config);
    }
  }

  @Action(PickConfigBackUp)
  pickTrainingConfigBackUp(
    ctx: StateContext<ActivityStateModel>,
    { config }: StopTrainingConfig,
  ) {
    // If the plan is in the future, delete it, and update the referencing plan.
    ctx.dispatch(new UpdateTrainingConfig({ id: config.id, end_date: null }));
  }

  @Action(ScheduleTrainingPlanAfterConfig)
  schedulePlan(
    ctx: StateContext<ActivityStateModel>,
    { trainingId, configId }: ScheduleTrainingPlanAfterConfig,
  ) {
    // Create the scheduled plan.
    const configToPlanAfter = ctx.getState().trainingConfigs[configId];
    const newConfig: Partial<TrainingConfig> = {
      training_id: trainingId,
    };

    // If the config plan we are going after already has a next config ID, we
    // handle that by making our next_config_id. Essentially, we are inserting
    // ourselves into the list.
    if (configToPlanAfter.next_config_id) {
      newConfig.next_config_id = configToPlanAfter.next_config_id;
    }

    // Create the new config, then update the current config accordingly.
    return this.trainingConfigService.store(newConfig).pipe(
      tap(saved =>
        ctx.setState(
          produce(draft => {
            draft.trainingConfigs[saved.id] = saved;
            draft.trainingDateIndex = createTrainingDateIndex(
              Object.values(draft.trainingConfigs),
            );
          }),
        ),
      ),
      switchMap(saved => {
        return ctx.dispatch(
          new UpdateTrainingConfig({
            id: configToPlanAfter.id,
            next_config_id: saved.id,
          }),
        );
      }),
    );
  }

  // @Action(WorkoutSessionCreated)
  // workoutSessionCreated(ctx: StateContext<ActivityStateModel>, {session}: WorkoutSessionCreated) {
  //   ctx.setState(produce((draft) => {
  //     draft.days[session.workout_date].workoutSessions.push(session.uuid);
  //   }));
  // }

  @Action(ChangeRestDay)
  changeRestDay(
    ctx: StateContext<ActivityStateModel>,
    { value, activeOn }: ChangeRestDay,
  ) {
    // Get the active training plan, if one exists.
    const { trainingDateIndex, trainingConfigs } = ctx.getState();
    const indexRecord = getTrainingConfigForDateWithIndex(
      activeOn,
      trainingDateIndex,
    );

    ctx.dispatch(new SetDefaultRestDay(value));

    if (!indexRecord) {
      return null;
    }

    const tc = trainingConfigs[indexRecord.id];
    if (!tc) {
      throw new Error('Could not find config with that ID.');
    }

    function getLastRestDayConfig(restDays) {
      return restDays[restDays.length - 1];
    }

    let rest_days = [...(tc.modifications?.rest_days || [])];

    // If there is no value set there, just go ahead and set it as the default. If today is the same
    // day it started, set it as the default
    if (isSameDay(tc.start_date, activeOn)) {
      rest_days = [{ day: value, start_date: activeOn }];
    } else {
      const lastRestDay = getLastRestDayConfig(rest_days);
      if (
        lastRestDay &&
        lastRestDay.start_date &&
        isSameDay(lastRestDay.start_date, activeOn)
      ) {
        rest_days.pop();
        rest_days.push({ ...lastRestDay, day: value });
      } else {
        rest_days.push({ start_date: activeOn, day: value });
      }
    }

    return ctx.dispatch(
      new UpdateTrainingConfig(
        produce(tc, draft => {
          draft.modifications.rest_days = rest_days;
        }),
      ),
    );
  }

  @Action(DeleteTrainingConfig)
  private deleteConfig(
    ctx: StateContext<ActivityStateModel>,
    { config }: DeleteTrainingConfig,
  ) {
    return this.trainingConfigService.delete(config.id).pipe(
      tap(() =>
        ctx.setState(
          produce(draft => {
            delete draft.trainingConfigs[config.id];
            draft.trainingDateIndex = createTrainingDateIndex([
              ...Object.values<TrainingConfig>(draft.trainingConfigs),
            ]);
          }),
        ),
      ),
    );
  }

  @Action(SetInitialOffset)
  private setInitialOffset(
    ctx: StateContext<ActivityStateModel>,
    { configId, day }: SetInitialOffset,
  ) {
    const modifications =
      ctx.getState().trainingConfigs[configId].modifications;
    return this.trainingConfigService
      .update({
        id: configId,
        modifications: produce(modifications, draft => {
          draft.offsets = day > 0 ? [{ offset: day }] : [];
        }),
      })
      .pipe(
        tap(updated =>
          ctx.setState(
            produce(draft => {
              draft.trainingConfigs[updated.id] = {
                ...draft.trainingConfigs[updated.id],
                ...updated,
              };
              draft.trainingDateIndex = createTrainingDateIndex([
                ...Object.values<TrainingConfig>(draft.trainingConfigs),
              ]);
            }),
          ),
        ),
      );
  }

  @Action(UpdateTrainingConfig)
  public updateTrainingConfig(
    ctx: StateContext<ActivityStateModel>,
    { config }: UpdateTrainingConfig,
  ) {
    return this.trainingConfigService.update(config).pipe(
      tap((updated: TrainingConfig) =>
        ctx.setState(
          produce(draft => {
            draft.trainingConfigs[updated.id] = {
              ...draft.trainingConfigs[updated.id],
              ...updated,
            };
            draft.trainingDateIndex = createTrainingDateIndex([
              ...Object.values<TrainingConfig>(draft.trainingConfigs),
            ]);
          }),
        ),
      ),
    );
  }

  @Action(ActivateTrainingConfig)
  public activateTrainingConfig(
    ctx: StateContext<ActivityStateModel>,
    { config }: ActivateTrainingConfig,
  ) {
    // Set the default rest day based on the current configuration.
    const modifications = {
      rest_days: [{ day: ctx.getState().defaultRestDay, start_date: null }],
    };

    return ctx.dispatch(
      new UpdateTrainingConfig({
        id: config.id,
        start_date: new Date(),
        modifications,
      }),
    );
  }

  private deleteFuturePlan(
    ctx: StateContext<ActivityStateModel>,
    config: TrainingConfig,
  ) {
    const referencingPlan = Object.values(ctx.getState().trainingConfigs).find(
      tc => tc.next_config_id === config.id,
    );

    return forkJoin([
      defer(() => ctx.dispatch(new DeleteTrainingConfig(config))),
      referencingPlan
        ? defer(() =>
            ctx.dispatch(
              new UpdateTrainingConfig({
                ...referencingPlan,
                next_config_id: null,
              }),
            ),
          )
        : EMPTY,
    ]);
  }

  private stopCurrentPlan(
    ctx: StateContext<ActivityStateModel>,
    config: TrainingConfig,
  ) {
    const jobs = [];

    if (isSameDay(config.start_date, new Date())) {
      // Delete it if we share the same date.
      jobs.push(defer(() => ctx.dispatch(new DeleteTrainingConfig(config))));
    } else {
      // Otherwise, just update it to set the end date to yesterday.
      jobs.push(
        defer(() =>
          ctx.dispatch(
            new UpdateTrainingConfig({
              id: config.id,
              end_date: subDays(new Date(), 1),
            }),
          ),
        ),
      );
    }

    // If the config we are stopping has another plan linked to it, we can go ahead and make that the new plan.
    if (config.next_config_id) {
      const nextPlan = Object.values(ctx.getState().trainingConfigs).find(
        tc => tc.id === config.next_config_id,
      );
      if (nextPlan) {
        jobs.push(
          defer(() => ctx.dispatch(new ActivateTrainingConfig(nextPlan))),
        );
      }
    }

    return forkJoin(...jobs);
  }

  @Action([
    UpdateStepsGoal,
    SetWalkTimerGoal,
    UpdateDoingWorkouts,
    UpdateDoingWalkTimer,
  ])
  updateActivityConfig(ctx: StateContext<ActivityStateModel>, action) {
    const currentConfig = Object.values(ctx.getState().activityConfigs)
      .sort((a, b) => (a.valid_from < b.valid_from ? 1 : -1))
      .find(i => isBefore(i.valid_from, action.date));

    // If the current config's date is today, we just update it.
    // If it isn't today, then we create a new one.
    if (isSameDay(currentConfig.valid_from, action.date)) {
      ctx.setState(
        produce(draft => {
          draft.activityConfigs[currentConfig.uuid] = {
            ...draft.activityConfigs[currentConfig.uuid],
            ...action,
          };
        }),
      );

      this.trainingConfigService
        .updateActivityConfig(
          ctx.getState().activityConfigs[currentConfig.uuid],
        )
        .subscribe();

      return;
    }

    // Create a new one, based off the current config.
    ctx.dispatch(
      new CreateActivityConfig({
        ...currentConfig,
        ...action,
        valid_from: startOfDay(action.date),
        uuid: v1(),
        id: undefined,
      }),
    );
  }

  @Action(CreateActivityConfig)
  createActivityConfig(
    ctx: StateContext<ActivityStateModel>,
    { config }: CreateActivityConfig,
  ) {
    config = ensureActivityConfigFields(config);

    ctx.setState(
      produce(draft => {
        draft.activityConfigs[config.uuid] = config;
        draft.activityDateIndex = createActivityConfigIndex(
          Object.values(draft.activityConfigs),
        );
      }),
    );

    this.trainingConfigService.createActivityConfig(config).subscribe();
  }

  @Action(SetDefaultRestDay)
  public setDefaultRestDay(
    ctx: StateContext<ActivityStateModel>,
    { day }: SetDefaultRestDay,
  ) {
    ctx.patchState({ defaultRestDay: day });
  }

  @Action(SwapDays)
  public swapDays(
    ctx: StateContext<ActivityStateModel>,
    { tc, firstDay, secondDay }: SwapDays,
  ) {
    const existingSwaps =
      ctx.getState().trainingConfigs[tc.id].modifications?.swap_days || [];

    // Handle "swapping back".
    if (existingSwaps.length > 0) {
      if (
        existingSwaps.find(
          es => es.firstDay === firstDay && es.secondDay === secondDay,
        )
      ) {
        ctx.setState(
          produce(draft => {
            draft.trainingConfigs[tc.id].modifications.swap_days =
              existingSwaps.filter(
                s => !(s.firstDay === firstDay && s.secondDay === secondDay),
              );
          }),
        );
      } else if (
        existingSwaps.find(
          es => es.firstDay === secondDay && es.secondDay === firstDay,
        )
      ) {
        // Handle opposite swaps? Duplication?
        ctx.setState(
          produce(draft => {
            draft.trainingConfigs[tc.id].modifications.swap_days =
              existingSwaps.filter(
                s => !(s.firstDay === secondDay && s.secondDay === firstDay),
              );
          }),
        );
      } else {
        ctx.setState(
          produce(draft => {
            draft.trainingConfigs[tc.id].modifications.swap_days.push({
              firstDay,
              secondDay,
              recorded: new Date(),
            });
          }),
        );
      }
    } else {
      ctx.setState(
        produce(draft => {
          draft.trainingConfigs[tc.id].modifications =
            draft.trainingConfigs[tc.id].modifications || {};
          draft.trainingConfigs[tc.id].modifications.swap_days = [
            ...(draft.trainingConfigs[tc.id].modifications.swap_days || []),
            { firstDay, secondDay, recorded: new Date() },
          ];
        }),
      );
    }

    this.trainingConfigService
      .update(ctx.getState().trainingConfigs[tc.id])
      .subscribe();
  }
}

function createActivityConfigIndex(activityConfigs: ActivityConfig[]) {
  return activityConfigs
    .sort((a, b) => (a.valid_from < b.valid_from ? 1 : -1))
    .map((ac, i, array) => {
      return {
        start: ac.valid_from,
        end: i === 0 ? null : subSeconds(array[i - 1].valid_from, 1),
        uuid: ac.uuid,
      };
    });
}

function ensureActivityConfigFields(ac: ActivityConfig): ActivityConfig {
  if (ac.walk_timer_goal === null) {
    ac.walk_timer_goal = 1800;
  }

  if (ac.pedometer_goal_steps === null) {
    ac.walk_timer_goal = 7000;
  }

  return ac;
}
