import { PickedFile } from '@capawesome/capacitor-file-picker';
import { ActionSheetButton } from '@ionic/angular';

export enum LiveStreamStatus {
  LIVE = 'live',
}

export interface Video<T = Date> {
  id: number;
  total_views: number;
  description: string;
  live_status: string;
  duration: string;
  length: number;
  created_at: T;
  publish_date?: T;
  poster: string;
  thumbnail: string;
  signed_url: string;
  title: string;
}

export interface Videos {
  videos?: Video[];
  active_stream?: Video;
}

export interface FeedItem {
  id: number;
  text: string;
  author: string;
  special: boolean;
  sticky: boolean;
  transphormer_id: number;
  approved_at: string;
  created_at: string;
  updated_at: string;
  profile_picture_url?: string;
}

export type UrlHandlerCallback = (
  url: string | Pick<PickedFile, 'path' | 'mimeType'>,
) => void;

export class MediaAdderOptions {
  overwriteHandler = false;
  extraButtons: ActionSheetButton[] = [];
  multiple?: boolean = false;
}
