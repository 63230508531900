import { MessageAttachment3 } from '../message';

export interface ChatListItemResponse {
  id: string; // uuid
  trainerTransphormerId?: number;
  trainerId: number;
  transphormerId: number;
  advisorUnread: boolean;
  transphormerUnread: boolean;
  lastMessageSent: string; //date
  lastTransphormerMessage: string; //date
  lastAdvisorMessage: string; //date
  createdAt?: string; //date
  updatedAt?: string; //date
  latestMessageId: string;
  oldestUnrespondedTransphormerMessage?: string; //date
}

export interface ChatListItem extends ChatListItemResponse {
  displayName?: string;
  latestMessage?: LatestMessageItemResponse;
  lastestMessageIsAttachment?: boolean;
  latestMessageDate?: string;
  iSentLastMessage?: boolean;
  isBattleBuddyAssignee?: boolean;
}

export interface DraftListItem {
  id?: string;
  message?: string;
}

export interface PusherChatListItem extends ChatListItemResponse {
  latestMessage: LatestMessageItemResponse;
}

export interface LatestMessageItemResponse {
  id?: string;
  createdAt: string;
  message: string;
  readAt?: string;
  status?: string;
  type?: string;
  messageAttachments?: MessageAttachment3[];
}

export interface NormalizedChatListResponseData {
  normalChatList: ChatListItemResponse[];
  normalLatestMessages: LatestMessageItemResponse[];
}

export interface FlatChatListResponseData {
  chats: ChatListItemResponse[];
  latestMessages: LatestMessageItemResponse[];
}

export enum ChatListSortByOptions {
  LAST_MSG = 'lastMessageSent',
  LAST_TRANSPHORMER_MSG = 'lastTransphormerMessage',
  LAST_ADVISOR_MSG = 'lastAdvisorMessage',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  OLDEST_TRANSPHORMER_UNREAD_MSG = 'oldestUnrespondedTransphormerMessage',
}

export enum ChatListFilterByOptions {
  TEAM_ID = 'teamId',
  ADVISOR_ID = 'advisorId',
  UUID = 'uuid',
  SEARCH = 'search',
  ADVISOR_UNREAD = 'advisorUnread',
  TRANSPHORMER_UNREAD = 'transphormerUnread',
  OLDEST_TRANSPHORMER_UNREAD_MSG = 'oldestUnrespondedTransphormerMessage',
  EXCLUDE_MINE = 'excludeMine',
  NOT_RESPONDED = 'notResponded',
}
