import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Rollbar from 'rollbar';
import { RollbarService } from '../../../rollbar';
import { apiUrl } from '../../../helpers';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  public constructor(@Inject(RollbarService) public rollbar: Rollbar) {}

  private ignore422ErrorsForUrl(url: string) {
    return (
      url.endsWith('auth/forgot-password') ||
      url.startsWith(apiUrl('change-password'))
    );
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          (error.status === 422 && !this.ignore422ErrorsForUrl(error.url)) ||
          error.status.toString()[0] === '5'
        ) {
          this.rollbar.error(error.message, { request: req, error });
        }

        if (error.url.endsWith('wants_link=true') && error.status === 404) {
          return EMPTY;
        }

        throw error;
      }),
    );
  }
}
