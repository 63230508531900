import {
  AmountConsumed,
  CaloriesMacros,
  isNutrition,
  isQuickAddItem,
  isTrackedItem,
  MealConfig,
  NutritionConfiguration,
  NutritionData,
  NutritionPlan,
  SimpleNutritionData,
  TrackableItem,
  TrackedItem,
  TrackedMeal,
} from '../../interfaces';

import { v1 } from 'uuid';
import { AlternativeServings } from '../../services/nutritionix/types';
import { _yyyyMMdd, isSameDayOrAfter } from '../../../../helpers/date';

function getMealConfig(
  config: NutritionConfiguration,
  mealId: number,
): MealConfig {
  if (config === null || !config.meals) {
    return { meal: mealId, expanded: true };
  }

  return config.meals.find(m => m.meal === mealId);
}

export const generateTrackedMealsForTrackedItems = (
  foodItems: TrackedItem[],
  config: NutritionConfiguration = null,
) => {
  const meals = [
    {
      meal_count: 1,
      food_items: [],
      active: getMealConfig(config, 1).expanded,
    },
    {
      meal_count: 2,
      food_items: [],
      active: getMealConfig(config, 2).expanded,
    },
    {
      meal_count: 3,
      food_items: [],
      active: getMealConfig(config, 3).expanded,
    },
    {
      meal_count: 4,
      food_items: [],
      active: getMealConfig(config, 4).expanded,
    },
    {
      meal_count: 5,
      food_items: [],
      active: getMealConfig(config, 5).expanded,
    },
    {
      meal_count: 6,
      food_items: [],
      active: getMealConfig(config, 6).expanded,
    },
  ];

  // Assign all of the items to their meals.
  foodItems.forEach(fi => {
    meals.find(m => m.meal_count === fi.meal).food_items.push(fi);
  });

  return meals.map(meal => {
    const numberQuickAddedItems = meal.food_items.reduce(
      (a, v) => (isQuickAddItem(v) ? a + 1 : a),
      0,
    );
    const onlyQuickAddedItems =
      numberQuickAddedItems === meal.food_items.length &&
      meal.food_items.length > 0;
    return {
      ...meal,
      numberQuickAddedItems,
      onlyQuickAddedItems,
      macros: calculateMacrosForMeal(meal),
    };
  });
};

export function calculateMacrosForMeal(
  meal: TrackedMeal | { food_items: CaloriesMacros[] },
): CaloriesMacros {
  return calculateMacrosForTrackedItems(meal.food_items);
}

export function applyConsumedMultiplier(items: TrackedItem[], multiplier = 1) {
  return items.map(i => ({
    ...i,
    consumed: { ...i.consumed, amount: i.consumed.amount * multiplier },
  }));
}

export function calculateMacrosForTrackedItems(
  trackedItems: CaloriesMacros[],
): CaloriesMacros {
  return {
    fiber: Math.round(
      trackedItems.reduce((fiber, foodItem) => fiber + +foodItem.fiber, 0),
    ),
    calories: Math.round(
      trackedItems.reduce(
        (calories, foodItem) => calories + +foodItem.calories,
        0,
      ),
    ),
    protein: Math.round(
      trackedItems.reduce(
        (protein, foodItem) => protein + +foodItem.protein,
        0,
      ),
    ),
    carbs: Math.round(
      trackedItems.reduce((carbs, foodItem) => carbs + +foodItem.carbs, 0),
    ),
    fats: Math.round(
      trackedItems.reduce((fats, foodItem) => fats + +foodItem.fats, 0),
    ),
  };
}

export function generateTrackedMealsForNutritionData(
  dayData: SimpleNutritionData | NutritionData,
  config: NutritionConfiguration = null,
): TrackedMeal[] {
  if (isNutrition(dayData)) {
    return generateTrackedMealsForTrackedItems(dayData.food, config);
  }

  return [];
}

export function quickAddToTrackedItem(macros: CaloriesMacros): TrackedItem {
  const { calories, carbs, fiber, fats, protein } = macros;
  return {
    localId: v1(),
    serving_size: undefined,
    servings: [],
    source: 'quick-add',
    source_id: null,
    source_type: 'quick-add',
    thumbnail: null,
    name: 'Quick Add',
    calories,
    carbs,
    fats,
    fiber,
    protein,
    consumed: {
      amount: 1,
      unit: 'serving',
    },
  };
}

export function calculateValues(
  foodItem: TrackableItem | TrackedItem,
  consumed: AmountConsumed,
): CaloriesMacros {
  const calculatedMacros: CaloriesMacros = <CaloriesMacros>{};
  ['calories', 'protein', 'carbs', 'fats', 'fiber'].forEach(key => {
    calculatedMacros[key] =
      calculateRelativeValueConsumed(foodItem, key, consumed) || 0;
  });
  return calculatedMacros;
}

export function findBestServingSize(
  consumed: AmountConsumed,
  servings: AlternativeServings[],
  originalServings: AlternativeServings[] = [],
) {
  const exactMatch = servings.find(
    serving => serving.measure === consumed.unit,
  );

  if (exactMatch) {
    return exactMatch;
  }

  // Is there an original Serving size?
  const originalMatch = originalServings.find(
    serving => serving.measure === consumed.unit,
  );

  if (originalMatch) {
    return originalMatch;
  }

  const matchByName = servings.find(
    serving => serving.measure.indexOf(consumed.unit) !== -1,
  );

  if (matchByName) {
    return matchByName;
  }

  const firstSequenceItem = servings.find(serving => serving.seq === 1);

  if (firstSequenceItem) {
    return firstSequenceItem;
  }

  // Give up.
  return servings[0];
}

export function calculateServingSize(macros: CaloriesMacros, servings: number) {
  const calculatedMacros: CaloriesMacros = <CaloriesMacros>{};

  ['calories', 'protein', 'carbs', 'fats', 'fiber'].forEach(key => {
    calculatedMacros[key] = fixDecimal(macros[key] / servings) || 0;
  });

  return calculatedMacros;
}

function calculateRelativeValueConsumed(
  foodItem: TrackedItem | TrackableItem,
  macro: string,
  consumed: AmountConsumed,
): number {
  // If our consumed size matches the base unit size, then just do the math.
  if (consumed.unit === foodItem.serving_size.unit) {
    return fixDecimal(
      (consumed.amount / foodItem.serving_size.amount) *
        foodItem.serving_size[macro],
    );
  }

  // Otherwise, we need to figure out the ratio, and compute that.
  const baseServing = foodItem.servings.find(
    serving => serving.measure === foodItem.serving_size.unit,
  );

  const consumedSize = findBestServingSize(
    consumed,
    foodItem.servings,
    isTrackedItem(foodItem) ? foodItem.originalServings : [],
  );

  // Based on the information we get back from Nutritionix, calculate the new "weight" of the product.
  // Take the serving weight, which defines, for a given size, e.g. cup, how many base units exist within the product.
  // E.g. if I have 2 "cups" selected, the serving weight will be 244. In many cases, the quantity in the consumedSize
  // is 1 or is actually the serving_weight itself (like when using grams). However, in come cases, the quantity is
  // not 1 or 100, but might be "3" like it is with grilled chicken breast. I'm not sure they don't just say that the
  // quantity is 28.33, but it could just be that is how they represent the fraction.
  const perConsumedSizeWeight = consumedSize.serving_weight / consumedSize.qty;
  // We then take that amount per unit consumed and actually multiply it by the amount we are consuming.
  const newWeight = perConsumedSizeWeight * consumed.amount;
  // Now, we know how much of the product's base weight is being consumed. Since *most* products have a base weight
  // associated with them (e.g. 200 calories = 50 grams) then we need to figure out what the new macro is by dividing
  // that by the base weight first and then multiplying that by the new weight.
  // It turns out that for some products (like metric liquids), there is no base weight and the products themselves
  // are just measured by some base unit like mL. Therefore, if the base_weight is null, just use 1 since when the
  // food is converted, we are adding the default serving quantity, which fixes everything above.
  const perServingWeight =
    baseServing.qty / baseServing.serving_weight / foodItem.serving_size.amount;
  return fixDecimal(
    newWeight * foodItem.serving_size[macro] * perServingWeight,
  );
}

function fixDecimal(value: number): number {
  return parseFloat(value.toFixed(2));
}

export function foodEquals(itemToMatch) {
  return fi => {
    if (fi.localId) {
      return fi.localId === itemToMatch.localId;
    }
    if (fi.source_id && itemToMatch.source_id) {
      return (
        fi.source === itemToMatch.source &&
        fi.source_type === itemToMatch.source_type &&
        fi.source_id === fi.source_id
      );
    }
    return false;
  };
}

export function isSimpleNutritionType(plan: NutritionPlan) {
  return (
    plan === NutritionPlan.PortionControl ||
    plan === NutritionPlan.MacroMealPlan
  );
}

export function isComplexNutritionType(plan: NutritionPlan) {
  return plan === NutritionPlan.CalorieMacroCounting;
}

/**
 * Return true if the trackDate is on today or in the future.
 * @param day
 * @param beforeDate
 */
export function trackDateIsTodayOrFuture(day: string, beforeDate: Date = null) {
  if (!beforeDate) {
    beforeDate = new Date();
  }

  return isSameDayOrAfter(_yyyyMMdd(day), beforeDate);
}
