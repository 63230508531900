import { environment } from '../environments/environment';
import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
} from '@angular/core';
import * as Rollbar from 'rollbar';
import { Configuration } from 'rollbar';

const IGNORED_CUSTOM_ERRORS = [
  'Warning. Session initialization already happened. To force a new session, set intent extra, "branch_force_new_session", to true.',
];

export const rollbarConfig = <Configuration>{
  accessToken: environment.rollbar.accessToken,
  itemsPerMinute: 100,
  captureUncaught: false,
  reportLevel: environment.production ? 'warning' : 'info',
  enabled: environment.production,
  verbose: !environment.production,
  ignoredMessages: [
    'The user credentials were incorrect.',
    'The operation was aborted.',
    'has no access to camera',
    'pickFiles canceled.',
    'User cancelled photos app',
    'User cancelled video',
    'Camera already started!',
    'Uncaught (in promise): Session not started',
    'Session not started',
    /^Transaction expired:/,
    /^Cannot validate purchases./,
  ],
  filterTelemetry: function (e) {
    return (
      e.type === 'network' &&
      (e.body.subtype === 'xhr' || e.body.subtype === 'fetch') &&
      e.body.url &&
      typeof e.body.url === 'string' &&
      e.body.url.indexOf('.svg') !== -1
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform: (payload: any) => {
    const trace = payload.body.trace;
    const locRegex = /(?:.+\/)(.+)/;
    if (trace && trace.frames) {
      for (let i = 0; i < trace.frames.length; i++) {
        const filename = trace.frames[i].filename;
        if (filename) {
          const m = filename.match(locRegex);
          if (!m || m.length < 2) {
            return;
          }
          // Be sure that the minified_url when uploading includes `//${UNIQUE_DOMAIN}` on upload-source-maps.sh
          trace.frames[i].filename =
            `http://${environment.rollbar.uniqueDomain}/${m[1]}`;
        }
      }
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkIgnore: (isUncaught, args: any, payload: any) => {
    if (
      args &&
      args.body &&
      args.body.trace &&
      args.body.trace.exeception &&
      args.body.trace.exeception.description ===
        'Launch Darkly connection issue'
    ) {
      return true;
    }

    if (
      args &&
      args[1] &&
      args[1].error &&
      args[1].error.status === 422 &&
      args[1].error.url.includes('auth/register')
    ) {
      return true;
    }

    if (
      payload &&
      payload.level === 'error' &&
      payload.custom &&
      IGNORED_CUSTOM_ERRORS.includes(payload.custom.error)
    ) {
      return true;
    }

    if (
      payload.body?.trace?.exception?.description?.includes(
        'Domain=com.google.iid Code=-25299',
      ) ||
      payload.body?.trace?.exception?.description?.includes(
        'Domain=NSURLErrorDomain Code=-1005',
      ) ||
      payload.body?.trace?.exception?.description?.includes(
        'Domain=NSURLErrorDomain Code=-1001',
      ) ||
      payload.body?.trace?.exception?.class?.includes(
        'LaunchDarklyUnexpectedResponseError',
      )
    ) {
      return true;
    }

    return false;
  },
  captureUnhandledRejections: true,
  payload: {
    environment: environment.production ? 'production' : 'dev',
    client: {
      javascript: {
        code_version: environment.gitHash,
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

const isPromiseRejection = (a): a is UncaughtPromiseError =>
  a.rejection !== undefined;
const getRejectionClassName = (a: UncaughtPromiseError): string | null =>
  a.rejection.__proto__?.name;
const rejectionClassIs = (a: UncaughtPromiseError, s: string): boolean =>
  getRejectionClassName(a) === s;

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: Error | UncaughtPromiseError): void {
    if (isPromiseRejection(err)) {
      // If we get a LaunchDarklyFlagFetchError, let's just pass this on as a warning.
      if (rejectionClassIs(err, 'LaunchDarklyFlagFetchError')) {
        this.rollbar.warn('Launch Darkly connection issue', err);

        return;
      }
    }

    this.rollbar.error(err.message, err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
