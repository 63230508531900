import { mapWeightUnit } from '../../helpers/map-unit-helper';
import { dateMorph, Transphormer, Weight } from '../../interfaces';

export function convertTransphormerWeights<
  T extends Pick<Transphormer, 'latest_weight' | 'starting_weight'>,
>(transphormer: T): T {
  if (transphormer.latest_weight) {
    transphormer.latest_weight = mapWeightUnit(transphormer.latest_weight);
  }
  if (transphormer.starting_weight) {
    transphormer.starting_weight = mapWeightUnit(transphormer.starting_weight);
  }
  return transphormer;
}

/**
 * Converts logged date string value to date value
 * @param weight
 */
export function loggedDateConversion(weight: Weight<string>): Weight {
  return {
    ...mapWeightUnit(weight as unknown as Weight),
    ...dateMorph(weight),
  };
}
