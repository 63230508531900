import { ActivityDataPoint, WorkoutExerciseData } from '../../../interfaces';

export class LoadWorkoutById {
  static readonly type = '[Workouts] Load Workout By Id';

  constructor(public workoutId: number) {}
}

export class LoadTrainingProgram {
  static readonly type = '[Workouts] Load Training Program';

  constructor(public trainingId: number) {}
}

export class LoadCustomWorkout {
  static readonly type = '[Workouts] Load Custom Workout';
}

export class ToggleWorkoutSessionCompletion {
  static readonly type = '[Workouts] Toggle Workout Session completion';

  constructor(public sessionInfo: string) {}
}

export class CreateNewWorkoutSession {
  static readonly type = '[Workouts] Create New Workout Session';
  constructor(
    public workoutId: number,
    public date: Date = new Date(),
  ) {}
}

export class AddUuidToWorkoutSession {
  static readonly type = '[Workouts] Add UUID to Workout Session';
  constructor(public sessionId: number) {}
}

export class UpdateSessionNotes {
  static readonly type = '[Workouts] Update Session Notes';

  constructor(
    public sessionId: string,
    public notes: string,
  ) {}
}

export class EnsureWorkoutSessionPersisted {
  static readonly type = '[Workouts] Ensure Workout Session Persisted';

  constructor(public sessionId: string) {}
}

export class ToggleSetRepVisibility {
  static readonly type = '[Workouts] Toggle Set Rep Visibility';

  constructor(
    public sessionId: string,
    public groupId: number,
    public groupMovementId: number,
  ) {}
}

export class LoadWorkoutSession {
  static readonly type = '[Workouts] Load Workout Session';

  constructor(public sessionId: string) {}
}

export class LoadActivityDataBySession {
  static readonly type = '[Workouts] Load Activity Data For Session';

  constructor(public sessionId: string) {}
}

export class UpdateMovementSetsReps {
  static readonly type = '[Workouts] Update Movement Sets Reps';

  constructor(
    public sessionId: string,
    public workoutData: Partial<WorkoutExerciseData>,
  ) {}
}

export class UpdateMovementGroup {
  static readonly type = '[Workouts] Update Movement Group Values';

  constructor(public exerciseData: ActivityDataPoint) {}
}

export class CompleteSessionWarmup {
  static readonly type = '[Workouts] Complete Session Warmup';

  constructor(
    public sessionId: string,
    public completed: boolean = true,
  ) {}
}

export class CompleteSessionCooldown {
  static readonly type = '[Workouts] Complete Session Cooldown';

  constructor(
    public sessionId: string,
    public completed: boolean = true,
  ) {}
}
