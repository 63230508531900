<ion-img src="assets/att/help-us-help-you.jpg" alt="welcome image"> </ion-img>
<h4 class="ion-margin-top ion-margin-horizontal">
  Help Us Improve Your Experience
</h4>
<ion-content class="ion-padding-horizontal">
  <ion-text> {{ promptText }}</ion-text>
</ion-content>
<ion-footer collapse="fade">
  <ion-toolbar>
    <ion-button
      expand="full"
      (click)="showPrompt()"
      class="ion-margin ion-justify-content-end"
      >Continue
    </ion-button>
  </ion-toolbar>
</ion-footer>
