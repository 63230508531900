import { Inject, Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  GlobalEmitterService,
  GlobalEvents,
} from '../global-emitter/global-emitter.service';
import { Router } from '@angular/router';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';
import { apiUrl } from '../../helpers';
import {
  ActionPerformed,
  PushNotifications,
} from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private allowedPermissions = false;
  constructor(
    public http: HttpClient,
    private navCtrl: NavController,
    private globalEmitter: GlobalEmitterService,
    private router: Router,
    @Inject(RollbarService) public rollbar: Rollbar,
  ) {}
  /**
   * Handle initializing the firebase connection when a user
   */
  public async registerUser() {
    this.rollbar.debug('firebase: registerUser');
    // Non-native platforms are not supported.
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      // Get Push Notifications permissions and register ourselves.
      this.rollbar.debug('firebase: need to prompt');
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      this.allowedPermissions = false;
      this.rollbar.debug('firebase: no permissions!');
      // Don't throw an error. This isn't an error!
      // throw new Error('User denied permissions!');
      return;
    }

    await PushNotifications.addListener('registration', token => {
      this.rollbar.debug('Registration token: ', token.value);
      this.allowedPermissions = true;

      // Get our token and send it to the server so that FCM works.
      this.getAndSaveToken();

      // Subscribe to the main topic.
      this.subscribeTo('1stphorm');
    });

    await PushNotifications.addListener('registrationError', err => {
      this.rollbar.info('[Firebase] Registration error: ', err.error);
    });

    // This has to happen before we can do the getToken().
    await PushNotifications.register();

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      notification => this.performNotificationAction(notification),
    );

    // Check the auto initialization status
    FCM.isAutoInitEnabled().then(r => {
      this.rollbar.debug(
        `firebase: Auto init is ${r.enabled ? 'enabled' : 'disabled'}`,
      );
    });
  }

  /**
   * Make sure we clean up after a user. Call this upon logout. Make sure
   * the listeners are gone and that everything is cleared for the next user.
   */
  async unregisterUser() {
    // Don't run the unregistration stuff we don't have permissions!
    if (!Capacitor.isNativePlatform() || !this.allowedPermissions) {
      return;
    }

    await PushNotifications.removeAllListeners();
    await PushNotifications.removeAllDeliveredNotifications();
    await FCM.deleteInstance();
  }

  unsubscribeFrom(topic: string) {
    // We do not support web (for now).
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    if (!this.allowedPermissions) {
      return;
    }

    FCM.unsubscribeFrom({ topic })
      .then(() => {
        /* intentionally empty */
      })
      .catch(err => this.rollbar.info('FCM.unsubscribeFrom error', { err }));
  }

  subscribeTo(topic: string) {
    // We do not support web (for now).
    if (!Capacitor.isNativePlatform()) {
      return false;
    }

    if (!this.allowedPermissions) {
      return false;
    }

    return FCM.subscribeTo({ topic })
      .then(v => {
        this.rollbar.debug(`firebase topic ${topic} subscribed`);
        return v;
      })
      .catch(err => this.rollbar.info('FCM.subscribeTo error', { err }));
  }

  public saveTokenToServer(token: string): Observable<void> {
    if (token !== null && token !== '' && token !== 'null') {
      return this.http.post<void>(apiUrl('auth/device-token'), { token });
    } else {
      return new Observable<void>();
    }
  }

  private navigateAfterDelay(page: string) {
    // @todo: Callback behaviour on tap unpredictable causing it not being called when tapping a push notification.
    setTimeout(async () => {
      this.rollbar.debug('Firebase navigation: ' + page);
      this.navCtrl.navigateRoot(page).then();
    }, 100);
  }

  subscribeToLivestreamNotification = () => this.subscribeTo('livestream');
  subscribeToMotivationNotification = () => this.subscribeTo('motivation');
  unsubscribeToMotivationNotification = () =>
    this.unsubscribeFrom('motivation');
  unsubscribeToLivestreamNotification = () =>
    this.unsubscribeFrom('livestream');

  private performNotificationAction(notification: ActionPerformed) {
    const data = notification.notification.data;
    this.rollbar.debug('firebase: performNotificationAction', {
      notification,
      data,
    });

    if (notification.actionId === 'tap') {
      // TODO: Remove switch and just navigate to the URL once the backend is updated to create notifications with
      // entire URL containing ids
      if (data.page === 'advisor-messages' && data.transphormer_id) {
        this.navigateAfterDelay(
          `advisor/transphormer/${data.transphormer_id}/advisor-messages`,
        );
      } else if (data.page) {
        this.navigateAfterDelay(data.page);
      }
    } else {
      // sending notification through the system that new announcements are here.
      if (data.page === 'announcements') {
        this.globalEmitter.emit(GlobalEvents.NewAnnouncement);
      }

      // sending notification through the system that new notification message
      if (data.page === 'messages' && this.router.url !== '/messages') {
        this.globalEmitter.emit(GlobalEvents.NewTransphormerMessages);
      }

      // sending notification through the system that new notification message
      if (
        data.page === 'advisor-messages' &&
        this.router.url !==
          `/advisor/transphormer/${data.transphormer_id}/advisor-messages`
      ) {
        this.globalEmitter.emit(GlobalEvents.NewAdvisorMessages);
      }
    }
  }

  getAndSaveToken = () =>
    FCM.getToken().then(result => {
      this.saveTokenToServer(result.token).subscribe();
    });
}
