<div
  id="__dark_mode_wrapper__"
  style="height: 100%; background: var(--ion-background-color)"
  [class.dark]="darkModeService.darkModeEnabled$ | async"
>
  <ion-app
    [class.with-bottom-menu]="(bottomMenuVisible$ | async) === true"
    [class.split]="(isSplit$ | async) === true"
  >
    <div class="my-windows">
      <div
        [class]="
          (displayRightSide$ | async) === true ? 'left-half split' : 'full'
        "
      >
        <ion-router-outlet
          [class]="(displayRightSide$ | async) === true ? 'left-side' : ''"
          id="content"
        ></ion-router-outlet>
        <app-bottom-nav
          [style.display]="
            (bottomMenuVisible$ | async) === true ? 'block' : 'none'
          "
          [transfersVisible]="(transfersVisible$ | async) === true"
        ></app-bottom-nav>
      </div>
      <div
        [style.display]="
          (displayRightSide$ | async) === true ? 'block' : 'none'
        "
        class="right-half"
      >
        <ion-router-outlet
          class="right-side"
          id="right"
          name="right"
        ></ion-router-outlet>
      </div>
    </div>
  </ion-app>
</div>
