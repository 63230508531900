import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

@Injectable()
export class AutomaticRetryInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const shouldNotRetry =
      request.headers.has('X-No-Retry') &&
      !!request.headers.delete('X-No-Retry');
    return next
      .handle(request)
      .pipe(
        retryWhen(
          genericRetryStrategy({ maxRetryAttempts: shouldNotRetry ? -1 : 5 }),
        ),
      );
  }
}

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 5,
    scalingDuration = 10,
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
  } = {}) =>
  (attempts: Observable<HttpErrorResponse>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (error.status !== 0 || retryAttempt > maxRetryAttempts) {
          return throwError(error);
        }
        console.log(
          `Attempt ${retryAttempt}: retrying ${error.url} in ${
            retryAttempt * scalingDuration
          }ms`,
        );
        return timer(retryAttempt * scalingDuration);
      }),
    );
  };
