import {
  HealthAggregationBucket,
  HealthDataType,
  HealthStepsDayRecord,
} from '../types';

export class LoadRecentData {
  static readonly type = '[Health] Load recent data';
}

export class LoadHistoryData {
  static readonly type = '[Health] Load history data';

  constructor(
    public startDate: Date,
    public endDate: Date,
    public dataType: HealthDataType,
    public bucket: HealthAggregationBucket,
  ) {}
}

export class LoadStepsByDate {
  static readonly type = '[Health] Load steps by date';

  constructor(public date: string) {}
}

export class UpdateSteps {
  static readonly type = '[Health] Update Steps';

  constructor(public steps: HealthStepsDayRecord[]) {}
}
