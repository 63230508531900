import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, interval, Observable, of, Subject } from 'rxjs';
import { apiUrl } from '../../helpers';
import {
  concatMap,
  debounce,
  groupBy,
  map,
  mergeMap,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { handleApiError, ignore404 } from '../../helpers/operators';
import { ApiData } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  setValueDebouncer = new Subject<{ key: string; value }>();
  private valuesRequest$: Observable<Record<string, unknown>>;
  private buffer$ = new BehaviorSubject({});

  constructor(private http: HttpClient) {
    this.setValueDebouncer
      .pipe(
        groupBy(v => v.key),
        mergeMap(group => from(group).pipe(debounce(() => interval(1000)))),
        tap(({ key, value }) => (this.cache[key] = value)),
        concatMap(({ key, value }) =>
          this.http.post(apiUrl(`value/${key}`), { value }),
        ),
      )
      .subscribe();
  }

  private _cache = {};
  private preloaded = false;
  private debugging = false;

  get cache() {
    return this._cache;
  }

  set cache(v) {
    this._cache = v;
    this.buffer$.next(v);
  }

  preload() {
    this.debug('UserStorageService.preload()');
    this.requestValues().subscribe();
  }

  private requestValues(): Observable<Record<string, unknown>> {
    if (!this.valuesRequest$) {
      this.valuesRequest$ = this.http
        .get<ApiData<{ key: string; value: unknown }[]>>(apiUrl('values'))
        .pipe(
          map(serverValues =>
            serverValues.data.reduce(
              (o, v) => ({ ...o, [v.key]: v.value }),
              {},
            ),
          ),
          tap(v => this.debug('UserStorageService.preload() got', v)),
          tap(valueObject => {
            this.cache = valueObject;
            this.debug('cache is', this.cache);
            this.preloaded = true;
          }),
          tap(() => this.debug('UserStorageService.preload() finished')),
          shareReplay(1),
        );
    }

    return this.valuesRequest$;
  }

  /**
   * Remove all of our cached data, for instance, when the logout occurs.
   */
  unload() {
    this.debug('Unloading occurring...');
    this.preloaded = false;
    this.cache = {};
  }

  debug(...args) {
    if (this.debugging) console.debug(...args);
  }

  get<T>(key: string, defaultValue = null): Observable<T> {
    this.debug(`get(${key})`);
    this.debug(`cache: ${JSON.stringify(this.cache)}`);
    const base = this.preloaded ? of(this.cache) : this.requestValues();
    this.debug('base', this.preloaded ? 'preloaded' : 'request');
    return base.pipe(map(v => v[key] ?? defaultValue));
  }

  getStream<T>(key: string, defaultValue = null): Observable<T> {
    return this.buffer$.pipe(map(v => v[key] ?? defaultValue));
  }

  delete(key: string) {
    this.http
      .delete(apiUrl(`value/${key}`))
      .pipe(ignore404(), handleApiError())
      .subscribe(() => {
        if (this.preloaded) {
          delete this.cache[key];
        }
      });
  }

  set(key: string, value) {
    this.debug(`UserStorageService.set(${key}, ${JSON.stringify(value)})`);
    this.buffer$.next({ ...this.buffer$.value, [key]: value });
    this.setValueDebouncer.next({ key, value });
  }
}
