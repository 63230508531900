import { Inject, Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { PusherUnauthenticated } from '../pusher-factory';

export type PusherConnection = {
  channel: {
    bind: <T = unknown>(
      name: string,
      callback: (param: T) => void,
    ) => { close: () => void };
  };
  pusher: Pusher;
  close: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  protected service;

  constructor(@Inject(PusherUnauthenticated) private readonly pusher: Pusher) {}

  public getGeneralStreamChannel() {
    const channel = this.pusher.subscribe('stream');
    return {
      channel,
      pusher: this.pusher,
      close() {
        this.pusher.unsubscribe('stream');
      },
    };
  }

  public getChannelForLiveStream(stream) {
    const channel = this.pusher.subscribe('stream.' + stream);
    return {
      channel,
      pusher: this.pusher,
      close() {
        this.pusher.unsubscribe('stream.' + stream);
      },
    };
  }
}
