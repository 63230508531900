import {
  Action,
  createPropertySelectors,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  AmountConsumed,
  CaloriesMacros,
  isTrackedItem,
  ServingSize,
  TrackableItem,
  TrackedItem,
  UnitAmount,
} from '../../interfaces';
import {
  BeginEditingServing,
  ClearEditingFood,
  EndEditingServing,
  SetEditingFood,
  SetFoodMealNumber,
  UpdateCalories,
  UpdateConsumedAmounts,
  UpdateFoodMealNumber,
  UpdateImage,
  UpdateMacros,
  UpdateName,
  UpdateServing,
} from './food-editing.actions';
import { AlternativeServings } from '../../services/nutritionix/types';
import { calculateValues, findBestServingSize } from '../nutrition/functions';
import { defaultState } from './food-editing.state.default';
import produce from 'immer';
import { calculateQuickCalories } from '../../functions';

export const FOOD_EDITING_STATE_TOKEN = new StateToken<FoodEditingStateModel>(
  'food_editing',
);

export interface FoodEditingStateModel {
  // The "original" food item.
  foodItem: TrackableItem | TrackedItem;
  // The amount (and unit) consumed.
  consumed: UnitAmount;
  // The "base serving". Used for calculating the final macros.
  serving: ServingSize;
  // If set, the meal number of the food item.
  meal?: number;

  editingServing: boolean;
  // Various metadata about the current state.
  // Is editing the consumption information allowed?
  mayEditConsumption: boolean;
  // Does the serving size need to be set? i.e. creating a new item?
  requiresServingSize: boolean;
  // True if creating a new item.
  usesDefaultServings: boolean;
}

@State<FoodEditingStateModel>({
  name: FOOD_EDITING_STATE_TOKEN,
  defaults: defaultState,
})
@Injectable({
  providedIn: 'root',
})
export class FoodEditingState {
  // Generate a tracked item.
  @Selector([FoodEditingState])
  static trackedItem(state: FoodEditingStateModel): TrackedItem {
    const { foodItem, consumed, meal } = state;
    const { carbs, calories, protein, fats, fiber } = state.serving;

    return {
      ...foodItem,
      consumed,
      carbs,
      calories,
      protein,
      fats,
      fiber,
      meal,
    };
  }

  @Selector()
  static fullFoodEditingState(state: FoodEditingStateModel) {
    return state;
  }

  @Selector([FoodEditingState._foodItem])
  static foodItem(foodItem: TrackableItem | TrackedItem) {
    let thumbnail =
      foodItem.thumbnail && foodItem.thumbnail.endsWith('nix-apple-grey.png')
        ? null
        : foodItem.thumbnail;

    if (foodItem.picture_url) {
      thumbnail = foodItem.picture_url;
    }

    return { ...foodItem, thumbnail };
  }

  @Selector([FoodEditingState])
  static _foodItem(state: FoodEditingStateModel) {
    return state.foodItem;
  }

  @Selector([FoodEditingState._foodItem])
  static mayEditName(foodItem: FoodEditingStateModel['foodItem']) {
    // Are we allowed to edit the name?
    return foodItem.source === 'custom' || !foodItem.source;
  }

  @Selector([FoodEditingState])
  static mealInfo(state: FoodEditingStateModel) {
    return {
      meal: state.meal || null,
    };
  }

  @Selector()
  static availableServingUnits(
    state: FoodEditingStateModel,
  ): AlternativeServings[] {
    if (state.usesDefaultServings) {
      return [
        { measure: 'serving', qty: 1, serving_weight: 1, seq: 0 },
        { measure: 'ounces', qty: 1, serving_weight: 1, seq: 1 },
        { measure: 'grams', qty: 1, serving_weight: 1, seq: 2 },
        { measure: 'fl. ounces', qty: 1, serving_weight: 1, seq: 3 },
        { measure: 'ml', qty: 1, serving_weight: 1, seq: 4 },
        { measure: 'cup', qty: 1, serving_weight: 1, seq: 5 },
        { measure: 'tbsp', qty: 1, serving_weight: 1, seq: 6 },
        { measure: 'pound', qty: 1, serving_weight: 1, seq: 7 },
      ];
    } else {
      return state.foodItem.servings;
    }
  }

  @Selector([FoodEditingState._foodItem])
  static availableConsumptionUnits(
    foodItem: TrackableItem | TrackedItem,
  ): AlternativeServings[] {
    return foodItem.servings;
  }

  @Selector([FoodEditingState])
  static editingServing(state: FoodEditingStateModel) {
    return state.editingServing;
  }

  @Selector([FoodEditingState])
  static serving(state: FoodEditingStateModel) {
    return state.serving;
  }

  @Selector([FoodEditingState.consumed, FoodEditingState.editingServing])
  static isReadyToTrack(consumed: AmountConsumed, editingServing: boolean) {
    // If we are editing the serving, we cannot track.
    return (
      !editingServing &&
      !isNaN(consumed.amount) &&
      +consumed.amount !== 0 &&
      consumed.unit !== null
    );
  }

  @Selector([FoodEditingState])
  static consumed(state: FoodEditingStateModel) {
    return state.consumed;
  }

  @Selector([
    FoodEditingState.editingServing,
    FoodEditingState._foodItem,
    FoodEditingState.serving,
  ])
  static formValues(
    editingServing,
    foodItem: TrackableItem | TrackedItem,
    serving,
  ) {
    return editingServing ? foodItem.serving_size : serving;
  }

  @Selector([FoodEditingState._foodItem])
  static baseServing(foodItem: TrackableItem | TrackedItem): CaloriesMacros {
    return foodItem.serving_size;
  }

  @Action(SetEditingFood)
  setEditingFood(
    ctx: StateContext<FoodEditingStateModel>,
    { food }: SetEditingFood,
  ) {
    // Make a copy of the food item?
    const foodItem = { ...food };

    // Some foods have an existing serving but no servings array. If they do, then go ahead
    // and fake it since that's what is expected.
    if (
      food.servings &&
      food.servings.length === 0 &&
      food.serving_size &&
      food.serving_size.amount !== null
    ) {
      foodItem.servings = [
        {
          qty: food.serving_size.amount || 1,
          measure: food.serving_size.unit,
          serving_weight: 1,
          seq: 0,
        },
      ];
    }

    // Set a few basic items up. If the food doesn't have any servings to start off with,
    // let's make sure we use the default servings.
    const usesDefaultServings = !food.servings || food.servings.length === 0;
    const requiresServingSize =
      !Array.isArray(foodItem.servings) || foodItem.servings.length === 0;
    const mayEditConsumption = !requiresServingSize;

    // If the food item has no servings but the foodItem has serving size unit, then create
    // a default serving for that item. Since we only do this upon set, we can set it once
    // and be assured that it will not change again.
    if (foodItem.servings.length === 0) {
      foodItem.servings = [
        {
          qty: 1,
          measure: 'serving',
          serving_weight: 1,
          seq: 0,
        },
      ];
    }

    if (requiresServingSize) {
      foodItem.serving_size = {
        ...foodItem.serving_size,
        unit: 'serving',
        amount: 1,
      };
    }

    let consumed: AmountConsumed;

    if (isTrackedItem(foodItem)) {
      consumed = foodItem.consumed;
    } else {
      if (foodItem.default_consumption_value) {
        consumed = {
          amount: foodItem.default_consumption_value.amount,
          unit:
            !usesDefaultServings &&
            foodItem.servings.findIndex(
              serving =>
                serving.measure === foodItem.default_consumption_value.unit,
            ) === -1
              ? findBestServingSize(
                  foodItem.default_consumption_value,
                  foodItem.servings,
                ).measure
              : foodItem.default_consumption_value.unit,
        };
      } else {
        consumed = {
          amount: null,
          unit: null,
        };
      }
    }

    ctx.patchState({
      usesDefaultServings,
      foodItem,
      consumed,
      serving: foodItem.serving_size,
      requiresServingSize,
      mayEditConsumption,
    });

    if (isTrackedItem(food)) {
      this.updateConsumedAmounts(ctx, { consumed });
    }
  }

  @Action(ClearEditingFood)
  clearEditingFood(ctx: StateContext<FoodEditingStateModel>) {
    ctx.setState(defaultState);
  }

  @Action(BeginEditingServing)
  beginEditingServing(ctx: StateContext<FoodEditingStateModel>) {
    ctx.patchState({ editingServing: true });
  }

  @Action(EndEditingServing)
  endEditingServing(ctx: StateContext<FoodEditingStateModel>) {
    ctx.patchState({ editingServing: false });
    return ctx.dispatch(new UpdateConsumedAmounts(ctx.getState()['consumed']));
  }

  @Action(UpdateMacros)
  updateMacros(
    ctx: StateContext<FoodEditingStateModel>,
    { macros }: UpdateMacros,
  ) {
    ctx.setState(
      produce((draft: FoodEditingStateModel) => {
        draft.foodItem.serving_size = {
          ...draft.foodItem.serving_size,
          ...macros,
          calories: calculateQuickCalories(macros),
        };
      }),
    );
  }

  @Action(UpdateCalories)
  updateCalories(
    ctx: StateContext<FoodEditingStateModel>,
    { calories }: UpdateCalories,
  ) {
    ctx.setState(
      produce((draft: FoodEditingStateModel) => {
        draft.foodItem.serving_size.calories = calories;
      }),
    );
  }

  /**
   * This action commits the serving size for a custom food item.
   *
   * This action only matters for foods where we are defining what the custom serving size is. We
   * need to know what the serving size is so that we can calculate it the consumed amount correctly.
   */
  @Action(UpdateServing)
  updateServing(
    ctx: StateContext<FoodEditingStateModel>,
    { servingSize }: UpdateServing,
  ) {
    // We will allow editing the consumption if the serving size is completely set.
    const mayEditConsumption =
      servingSize.amount !== 0 && servingSize.unit !== '';
    // The serving amount has to be non-zero.
    // servingSize.amount = Math.max(servingSize.amount, .01);

    ctx.setState(
      produce((draft: FoodEditingStateModel) => {
        if (draft.usesDefaultServings) {
          draft.consumed.unit = servingSize.unit;
          draft.foodItem.servings = [
            {
              qty: servingSize.amount,
              measure: servingSize.unit,
              serving_weight: 1,
              seq: 0,
            },
          ];
          draft.foodItem.serving_size = {
            ...draft.foodItem.serving_size,
            ...servingSize,
          };
        } else {
          draft.serving = servingSize;
        }
        draft.mayEditConsumption = mayEditConsumption;
      }),
    );
  }

  @Action(UpdateName)
  updateName(ctx: StateContext<FoodEditingStateModel>, { name }: UpdateName) {
    const { foodItem } = ctx.getState();
    ctx.patchState({ foodItem: { ...foodItem, name } });
  }

  @Action(UpdateImage)
  updateImage(
    ctx: StateContext<FoodEditingStateModel>,
    { picture_url }: UpdateImage,
  ) {
    const { foodItem } = ctx.getState();
    ctx.patchState({
      foodItem: { ...foodItem, picture_url, thumbnail: picture_url },
    });
  }

  @Action(UpdateConsumedAmounts)
  updateConsumedAmounts(
    ctx: StateContext<FoodEditingStateModel>,
    { consumed }: UpdateConsumedAmounts,
  ) {
    ctx.patchState({
      consumed,
    });

    if (!consumed.amount || !consumed.unit) {
      return;
    }

    const { foodItem } = ctx.getState();
    const calculatedValues = calculateValues(foodItem, consumed);

    ctx.patchState({
      serving: {
        ...calculatedValues,
        unit: consumed.unit,
        amount: consumed.amount,
      },
    });
  }

  @Action(SetFoodMealNumber)
  setFoodMealNumber(
    ctx: StateContext<FoodEditingStateModel>,
    { meal }: SetFoodMealNumber,
  ) {
    ctx.patchState({
      meal,
    });
  }

  @Action(UpdateFoodMealNumber)
  updateFoodMealNumber(
    ctx: StateContext<FoodEditingStateModel>,
    { meal }: UpdateFoodMealNumber,
  ) {
    ctx.patchState({ meal });
  }
}

export class FoodEditingSelectors {
  static fullFoodEditingState = createPropertySelectors<FoodEditingStateModel>(
    FoodEditingState.fullFoodEditingState,
  );
}
