export enum AnalyticEvents {
  ViewingWorkouts = 'viewing_workouts',
  AddingWeightIn = 'adding_weigh_in',
  LoggingNutrition = 'logging_nutrition',
  MessagingWithTrainer = 'messaging_with_trainer',
  WatchingLiveStream = 'watching_live_stream',
  HelpView = 'help_view',
  WorkoutHelp = 'workout_help',
  SearchingAdvisor = 'searching_advisor',
  DroppingAdvisor = 'dropping_advisor',
  TakingPhotos = 'taking_photos',
  ViewingAnnouncements = 'viewing_announcements',
  UpdatingProfile = 'updating_profile',
  ChangingConfiguration = 'changing_configuration',
  Purchase = 'PURCHASE',
  CompleteRegistration = 'COMPLETE_REGISTRATION',
  CompleteTutorial = 'COMPLETE_TUTORIAL',
}

export enum DetailedAnalyticEvents {
  'viewing_workouts' = 'Viewing Workouts',
  'adding_weigh_in' = 'Adding Weigh In',
  'logging_nutrition' = 'Logging Nutrition',
  'messaging_with_trainer' = 'Messaging with Trainer',
  'watching_live_stream' = 'Watching Live Stream',
  'help_view' = 'Viewing Support',
  'workout_help' = 'Workout Help',
  'searching_advisor' = 'Searching for Advisor',
  'dropping_advisor' = 'Dropping an Advisor',
  'taking_photos' = 'Taking Photos',
  'viewing_announcements' = 'Viewing Announcements',
  'updating_profile' = 'Updating Profile',
  'changing_configuration' = 'Changing Config',
  'PURCHASE' = 'Purchase',
  'COMPLETE_REGISTRATION' = 'Completed Registration',
  'COMPLETE_TUTORIAL' = 'Completed Tutorial',
}
